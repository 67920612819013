import validator from "validator";
import customValidator from "app/utils/validators/customValidator";
import { i18n } from "app/i18n";

const tabs = [{ name: i18n("label.postType") }, { name: i18n("label.slugs") }, { name: i18n("label.categories") }, { name: i18n("label.advanced") }];

const components = [
  {
    tab: 0,
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    onBlur: { key: "key", lower: true },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    tab: 0,
    type: "Text",
    key: "key",
    title: i18n("label.key"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isPostTypeKeyAvailable,
        validWhen: true,
        args: { id: undefined },
        message: i18n("input.key_already_in_use"),
      },
    ],
  },
  {
    tab: 0,
    type: "Select",
    key: "templates",
    title: i18n("label.templates"),
    multiple: true,
    options: [],
  },
  {
    tab: 1,
    type: "Group",
    key: "slugs",
    format: "table",
    title: i18n("label.slugs"),
    children: [],
  },
  {
    tab: 2,
    type: "List",
    title: i18n("label.categories"),
    key: "categories",
    format: "table",
    components: [
      {
        type: "Text",
        key: "title",
        onBlur: { key: "key", lower: true, replacement: "", removeExtra: true },
        title: i18n("label.title"),
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
      {
        type: "Text",
        key: "key",
        title: i18n("label.key"),
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
      {
        type: "Toggle",
        key: "hierarchy",
        title: i18n("label.hierarchy"),
      },
      {
        type: "Toggle",
        key: "multi",
        title: i18n("label.multiple"),
      },
      {
        type: "Toggle",
        key: "icon",
        title: i18n("label.icon"),
      },
    ],
  },
  {
    tab: 3,
    type: "Toggle",
    key: "singlePage",
    title: i18n("label.single_page"),
  },
  {
    tab: 3,
    type: "Toggle",
    key: "hierarchy",
    title: i18n("label.allow_hierarchy"),
  },
  {
    tab: 3,
    type: "Toggle",
    key: "webservice",
    title: i18n("label.allow_webservice"),
  },
  {
    tab: 3,
    type: "Select",
    key: "projects",
    title: i18n("label.projects"),
    multiple: true,
    options: [],
  },
];

export { tabs, components };
