import { useEffect, useState } from "react";

// Atoms
import { Struture, Header, Content, Footer } from "../../../atoms/modal";
import Form from "app/components/organisms/form";

// Helpers
import { validation } from "app/utils/validators";
import { components } from "./constants";
import { i18n } from "app/i18n";
import { useSelector } from "react-redux";

const defaultTypes = [
  { label: "Offer", value: "OFFER" },
  { label: "Experience", value: "EXPERIENCE" },
  { label: "Story", value: "STORY" },
];

export default function ModalRoute(props) {
  const { isOpen = false, item, buttonText = i18n("button.update"), onSubmit, isLoading, onClose = () => {} } = props;

  // PARAMS
  const { language } = useSelector((store) => store.project);

  // STATE
  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(components);

  useEffect(() => {
    if (!isOpen) {
      setValue(null);
      setStructure(components);
    }
    if (isOpen && item) {
      fillForm(item);
    }
  }, [isOpen]);

  const fillForm = (item) => {
    setValue({
      title: item.title,
      description: item.description,
      gallery: item.gallery,
      status: item.status === "PUBLIC",
      elements: item.elements.map(({ element }) => {
        const type = defaultTypes.find((e) => e.value === element.type);
        const translation = element.translations.find((e) => e.language.slug === language.slug);
        return {
          element: {
            type: type,
            element: { label: translation.title, value: element.id },
          },
        };
      }),
    });
  };

  const onClickEnter = (code) => {
    if (code === 13) onClickSubmit();
  };

  const onClickSubmit = () => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) onSubmit(value);
    });
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={item ? i18n("label.edit_route") : i18n("label.create_route")} onClose={onClose} />
      <Content>
        <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} onKeyDown={onClickEnter} />
      </Content>
      <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} />
    </Struture>
  );
}
