import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const components = [
  {
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    onBlur: { key: "slug", lower: true, replacement: "-" },
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Slug",
    key: "slug",
    title: i18n("label.slug"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isElementSlugAvailable,
        validWhen: true,
        args: {
          type: undefined,
          language: undefined,
        },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
  {
    type: "Select",
    key: "content",
    title: i18n("label.copy_from"),
    options: [],
  },
  {
    type: "Toggle",
    key: "auto",
    title: "Translate Content",
  },
];

export { components };
