import { getSource } from "app/utils/content";
import React from "react";

export default function LongTextImage(props) {
  const { text, imagetitle, image, caption, options } = props;

  let styles = {
    box: "flex w-full",
    text: "w-2/3 my-3 pr-5",
    image: "w-1/3 my-3",
  };

  if (options?.inverted) {
    styles.box += " flex-row-reverse";
    styles.text += " pr-0 pl-5";
  }

  return (
    <div className={styles.box}>
      <div className={styles.text}>
        <div className="w-full h-full">
          {text ? (
            <div className="editor-dynamic" dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            <>
              <div className="h-2.5 bg-gray-200 rounded-full w-full mb-2" />
              <div className="h-2.5 bg-gray-200 rounded-full w-full mb-2" />
              <div className="h-1.5 bg-gray-200 rounded-full max-w-[100%] mb-2" />
              <div className="h-1.5 bg-gray-200 rounded-full max-w-[85%] mb-2" />
              <div className="h-1.5 bg-gray-200 rounded-full max-w-[90%] mb-2" />
              <div className="h-1.5 bg-gray-200 rounded-full max-w-[85%] mb-2" />
              <div className="h-1.5 bg-gray-200 rounded-full max-w-[80%] mb-2" />
              <div className="h-1.5 bg-gray-200 rounded-full max-w-[85%] mb-2" />
              <div className="h-1.5 bg-gray-200 rounded-full max-w-[80%] mb-1" />
            </>
          )}
        </div>
      </div>
      <div className={styles.image}>
        <div className="flex items-center justify-center w-full h-40 bg-gray-200 rounded mb-2">
          {image?.path ? (
            <img src={getSource(image.path)} alt="" className="w-full h-full object-cover" />
          ) : (
            <svg className="w-8 h-8 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/3000/svg" fill="currentColor" viewBox="0 0 20 18">
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          )}
        </div>
        {imagetitle && <p className="text-xs font-bold" dangerouslySetInnerHTML={{ __html: imagetitle }} />}
        {caption && <p className="text-xs" dangerouslySetInnerHTML={{ __html: caption }} />}
      </div>
    </div>
  );
}
