import Dropdown from "app/components/molecules/dropdown";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useBecomeSuggestionMutation, useCreateRouteMutation, useGetRoutesQuery, useUpdateRouteMutation } from "app/stores/private";
import { i18n } from "app/i18n";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalRouteDetails from "app/components/molecules/modals/modalRouteDetails";
import ModalRoute from "app/components/molecules/modals/modalRoute";
import { showServerError, showServerSuccess } from "app/utils/server";
import Icon from "app/components/atoms/icons/icon";

export default function Route() {
  // PARAMS
  const { project } = useParams();
  const { language } = useSelector((store) => store.project);
  const { take, skip, where, orderBy } = useSelector((store) => store.private);

  // STATES
  const [modal, setModal] = useState(null);
  const [modalDetails, setModalDetails] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // QUERY
  const { data, refetch, isLoading: isGetting } = useGetRoutesQuery({ take, skip, where, orderBy });

  // MUTATION
  const [createRoute, { isLoading: isCreating }] = useCreateRouteMutation();
  const [updateRoute, { isLoading: isUpdating }] = useUpdateRouteMutation();
  const [becomeSuggestion, { isLoading: isBecoming }] = useBecomeSuggestionMutation();

  // VARS
  const isLoading = isGetting || isBecoming || isCreating || isUpdating;

  const onCreateRoute = () => {
    setModal({
      isOpen: true,
      onSubmit: (data) => {
        createRoute({ ...data }).then((res) => {
          if (res?.data) {
            showServerSuccess(i18n("toast.success_create"));
            refetch();
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  // ON OPEN TO SEE MORE
  const onOpenRoute = (item) => {
    setModalDetails({
      isOpen: true,
      info: item,
      onUpdate: () => {
        onEditRoute(item);
        setModalDetails(null);
      },
      onConvert: item.status === "DRAFT" ? () => onConvertRoute(item) : null,
      onClose: () => setModalDetails(null),
    });
  };

  const onEditRoute = (item) => {
    setModal({
      isOpen: true,
      item: item,
      onSubmit: (data) => {
        updateRoute({ id: item.id, ...data }).then((res) => {
          if (res?.data) {
            showServerSuccess(i18n("toast.success_update"));
            refetch();
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  const onConvertRoute = (item) => {
    setModalConfirm({
      isOpen: true,
      title: "Tornar em rota sugerida",
      text: "Ao confirmar, a rota irá ficar disponível na página principal da plataforma e disponível para todos os utilizadores que a desejem consultar ou guardar.",
      onClose: () => setModalConfirm(null),
      onConfirm: () => {
        becomeSuggestion({ id: item.id });
        refetch();
        setModalConfirm(null);
      },
    });
  };

  const onDeleteRoute = () => {};

  return (
    <div className="max-w-7xl mx-auto pt-20">
      {/* BREADCRUMBS */}
      <div className="mb-3">
        <Link to={`/project/${project}/dashboard`} className="inline-flex items-center gap-2 text-sm text-gray-400 hover:text-primary-500 transition-all duration-200 ease-linear">
          <Icon name="chevronLeft" height="h-4" width="w-4" />
          <span>Back</span>
        </Link>
      </div>

      {/* HEADER */}
      <div className="flex items-center justify-between  mb-10">
        <h1 className="text-2xl font-bold text-slate-900">Routes</h1>
        {isLoading && "loading..."}
        <button type="button" onClick={onCreateRoute} className="btn-primary-outline">
          <Icon name="plus" height="h-4" width="w-4" />
          <span>Add New</span>
        </button>
      </div>

      <div className="overflow-x-auto overflow-y-visible relative h-full pb-16">
        <table className="listTable w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
            <tr className="relative">
              <th scope="col" className="p-6">
                <span>Title</span>
              </th>
              <th scope="col" className="p-6">
                <span>Status</span>
              </th>
              <th scope="col" className="p-6">
                <span>Author</span>
              </th>
              <th scope="col" className="p-6">
                <span>Points</span>
              </th>
              <th></th>
            </tr>
          </thead>
          {!!data?.length ? (
            <tbody className="relative">
              {data.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <Link to={`${item.id}/${language?.slug}`}>
                        <span>{item?.title || "-"}</span>
                      </Link>
                    </td>
                    <td>
                      <span>{item?.status || "-"}</span>
                    </td>
                    <td>
                      <span>{item?.author?.name || "-"}</span>
                    </td>
                    <td>
                      <span>{item?.elements?.length || 0}</span>
                    </td>
                    <td>
                      <Dropdown
                        icon="ellipsis"
                        options={[
                          { title: i18n("button.see"), onClick: () => onOpenRoute(item), icon: "info" },
                          { title: i18n("button.edit"), icon: "edit", onClick: () => onEditRoute(item) },
                          { title: i18n("button.convert_to_suggestion"), onClick: () => onConvertRoute(item), icon: "star", disabled: item.status !== "DRAFT" },
                          // { title: i18n("button.delete"), onClick: () => onDeleteRoute(item), icon: "delete", delete: true },
                        ]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody className="relative">
              <tr>
                <td colSpan={2}>nothing found</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <ModalRoute {...modal} />
      <ModalRouteDetails {...modalDetails} />
      <ModalConfirm {...modalConfirm} isLoading={isLoading} />
    </div>
  );
}
