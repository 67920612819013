import { Link } from "react-router-dom";

export default function PrivateArea() {
  return (
    <div className="max-w-7xl mx-auto pt-20">
      <h1 className="text-3xl font-bold text-slate-900 mb-10">Área de Gestão</h1>
      <div className="grid grid-cols-6 gap-4">
        <Link to="stories" className="col-span-2 p-8 rounded-xl bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">
          <div className="">
            <h2>Histórias</h2>
          </div>
        </Link>
        <Link to="experiences" className="col-span-2 p-8 rounded-xl bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">
          <div className="">
            <h2>Experiências</h2>
          </div>
        </Link>
        <Link to="offers" className="col-span-2 p-8 rounded-xl bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">
          <div className="">
            <h2>Ofertas</h2>
          </div>
        </Link>
        <Link to="partners" className="col-span-3 p-8 rounded-xl bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">
          <div className="">
            <h2>Parceiros</h2>
          </div>
        </Link>
        <Link to="categories" className="col-span-3 p-8 rounded-xl bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">
          <div className="">
            <h2>Categorias</h2>
          </div>
        </Link>
        <hr className="border col-span-6" />
        <Link to="accounts" className="col-span-3 p-8 rounded-xl bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">
          <div className="">
            <h2>Contas</h2>
          </div>
        </Link>
        <Link to="routes" className="col-span-3 p-8 rounded-xl bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">
          <div className="">
            <h2>Rotas</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}
