import React from "react";
import { Link, useParams } from "react-router-dom";

export default function RouteEdit() {
  const { project } = useParams();

  return (
    <div className="max-w-7xl mx-auto pt-20">
      {/* BREADCRUMBS */}
      <div className="mb-3">
        <Link to={`/project/${project}/dashboard/routes`}>
          <button className="bg-slate-200 hover:bg-slate-300 transition-all duration-200 ease-linear">Back</button>
        </Link>
      </div>

      {/* HEADER */}
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold text-slate-900 mb-10">Edit Route</h1>
      </div>
    </div>
  );
}
