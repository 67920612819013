import React from "react";

export default function OnOff(props) {
  const { active } = props;

  const styles = {
    outer: "inline-flex h-3 mr-2 aspect-square rounded-full justify-center items-center",
    inner: "inline-block h-2 aspect-square rounded-full",
  };

  if (active) {
    styles.outer += " bg-green-200";
    styles.inner += " bg-green-500";
  } else {
    styles.outer += " bg-red-200";
    styles.inner += " bg-red-500";
  }

  return (
    <span className={styles.outer}>
      <span className={styles.inner} />
    </span>
  );
}
