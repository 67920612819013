import React, { useEffect, useState } from "react";
import { components } from "./constants";
import Form from "app/components/organisms/form";
import { Content } from "app/components/atoms/modal";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import Dropdown from "app/components/molecules/dropdown";
import { validation } from "app/utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { setUnsavedChanges } from "app/stores/project";
import { useParams } from "react-router-dom";
import ModalRevision from "app/components/molecules/modals/modalRevision";

export default function ElementEdition(props) {
  const { element, isLoading, onSave } = props;

  const dispatch = useDispatch();
  const { lang } = useParams();
  const { unsavedChanges } = useSelector((state) => state.project);

  const [value, setValue] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);
  const [structure, setStructure] = useState(components);
  const [modalRevision, setModalRevision] = useState(null);

  // VARS
  const type = element?.type || undefined;
  const content = element?.translations?.find((item) => item.language.slug === lang);
  const last = content?.revisions?.length ? content.revisions[0] : {};

  useEffect(() => {
    if (element && content) {
      const value = getValue();
      setValue({ ...value });
    }
  }, [element, content]);

  useEffect(() => {
    const isDifferent = JSON.stringify(getValue()) !== JSON.stringify(value);
    setDataChanged(isDifferent);
  }, [content, value]);

  useEffect(() => {
    if (dataChanged !== unsavedChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  const getValue = () => {
    if (!element || !content) return undefined;

    let temp = { ...last?.data };
    if (element?.email) temp.email = element?.email;
    if (element?.banners) temp.banners = element?.banners;
    if (element?.channels) temp.channels = element?.channels;
    if (element?.phonenumber) temp.phonenumber = element?.phonenumber;

    return { ...temp };
  };

  const onSubmitNewContent = (status) => {
    validation(structure, value, (structure, validation) => {
      setStructure([...structure]);
      if (validation.isValid) {
        onSave(undefined, undefined, {
          data: value,
          status: status ? "PUBLIC" : "DRAFT",
        });
        dispatch(setUnsavedChanges(false));
      }
    });
  };

  const onShowRevisions = () => {
    setModalRevision({
      isOpen: true,
      map: true,
      content: content,
      onClose: () => setModalRevision(null),
    });
  };

  const cleanChanges = () => {
    setValue({ ...getValue() });
  };

  return (
    <>
      <div className="flex flex-col flex-1 h-full overflow-hidden rounded-t-md">
        <div className="bg-white w-full overflow-hidden border-b h-12" />
        <div className="bg-white w-full flex-1 rounded-b-md overflow-hidden flex flex-col post-editor shadow-lg relative">
          <div className="flex-1 overflow-auto">
            <Content>
              <Form value={value} structure={structure.filter((e) => e.datatype.includes(type))} onChange={setValue} />
            </Content>
          </div>
          <div className="border-t py-4 px-6 flex justify-between">
            <Dropdown
              position="top-left"
              options={[
                { title: i18n("label.revisions"), onClick: onShowRevisions, icon: "revision" },
                { title: i18n("button.clean_changes"), onClick: cleanChanges, icon: "rollback", delete: true },
              ]}
            />
            <div className="flex">
              <button
                type="button"
                disabled={!dataChanged || isLoading}
                onClick={() => onSubmitNewContent(false)}
                className="flex mr-1.5 items-center relative justify-center rounded-md border border-blue-600 bg-transparent px-4 py-2 text-sm font-medium text-blue-600 shadow-sm hover:bg-blue-500 hover:border-blue-500 hover:text-white focus:outline-none  disabled:text-blue-200 disabled:border-blue-200 disabled:hover:bg-white disabled:hover:border-blue-200 disabled:cursor-not-allowed"
              >
                {i18n("button.save_as_draft")}
              </button>
              <Permission project dev min="EDITOR">
                <button
                  type="button"
                  disabled={!dataChanged || isLoading}
                  className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
                  onClick={() => onSubmitNewContent(true)}
                >
                  {i18n("button.publish")}
                </button>
              </Permission>
            </div>
          </div>
        </div>
      </div>

      <ModalRevision {...modalRevision} />
    </>
  );
}
