import ElementEdition from "app/components/organisms/editor/element/edition";
import ElementHeader from "app/components/organisms/editor/element/header";
import ElementSidebar from "app/components/organisms/editor/element/sidebar";
import { i18n } from "app/i18n";
import { useGetExperienceQuery, useUpdateExperienceMutation } from "app/stores/private";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useParams } from "react-router-dom";

export default function ExperienceEdit() {
  // PARAMS
  const { id, lang } = useParams();

  // QUERY
  const { data, isLoading: isGetting, refetch } = useGetExperienceQuery({ id });

  // MUTATION
  const [updateElement, { isLoading: isUpdating }] = useUpdateExperienceMutation();

  // VARS
  const isLoading = isGetting || isUpdating;
  const content = lang && data?.translations.find((e) => e.language.slug === lang);

  const onSaveElement = (content, element, revision) => {
    if (content) delete content.id;
    if (content) delete content.publishedDate;

    let values = {
      latitude: element?.coords?.latitude || data?.latitude,
      longitude: element?.coords?.longitude || data?.longitude,
      district: element?.coords?.district || data?.district?.name, // DISTRITO
      city: element?.coords?.city || data?.place?.city?.name, // CONCELHO
      place: element?.coords?.place || data?.place?.name, // FREGUESIA
      duration: element?.duration || undefined,
      image: undefined,
      video: undefined,
      categories: undefined,
      content: content || undefined,
      revision: revision || undefined,
    };

    // IMAGE
    if (element?.image) {
      // CONNECT TO FILE
      if (element?.image?.id) values.image = { connect: { id: element?.image?.id } };
      // IF DISCONNECT
      if (element?.image?.disconnect) values.image = { disconnect: true };
    }

    // VIDEO
    if (element?.video) {
      // CONNECT TO FILE
      if (element?.video?.id) values.video = { connect: { id: element?.video?.id } };
      // IF DISCONNECT
      if (element?.video?.disconnect) values.video = { disconnect: true };
    }

    // PARTNER
    if (element?.partner) {
      if (element?.partner?.value) values.partner = { connect: { id: element?.partner?.value } };
      // IF DISCONNECT
    }

    // CATEGORIES
    if (element?.categories) {
      values.categories = { connect: element.categories.map((e) => ({ id: e.value })) };
    }

    updateElement({ id: data.id, language: lang, data: values }).then((res) => {
      if (res?.data) {
        showServerSuccess(i18n("toast.success_update"));
        refetch();
      } else {
        showServerError(res);
      }
    });
  };

  return (
    <>
      <div className="h-full flex flex-col relative">
        {/* HEADER */}
        <ElementHeader element={data} content={content} onSave={onSaveElement} />

        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="h-12 bg-blue-900" />
          <div className="-mt-12 custom-container flex-1 h-full items-start overflow-hidden flex flex-row gap-6">
            {/* EDITION */}
            <ElementEdition element={data} loading={isLoading} onSave={onSaveElement} />

            {/* SIDEBAR */}
            <ElementSidebar element={data} loading={isLoading} onSave={onSaveElement} whenTranslateSuccedded={refetch} />
          </div>
          <div className="h-6" />
        </div>
      </div>
    </>
  );
}
