import React, { useState } from "react";
import ModalIcon from "app/components/molecules/modals/modalIcon";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/iconFA";
import { i18n } from "app/i18n";

export default function SelectIcon(props) {
  const { title, description, value, error, isRequired, onChange } = props;

  let styles = {
    div: "block relative mb-3",
    title: "block text-sm font-medium text-slate-700 ",
    description: "block text-xs font-light text-slate-400 -mt-0.5 mb-1",
  };

  if (error) styles.title += " text-red-600";

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const onSelectIcon = (val) => {
    if (val !== value) onChange(val);
  };

  return (
    <div className={styles.div}>
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className="flex items-center">
        <div className="h-10 w-14 border border-r-0 flex items-center justify-center rounded-l-lg">
          {value ? <Icon name={value} width={5} height={5} /> : <span className="text-xs text-gray-300">-</span>}
        </div>
        <button type="button" onClick={onOpen} className="h-10 px-4 bg-gray-50 border border-gray-300 rounded-r-lg text-sm">
          {i18n("button.change")}
        </button>
      </div>

      <ModalIcon selected={value} isOpen={isOpen} onClose={onClose} onSubmit={onSelectIcon} />
    </div>
  );
}
