import { i18n } from "app/i18n";
import customValidator from "app/utils/validators/customValidator";
import validator from "validator";

const postStructure = [
  {
    type: "Text",
    key: "title",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Toggle",
    key: "published",
    rules: [
      {
        method: customValidator.isUndefinedOrNull,
        validWhen: false,
        message: i18n("input.invalid_format"),
      },
    ],
  },
  {
    type: "Slug",
    key: "slug",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: validator.isSlug,
        validWhen: true,
        message: i18n("input.invalid_format"),
      },
      {
        method: customValidator.isPageSlugAvailable,
        validWhen: true,
        args: {
          language: undefined,
          type: undefined,
          project: undefined,
          id: undefined,
        },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
  {
    type: "Date",
    key: "publishedDate",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: customValidator.isValidDate,
        validWhen: true,
        message: i18n("input.invalid_date_format"),
      },
    ],
  },
];

const blockStructure = [
  {
    type: "Text",
    key: "title",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Toggle",
    key: "published",
    rules: [
      {
        method: customValidator.isUndefinedOrNull,
        validWhen: false,
        message: i18n("input.invalid_format"),
      },
    ],
  },
  {
    type: "Slug",
    key: "slug",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: validator.isSlug,
        validWhen: true,
        message: i18n("input.invalid_format"),
      },
      {
        method: customValidator.isBlockKeyAvailable,
        validWhen: true,
        args: {
          language: undefined,
          project: undefined,
          id: undefined,
        },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
];

const formStructure = [
  {
    type: "Text",
    key: "title",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Toggle",
    key: "published",
    rules: [
      {
        method: customValidator.isUndefinedOrNull,
        validWhen: false,
        message: i18n("input.invalid_format"),
      },
    ],
  },
];

const elementStructure = [
  {
    type: "Text",
    key: "title",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Toggle",
    key: "published",
    rules: [
      {
        method: customValidator.isUndefinedOrNull,
        validWhen: false,
        message: i18n("input.invalid_format"),
      },
    ],
  },
  {
    type: "Slug",
    key: "slug",
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
      {
        method: validator.isSlug,
        validWhen: true,
        message: i18n("input.invalid_format"),
      },
      {
        method: customValidator.isElementSlugAvailable,
        validWhen: true,
        args: {
          id: undefined,
          type: undefined,
          language: undefined,
        },
        message: i18n("input.slug_in_use"),
      },
    ],
  },
];

export { postStructure, blockStructure, formStructure, elementStructure };
