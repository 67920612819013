import { getSource } from "app/utils/content";
import React from "react";

export default function Image(props) {
  const { image, title, caption, options } = props;

  const styles = {
    box: "flex flex-col justify-center w-full",
  };

  if (!options?.fullWidth) {
    styles.box += " max-w-[600px] mx-auto";
  }

  return (
    <div className={styles.box}>
      <div className="flex items-center justify-center w-full h-56 bg-gray-200 rounded my-3 overflow-hidden">
        <svg className="w-8 h-8 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/3000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
      {title && <p className="text-xs font-bold" dangerouslySetInnerHTML={{ __html: title }} />}
      {caption && <p className="text-xs" dangerouslySetInnerHTML={{ __html: caption }} />}
    </div>
  );
}
