import Icon from "app/components/atoms/icons/icon";
import React from "react";
import { useSelector } from "react-redux";

export default function ItemCategory(props) {
  const { id, slug, translations, icon, son, onEdit } = props;

  // STORE
  const { language, languages } = useSelector((state) => state.project);

  // GET TITLE
  const getOptionTitle = (translations) => {
    if (!translations?.length) return "";
    const translation = translations.find((e) => e.language.slug === language?.slug);
    if (translation) return translation.value;
    const translationDefault = translations.find((e) => e.language.slug === languages?.find((e) => e.default)?.language?.slug);
    if (translationDefault) return translationDefault.value;
    return "";
  };

  return (
    <div key={id} className="flex flex-col">
      <div className="flex w-full px-3 py-1 bg-gray-200 justify-between items-center rounded-md overflow-hidden text-sm cursor-pointer transition-all duration-300 hover:bg-gray-300">
        <span>{getOptionTitle(translations)}</span>
        <div>
          {onEdit && (
            <button type="button" className="btn-icon" onClick={() => onEdit({ id, icon, key: slug, translations })}>
              <Icon name="edit" />
            </button>
          )}
        </div>
      </div>
      {/* {!son && (
        <div className="pl-3 pt-1 mb-1 flex flex-col gap-1">
          <ItemCategory {...props} slug={item.key} onEdit={onEdit} son={true} />
          <ItemCategory {...props} slug={item.key} onEdit={onEdit} son={true} />
        </div>
      )} */}
    </div>
  );
}
