import { EyeIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import QuickEdition from "app/components/organisms/quickEdition";
import { i18n } from "app/i18n";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const typeSlugs = {
  STORY: "stories",
  EXPERIENCE: "experiences",
  PARTNER: "partners",
  OFFER: "offers",
};

export default function ElementHeader(props) {
  const { content, element, onSave } = props;

  const { project: idProject } = useParams();

  // STORE
  const { project } = useSelector((store) => store.project);

  // STATES
  const [editing, setEditing] = useState(false);

  // VARS
  const key = element?.type;
  const loading = false;

  const onSaveChanges = (e) => {
    onSave(e);
    setEditing(false);
  };

  return (
    <div className="bg-blue-900 text-white">
      <div className="custom-container" id="header-edition">
        <div className="pt-8 mb-2">
          <Link to={`/project/${idProject}/dashboard/${typeSlugs[key]}`} className="inline-flex items-center gap-2 text-sm text-gray-400 hover:text-white transition-all duration-200 ease-linear">
            <Icon name="chevronLeft" height="h-4" width="w-4" />
            <span>Back</span>
          </Link>
        </div>
        <div className="inputs-box pb-8 md:flex md:items-center lg:justify-between">
          <div className="min-w-0">
            <QuickEdition {...props} active={editing} loading={loading} onToggle={setEditing} onSave={onSaveChanges} />
          </div>
          <div className="mt-5 flex lg:mt-0 lg:ml-4">
            <div className="flex gap-3">
              {/* OPEN PAGE IN NEW TAB */}
              {!!project?.url && content?.published && (
                <a href={`${project.url}${content.path}`} target="_black" rel="noopener noreferrer">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-gray-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 !outline-none transition-all duration-500 ease-in-out !ring-0"
                  >
                    <EyeIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                    {i18n("button.see_page")}
                  </button>
                </a>
              )}
              {/* PREVIEW */}
              {/* <Preview /> */}

              {/* REBUILD */}
              {/* <Rebuild /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
