import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Accordion from "app/components/molecules/accordion";
import ModalForm from "app/components/molecules/modals/modalForm";
import Permission from "app/components/molecules/permission";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { useCreateFormTranslationMutation, useUpdateFormMutation } from "app/stores/forms";
import { setUnsavedChanges } from "app/stores/project";
import { cleanStructure } from "app/utils/content";
import { getFlag } from "app/utils/languages";
import { hasPermissionProject } from "app/utils/roles";
import { showServerError, showServerSuccess } from "app/utils/server";
import { validation } from "app/utils/validators";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { components, tabs } from "./constants";

export default function FormSidebar(props) {
  // PARAMS
  const { loading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project: idProject, lang } = useParams();

  // STORE
  const { user } = useSelector((state) => state.auth);
  const { form } = useSelector((state) => state.form);
  const { project, sidebarChanges, languages } = useSelector((state) => state.project);

  // STATE
  const [value, setValue] = useState(null);
  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  // ACTIONS
  const [updateForm, { isLoading: isUpdating }] = useUpdateFormMutation();
  const [createFormTranslation] = useCreateFormTranslationMutation();

  // VARIABLES
  const content = form?.translations?.find((item) => item.language.slug === lang);
  const isLoading = loading || !content || isUpdating;

  useEffect(() => {
    buildStructure();
    if (form) {
      const vals = getValue();
      if (vals) setValue({ ...vals });
    }
  }, [form, project, components]);

  useEffect(() => {
    setDataChanged(isDataDifferent());
  }, [value]);

  useEffect(() => {
    if (dataChanged !== sidebarChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
  }, [dataChanged]);

  // APPLY INPUT FOR RECIPIENTS WITH DISABLE
  const buildStructure = () => {
    if (!project || !form) return null;

    const temp = cleanStructure(components);
    if (!hasPermissionProject(user, project, "EDITOR", true)) {
      const indexTab = temp.findIndex((e) => e.key === "settings");
      if (indexTab !== -1) {
        const index = temp[indexTab].structure.findIndex((e) => e.key === "recipients");
        if (index !== -1) temp[indexTab].structure[index].disabled = true;
      }
    }
    setStructure([...temp]);
  };

  // FILL FORM WITH VALUES
  const getValue = () => {
    if (!content?.email) return null;
    const temp = {};
    if (content.email?.recipients?.length) {
      temp.recipients = content.email.recipients.map((e) => ({ email: e }));
    }
    return temp;
  };

  const isDataDifferent = () => {
    if (isLoading) return false;
    return JSON.stringify(getValue()) !== JSON.stringify(value);
  };

  const submitChanges = () => {
    let dataStructure = null;
    if (structure?.length) dataStructure = cleanStructure(structure[0].structure);

    validation(dataStructure, value, (newStructure, validation) => {
      const tempStructure = structure;
      if (tempStructure?.length) tempStructure[0].structure = newStructure;
      setStructure([...tempStructure]);
      if (validation.isValid) {
        if (value?.recipients?.length) {
          updateForm({ id: content.id, recipients: value.recipients.map((e) => e.email) }).then((res) => {
            if (res?.data) showServerSuccess(i18n("toast.settings_updated"));
            else showServerError(res);
          });
        }
      }
    });
  };

  const onCreateTranslation = (language) => {
    const availableTranslations = languages
      .filter((e) => form.translations.find((content) => content.language.slug === e.language.slug))
      .map((e) => {
        const title = form.translations.find((content) => content.language.slug === e.language.slug).title;
        return { value: e.language.id, label: `<span className="mr-1">${getFlag(e.language.slug)}</span> ${title}` };
      });

    setModal({
      isOpen: true,
      duplicate: true,
      availableTranslations,
      onSubmit: ({ title, copy, language: origin, translate }) => {
        createFormTranslation({
          form: form.id,
          language: language.id,
          title,
          copy,
          origin: origin.value,
          translate,
        }).then((res) => {
          if (!res.error && res.data) {
            navigate(`/project/${project.id}/forms/${res.data.id}/${language.slug}`);
            showServerSuccess(i18n("toast.success_duplicate"));
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  return (
    <>
      <div className="h-full">
        <div className="bg-white min-w-[336px] w-[336px] rounded-t-md border-b h-12" />
        <div className="bg-white flex flex-col justify-between rounded-b-xl xl:rounded-b-md rounded-t-xl xl:rounded-t-none w-full md:min-w-[336px] xl:w-[336px] shadow-lg pb-3 max-h-full overflow-hidden">
          <div className="relative z-10">
            <Accordion title={i18n("label.translations")} disabled defaultOpen={true}>
              {!!languages?.length && (
                <div>
                  <ul className="p-5">
                    {languages.map(({ language }, index) => {
                      const exists = form?.translations?.length ? form.translations.find((e) => e.language.slug === language.slug) : null;
                      return (
                        <li key={index} className="flex items-center justify-between py-3 border-b last:border-b-0">
                          {exists && content ? (
                            <>
                              {content.id === exists.id ? (
                                <>
                                  <span className="text-sm text-slate-900">
                                    <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <Link to={`/project/${idProject}/forms/${form.id}/${language.slug}`}>
                                    <span className="text-sm">
                                      <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                    </span>
                                  </Link>
                                  <Link to={`/project/${idProject}/forms/${form.id}/${language.slug}`}>
                                    <PencilIcon className="w-5" />
                                  </Link>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <span className="text-sm text-gray-400">
                                <span className="mr-1">{getFlag(language.slug)}</span> {language.name}
                              </span>
                              <Permission project dev min="MANAGER">
                                <button type="button" onClick={() => onCreateTranslation(language)}>
                                  <PlusIcon className="w-5" />
                                </button>
                              </Permission>
                            </>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </Accordion>
            {tabs.map((item, index) => {
              const inputs = structure?.find((e) => e.key === item.key);
              if (!inputs || !inputs.structure || !inputs.structure.length) {
                return null;
              }
              return (
                <Accordion key={item.key} title={item.title} className={`relative z-${(tabs.length - index) * 10}`} disabled defaultOpen={true}>
                  {isLoading ? (
                    <div role="status" className="max-w-sm animate-pulse pt-7 pb-5">
                      <div className="h-2.5 bg-slate-300 rounded-full w-48 mb-4"></div>
                      <div className="h-2 bg-slate-300 rounded-full max-w-[360px] mb-2.5"></div>
                      <div className="h-2 bg-slate-300 rounded-full mb-2.5"></div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div className={!index ? "px-5 py-3" : ""}>
                      <Form structure={inputs.structure} onChange={setValue} value={value} />
                    </div>
                  )}
                </Accordion>
              );
            })}
          </div>
          {dataChanged ? (
            <div className="flex justify-end pr-10 pt-4">
              <button
                type="button"
                disabled={isLoading}
                onClick={submitChanges}
                className="flex my-3 items-center relative justify-center rounded-md border border-transparent bg-blue-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none  disabled:bg-gray-300 disabled:pointer-events-none transition-all ease-in-out duration-300"
              >
                {i18n("button.save")}
              </button>
            </div>
          ) : (
            <div className="pb-7" />
          )}
        </div>
      </div>

      <ModalForm {...modal} isLoading={isLoading} />
    </>
  );
}
