import React from "react";

export default function LongText(props) {
  const { text, options } = props;

  let styles = {
    box: "flex w-full",
    text: "w-full max-w-md my-3 pr-5",
  };

  if (options?.alignment?.value === "left") styles.box += " justify-start";
  if (options?.alignment?.value === "center") styles.box += " justify-center";
  if (options?.alignment?.value === "right") styles.box += " justify-end";

  if (options?.alignment?.value === "full") styles.text = "w-full my-3 pr-5";

  return (
    <div className={styles.box}>
      <div className={styles.text}>
        {text ? (
          <div className="editor-dynamic" dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <>
            <div className="h-1.5 bg-gray-200 rounded-full max-w-[100%] mb-2" />
            <div className="h-1.5 bg-gray-200 rounded-full max-w-[85%] mb-2" />
            <div className="h-1.5 bg-gray-200 rounded-full max-w-[90%] mb-2" />
            <div className="h-1.5 bg-gray-200 rounded-full max-w-[85%] mb-2" />
            <div className="h-1.5 bg-gray-200 rounded-full max-w-[80%] mb-2" />
            <div className="h-1.5 bg-gray-200 rounded-full max-w-[85%] mb-2" />
            <div className="h-1.5 bg-gray-200 rounded-full max-w-[80%] mb-1" />
          </>
        )}
      </div>
    </div>
  );
}
