import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import Form from "app/components/organisms/form";
import { memo } from "react";
import Draggable from "../draggable";
import BlockHeader from "./header";

const Block = memo(function (props) {
  const { name, value, structure, errors, disabled, inline, onChange, onLabelChange, duplicate, remove, onChangeOrder, dragActive } = props;

  return (
    <>
      {dragActive ? (
        <Draggable accept={name} value={value} onChange={onChangeOrder} remove={remove} duplicate={duplicate} errors={errors} />
      ) : (
        <Accordion allowMultiple>
          {value.map((item, key) => (
            <AccordionItem
              key={key}
              header={({ state }) => (
                <BlockHeader
                  {...state}
                  {...item}
                  remove={() => remove(key)}
                  duplicate={() => duplicate(key)}
                  labelChange={(v) => onLabelChange(key, v)}
                  error={errors && errors[key] && Object.keys(errors[key]).length > 0}
                />
              )}
            >
              <Form value={{ ...item }} onChange={(v) => onChange(key, v)} structure={structure} disabled={disabled} errors={errors ? errors[key] : undefined} />
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </>
  );
});
export default Block;
