import { i18n } from "app/i18n";
import { useState } from "react";
import Dropzone from "react-dropzone";
import Icon from "../../icons/icon";
import Loading from "../../loading";

export default function DropzoneInput(props) {
  const { error, formats, multi, isLoading, disabled, errorMessage, onChange } = props;

  const [inputErrors, setInputErrors] = useState(null);

  const fileTypes = {
    IMAGE: {
      jpg: "image/jpeg",
      png: "image/png",
      gif: "image/gif",
      webp: "image/webp",
      svg: "image/svg+xml",
    },
    FILE: {
      pdf: "application/pdf",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      txt: "text/plain",
      zip: "application/zip",
      rar: "application/x-rar-compressed",
      tar: "application/x-tar",
      "7z": "application/x-7z-compressed",
    },
    VIDEO: {
      mp4: "video/mp4",
      avi: "video/x-msvideo",
      mov: "video/quicktime",
      mkv: "video/x-matroska",
      wmv: "video/x-ms-wmv",
    },
  };

  const buildString = (formats, getKey, getValue) => {
    function join(array) {
      if (array.length === 0) {
        return "";
      } else if (array.length === 1) {
        return array[0];
      } else if (array.length === 2) {
        return array.join(" " + i18n("label.or") + " ");
      } else {
        const lastElement = array.pop();
        return array.join(", ") + " " + i18n("label.or") + " " + lastElement;
      }
    }

    let value = "";
    if (formats?.length) {
      for (let i = 0; i < formats.length; i++) {
        const key = formats[i];

        if (fileTypes[key]) {
          if (getKey) {
            value += join(Object.keys(fileTypes[key])) + " ";
          } else if (getValue) {
            const keys = Object.keys(fileTypes[key]);
            if (keys?.length) {
              if (!value) value = {};
              for (let y = 0; y < keys.length; y++) {
                value[fileTypes[key][keys[y]]] = [];
              }
            }
          }
        }
      }
    }
    return value;
  };

  function getAvailableExtensions() {
    let response = "";
    if (formats?.length) response = buildString(formats, true);
    else if (typeof formats === "string") response = buildString([formats], true);
    else response = i18n("label.dropzone_allavailable");
    return response;
  }

  function getAvailableMimetypes() {
    let response = "";
    if (formats?.length) response = buildString(formats, false, true);
    else if (typeof formats === "string") response = buildString([formats], false, true);
    else response = undefined;
    return response;
  }

  function onError(ev) {
    setInputErrors(null);
    if (ev instanceof TypeError) {
      if (ev.message.indexOf("validity") != -1) setInputErrors(i18n("error.file_invalid"));
      else console.log(ev);
    }
  }

  return (
    <>
      <Dropzone multiple={multi} onDrop={(files) => onChange(files, true)} onError={onError} disabled={disabled} accept={getAvailableMimetypes()}>
        {({ getRootProps, getInputProps, isDragActive, isDragAccept }) => (
          <div
            {...getRootProps()}
            className={`dropzone flex flex-col justify-center items-center w-full text-gray-400 h-72 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer transition-all ease-in-out duration-300 ${
              isDragActive ? " isDragActive" : ""
            }${isDragAccept ? " isDragAccept" : ""}`}
          >
            <input {...getInputProps()} accept={getAvailableMimetypes()} id="input-upload-media" />
            <div className="flex flex-col justify-center items-center pt-5 pb-6 pointer-events-none">
              <div className="mb-3">{isLoading ? <Loading active size="w-14 h-14" /> : <Icon name="upload" height="h-14" width="w-14" />}</div>

              <p className="mb-2 text-sm">
                <span className="font-semibold">{i18n("label.click_upload")}</span> {i18n("label.or_drag_and_drop")}
              </p>
              <p className="text-xs uppercase">{getAvailableExtensions()}</p>
              {error && !inputErrors && <p className="mt-2 text-xs text-red-600">{errorMessage}</p>}
              {!error && inputErrors && <p className="mt-2 text-xs text-red-600">{inputErrors}</p>}
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
}
