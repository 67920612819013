import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "app/utils/server";
import { addPostsToStore } from "app/utils/visual";

const initialState = {
  types: [],
  categories: [],
  posts: [],
  elements: [],
};

export const inputsSlice = createSlice({
  name: "inputs",
  initialState,
  reducers: {
    setPostTypes: (state, { payload }) => {
      state.types = payload;
    },
    setPosts: (state, { payload }) => {
      state.posts = payload;
    },
    setElements: (state, { payload }) => {
      state.elements = payload;
    },
  },
});

export const inputsApi = createApi({
  reducerPath: "inputAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getInputPosts: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: ContentWhere, $orderBy: ContentOrderBy) {
            contents(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              title
              post {
                id
                postType {
                  id
                }
              }
              language {
                slug
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.contents.map((e) => ({ id: e.post.id, title: e.title, language: e.language.slug, posttype: e.post.postType.id })),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { inputs } = getState();
          addPostsToStore(inputs?.posts, data, inputsSlice, dispatch);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getDynamicSelection: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: ContentWhere, $orderBy: ContentOrderBy) {
            contents(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              url
              title
              excerpt
              thumbnail {
                href
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.contents,
    }),
    getElements: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query ($take: Int, $skip: Int, $where: MapElementWhere, $orderBy: MapElementOrderBy) {
            elements(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              translations {
                title
                language {
                  slug
                }
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.elements,
    }),
    getTypeCategories: builder.query({
      query: ({ id }) => ({
        document: gql`
          query postType($id: String!) {
            postType(id: $id) {
              id
              categories {
                id
                title
                options {
                  id
                  translations {
                    value
                    language {
                      slug
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.postType.categories,
    }),
  }),
});

export const { setPostTypes, setElements, setPosts } = inputsSlice.actions;

export const { useGetInputPostsQuery, useGetDynamicSelectionQuery, useGetElementsQuery, useGetTypeCategoriesQuery } = inputsApi;

export default inputsSlice.reducer;
