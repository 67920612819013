import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import projectsState, { projectsApi } from "./stores/projects";
import authState, { authApi } from "./stores/auth";
import usersState, { usersApi } from "./stores/users";
import fileState, { filesApi } from "./stores/files";
import environmentState, { environmentsApi } from "./stores/environment";
import builderState, { buildersApi } from "./stores/builder";
import { languagesApi } from "./stores/languages";
import projectState, { projectApi } from "./stores/project";
import postState, { postApi } from "./stores/post";
import blockState, { blockApi } from "./stores/block";
import inputState, { inputsApi } from "./stores/inputs";
import formState, { formsApi } from "./stores/forms";
import translationState, { translationsApi } from "./stores/translations";
import privateState, { privateApi } from "./stores/private";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [projectsApi.reducerPath]: projectsApi.reducer,
    [languagesApi.reducerPath]: languagesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [buildersApi.reducerPath]: buildersApi.reducer,
    [environmentsApi.reducerPath]: environmentsApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [blockApi.reducerPath]: blockApi.reducer,
    [inputsApi.reducerPath]: inputsApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [translationsApi.reducerPath]: translationsApi.reducer,
    [privateApi.reducerPath]: privateApi.reducer,

    builder: builderState,
    projects: projectsState,
    auth: authState,
    user: usersState,
    file: fileState,
    environments: environmentState,
    project: projectState,
    post: postState,
    block: blockState,
    inputs: inputState,
    form: formState,
    translations: translationState,
    private: privateState,
  },
  devTools: process.env.NODE_ENV === "development",

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(projectsApi.middleware)
      .concat(languagesApi.middleware)
      .concat(authApi.middleware)
      .concat(usersApi.middleware)
      .concat(filesApi.middleware)
      .concat(buildersApi.middleware)
      .concat(environmentsApi.middleware)
      .concat(projectApi.middleware)
      .concat(postApi.middleware)
      .concat(inputsApi.middleware)
      .concat(blockApi.middleware)
      .concat(formsApi.middleware)
      .concat(translationsApi.middleware)
      .concat(privateApi.middleware),
});

setupListeners(store.dispatch);
