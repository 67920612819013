import IconBell from "../IconBell";
import IconForbidden from "../IconForbidden";
import IconAlert from "../iconAlert";
import IconBlock from "../iconBlock";
import IconCalendar from "../iconCalendar";
import IconCheck from "../iconCheck";
import IconChevron from "../iconChevron";
import IconClean from "../iconClean";
import IconComment from "../iconComment";
import IconDelete from "../iconDelete";
import IconDesign from "../iconDesign";
import IconDocument from "../iconDocument";
import IconDownload from "../iconDownload";
import IconDuplicate from "../iconDuplicate";
import IconEdit from "../iconEdit";
import IconEllipsis from "../iconEllipsis";
import IconEmail from "../iconEmail";
import IconExpand from "../iconExpand";
import IconExport from "../iconExport";
import IconForm from "../iconForm";
import IconGlobe from "../iconGlobe";
import IconGrid from "../iconGrid";
import IconHouse from "../iconHouse";
import IconImport from "../iconImport";
import IconInfo from "../iconInfo";
import IconKey from "../iconKey";
import IconList from "../iconList";
import IconLock from "../iconLock";
import IconLogout from "../iconLogout";
import IconMedia from "../iconMedia";
import IconMenu from "../iconMenu";
import IconMinus from "../iconMinus";
import IconModule from "../iconModule";
import IconOrder from "../iconOrder";
import IconPencil from "../iconPencil";
import IconPlay from "../iconPlay";
import IconPlus from "../iconPlus";
import IconRevision from "../iconRevisions";
import IconSettings from "../iconSettings";
import IconShare from "../iconShare";
import IconSitemap from "../iconSitemap";
import IconStar from "../iconStar";
import IconUpload from "../iconUpload";
import IconUser from "../iconUser";
import IconUsers from "../iconUsers";
import IconUturn from "../iconUturn";
import IconX from "../iconX";
import IconFA from "../iconFA";

export default function Icon(props) {
  const { name, outline, width = "w-5", height = "h-5", strokeWidth, fa, className } = props;

  function showIcon() {
    switch (name) {
      case "media":
        return <IconMedia aria-hidden="true" width={width} height={height} className={className} outline={outline} />;
      case "play":
        return <IconPlay aria-hidden="true" width={width} height={height} className={className} outline={outline} />;
      case "home":
      case "house":
        return <IconHouse aria-hidden="true" width={width} height={height} className={className} outline={outline} />;
      case "design":
        return <IconDesign aria-hidden="true" width={width} height={height} className={className} outline={outline} />;
      case "block":
        return <IconBlock aria-hidden="true" width={width} height={height} className={className} outline={outline} />;
      case "unblock":
        return <IconBlock aria-hidden="true" width={width} height={height} className={className} outline={outline} open={true} />;
      case "ellipsis":
        return <IconEllipsis aria-hidden="true" width={width} height={height} className={className} />;
      case "import":
        return <IconImport aria-hidden="true" width={width} height={height} className={className} />;
      case "expand":
        return <IconExpand aria-hidden="true" width={width} height={height} className={className} />;
      case "export":
        return <IconExport aria-hidden="true" width={width} height={height} className={className} />;
      case "alert":
        return <IconAlert aria-hidden="true" width={width} height={height} className={className} />;
      case "comment":
        return <IconComment aria-hidden="true" width={width} height={height} className={className} />;
      case "forbidden":
        return <IconForbidden aria-hidden="true" width={width} height={height} strokeWidth={strokeWidth} className={className} />;
      case "lock":
        return <IconLock aria-hidden="true" width={width} height={height} className={className} />;
      case "unlock":
        return <IconLock aria-hidden="true" width={width} height={height} className={className} open={true} />;
      case "email":
        return <IconEmail aria-hidden="true" width={width} height={height} className={className} />;
      case "user":
        return <IconUser aria-hidden="true" width={width} height={height} strokeWidth={strokeWidth} className={className} />;
      case "users":
        return <IconUsers aria-hidden="true" width={width} height={height} className={className} />;
      case "module":
        return <IconModule aria-hidden="true" width={width} height={height} className={className} />;
      case "logout":
        return <IconLogout aria-hidden="true" width={width} height={height} className={className} />;
      case "check":
        return <IconCheck aria-hidden="true" width={width} height={height} className={className} />;
      case "key":
        return <IconKey aria-hidden="true" width={width} height={height} className={className} />;
      case "download":
        return <IconDownload aria-hidden="true" width={width} height={height} className={className} />;
      case "share":
        return <IconShare aria-hidden="true" width={width} height={height} className={className} />;
      case "copy":
      case "duplicate":
        return <IconDuplicate aria-hidden="true" width={width} height={height} className={className} />;
      case "edit":
        return <IconEdit aria-hidden="true" width={width} height={height} className={className} />;
      case "plus":
      case "add":
        return <IconPlus aria-hidden="true" width={width} height={height} className={className} />;
      case "minus":
        return <IconMinus aria-hidden="true" width={width} height={height} className={className} />;
      case "delete":
        return <IconDelete aria-hidden="true" width={width} height={height} className={className} />;
      case "info":
        return <IconInfo aria-hidden="true" width={width} height={height} className={className} />;
      case "upload":
        return <IconUpload aria-hidden="true" width={width} height={height} className={className} />;
      case "order":
        return <IconOrder aria-hidden="true" width={width} height={height} className={className} />;
      case "star":
        return <IconStar aria-hidden="true" width={width} height={height} className={className} />;
      case "menu":
        return <IconMenu aria-hidden="true" width={width} height={height} className={className} />;
      case "form":
        return <IconForm aria-hidden="true" width={width} height={height} className={className} />;
      case "globe":
      case "link":
        return <IconGlobe aria-hidden="true" width={width} height={height} className={className} />;
      case "clean":
        return <IconClean aria-hidden="true" width={width} height={height} className={className} />;
      case "revision":
        return <IconRevision aria-hidden="true" width={width} height={height} className={className} />;
      case "undo":
      case "rollback":
        return <IconUturn prev aria-hidden="true" width={width} height={height} className={className} />;
      case "redo":
        return <IconUturn next aria-hidden="true" width={width} height={height} className={className} />;
      case "chevronDown":
        return <IconChevron down aria-hidden="true" width={width} height={height} className={className} />;
      case "chevronUp":
        return <IconChevron up aria-hidden="true" width={width} height={height} className={className} />;
      case "chevronRight":
        return <IconChevron right aria-hidden="true" width={width} height={height} className={className} />;
      case "chevronLeft":
        return <IconChevron left aria-hidden="true" width={width} height={height} className={className} />;
      case "chevronUpDown":
        return <IconChevron updown aria-hidden="true" width={width} height={height} className={className} />;
      case "calendar":
        return <IconCalendar aria-hidden="true" width={width} height={height} className={className} />;
      case "sitemap":
        return <IconSitemap aria-hidden="true" width={width} height={height} className={className} />;
      case "cross":
      case "close":
        return <IconX aria-hidden="true" width={width} height={height} className={className} />;
      case "list":
        return <IconList aria-hidden="true" width={width} height={height} className={className} />;
      case "grid":
        return <IconGrid aria-hidden="true" width={width} height={height} className={className} />;
      case "bell":
      case "notification":
        return <IconBell aria-hidden="true" width={width} height={height} className={className} />;
      case "cog":
        return <IconSettings cog aria-hidden="true" width={width} height={height} className={className} />;
      case "settings":
        return <IconSettings aria-hidden="true" width={width} height={height} className={className} />;
      case "pencil":
        return <IconPencil aria-hidden="true" width={width} height={height} className={className} />;
      case "document":
        return <IconDocument aria-hidden="true" width={width} height={height} className={className} />;

      default:
        return <IconEdit aria-hidden="true" width={width} height={height} className={className} />;
    }
  }

  if (fa) return <IconFA {...props} />;

  return showIcon();
}
