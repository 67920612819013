import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal } from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";

export default function Icon(props) {
  const { name = "faMasksTheater", width = 6, height = 6 } = props;

  const h = "h-" + String(height);
  const w = "w-" + String(width);

  return <FontAwesomeIcon icon={fal[name]} className={`${h} ${w}`} />;
}

Icon.propTypes = {
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
