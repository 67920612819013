import validator from "validator";

const { i18n } = require("app/i18n");

const components = [
  {
    type: "Text",
    title: "Phone Number",
    key: "phonenumber",
    datatype: ["PARTNER"],
  },
  {
    type: "Text",
    title: "Email",
    key: "email",
    datatype: ["PARTNER"],
  },

  {
    type: "Editor",
    key: "description",
    title: i18n("label.description"),
    datatype: ["EXPERIENCE", "OFFER", "PARTNER"],
    modules: ["undo", "redo", "bold", "italic", "underline", "strikethrough", "link", "bullist", "numlist", "superscript", "subscript", "blockquote", "removeformat"],
  },
  {
    type: "Gallery",
    key: "gallery",
    title: i18n("label.gallery"),
    datatype: ["EXPERIENCE", "OFFER"],
  },
  {
    tab: "specs",
    type: "Group",
    title: "Channels",
    key: "channels",
    datatype: ["PARTNER"],
    children: [
      {
        type: "Text",
        key: "website",
        title: "Website",
      },
      {
        type: "Text",
        key: "facebook",
        title: "Facebook",
      },
      {
        type: "Text",
        key: "airbnb",
        title: "Airbnb",
      },
      {
        type: "Text",
        key: "tripadvisor",
        title: "Tripadvisor",
      },
      {
        type: "Text",
        key: "booking",
        title: "Booking",
      },
      {
        type: "Text",
        key: "thefork",
        title: "The Fork",
      },
    ],
  },
  {
    tab: "specs",
    type: "Group",
    title: "Banners",
    key: "banners",
    datatype: ["PARTNER"],
    children: [
      {
        type: "Multimedia",
        key: "square",
        title: "Square",
        formats: ["IMAGE"],
      },
      {
        type: "Multimedia",
        key: "rectangle",
        title: "Rectangle",
        formats: ["IMAGE"],
      },
    ],
  },
  {
    type: "List",
    key: "features",
    title: i18n("label.features"),
    datatype: ["EXPERIENCE"],
    children: [
      {
        type: "SelectIcon",
        key: "icon",
        title: i18n("label.icon"),
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
      {
        type: "Text",
        key: "title",
        title: i18n("label.title"),
        rules: [
          {
            method: validator.isEmpty,
            validWhen: false,
            message: i18n("input.required_field"),
          },
        ],
      },
    ],
  },
  {
    type: "Group",
    key: "operator",
    title: i18n("label.operator"),
    datatype: ["EXPERIENCE"],
    children: [
      {
        type: "Toggle",
        key: "active",
        title: i18n("label.active"),
      },
      {
        type: "Text",
        key: "name",
        title: i18n("label.name"),
        conditional: {
          field: "active",
          when: "isTrue",
        },
      },
      {
        type: "Multimedia",
        key: "image",
        title: i18n("label.image"),
        formats: ["IMAGE"],
        conditional: {
          field: "active",
          when: "isTrue",
        },
      },
      {
        type: "Text",
        key: "website",
        title: i18n("label.website"),
        conditional: {
          field: "active",
          when: "isTrue",
        },
      },
    ],
  },
  {
    type: "Group",
    key: "offer",
    title: i18n("label.offer"),
    datatype: ["EXPERIENCE", "OFFER"],
    children: [
      {
        type: "Toggle",
        key: "active",
        title: i18n("label.active"),
      },
      {
        type: "Text",
        key: "text",
        title: i18n("label.text"),
        conditional: {
          field: "active",
          when: "isTrue",
        },
      },
      {
        type: "Text",
        key: "link",
        title: i18n("label.link"),
        conditional: {
          field: "active",
          when: "isTrue",
        },
      },
    ],
  },
  {
    type: "SuperDynamic",
    key: "content",
    title: i18n("label.content"),
    datatype: ["STORY"],
  },
];

export { components };
