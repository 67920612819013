const icons = [
  { id: "house", icon: "faHouse", slug: "fa-house", name: "House" },
  { id: "magnifying-glass", icon: "faMagnifyingGlass", slug: "fa-magnifying-glass", name: "Magnifying Glass" },
  { id: "user", icon: "faUser", slug: "fa-user", name: "User" },
  { id: "check", icon: "faCheck", slug: "fa-check", name: "Check" },
  { id: "download", icon: "faDownload", slug: "fa-download", name: "Download" },
  { id: "image", icon: "faImage", slug: "fa-image", name: "Image" },
  { id: "phone", icon: "faPhone", slug: "fa-phone", name: "Phone" },
  { id: "bars", icon: "faBars", slug: "fa-bars", name: "Bars" },
  { id: "envelope", icon: "faEnvelope", slug: "fa-envelope", name: "Envelope" },
  { id: "star", icon: "faStar", slug: "fa-star", name: "Star" },
  { id: "location-dot", icon: "faLocationDot", slug: "fa-location-dot", name: "Location Dot" },
  { id: "music", icon: "faMusic", slug: "fa-music", name: "Music" },
  { id: "wand-magic-sparkles", icon: "faWandMagicSparkles", slug: "fa-wand-magic-sparkles", name: "Wand Magic Sparkles" },
  { id: "face-awesome", icon: "faFaceAwesome", slug: "fa-face-awesome", name: "Face Awesome" },
  { id: "heart", icon: "faHeart", slug: "fa-heart", name: "Heart" },
  { id: "arrow-right", icon: "faArrowRight", slug: "fa-arrow-right", name: "Arrow Right" },
  { id: "circle-xmark", icon: "faCircleXmark", slug: "fa-circle-xmark", name: "Circle Xmark" },
  { id: "bomb", icon: "faBomb", slug: "fa-bomb", name: "Bomb" },
  { id: "poo", icon: "faPoo", slug: "fa-poo", name: "Poo" },
  { id: "camera-retro", icon: "faCameraRetro", slug: "fa-camera-retro", name: "Camera Retro" },
  { id: "xmark", icon: "faXmark", slug: "fa-xmark", name: "Xmark" },
  { id: "cloud", icon: "faCloud", slug: "fa-cloud", name: "Cloud" },
  { id: "comment", icon: "faComment", slug: "fa-comment", name: "Comment" },
  { id: "caret-up", icon: "faCaretUp", slug: "fa-caret-up", name: "Caret Up" },
  { id: "truck-fast", icon: "faTruckFast", slug: "fa-truck-fast", name: "Truck Fast" },
  { id: "pen-nib", icon: "faPenNib", slug: "fa-pen-nib", name: "Pen Nib" },
  { id: "arrow-up", icon: "faArrowUp", slug: "fa-arrow-up", name: "Arrow Up" },
  { id: "hippo", icon: "faHippo", slug: "fa-hippo", name: "Hippo" },
  { id: "face-smile", icon: "faFaceSmile", slug: "fa-face-smile", name: "Face Smile" },
  { id: "calendar-days", icon: "faCalendarDays", slug: "fa-calendar-days", name: "Calendar Days" },
  { id: "paperclip", icon: "faPaperclip", slug: "fa-paperclip", name: "Paperclip" },
  { id: "shield-halved", icon: "faShieldHalved", slug: "fa-shield-halved", name: "Shield Halved" },
  { id: "file", icon: "faFile", slug: "fa-file", name: "File" },
  { id: "bell", icon: "faBell", slug: "fa-bell", name: "Bell" },
  { id: "cart-shopping", icon: "faCartShopping", slug: "fa-cart-shopping", name: "Cart Shopping" },
  { id: "clipboard", icon: "faClipboard", slug: "fa-clipboard", name: "Clipboard" },
  { id: "filter", icon: "faFilter", slug: "fa-filter", name: "Filter" },
  { id: "circle-info", icon: "faCircleInfo", slug: "fa-circle-info", name: "Circle Info" },
  { id: "arrow-up-from-bracket", icon: "faArrowUpFromBracket", slug: "fa-arrow-up-from-bracket", name: "Arrow Up From Bracket" },
  { id: "alarm-clock", icon: "faAlarmClock", slug: "fa-alarm-clock", name: "Alarm Clock" },
  { id: "bolt", icon: "faBolt", slug: "fa-bolt", name: "Bolt" },
  { id: "raygun", icon: "faRaygun", slug: "fa-raygun", name: "Raygun" },
  { id: "car", icon: "faCar", slug: "fa-car", name: "Car" },
  { id: "ghost", icon: "faGhost", slug: "fa-ghost", name: "Ghost" },
  { id: "mug-hot", icon: "faMugHot", slug: "fa-mug-hot", name: "Mug Hot" },
  { id: "circle-user", icon: "faCircleUser", slug: "fa-circle-user", name: "Circle User" },
  { id: "rocket-launch", icon: "faRocketLaunch", slug: "fa-rocket-launch", name: "Rocket Launch" },
  { id: "pen", icon: "faPen", slug: "fa-pen", name: "Pen" },
  { id: "umbrella", icon: "faUmbrella", slug: "fa-umbrella", name: "Umbrella" },
  { id: "gift", icon: "faGift", slug: "fa-gift", name: "Gift" },
  { id: "film", icon: "faFilm", slug: "fa-film", name: "Film" },
  { id: "grid-2", icon: "faGrid2", slug: "fa-grid-2", name: "Grid 2" },
  { id: "list", icon: "faList", slug: "fa-list", name: "List" },
  { id: "gear", icon: "faGear", slug: "fa-gear", name: "Gear" },
  { id: "trash", icon: "faTrash", slug: "fa-trash", name: "Trash" },
  { id: "circle-up", icon: "faCircleUp", slug: "fa-circle-up", name: "Circle Up" },
  { id: "circle-down", icon: "faCircleDown", slug: "fa-circle-down", name: "Circle Down" },
  { id: "inbox", icon: "faInbox", slug: "fa-inbox", name: "Inbox" },
  { id: "rotate-right", icon: "faRotateRight", slug: "fa-rotate-right", name: "Rotate Right" },
  { id: "sparkles", icon: "faSparkles", slug: "fa-sparkles", name: "Sparkles" },
  { id: "lock", icon: "faLock", slug: "fa-lock", name: "Lock" },
  { id: "headphones", icon: "faHeadphones", slug: "fa-headphones", name: "Headphones" },
  { id: "volume", icon: "faVolume", slug: "fa-volume", name: "Volume" },
  { id: "barcode", icon: "faBarcode", slug: "fa-barcode", name: "Barcode" },
  { id: "tag", icon: "faTag", slug: "fa-tag", name: "Tag" },
  { id: "book", icon: "faBook", slug: "fa-book", name: "Book" },
  { id: "bookmark", icon: "faBookmark", slug: "fa-bookmark", name: "Bookmark" },
  { id: "print", icon: "faPrint", slug: "fa-print", name: "Print" },
  { id: "camera", icon: "faCamera", slug: "fa-camera", name: "Camera" },
  { id: "font", icon: "faFont", slug: "fa-font", name: "Font" },
  { id: "video", icon: "faVideo", slug: "fa-video", name: "Video" },
  { id: "circle-half-stroke", icon: "faCircleHalfStroke", slug: "fa-circle-half-stroke", name: "Circle Half Stroke" },
  { id: "droplet", icon: "faDroplet", slug: "fa-droplet", name: "Droplet" },
  { id: "pen-to-square", icon: "faPenToSquare", slug: "fa-pen-to-square", name: "Pen To Square" },
  { id: "share-from-square", icon: "faShareFromSquare", slug: "fa-share-from-square", name: "Share From Square" },
  { id: "plus", icon: "faPlus", slug: "fa-plus", name: "Plus" },
  { id: "minus", icon: "faMinus", slug: "fa-minus", name: "Minus" },
  { id: "share", icon: "faShare", slug: "fa-share", name: "Share" },
  { id: "circle-exclamation", icon: "faCircleExclamation", slug: "fa-circle-exclamation", name: "Circle Exclamation" },
  { id: "fire", icon: "faFire", slug: "fa-fire", name: "Fire" },
  { id: "eye", icon: "faEye", slug: "fa-eye", name: "Eye" },
  { id: "eye-slash", icon: "faEyeSlash", slug: "fa-eye-slash", name: "Eye Slash" },
  { id: "plane", icon: "faPlane", slug: "fa-plane", name: "Plane" },
  { id: "magnet", icon: "faMagnet", slug: "fa-magnet", name: "Magnet" },
  { id: "hand", icon: "faHand", slug: "fa-hand", name: "Hand" },
  { id: "folder", icon: "faFolder", slug: "fa-folder", name: "Folder" },
  { id: "folder-open", icon: "faFolderOpen", slug: "fa-folder-open", name: "Folder Open" },
  { id: "money-bill", icon: "faMoneyBill", slug: "fa-money-bill", name: "Money Bill" },
  { id: "thumbs-up", icon: "faThumbsUp", slug: "fa-thumbs-up", name: "Thumbs Up" },
  { id: "thumbs-down", icon: "faThumbsDown", slug: "fa-thumbs-down", name: "Thumbs Down" },
  { id: "comments", icon: "faComments", slug: "fa-comments", name: "Comments" },
  { id: "grill-hot", icon: "faGrillHot", slug: "fa-grill-hot", name: "Grill Hot" },
  { id: "lemon", icon: "faLemon", slug: "fa-lemon", name: "Lemon" },
  { id: "key", icon: "faKey", slug: "fa-key", name: "Key" },
  { id: "thumbtack", icon: "faThumbtack", slug: "fa-thumbtack", name: "Thumbtack" },
  { id: "gears", icon: "faGears", slug: "fa-gears", name: "Gears" },
  { id: "paper-plane", icon: "faPaperPlane", slug: "fa-paper-plane", name: "Paper Plane" },
  { id: "code", icon: "faCode", slug: "fa-code", name: "Code" },
  { id: "globe", icon: "faGlobe", slug: "fa-globe", name: "Globe" },
  { id: "text", icon: "faText", slug: "fa-text", name: "Text" },
  { id: "arrow-down-to-line", icon: "faArrowDownToLine", slug: "fa-arrow-down-to-line", name: "Arrow Down To Line" },
  { id: "truck", icon: "faTruck", slug: "fa-truck", name: "Truck" },
  { id: "city", icon: "faCity", slug: "fa-city", name: "City" },
  { id: "ticket", icon: "faTicket", slug: "fa-ticket", name: "Ticket" },
  { id: "tree", icon: "faTree", slug: "fa-tree", name: "Tree" },
  { id: "wifi", icon: "faWifi", slug: "fa-wifi", name: "Wifi" },
  { id: "window", icon: "faWindow", slug: "fa-window", name: "Window" },
  { id: "burger-soda", icon: "faBurgerSoda", slug: "fa-burger-soda", name: "Burger Soda" },
  { id: "camera-movie", icon: "faCameraMovie", slug: "fa-camera-movie", name: "Camera Movie" },
  { id: "paint-roller", icon: "faPaintRoller", slug: "fa-paint-roller", name: "Paint Roller" },
  { id: "bicycle", icon: "faBicycle", slug: "fa-bicycle", name: "Bicycle" },
  { id: "sliders", icon: "faSliders", slug: "fa-sliders", name: "Sliders" },
  { id: "brush", icon: "faBrush", slug: "fa-brush", name: "Brush" },
  { id: "hashtag", icon: "faHashtag", slug: "fa-hashtag", name: "Hashtag" },
  { id: "megaphone", icon: "faMegaphone", slug: "fa-megaphone", name: "Megaphone" },
  { id: "flask", icon: "faFlask", slug: "fa-flask", name: "Flask" },
  { id: "briefcase", icon: "faBriefcase", slug: "fa-briefcase", name: "Briefcase" },
  { id: "compass", icon: "faCompass", slug: "fa-compass", name: "Compass" },
  { id: "dumpster-fire", icon: "faDumpsterFire", slug: "fa-dumpster-fire", name: "Dumpster Fire" },
  { id: "person", icon: "faPerson", slug: "fa-person", name: "Person" },
  { id: "person-dress", icon: "faPersonDress", slug: "fa-person-dress", name: "Person Dress" },
  { id: "balloons", icon: "faBalloons", slug: "fa-balloons", name: "Balloons" },
  { id: "address-book", icon: "faAddressBook", slug: "fa-address-book", name: "Address Book" },
  { id: "bath", icon: "faBath", slug: "fa-bath", name: "Bath" },
  { id: "handshake", icon: "faHandshake", slug: "fa-handshake", name: "Handshake" },
  { id: "snowflake", icon: "faSnowflake", slug: "fa-snowflake", name: "Snowflake" },
  { id: "right-to-bracket", icon: "faRightToBracket", slug: "fa-right-to-bracket", name: "Right To Bracket" },
  { id: "earth-americas", icon: "faEarthAmericas", slug: "fa-earth-americas", name: "Earth Americas" },
  { id: "cloud-arrow-up", icon: "faCloudArrowUp", slug: "fa-cloud-arrow-up", name: "Cloud Arrow Up" },
  { id: "binoculars", icon: "faBinoculars", slug: "fa-binoculars", name: "Binoculars" },
  { id: "palette", icon: "faPalette", slug: "fa-palette", name: "Palette" },
  { id: "layer-group", icon: "faLayerGroup", slug: "fa-layer-group", name: "Layer Group" },
  { id: "brackets-curly", icon: "faBracketsCurly", slug: "fa-brackets-curly", name: "Brackets Curly" },
  { id: "users", icon: "faUsers", slug: "fa-users", name: "Users" },
  { id: "gamepad", icon: "faGamepad", slug: "fa-gamepad", name: "Gamepad" },
  { id: "business-time", icon: "faBusinessTime", slug: "fa-business-time", name: "Business Time" },
  { id: "feather", icon: "faFeather", slug: "fa-feather", name: "Feather" },
  { id: "sun", icon: "faSun", slug: "fa-sun", name: "Sun" },
  { id: "computer-classic", icon: "faComputerClassic", slug: "fa-computer-classic", name: "Computer Classic" },
  { id: "link", icon: "faLink", slug: "fa-link", name: "Link" },
  { id: "pen-fancy", icon: "faPenFancy", slug: "fa-pen-fancy", name: "Pen Fancy" },
  { id: "badge-check", icon: "faBadgeCheck", slug: "fa-badge-check", name: "Badge Check" },
  { id: "fish", icon: "faFish", slug: "fa-fish", name: "Fish" },
  { id: "joystick", icon: "faJoystick", slug: "fa-joystick", name: "Joystick" },
  { id: "play-pause", icon: "faPlayPause", slug: "fa-play-pause", name: "Play Pause" },
  { id: "bug", icon: "faBug", slug: "fa-bug", name: "Bug" },
  { id: "shop", icon: "faShop", slug: "fa-shop", name: "Shop" },
  { id: "mug-saucer", icon: "faMugSaucer", slug: "fa-mug-saucer", name: "Mug Saucer" },
  { id: "planet-ringed", icon: "faPlanetRinged", slug: "fa-planet-ringed", name: "Planet Ringed" },
  { id: "landmark", icon: "faLandmark", slug: "fa-landmark", name: "Landmark" },
  { id: "campfire", icon: "faCampfire", slug: "fa-campfire", name: "Campfire" },
  { id: "poo-storm", icon: "faPooStorm", slug: "fa-poo-storm", name: "Poo Storm" },
  { id: "chart-simple", icon: "faChartSimple", slug: "fa-chart-simple", name: "Chart Simple" },
  { id: "shirt", icon: "faShirt", slug: "fa-shirt", name: "Shirt" },
  { id: "files", icon: "faFiles", slug: "fa-files", name: "Files" },
  { id: "dolphin", icon: "faDolphin", slug: "fa-dolphin", name: "Dolphin" },
  { id: "anchor", icon: "faAnchor", slug: "fa-anchor", name: "Anchor" },
  { id: "quote-left", icon: "faQuoteLeft", slug: "fa-quote-left", name: "Quote Left" },
  { id: "atom-simple", icon: "faAtomSimple", slug: "fa-atom-simple", name: "Atom Simple" },
  { id: "bag-shopping", icon: "faBagShopping", slug: "fa-bag-shopping", name: "Bag Shopping" },
  { id: "bed-front", icon: "faBedFront", slug: "fa-bed-front", name: "Bed Front" },
  { id: "people-simple", icon: "faPeopleSimple", slug: "fa-people-simple", name: "People Simple" },
  { id: "gauge", icon: "faGauge", slug: "fa-gauge", name: "Gauge" },
  { id: "signal-bars", icon: "faSignalBars", slug: "fa-signal-bars", name: "Signal Bars" },
  { id: "code-compare", icon: "faCodeCompare", slug: "fa-code-compare", name: "Code Compare" },
  { id: "user-secret", icon: "faUserSecret", slug: "fa-user-secret", name: "User Secret" },
  { id: "stethoscope", icon: "faStethoscope", slug: "fa-stethoscope", name: "Stethoscope" },
  { id: "car-side", icon: "faCarSide", slug: "fa-car-side", name: "Car Side" },
  { id: "hand-holding-heart", icon: "faHandHoldingHeart", slug: "fa-hand-holding-heart", name: "Hand Holding Heart" },
  { id: "ufo", icon: "faUfo", slug: "fa-ufo", name: "Ufo" },
  { id: "alien-8bit", icon: "faAlien8bit", slug: "fa-alien-8bit", name: "Alien 8bit" },
  { id: "block-question", icon: "faBlockQuestion", slug: "fa-block-question", name: "Block Question" },
  { id: "aperture", icon: "faAperture", slug: "fa-aperture", name: "Aperture" },
  { id: "bird", icon: "faBird", slug: "fa-bird", name: "Bird" },
  { id: "strawberry", icon: "faStrawberry", slug: "fa-strawberry", name: "Strawberry" },
  { id: "envelopes", icon: "faEnvelopes", slug: "fa-envelopes", name: "Envelopes" },
  { id: "face-vomit", icon: "faFaceVomit", slug: "fa-face-vomit", name: "Face Vomit" },
  { id: "fort", icon: "faFort", slug: "fa-fort", name: "Fort" },
  { id: "truck-front", icon: "faTruckFront", slug: "fa-truck-front", name: "Truck Front" },
  { id: "crab", icon: "faCrab", slug: "fa-crab", name: "Crab" },
  { id: "user-bounty-hunter", icon: "faUserBountyHunter", slug: "fa-user-bounty-hunter", name: "User Bounty Hunter" },
  { id: "thought-bubble", icon: "faThoughtBubble", slug: "fa-thought-bubble", name: "Thought Bubble" },
  { id: "cable-car", icon: "faCableCar", slug: "fa-cable-car", name: "Cable Car" },
  { id: "mountain-sun", icon: "faMountainSun", slug: "fa-mountain-sun", name: "Mountain Sun" },
  { id: "traffic-cone", icon: "faTrafficCone", slug: "fa-traffic-cone", name: "Traffic Cone" },
  { id: "diagram-venn", icon: "faDiagramVenn", slug: "fa-diagram-venn", name: "Diagram Venn" },
  { id: "bowl-chopsticks-noodles", icon: "faBowlChopsticksNoodles", slug: "fa-bowl-chopsticks-noodles", name: "Bowl Chopsticks Noodles" },
  { id: "moped", icon: "faMoped", slug: "fa-moped", name: "Moped" },
  { id: "burrito", icon: "faBurrito", slug: "fa-burrito", name: "Burrito" },
  { id: "robot-astromech", icon: "faRobotAstromech", slug: "fa-robot-astromech", name: "Robot Astromech" },
  { id: "bat", icon: "faBat", slug: "fa-bat", name: "Bat" },
  { id: "location-pin", icon: "faLocationPin", slug: "fa-location-pin", name: "Location Pin" },
  { id: "info", icon: "faInfo", slug: "fa-info", name: "Info" },
  { id: "user-minus", icon: "faUserMinus", slug: "fa-user-minus", name: "User Minus" },
  { id: "calendar", icon: "faCalendar", slug: "fa-calendar", name: "Calendar" },
  { id: "cart-plus", icon: "faCartPlus", slug: "fa-cart-plus", name: "Cart Plus" },
  { id: "clock", icon: "faClock", slug: "fa-clock", name: "Clock" },
  { id: "person-sign", icon: "faPersonSign", slug: "fa-person-sign", name: "Person Sign" },
  { id: "circle", icon: "faCircle", slug: "fa-circle", name: "Circle" },
  { id: "play", icon: "faPlay", slug: "fa-play", name: "Play" },
  { id: "cross", icon: "faCross", slug: "fa-cross", name: "Cross" },
  { id: "backward", icon: "faBackward", slug: "fa-backward", name: "Backward" },
  { id: "chart-scatter", icon: "faChartScatter", slug: "fa-chart-scatter", name: "Chart Scatter" },
  { id: "handshake-slash", icon: "faHandshakeSlash", slug: "fa-handshake-slash", name: "Handshake Slash" },
  { id: "chevron-up", icon: "faChevronUp", slug: "fa-chevron-up", name: "Chevron Up" },
  { id: "passport", icon: "faPassport", slug: "fa-passport", name: "Passport" },
  { id: "question", icon: "faQuestion", slug: "fa-question", name: "Question" },
  { id: "pencil", icon: "faPencil", slug: "fa-pencil", name: "Pencil" },
  { id: "phone-volume", icon: "faPhoneVolume", slug: "fa-phone-volume", name: "Phone Volume" },
  { id: "car-building", icon: "faCarBuilding", slug: "fa-car-building", name: "Car Building" },
  { id: "head-side", icon: "faHeadSide", slug: "fa-head-side", name: "Head Side" },
  { id: "upload", icon: "faUpload", slug: "fa-upload", name: "Upload" },
  { id: "strikethrough", icon: "faStrikethrough", slug: "fa-strikethrough", name: "Strikethrough" },
  { id: "computer-speaker", icon: "faComputerSpeaker", slug: "fa-computer-speaker", name: "Computer Speaker" },
  { id: "credit-card", icon: "faCreditCard", slug: "fa-credit-card", name: "Credit Card" },
  { id: "street-view", icon: "faStreetView", slug: "fa-street-view", name: "Street View" },
  { id: "database", icon: "faDatabase", slug: "fa-database", name: "Database" },
  { id: "copy", icon: "faCopy", slug: "fa-copy", name: "Copy" },
  { id: "mobile", icon: "faMobile", slug: "fa-mobile", name: "Mobile" },
  { id: "square", icon: "faSquare", slug: "fa-square", name: "Square" },
  { id: "sort", icon: "faSort", slug: "fa-sort", name: "Sort" },
  { id: "forward", icon: "faForward", slug: "fa-forward", name: "Forward" },
  { id: "hourglass-start", icon: "faHourglassStart", slug: "fa-hourglass-start", name: "Hourglass Start" },
  { id: "signal-slash", icon: "faSignalSlash", slug: "fa-signal-slash", name: "Signal Slash" },
  { id: "newspaper", icon: "faNewspaper", slug: "fa-newspaper", name: "Newspaper" },
  { id: "notes-medical", icon: "faNotesMedical", slug: "fa-notes-medical", name: "Notes Medical" },
  { id: "table", icon: "faTable", slug: "fa-table", name: "Table" },
  { id: "building", icon: "faBuilding", slug: "fa-building", name: "Building" },
  { id: "globe-stand", icon: "faGlobeStand", slug: "fa-globe-stand", name: "Globe Stand" },
  { id: "square-heart", icon: "faSquareHeart", slug: "fa-square-heart", name: "Square Heart" },
  { id: "stop", icon: "faStop", slug: "fa-stop", name: "Stop" },
  { id: "tally", icon: "faTally", slug: "fa-tally", name: "Tally" },
  { id: "store", icon: "faStore", slug: "fa-store", name: "Store" },
  { id: "flag", icon: "faFlag", slug: "fa-flag", name: "Flag" },
  { id: "lightbulb-slash", icon: "faLightbulbSlash", slug: "fa-lightbulb-slash", name: "Lightbulb Slash" },
  { id: "file-excel", icon: "faFileExcel", slug: "fa-file-excel", name: "File Excel" },
  { id: "browser", icon: "faBrowser", slug: "fa-browser", name: "Browser" },
  { id: "network-wired", icon: "faNetworkWired", slug: "fa-network-wired", name: "Network Wired" },
  { id: "cash-register", icon: "faCashRegister", slug: "fa-cash-register", name: "Cash Register" },
  { id: "file-export", icon: "faFileExport", slug: "fa-file-export", name: "File Export" },
  { id: "hand-point-up", icon: "faHandPointUp", slug: "fa-hand-point-up", name: "Hand Point Up" },
  { id: "angle-up", icon: "faAngleUp", slug: "fa-angle-up", name: "Angle Up" },
  { id: "shield", icon: "faShield", slug: "fa-shield", name: "Shield" },
  { id: "border-top", icon: "faBorderTop", slug: "fa-border-top", name: "Border Top" },
  { id: "address-card", icon: "faAddressCard", slug: "fa-address-card", name: "Address Card" },
  { id: "expand", icon: "faExpand", slug: "fa-expand", name: "Expand" },
  { id: "flag-checkered", icon: "faFlagCheckered", slug: "fa-flag-checkered", name: "Flag Checkered" },
  { id: "quote-right", icon: "faQuoteRight", slug: "fa-quote-right", name: "Quote Right" },
  { id: "tags", icon: "faTags", slug: "fa-tags", name: "Tags" },
  { id: "server", icon: "faServer", slug: "fa-server", name: "Server" },
  { id: "user-nurse", icon: "faUserNurse", slug: "fa-user-nurse", name: "User Nurse" },
  { id: "video-slash", icon: "faVideoSlash", slug: "fa-video-slash", name: "Video Slash" },
  { id: "arrow-down", icon: "faArrowDown", slug: "fa-arrow-down", name: "Arrow Down" },
  { id: "blog", icon: "faBlog", slug: "fa-blog", name: "Blog" },
  { id: "school", icon: "faSchool", slug: "fa-school", name: "School" },
  { id: "file-invoice", icon: "faFileInvoice", slug: "fa-file-invoice", name: "File Invoice" },
  { id: "rocket", icon: "faRocket", slug: "fa-rocket", name: "Rocket" },
  { id: "coin", icon: "faCoin", slug: "fa-coin", name: "Coin" },
  { id: "spinner", icon: "faSpinner", slug: "fa-spinner", name: "Spinner" },
  { id: "square-question", icon: "faSquareQuestion", slug: "fa-square-question", name: "Square Question" },
  { id: "tty", icon: "faTty", slug: "fa-tty", name: "Tty" },
  { id: "exclamation", icon: "faExclamation", slug: "fa-exclamation", name: "Exclamation" },
  { id: "water", icon: "faWater", slug: "fa-water", name: "Water" },
  { id: "shield-check", icon: "faShieldCheck", slug: "fa-shield-check", name: "Shield Check" },
  { id: "registered", icon: "faRegistered", slug: "fa-registered", name: "Registered" },
  { id: "empty-set", icon: "faEmptySet", slug: "fa-empty-set", name: "Empty Set" },
  { id: "signature", icon: "faSignature", slug: "fa-signature", name: "Signature" },
  { id: "laptop", icon: "faLaptop", slug: "fa-laptop", name: "Laptop" },
  { id: "phone-office", icon: "faPhoneOffice", slug: "fa-phone-office", name: "Phone Office" },
  { id: "line-height", icon: "faLineHeight", slug: "fa-line-height", name: "Line Height" },
  { id: "transporter-3", icon: "faTransporter3", slug: "fa-transporter-3", name: "Transporter 3" },
  { id: "projector", icon: "faProjector", slug: "fa-projector", name: "Projector" },
  { id: "moon-stars", icon: "faMoonStars", slug: "fa-moon-stars", name: "Moon Stars" },
  { id: "restroom", icon: "faRestroom", slug: "fa-restroom", name: "Restroom" },
  { id: "power-off", icon: "faPowerOff", slug: "fa-power-off", name: "Power Off" },
  { id: "sitemap", icon: "faSitemap", slug: "fa-sitemap", name: "Sitemap" },
  { id: "icons", icon: "faIcons", slug: "fa-icons", name: "Icons" },
  { id: "desktop", icon: "faDesktop", slug: "fa-desktop", name: "Desktop" },
  { id: "moon", icon: "faMoon", slug: "fa-moon", name: "Moon" },
  { id: "basketball-hoop", icon: "faBasketballHoop", slug: "fa-basketball-hoop", name: "Basketball Hoop" },
  { id: "loveseat", icon: "faLoveseat", slug: "fa-loveseat", name: "Loveseat" },
  { id: "square-info", icon: "faSquareInfo", slug: "fa-square-info", name: "Square Info" },
  { id: "calendar-week", icon: "faCalendarWeek", slug: "fa-calendar-week", name: "Calendar Week" },
  { id: "pause", icon: "faPause", slug: "fa-pause", name: "Pause" },
  { id: "file-word", icon: "faFileWord", slug: "fa-file-word", name: "File Word" },
  { id: "triangle", icon: "faTriangle", slug: "fa-triangle", name: "Triangle" },
  { id: "watch", icon: "faWatch", slug: "fa-watch", name: "Watch" },
  { id: "vials", icon: "faVials", slug: "fa-vials", name: "Vials" },
  { id: "language", icon: "faLanguage", slug: "fa-language", name: "Language" },
  { id: "door-open", icon: "faDoorOpen", slug: "fa-door-open", name: "Door Open" },
  { id: "brain", icon: "faBrain", slug: "fa-brain", name: "Brain" },
  { id: "hotel", icon: "faHotel", slug: "fa-hotel", name: "Hotel" },
  { id: "marker", icon: "faMarker", slug: "fa-marker", name: "Marker" },
  { id: "star-of-life", icon: "faStarOfLife", slug: "fa-star-of-life", name: "Star Of Life" },
  { id: "leaf", icon: "faLeaf", slug: "fa-leaf", name: "Leaf" },
  { id: "walkie-talkie", icon: "faWalkieTalkie", slug: "fa-walkie-talkie", name: "Walkie Talkie" },
  { id: "person-dolly-empty", icon: "faPersonDollyEmpty", slug: "fa-person-dolly-empty", name: "Person Dolly Empty" },
  { id: "shower", icon: "faShower", slug: "fa-shower", name: "Shower" },
  { id: "glass", icon: "faGlass", slug: "fa-glass", name: "Glass" },
  { id: "mailbox", icon: "faMailbox", slug: "fa-mailbox", name: "Mailbox" },
  { id: "caret-down", icon: "faCaretDown", slug: "fa-caret-down", name: "Caret Down" },
  { id: "light-switch", icon: "faLightSwitch", slug: "fa-light-switch", name: "Light Switch" },
  { id: "badge", icon: "faBadge", slug: "fa-badge", name: "Badge" },
  { id: "file-import", icon: "faFileImport", slug: "fa-file-import", name: "File Import" },
  { id: "typewriter", icon: "faTypewriter", slug: "fa-typewriter", name: "Typewriter" },
  { id: "place-of-worship", icon: "faPlaceOfWorship", slug: "fa-place-of-worship", name: "Place Of Worship" },
  { id: "flower", icon: "faFlower", slug: "fa-flower", name: "Flower" },
  { id: "spinner-third", icon: "faSpinnerThird", slug: "fa-spinner-third", name: "Spinner Third" },
  { id: "wallet", icon: "faWallet", slug: "fa-wallet", name: "Wallet" },
  { id: "slash", icon: "faSlash", slug: "fa-slash", name: "Slash" },
  { id: "computer-mouse-scrollwheel", icon: "faComputerMouseScrollwheel", slug: "fa-computer-mouse-scrollwheel", name: "Computer Mouse Scrollwheel" },
  { id: "award", icon: "faAward", slug: "fa-award", name: "Award" },
  { id: "toggle-on", icon: "faToggleOn", slug: "fa-toggle-on", name: "Toggle On" },
  { id: "ship", icon: "faShip", slug: "fa-ship", name: "Ship" },
  { id: "file-check", icon: "faFileCheck", slug: "fa-file-check", name: "File Check" },
  { id: "stocking", icon: "faStocking", slug: "fa-stocking", name: "Stocking" },
  { id: "chalkboard", icon: "faChalkboard", slug: "fa-chalkboard", name: "Chalkboard" },
  { id: "planet-moon", icon: "faPlanetMoon", slug: "fa-planet-moon", name: "Planet Moon" },
  { id: "lights-holiday", icon: "faLightsHoliday", slug: "fa-lights-holiday", name: "Lights Holiday" },
  { id: "hands", icon: "faHands", slug: "fa-hands", name: "Hands" },
  { id: "signal", icon: "faSignal", slug: "fa-signal", name: "Signal" },
  { id: "radar", icon: "faRadar", slug: "fa-radar", name: "Radar" },
  { id: "motorcycle", icon: "faMotorcycle", slug: "fa-motorcycle", name: "Motorcycle" },
  { id: "booth-curtain", icon: "faBoothCurtain", slug: "fa-booth-curtain", name: "Booth Curtain" },
  { id: "arrow-up-right-from-square", icon: "faArrowUpRightFromSquare", slug: "fa-arrow-up-right-from-square", name: "Arrow Up Right From Square" },
  { id: "audio-description", icon: "faAudioDescription", slug: "fa-audio-description", name: "Audio Description" },
  { id: "seedling", icon: "faSeedling", slug: "fa-seedling", name: "Seedling" },
  { id: "closed-captioning", icon: "faClosedCaptioning", slug: "fa-closed-captioning", name: "Closed Captioning" },
  { id: "text-size", icon: "faTextSize", slug: "fa-text-size", name: "Text Size" },
  { id: "train", icon: "faTrain", slug: "fa-train", name: "Train" },
  { id: "arrow-left", icon: "faArrowLeft", slug: "fa-arrow-left", name: "Arrow Left" },
  { id: "books", icon: "faBooks", slug: "fa-books", name: "Books" },
  { id: "wrench", icon: "faWrench", slug: "fa-wrench", name: "Wrench" },
  { id: "microchip", icon: "faMicrochip", slug: "fa-microchip", name: "Microchip" },
  { id: "record-vinyl", icon: "faRecordVinyl", slug: "fa-record-vinyl", name: "Record Vinyl" },
  { id: "trophy", icon: "faTrophy", slug: "fa-trophy", name: "Trophy" },
  { id: "hammer", icon: "faHammer", slug: "fa-hammer", name: "Hammer" },
  { id: "diamond", icon: "faDiamond", slug: "fa-diamond", name: "Diamond" },
  { id: "robot", icon: "faRobot", slug: "fa-robot", name: "Robot" },
  { id: "file-pdf", icon: "faFilePdf", slug: "fa-file-pdf", name: "File Pdf" },
  { id: "tablet-rugged", icon: "faTabletRugged", slug: "fa-tablet-rugged", name: "Tablet Rugged" },
  { id: "hospital", icon: "faHospital", slug: "fa-hospital", name: "Hospital" },
  { id: "file-contract", icon: "faFileContract", slug: "fa-file-contract", name: "File Contract" },
  { id: "square-xmark", icon: "faSquareXmark", slug: "fa-square-xmark", name: "Square Xmark" },
  { id: "square-check", icon: "faSquareCheck", slug: "fa-square-check", name: "Square Check" },
  { id: "stars", icon: "faStars", slug: "fa-stars", name: "Stars" },
  { id: "crown", icon: "faCrown", slug: "fa-crown", name: "Crown" },
  { id: "speaker", icon: "faSpeaker", slug: "fa-speaker", name: "Speaker" },
  { id: "ballot-check", icon: "faBallotCheck", slug: "fa-ballot-check", name: "Ballot Check" },
  { id: "speakers", icon: "faSpeakers", slug: "fa-speakers", name: "Speakers" },
  { id: "user-plus", icon: "faUserPlus", slug: "fa-user-plus", name: "User Plus" },
  { id: "mug-tea", icon: "faMugTea", slug: "fa-mug-tea", name: "Mug Tea" },
  { id: "solar-system", icon: "faSolarSystem", slug: "fa-solar-system", name: "Solar System" },
  { id: "virus", icon: "faVirus", slug: "fa-virus", name: "Virus" },
  { id: "child", icon: "faChild", slug: "fa-child", name: "Child" },
  { id: "scanner-touchscreen", icon: "faScannerTouchscreen", slug: "fa-scanner-touchscreen", name: "Scanner Touchscreen" },
  { id: "repeat", icon: "faRepeat", slug: "fa-repeat", name: "Repeat" },
  { id: "cube", icon: "faCube", slug: "fa-cube", name: "Cube" },
  { id: "copyright", icon: "faCopyright", slug: "fa-copyright", name: "Copyright" },
  { id: "medal", icon: "faMedal", slug: "fa-medal", name: "Medal" },
  { id: "bullseye", icon: "faBullseye", slug: "fa-bullseye", name: "Bullseye" },
  { id: "mask", icon: "faMask", slug: "fa-mask", name: "Mask" },
  { id: "meat", icon: "faMeat", slug: "fa-meat", name: "Meat" },
  { id: "circle-check", icon: "faCircleCheck", slug: "fa-circle-check", name: "Circle Check" },
  { id: "radio", icon: "faRadio", slug: "fa-radio", name: "Radio" },
  { id: "reply", icon: "faReply", slug: "fa-reply", name: "Reply" },
  { id: "album", icon: "faAlbum", slug: "fa-album", name: "Album" },
  { id: "chair", icon: "faChair", slug: "fa-chair", name: "Chair" },
  { id: "route", icon: "faRoute", slug: "fa-route", name: "Route" },
  { id: "plug", icon: "faPlug", slug: "fa-plug", name: "Plug" },
  { id: "calculator", icon: "faCalculator", slug: "fa-calculator", name: "Calculator" },
  { id: "dragon", icon: "faDragon", slug: "fa-dragon", name: "Dragon" },
  { id: "certificate", icon: "faCertificate", slug: "fa-certificate", name: "Certificate" },
  { id: "fingerprint", icon: "faFingerprint", slug: "fa-fingerprint", name: "Fingerprint" },
  { id: "road", icon: "faRoad", slug: "fa-road", name: "Road" },
  { id: "crosshairs", icon: "faCrosshairs", slug: "fa-crosshairs", name: "Crosshairs" },
  { id: "heading", icon: "faHeading", slug: "fa-heading", name: "Heading" },
  { id: "percent", icon: "faPercent", slug: "fa-percent", name: "Percent" },
  { id: "user-tie", icon: "faUserTie", slug: "fa-user-tie", name: "User Tie" },
  { id: "square-minus", icon: "faSquareMinus", slug: "fa-square-minus", name: "Square Minus" },
  { id: "i-cursor", icon: "faICursor", slug: "fa-i-cursor", name: "I Cursor" },
  { id: "church", icon: "faChurch", slug: "fa-church", name: "Church" },
  { id: "joint", icon: "faJoint", slug: "fa-joint", name: "Joint" },
  { id: "container-storage", icon: "faContainerStorage", slug: "fa-container-storage", name: "Container Storage" },
  { id: "comments-dollar", icon: "faCommentsDollar", slug: "fa-comments-dollar", name: "Comments Dollar" },
  { id: "truck-monster", icon: "faTruckMonster", slug: "fa-truck-monster", name: "Truck Monster" },
  { id: "recycle", icon: "faRecycle", slug: "fa-recycle", name: "Recycle" },
  { id: "warehouse", icon: "faWarehouse", slug: "fa-warehouse", name: "Warehouse" },
  { id: "ruler", icon: "faRuler", slug: "fa-ruler", name: "Ruler" },
  { id: "font-case", icon: "faFontCase", slug: "fa-font-case", name: "Font Case" },
  { id: "police-box", icon: "faPoliceBox", slug: "fa-police-box", name: "Police Box" },
  { id: "soap", icon: "faSoap", slug: "fa-soap", name: "Soap" },
  { id: "starship-freighter", icon: "faStarshipFreighter", slug: "fa-starship-freighter", name: "Starship Freighter" },
  { id: "bullseye-pointer", icon: "faBullseyePointer", slug: "fa-bullseye-pointer", name: "Bullseye Pointer" },
  { id: "blanket", icon: "faBlanket", slug: "fa-blanket", name: "Blanket" },
  { id: "scroll", icon: "faScroll", slug: "fa-scroll", name: "Scroll" },
  { id: "coins", icon: "faCoins", slug: "fa-coins", name: "Coins" },
  { id: "house-night", icon: "faHouseNight", slug: "fa-house-night", name: "House Night" },
  { id: "alarm-snooze", icon: "faAlarmSnooze", slug: "fa-alarm-snooze", name: "Alarm Snooze" },
  { id: "wind", icon: "faWind", slug: "fa-wind", name: "Wind" },
  { id: "baby", icon: "faBaby", slug: "fa-baby", name: "Baby" },
  { id: "lightbulb", icon: "faLightbulb", slug: "fa-lightbulb", name: "Lightbulb" },
  { id: "file-exclamation", icon: "faFileExclamation", slug: "fa-file-exclamation", name: "File Exclamation" },
  { id: "transporter-empty", icon: "faTransporterEmpty", slug: "fa-transporter-empty", name: "Transporter Empty" },
  { id: "cauldron", icon: "faCauldron", slug: "fa-cauldron", name: "Cauldron" },
  { id: "voicemail", icon: "faVoicemail", slug: "fa-voicemail", name: "Voicemail" },
  { id: "puzzle-piece", icon: "faPuzzlePiece", slug: "fa-puzzle-piece", name: "Puzzle Piece" },
  { id: "keyboard", icon: "faKeyboard", slug: "fa-keyboard", name: "Keyboard" },
  { id: "clone", icon: "faClone", slug: "fa-clone", name: "Clone" },
  { id: "eraser", icon: "faEraser", slug: "fa-eraser", name: "Eraser" },
  { id: "wine-bottle", icon: "faWineBottle", slug: "fa-wine-bottle", name: "Wine Bottle" },
  { id: "dice", icon: "faDice", slug: "fa-dice", name: "Dice" },
  { id: "file-spreadsheet", icon: "faFileSpreadsheet", slug: "fa-file-spreadsheet", name: "File Spreadsheet" },
  { id: "receipt", icon: "faReceipt", slug: "fa-receipt", name: "Receipt" },
  { id: "ring", icon: "faRing", slug: "fa-ring", name: "Ring" },
  { id: "lamp", icon: "faLamp", slug: "fa-lamp", name: "Lamp" },
  { id: "wagon-covered", icon: "faWagonCovered", slug: "fa-wagon-covered", name: "Wagon Covered" },
  { id: "unlock", icon: "faUnlock", slug: "fa-unlock", name: "Unlock" },
  { id: "solar-panel", icon: "faSolarPanel", slug: "fa-solar-panel", name: "Solar Panel" },
  { id: "ruler-vertical", icon: "faRulerVertical", slug: "fa-ruler-vertical", name: "Ruler Vertical" },
  { id: "circle-notch", icon: "faCircleNotch", slug: "fa-circle-notch", name: "Circle Notch" },
  { id: "people-arrows", icon: "faPeopleArrows", slug: "fa-people-arrows", name: "People Arrows" },
  { id: "flower-tulip", icon: "faFlowerTulip", slug: "fa-flower-tulip", name: "Flower Tulip" },
  { id: "draw-square", icon: "faDrawSquare", slug: "fa-draw-square", name: "Draw Square" },
  { id: "engine-warning", icon: "faEngineWarning", slug: "fa-engine-warning", name: "Engine Warning" },
  { id: "raindrops", icon: "faRaindrops", slug: "fa-raindrops", name: "Raindrops" },
  { id: "rectangle-wide", icon: "faRectangleWide", slug: "fa-rectangle-wide", name: "Rectangle Wide" },
  { id: "dollar-sign", icon: "faDollarSign", slug: "fa-dollar-sign", name: "Dollar Sign" },
  { id: "tablet", icon: "faTablet", slug: "fa-tablet", name: "Tablet" },
  { id: "not-equal", icon: "faNotEqual", slug: "fa-not-equal", name: "Not Equal" },
  { id: "glasses", icon: "faGlasses", slug: "fa-glasses", name: "Glasses" },
  { id: "headset", icon: "faHeadset", slug: "fa-headset", name: "Headset" },
  { id: "code-branch", icon: "faCodeBranch", slug: "fa-code-branch", name: "Code Branch" },
  { id: "gopuram", icon: "faGopuram", slug: "fa-gopuram", name: "Gopuram" },
  { id: "images", icon: "faImages", slug: "fa-images", name: "Images" },
  { id: "window-restore", icon: "faWindowRestore", slug: "fa-window-restore", name: "Window Restore" },
  { id: "industry", icon: "faIndustry", slug: "fa-industry", name: "Industry" },
  { id: "staff", icon: "faStaff", slug: "fa-staff", name: "Staff" },
  { id: "stamp", icon: "faStamp", slug: "fa-stamp", name: "Stamp" },
  { id: "microphone-slash", icon: "faMicrophoneSlash", slug: "fa-microphone-slash", name: "Microphone Slash" },
  { id: "align-slash", icon: "faAlignSlash", slug: "fa-align-slash", name: "Align Slash" },
  { id: "cookie-bite", icon: "faCookieBite", slug: "fa-cookie-bite", name: "Cookie Bite" },
  { id: "otter", icon: "faOtter", slug: "fa-otter", name: "Otter" },
  { id: "chevron-down", icon: "faChevronDown", slug: "fa-chevron-down", name: "Chevron Down" },
  { id: "kiwi-bird", icon: "faKiwiBird", slug: "fa-kiwi-bird", name: "Kiwi Bird" },
  { id: "viruses", icon: "faViruses", slug: "fa-viruses", name: "Viruses" },
  { id: "kidneys", icon: "faKidneys", slug: "fa-kidneys", name: "Kidneys" },
  { id: "users-medical", icon: "faUsersMedical", slug: "fa-users-medical", name: "Users Medical" },
  { id: "umbrella-beach", icon: "faUmbrellaBeach", slug: "fa-umbrella-beach", name: "Umbrella Beach" },
  { id: "microwave", icon: "faMicrowave", slug: "fa-microwave", name: "Microwave" },
  { id: "subscript", icon: "faSubscript", slug: "fa-subscript", name: "Subscript" },
  { id: "tablets", icon: "faTablets", slug: "fa-tablets", name: "Tablets" },
  { id: "knife-kitchen", icon: "faKnifeKitchen", slug: "fa-knife-kitchen", name: "Knife Kitchen" },
  { id: "game-console-handheld", icon: "faGameConsoleHandheld", slug: "fa-game-console-handheld", name: "Game Console Handheld" },
  { id: "transporter", icon: "faTransporter", slug: "fa-transporter", name: "Transporter" },
  { id: "microphone", icon: "faMicrophone", slug: "fa-microphone", name: "Microphone" },
  { id: "blinds-raised", icon: "faBlindsRaised", slug: "fa-blinds-raised", name: "Blinds Raised" },
  { id: "border-none", icon: "faBorderNone", slug: "fa-border-none", name: "Border None" },
  { id: "dumbbell", icon: "faDumbbell", slug: "fa-dumbbell", name: "Dumbbell" },
  { id: "plane-departure", icon: "faPlaneDeparture", slug: "fa-plane-departure", name: "Plane Departure" },
  { id: "z", icon: "faZ", slug: "fa-z", name: "Z" },
  { id: "yin-yang", icon: "faYinYang", slug: "fa-yin-yang", name: "Yin Yang" },
  { id: "yen-sign", icon: "faYenSign", slug: "fa-yen-sign", name: "Yen Sign" },
  { id: "y", icon: "faY", slug: "fa-y", name: "Y" },
  { id: "xmarks-lines", icon: "faXmarksLines", slug: "fa-xmarks-lines", name: "Xmarks Lines" },
  { id: "xmark-to-slot", icon: "faXmarkToSlot", slug: "fa-xmark-to-slot", name: "Xmark To Slot" },
  { id: "xmark-large", icon: "faXmarkLarge", slug: "fa-xmark-large", name: "Xmark Large" },
  { id: "x-ray", icon: "faXRay", slug: "fa-x-ray", name: "X Ray" },
  { id: "x", icon: "faX", slug: "fa-x", name: "X" },
  { id: "wrench-simple", icon: "faWrenchSimple", slug: "fa-wrench-simple", name: "Wrench Simple" },
  { id: "wreath-laurel", icon: "faWreathLaurel", slug: "fa-wreath-laurel", name: "Wreath Laurel" },
  { id: "wreath", icon: "faWreath", slug: "fa-wreath", name: "Wreath" },
  { id: "worm", icon: "faWorm", slug: "fa-worm", name: "Worm" },
  { id: "won-sign", icon: "faWonSign", slug: "fa-won-sign", name: "Won Sign" },
  { id: "wine-glass-empty", icon: "faWineGlassEmpty", slug: "fa-wine-glass-empty", name: "Wine Glass Empty" },
  { id: "wine-glass-crack", icon: "faWineGlassCrack", slug: "fa-wine-glass-crack", name: "Wine Glass Crack" },
  { id: "wine-glass", icon: "faWineGlass", slug: "fa-wine-glass", name: "Wine Glass" },
  { id: "windsock", icon: "faWindsock", slug: "fa-windsock", name: "Windsock" },
  { id: "window-minimize", icon: "faWindowMinimize", slug: "fa-window-minimize", name: "Window Minimize" },
  { id: "window-maximize", icon: "faWindowMaximize", slug: "fa-window-maximize", name: "Window Maximize" },
  { id: "window-frame-open", icon: "faWindowFrameOpen", slug: "fa-window-frame-open", name: "Window Frame Open" },
  { id: "window-frame", icon: "faWindowFrame", slug: "fa-window-frame", name: "Window Frame" },
  { id: "window-flip", icon: "faWindowFlip", slug: "fa-window-flip", name: "Window Flip" },
  { id: "wind-warning", icon: "faWindWarning", slug: "fa-wind-warning", name: "Wind Warning" },
  { id: "wind-turbine", icon: "faWindTurbine", slug: "fa-wind-turbine", name: "Wind Turbine" },
  { id: "wifi-weak", icon: "faWifiWeak", slug: "fa-wifi-weak", name: "Wifi Weak" },
  { id: "wifi-slash", icon: "faWifiSlash", slug: "fa-wifi-slash", name: "Wifi Slash" },
  { id: "wifi-fair", icon: "faWifiFair", slug: "fa-wifi-fair", name: "Wifi Fair" },
  { id: "wifi-exclamation", icon: "faWifiExclamation", slug: "fa-wifi-exclamation", name: "Wifi Exclamation" },
  { id: "whistle", icon: "faWhistle", slug: "fa-whistle", name: "Whistle" },
  { id: "whiskey-glass-ice", icon: "faWhiskeyGlassIce", slug: "fa-whiskey-glass-ice", name: "Whiskey Glass Ice" },
  { id: "whiskey-glass", icon: "faWhiskeyGlass", slug: "fa-whiskey-glass", name: "Whiskey Glass" },
  { id: "wheelchair-move", icon: "faWheelchairMove", slug: "fa-wheelchair-move", name: "Wheelchair Move" },
  { id: "wheelchair", icon: "faWheelchair", slug: "fa-wheelchair", name: "Wheelchair" },
  { id: "wheat-slash", icon: "faWheatSlash", slug: "fa-wheat-slash", name: "Wheat Slash" },
  { id: "wheat-awn-slash", icon: "faWheatAwnSlash", slug: "fa-wheat-awn-slash", name: "Wheat Awn Slash" },
  { id: "wheat-awn-circle-exclamation", icon: "faWheatAwnCircleExclamation", slug: "fa-wheat-awn-circle-exclamation", name: "Wheat Awn Circle Exclamation" },
  { id: "wheat-awn", icon: "faWheatAwn", slug: "fa-wheat-awn", name: "Wheat Awn" },
  { id: "wheat", icon: "faWheat", slug: "fa-wheat", name: "Wheat" },
  { id: "whale", icon: "faWhale", slug: "fa-whale", name: "Whale" },
  { id: "weight-scale", icon: "faWeightScale", slug: "fa-weight-scale", name: "Weight Scale" },
  { id: "weight-hanging", icon: "faWeightHanging", slug: "fa-weight-hanging", name: "Weight Hanging" },
  { id: "webhook", icon: "faWebhook", slug: "fa-webhook", name: "Webhook" },
  { id: "web-awesome", icon: "faWebAwesome", slug: "fa-web-awesome", name: "Web Awesome" },
  { id: "waves-sine", icon: "faWavesSine", slug: "fa-waves-sine", name: "Waves Sine" },
  { id: "waveform-lines", icon: "faWaveformLines", slug: "fa-waveform-lines", name: "Waveform Lines" },
  { id: "waveform", icon: "faWaveform", slug: "fa-waveform", name: "Waveform" },
  { id: "wave-triangle", icon: "faWaveTriangle", slug: "fa-wave-triangle", name: "Wave Triangle" },
  { id: "wave-square", icon: "faWaveSquare", slug: "fa-wave-square", name: "Wave Square" },
  { id: "wave-sine", icon: "faWaveSine", slug: "fa-wave-sine", name: "Wave Sine" },
  { id: "wave-pulse", icon: "faWavePulse", slug: "fa-wave-pulse", name: "Wave Pulse" },
  { id: "wave", icon: "faWave", slug: "fa-wave", name: "Wave" },
  { id: "watermelon-slice", icon: "faWatermelonSlice", slug: "fa-watermelon-slice", name: "Watermelon Slice" },
  { id: "water-ladder", icon: "faWaterLadder", slug: "fa-water-ladder", name: "Water Ladder" },
  { id: "water-arrow-up", icon: "faWaterArrowUp", slug: "fa-water-arrow-up", name: "Water Arrow Up" },
  { id: "water-arrow-down", icon: "faWaterArrowDown", slug: "fa-water-arrow-down", name: "Water Arrow Down" },
  { id: "watch-smart", icon: "faWatchSmart", slug: "fa-watch-smart", name: "Watch Smart" },
  { id: "watch-fitness", icon: "faWatchFitness", slug: "fa-watch-fitness", name: "Watch Fitness" },
  { id: "watch-calculator", icon: "faWatchCalculator", slug: "fa-watch-calculator", name: "Watch Calculator" },
  { id: "watch-apple", icon: "faWatchApple", slug: "fa-watch-apple", name: "Watch Apple" },
  { id: "washing-machine", icon: "faWashingMachine", slug: "fa-washing-machine", name: "Washing Machine" },
  { id: "warehouse-full", icon: "faWarehouseFull", slug: "fa-warehouse-full", name: "Warehouse Full" },
  { id: "wand-sparkles", icon: "faWandSparkles", slug: "fa-wand-sparkles", name: "Wand Sparkles" },
  { id: "wand-magic", icon: "faWandMagic", slug: "fa-wand-magic", name: "Wand Magic" },
  { id: "wand", icon: "faWand", slug: "fa-wand", name: "Wand" },
  { id: "walker", icon: "faWalker", slug: "fa-walker", name: "Walker" },
  { id: "waffle", icon: "faWaffle", slug: "fa-waffle", name: "Waffle" },
  { id: "w", icon: "faW", slug: "fa-w", name: "W" },
  { id: "vr-cardboard", icon: "faVrCardboard", slug: "fa-vr-cardboard", name: "Vr Cardboard" },
  { id: "volume-xmark", icon: "faVolumeXmark", slug: "fa-volume-xmark", name: "Volume Xmark" },
  { id: "volume-slash", icon: "faVolumeSlash", slug: "fa-volume-slash", name: "Volume Slash" },
  { id: "volume-off", icon: "faVolumeOff", slug: "fa-volume-off", name: "Volume Off" },
  { id: "volume-low", icon: "faVolumeLow", slug: "fa-volume-low", name: "Volume Low" },
  { id: "volume-high", icon: "faVolumeHigh", slug: "fa-volume-high", name: "Volume High" },
  { id: "volleyball", icon: "faVolleyball", slug: "fa-volleyball", name: "Volleyball" },
  { id: "volcano", icon: "faVolcano", slug: "fa-volcano", name: "Volcano" },
  { id: "virus-slash", icon: "faVirusSlash", slug: "fa-virus-slash", name: "Virus Slash" },
  { id: "virus-covid-slash", icon: "faVirusCovidSlash", slug: "fa-virus-covid-slash", name: "Virus Covid Slash" },
  { id: "virus-covid", icon: "faVirusCovid", slug: "fa-virus-covid", name: "Virus Covid" },
  { id: "violin", icon: "faViolin", slug: "fa-violin", name: "Violin" },
  { id: "vihara", icon: "faVihara", slug: "fa-vihara", name: "Vihara" },
  { id: "video-plus", icon: "faVideoPlus", slug: "fa-video-plus", name: "Video Plus" },
  { id: "video-arrow-up-right", icon: "faVideoArrowUpRight", slug: "fa-video-arrow-up-right", name: "Video Arrow Up Right" },
  { id: "video-arrow-down-left", icon: "faVideoArrowDownLeft", slug: "fa-video-arrow-down-left", name: "Video Arrow Down Left" },
  { id: "vial-virus", icon: "faVialVirus", slug: "fa-vial-virus", name: "Vial Virus" },
  { id: "vial-circle-check", icon: "faVialCircleCheck", slug: "fa-vial-circle-check", name: "Vial Circle Check" },
  { id: "vial", icon: "faVial", slug: "fa-vial", name: "Vial" },
  { id: "vest-patches", icon: "faVestPatches", slug: "fa-vest-patches", name: "Vest Patches" },
  { id: "vest", icon: "faVest", slug: "fa-vest", name: "Vest" },
  { id: "venus-mars", icon: "faVenusMars", slug: "fa-venus-mars", name: "Venus Mars" },
  { id: "venus-double", icon: "faVenusDouble", slug: "fa-venus-double", name: "Venus Double" },
  { id: "venus", icon: "faVenus", slug: "fa-venus", name: "Venus" },
  { id: "vent-damper", icon: "faVentDamper", slug: "fa-vent-damper", name: "Vent Damper" },
  { id: "vector-square", icon: "faVectorSquare", slug: "fa-vector-square", name: "Vector Square" },
  { id: "vector-polygon", icon: "faVectorPolygon", slug: "fa-vector-polygon", name: "Vector Polygon" },
  { id: "vector-circle", icon: "faVectorCircle", slug: "fa-vector-circle", name: "Vector Circle" },
  { id: "vault", icon: "faVault", slug: "fa-vault", name: "Vault" },
  { id: "van-shuttle", icon: "faVanShuttle", slug: "fa-van-shuttle", name: "Van Shuttle" },
  { id: "value-absolute", icon: "faValueAbsolute", slug: "fa-value-absolute", name: "Value Absolute" },
  { id: "vacuum-robot", icon: "faVacuumRobot", slug: "fa-vacuum-robot", name: "Vacuum Robot" },
  { id: "vacuum", icon: "faVacuum", slug: "fa-vacuum", name: "Vacuum" },
  { id: "v", icon: "faV", slug: "fa-v", name: "V" },
  { id: "utility-pole-double", icon: "faUtilityPoleDouble", slug: "fa-utility-pole-double", name: "Utility Pole Double" },
  { id: "utility-pole", icon: "faUtilityPole", slug: "fa-utility-pole", name: "Utility Pole" },
  { id: "utensils-slash", icon: "faUtensilsSlash", slug: "fa-utensils-slash", name: "Utensils Slash" },
  { id: "utensils", icon: "faUtensils", slug: "fa-utensils", name: "Utensils" },
  { id: "users-viewfinder", icon: "faUsersViewfinder", slug: "fa-users-viewfinder", name: "Users Viewfinder" },
  { id: "users-slash", icon: "faUsersSlash", slug: "fa-users-slash", name: "Users Slash" },
  { id: "users-rectangle", icon: "faUsersRectangle", slug: "fa-users-rectangle", name: "Users Rectangle" },
  { id: "users-rays", icon: "faUsersRays", slug: "fa-users-rays", name: "Users Rays" },
  { id: "users-line", icon: "faUsersLine", slug: "fa-users-line", name: "Users Line" },
  { id: "users-gear", icon: "faUsersGear", slug: "fa-users-gear", name: "Users Gear" },
  { id: "users-between-lines", icon: "faUsersBetweenLines", slug: "fa-users-between-lines", name: "Users Between Lines" },
  { id: "user-xmark", icon: "faUserXmark", slug: "fa-user-xmark", name: "User Xmark" },
  { id: "user-vneck-hair-long", icon: "faUserVneckHairLong", slug: "fa-user-vneck-hair-long", name: "User Vneck Hair Long" },
  { id: "user-vneck-hair", icon: "faUserVneckHair", slug: "fa-user-vneck-hair", name: "User Vneck Hair" },
  { id: "user-vneck", icon: "faUserVneck", slug: "fa-user-vneck", name: "User Vneck" },
  { id: "user-visor", icon: "faUserVisor", slug: "fa-user-visor", name: "User Visor" },
  { id: "user-unlock", icon: "faUserUnlock", slug: "fa-user-unlock", name: "User Unlock" },
  { id: "user-tie-hair-long", icon: "faUserTieHairLong", slug: "fa-user-tie-hair-long", name: "User Tie Hair Long" },
  { id: "user-tie-hair", icon: "faUserTieHair", slug: "fa-user-tie-hair", name: "User Tie Hair" },
  { id: "user-tag", icon: "faUserTag", slug: "fa-user-tag", name: "User Tag" },
  { id: "user-slash", icon: "faUserSlash", slug: "fa-user-slash", name: "User Slash" },
  { id: "user-shield", icon: "faUserShield", slug: "fa-user-shield", name: "User Shield" },
  { id: "user-shakespeare", icon: "faUserShakespeare", slug: "fa-user-shakespeare", name: "User Shakespeare" },
  { id: "user-robot-xmarks", icon: "faUserRobotXmarks", slug: "fa-user-robot-xmarks", name: "User Robot Xmarks" },
  { id: "user-robot", icon: "faUserRobot", slug: "fa-user-robot", name: "User Robot" },
  { id: "user-police-tie", icon: "faUserPoliceTie", slug: "fa-user-police-tie", name: "User Police Tie" },
  { id: "user-police", icon: "faUserPolice", slug: "fa-user-police", name: "User Police" },
  { id: "user-pilot-tie", icon: "faUserPilotTie", slug: "fa-user-pilot-tie", name: "User Pilot Tie" },
  { id: "user-pilot", icon: "faUserPilot", slug: "fa-user-pilot", name: "User Pilot" },
  { id: "user-pen", icon: "faUserPen", slug: "fa-user-pen", name: "User Pen" },
  { id: "user-nurse-hair-long", icon: "faUserNurseHairLong", slug: "fa-user-nurse-hair-long", name: "User Nurse Hair Long" },
  { id: "user-nurse-hair", icon: "faUserNurseHair", slug: "fa-user-nurse-hair", name: "User Nurse Hair" },
  { id: "user-ninja", icon: "faUserNinja", slug: "fa-user-ninja", name: "User Ninja" },
  { id: "user-music", icon: "faUserMusic", slug: "fa-user-music", name: "User Music" },
  { id: "user-magnifying-glass", icon: "faUserMagnifyingGlass", slug: "fa-user-magnifying-glass", name: "User Magnifying Glass" },
  { id: "user-lock", icon: "faUserLock", slug: "fa-user-lock", name: "User Lock" },
  { id: "user-large-slash", icon: "faUserLargeSlash", slug: "fa-user-large-slash", name: "User Large Slash" },
  { id: "user-large", icon: "faUserLarge", slug: "fa-user-large", name: "User Large" },
  { id: "user-injured", icon: "faUserInjured", slug: "fa-user-injured", name: "User Injured" },
  { id: "user-helmet-safety", icon: "faUserHelmetSafety", slug: "fa-user-helmet-safety", name: "User Helmet Safety" },
  { id: "user-headset", icon: "faUserHeadset", slug: "fa-user-headset", name: "User Headset" },
  { id: "user-hair-mullet", icon: "faUserHairMullet", slug: "fa-user-hair-mullet", name: "User Hair Mullet" },
  { id: "user-hair-long", icon: "faUserHairLong", slug: "fa-user-hair-long", name: "User Hair Long" },
  { id: "user-hair-buns", icon: "faUserHairBuns", slug: "fa-user-hair-buns", name: "User Hair Buns" },
  { id: "user-hair", icon: "faUserHair", slug: "fa-user-hair", name: "User Hair" },
  { id: "user-group-simple", icon: "faUserGroupSimple", slug: "fa-user-group-simple", name: "User Group Simple" },
  { id: "user-group-crown", icon: "faUserGroupCrown", slug: "fa-user-group-crown", name: "User Group Crown" },
  { id: "user-group", icon: "faUserGroup", slug: "fa-user-group", name: "User Group" },
  { id: "user-graduate", icon: "faUserGraduate", slug: "fa-user-graduate", name: "User Graduate" },
  { id: "user-gear", icon: "faUserGear", slug: "fa-user-gear", name: "User Gear" },
  { id: "user-doctor-message", icon: "faUserDoctorMessage", slug: "fa-user-doctor-message", name: "User Doctor Message" },
  { id: "user-doctor-hair-long", icon: "faUserDoctorHairLong", slug: "fa-user-doctor-hair-long", name: "User Doctor Hair Long" },
  { id: "user-doctor-hair", icon: "faUserDoctorHair", slug: "fa-user-doctor-hair", name: "User Doctor Hair" },
  { id: "user-doctor", icon: "faUserDoctor", slug: "fa-user-doctor", name: "User Doctor" },
  { id: "user-crown", icon: "faUserCrown", slug: "fa-user-crown", name: "User Crown" },
  { id: "user-cowboy", icon: "faUserCowboy", slug: "fa-user-cowboy", name: "User Cowboy" },
  { id: "user-clock", icon: "faUserClock", slug: "fa-user-clock", name: "User Clock" },
  { id: "user-chef", icon: "faUserChef", slug: "fa-user-chef", name: "User Chef" },
  { id: "user-check", icon: "faUserCheck", slug: "fa-user-check", name: "User Check" },
  { id: "user-astronaut", icon: "faUserAstronaut", slug: "fa-user-astronaut", name: "User Astronaut" },
  { id: "user-alien", icon: "faUserAlien", slug: "fa-user-alien", name: "User Alien" },
  { id: "usb-drive", icon: "faUsbDrive", slug: "fa-usb-drive", name: "Usb Drive" },
  { id: "up-to-line", icon: "faUpToLine", slug: "fa-up-to-line", name: "Up To Line" },
  { id: "up-to-dotted-line", icon: "faUpToDottedLine", slug: "fa-up-to-dotted-line", name: "Up To Dotted Line" },
  { id: "up-to-bracket", icon: "faUpToBracket", slug: "fa-up-to-bracket", name: "Up To Bracket" },
  { id: "up-right-from-square", icon: "faUpRightFromSquare", slug: "fa-up-right-from-square", name: "Up Right From Square" },
  { id: "up-right-and-down-left-from-center", icon: "faUpRightAndDownLeftFromCenter", slug: "fa-up-right-and-down-left-from-center", name: "Up Right And Down Left From Center" },
  { id: "up-right", icon: "faUpRight", slug: "fa-up-right", name: "Up Right" },
  { id: "up-long", icon: "faUpLong", slug: "fa-up-long", name: "Up Long" },
  { id: "up-left", icon: "faUpLeft", slug: "fa-up-left", name: "Up Left" },
  { id: "up-from-line", icon: "faUpFromLine", slug: "fa-up-from-line", name: "Up From Line" },
  { id: "up-from-dotted-line", icon: "faUpFromDottedLine", slug: "fa-up-from-dotted-line", name: "Up From Dotted Line" },
  { id: "up-from-bracket", icon: "faUpFromBracket", slug: "fa-up-from-bracket", name: "Up From Bracket" },
  { id: "up-down-left-right", icon: "faUpDownLeftRight", slug: "fa-up-down-left-right", name: "Up Down Left Right" },
  { id: "up-down", icon: "faUpDown", slug: "fa-up-down", name: "Up Down" },
  { id: "up", icon: "faUp", slug: "fa-up", name: "Up" },
  { id: "unlock-keyhole", icon: "faUnlockKeyhole", slug: "fa-unlock-keyhole", name: "Unlock Keyhole" },
  { id: "universal-access", icon: "faUniversalAccess", slug: "fa-universal-access", name: "Universal Access" },
  { id: "union", icon: "faUnion", slug: "fa-union", name: "Union" },
  { id: "uniform-martial-arts", icon: "faUniformMartialArts", slug: "fa-uniform-martial-arts", name: "Uniform Martial Arts" },
  { id: "unicorn", icon: "faUnicorn", slug: "fa-unicorn", name: "Unicorn" },
  { id: "underline", icon: "faUnderline", slug: "fa-underline", name: "Underline" },
  { id: "umbrella-simple", icon: "faUmbrellaSimple", slug: "fa-umbrella-simple", name: "Umbrella Simple" },
  { id: "ufo-beam", icon: "faUfoBeam", slug: "fa-ufo-beam", name: "Ufo Beam" },
  { id: "u", icon: "faU", slug: "fa-u", name: "U" },
  { id: "tv-retro", icon: "faTvRetro", slug: "fa-tv-retro", name: "Tv Retro" },
  { id: "tv-music", icon: "faTvMusic", slug: "fa-tv-music", name: "Tv Music" },
  { id: "tv", icon: "faTv", slug: "fa-tv", name: "Tv" },
  { id: "turtle", icon: "faTurtle", slug: "fa-turtle", name: "Turtle" },
  { id: "turntable", icon: "faTurntable", slug: "fa-turntable", name: "Turntable" },
  { id: "turn-up", icon: "faTurnUp", slug: "fa-turn-up", name: "Turn Up" },
  { id: "turn-right", icon: "faTurnRight", slug: "fa-turn-right", name: "Turn Right" },
  { id: "turn-left-up", icon: "faTurnLeftUp", slug: "fa-turn-left-up", name: "Turn Left Up" },
  { id: "turn-left-down", icon: "faTurnLeftDown", slug: "fa-turn-left-down", name: "Turn Left Down" },
  { id: "turn-left", icon: "faTurnLeft", slug: "fa-turn-left", name: "Turn Left" },
  { id: "turn-down-right", icon: "faTurnDownRight", slug: "fa-turn-down-right", name: "Turn Down Right" },
  { id: "turn-down-left", icon: "faTurnDownLeft", slug: "fa-turn-down-left", name: "Turn Down Left" },
  { id: "turn-down", icon: "faTurnDown", slug: "fa-turn-down", name: "Turn Down" },
  { id: "turkish-lira-sign", icon: "faTurkishLiraSign", slug: "fa-turkish-lira-sign", name: "Turkish Lira Sign" },
  { id: "turkey", icon: "faTurkey", slug: "fa-turkey", name: "Turkey" },
  { id: "tugrik-sign", icon: "faTugrikSign", slug: "fa-tugrik-sign", name: "Tugrik Sign" },
  { id: "tty-answer", icon: "faTtyAnswer", slug: "fa-tty-answer", name: "Tty Answer" },
  { id: "trumpet", icon: "faTrumpet", slug: "fa-trumpet", name: "Trumpet" },
  { id: "truck-utensils", icon: "faTruckUtensils", slug: "fa-truck-utensils", name: "Truck Utensils" },
  { id: "truck-tow", icon: "faTruckTow", slug: "fa-truck-tow", name: "Truck Tow" },
  { id: "truck-ramp-couch", icon: "faTruckRampCouch", slug: "fa-truck-ramp-couch", name: "Truck Ramp Couch" },
  { id: "truck-ramp-box", icon: "faTruckRampBox", slug: "fa-truck-ramp-box", name: "Truck Ramp Box" },
  { id: "truck-ramp", icon: "faTruckRamp", slug: "fa-truck-ramp", name: "Truck Ramp" },
  { id: "truck-plow", icon: "faTruckPlow", slug: "fa-truck-plow", name: "Truck Plow" },
  { id: "truck-plane", icon: "faTruckPlane", slug: "fa-truck-plane", name: "Truck Plane" },
  { id: "truck-pickup", icon: "faTruckPickup", slug: "fa-truck-pickup", name: "Truck Pickup" },
  { id: "truck-moving", icon: "faTruckMoving", slug: "fa-truck-moving", name: "Truck Moving" },
  { id: "truck-medical", icon: "faTruckMedical", slug: "fa-truck-medical", name: "Truck Medical" },
  { id: "truck-ladder", icon: "faTruckLadder", slug: "fa-truck-ladder", name: "Truck Ladder" },
  { id: "truck-flatbed", icon: "faTruckFlatbed", slug: "fa-truck-flatbed", name: "Truck Flatbed" },
  { id: "truck-fire", icon: "faTruckFire", slug: "fa-truck-fire", name: "Truck Fire" },
  { id: "truck-field-un", icon: "faTruckFieldUn", slug: "fa-truck-field-un", name: "Truck Field Un" },
  { id: "truck-field", icon: "faTruckField", slug: "fa-truck-field", name: "Truck Field" },
  { id: "truck-droplet", icon: "faTruckDroplet", slug: "fa-truck-droplet", name: "Truck Droplet" },
  { id: "truck-container-empty", icon: "faTruckContainerEmpty", slug: "fa-truck-container-empty", name: "Truck Container Empty" },
  { id: "truck-container", icon: "faTruckContainer", slug: "fa-truck-container", name: "Truck Container" },
  { id: "truck-clock", icon: "faTruckClock", slug: "fa-truck-clock", name: "Truck Clock" },
  { id: "truck-bolt", icon: "faTruckBolt", slug: "fa-truck-bolt", name: "Truck Bolt" },
  { id: "truck-arrow-right", icon: "faTruckArrowRight", slug: "fa-truck-arrow-right", name: "Truck Arrow Right" },
  { id: "trowel-bricks", icon: "faTrowelBricks", slug: "fa-trowel-bricks", name: "Trowel Bricks" },
  { id: "trowel", icon: "faTrowel", slug: "fa-trowel", name: "Trowel" },
  { id: "trophy-star", icon: "faTrophyStar", slug: "fa-trophy-star", name: "Trophy Star" },
  { id: "trillium", icon: "faTrillium", slug: "fa-trillium", name: "Trillium" },
  { id: "tricycle-adult", icon: "faTricycleAdult", slug: "fa-tricycle-adult", name: "Tricycle Adult" },
  { id: "tricycle", icon: "faTricycle", slug: "fa-tricycle", name: "Tricycle" },
  { id: "triangle-person-digging", icon: "faTrianglePersonDigging", slug: "fa-triangle-person-digging", name: "Triangle Person Digging" },
  { id: "triangle-instrument", icon: "faTriangleInstrument", slug: "fa-triangle-instrument", name: "Triangle Instrument" },
  { id: "triangle-exclamation", icon: "faTriangleExclamation", slug: "fa-triangle-exclamation", name: "Triangle Exclamation" },
  { id: "trees", icon: "faTrees", slug: "fa-trees", name: "Trees" },
  { id: "tree-palm", icon: "faTreePalm", slug: "fa-tree-palm", name: "Tree Palm" },
  { id: "tree-large", icon: "faTreeLarge", slug: "fa-tree-large", name: "Tree Large" },
  { id: "tree-decorated", icon: "faTreeDecorated", slug: "fa-tree-decorated", name: "Tree Decorated" },
  { id: "tree-deciduous", icon: "faTreeDeciduous", slug: "fa-tree-deciduous", name: "Tree Deciduous" },
  { id: "tree-city", icon: "faTreeCity", slug: "fa-tree-city", name: "Tree City" },
  { id: "tree-christmas", icon: "faTreeChristmas", slug: "fa-tree-christmas", name: "Tree Christmas" },
  { id: "treasure-chest", icon: "faTreasureChest", slug: "fa-treasure-chest", name: "Treasure Chest" },
  { id: "trash-xmark", icon: "faTrashXmark", slug: "fa-trash-xmark", name: "Trash Xmark" },
  { id: "trash-undo", icon: "faTrashUndo", slug: "fa-trash-undo", name: "Trash Undo" },
  { id: "trash-slash", icon: "faTrashSlash", slug: "fa-trash-slash", name: "Trash Slash" },
  { id: "trash-plus", icon: "faTrashPlus", slug: "fa-trash-plus", name: "Trash Plus" },
  { id: "trash-list", icon: "faTrashList", slug: "fa-trash-list", name: "Trash List" },
  { id: "trash-clock", icon: "faTrashClock", slug: "fa-trash-clock", name: "Trash Clock" },
  { id: "trash-check", icon: "faTrashCheck", slug: "fa-trash-check", name: "Trash Check" },
  { id: "trash-can-xmark", icon: "faTrashCanXmark", slug: "fa-trash-can-xmark", name: "Trash Can Xmark" },
  { id: "trash-can-undo", icon: "faTrashCanUndo", slug: "fa-trash-can-undo", name: "Trash Can Undo" },
  { id: "trash-can-slash", icon: "faTrashCanSlash", slug: "fa-trash-can-slash", name: "Trash Can Slash" },
  { id: "trash-can-plus", icon: "faTrashCanPlus", slug: "fa-trash-can-plus", name: "Trash Can Plus" },
  { id: "trash-can-list", icon: "faTrashCanList", slug: "fa-trash-can-list", name: "Trash Can List" },
  { id: "trash-can-clock", icon: "faTrashCanClock", slug: "fa-trash-can-clock", name: "Trash Can Clock" },
  { id: "trash-can-check", icon: "faTrashCanCheck", slug: "fa-trash-can-check", name: "Trash Can Check" },
  { id: "trash-can-arrow-up", icon: "faTrashCanArrowUp", slug: "fa-trash-can-arrow-up", name: "Trash Can Arrow Up" },
  { id: "trash-can", icon: "faTrashCan", slug: "fa-trash-can", name: "Trash Can" },
  { id: "trash-arrow-up", icon: "faTrashArrowUp", slug: "fa-trash-arrow-up", name: "Trash Arrow Up" },
  { id: "transporter-7", icon: "faTransporter7", slug: "fa-transporter-7", name: "Transporter 7" },
  { id: "transporter-6", icon: "faTransporter6", slug: "fa-transporter-6", name: "Transporter 6" },
  { id: "transporter-5", icon: "faTransporter5", slug: "fa-transporter-5", name: "Transporter 5" },
  { id: "transporter-4", icon: "faTransporter4", slug: "fa-transporter-4", name: "Transporter 4" },
  { id: "transporter-2", icon: "faTransporter2", slug: "fa-transporter-2", name: "Transporter 2" },
  { id: "transporter-1", icon: "faTransporter1", slug: "fa-transporter-1", name: "Transporter 1" },
  { id: "transgender", icon: "faTransgender", slug: "fa-transgender", name: "Transgender" },
  { id: "transformer-bolt", icon: "faTransformerBolt", slug: "fa-transformer-bolt", name: "Transformer Bolt" },
  { id: "train-tunnel", icon: "faTrainTunnel", slug: "fa-train-tunnel", name: "Train Tunnel" },
  { id: "train-tram", icon: "faTrainTram", slug: "fa-train-tram", name: "Train Tram" },
  { id: "train-track", icon: "faTrainTrack", slug: "fa-train-track", name: "Train Track" },
  { id: "train-subway-tunnel", icon: "faTrainSubwayTunnel", slug: "fa-train-subway-tunnel", name: "Train Subway Tunnel" },
  { id: "train-subway", icon: "faTrainSubway", slug: "fa-train-subway", name: "Train Subway" },
  { id: "trailer", icon: "faTrailer", slug: "fa-trailer", name: "Trailer" },
  { id: "traffic-light-stop", icon: "faTrafficLightStop", slug: "fa-traffic-light-stop", name: "Traffic Light Stop" },
  { id: "traffic-light-slow", icon: "faTrafficLightSlow", slug: "fa-traffic-light-slow", name: "Traffic Light Slow" },
  { id: "traffic-light-go", icon: "faTrafficLightGo", slug: "fa-traffic-light-go", name: "Traffic Light Go" },
  { id: "traffic-light", icon: "faTrafficLight", slug: "fa-traffic-light", name: "Traffic Light" },
  { id: "trademark", icon: "faTrademark", slug: "fa-trademark", name: "Trademark" },
  { id: "tractor", icon: "faTractor", slug: "fa-tractor", name: "Tractor" },
  { id: "tower-observation", icon: "faTowerObservation", slug: "fa-tower-observation", name: "Tower Observation" },
  { id: "tower-control", icon: "faTowerControl", slug: "fa-tower-control", name: "Tower Control" },
  { id: "tower-cell", icon: "faTowerCell", slug: "fa-tower-cell", name: "Tower Cell" },
  { id: "tower-broadcast", icon: "faTowerBroadcast", slug: "fa-tower-broadcast", name: "Tower Broadcast" },
  { id: "tornado", icon: "faTornado", slug: "fa-tornado", name: "Tornado" },
  { id: "torii-gate", icon: "faToriiGate", slug: "fa-torii-gate", name: "Torii Gate" },
  { id: "toothbrush", icon: "faToothbrush", slug: "fa-toothbrush", name: "Toothbrush" },
  { id: "tooth", icon: "faTooth", slug: "fa-tooth", name: "Tooth" },
  { id: "toolbox", icon: "faToolbox", slug: "fa-toolbox", name: "Toolbox" },
  { id: "tombstone-blank", icon: "faTombstoneBlank", slug: "fa-tombstone-blank", name: "Tombstone Blank" },
  { id: "tombstone", icon: "faTombstone", slug: "fa-tombstone", name: "Tombstone" },
  { id: "tomato", icon: "faTomato", slug: "fa-tomato", name: "Tomato" },
  { id: "toilets-portable", icon: "faToiletsPortable", slug: "fa-toilets-portable", name: "Toilets Portable" },
  { id: "toilet-portable", icon: "faToiletPortable", slug: "fa-toilet-portable", name: "Toilet Portable" },
  { id: "toilet-paper-xmark", icon: "faToiletPaperXmark", slug: "fa-toilet-paper-xmark", name: "Toilet Paper Xmark" },
  { id: "toilet-paper-under-slash", icon: "faToiletPaperUnderSlash", slug: "fa-toilet-paper-under-slash", name: "Toilet Paper Under Slash" },
  { id: "toilet-paper-under", icon: "faToiletPaperUnder", slug: "fa-toilet-paper-under", name: "Toilet Paper Under" },
  { id: "toilet-paper-slash", icon: "faToiletPaperSlash", slug: "fa-toilet-paper-slash", name: "Toilet Paper Slash" },
  { id: "toilet-paper-check", icon: "faToiletPaperCheck", slug: "fa-toilet-paper-check", name: "Toilet Paper Check" },
  { id: "toilet-paper-blank-under", icon: "faToiletPaperBlankUnder", slug: "fa-toilet-paper-blank-under", name: "Toilet Paper Blank Under" },
  { id: "toilet-paper-blank", icon: "faToiletPaperBlank", slug: "fa-toilet-paper-blank", name: "Toilet Paper Blank" },
  { id: "toilet-paper", icon: "faToiletPaper", slug: "fa-toilet-paper", name: "Toilet Paper" },
  { id: "toilet", icon: "faToilet", slug: "fa-toilet", name: "Toilet" },
  { id: "toggle-off", icon: "faToggleOff", slug: "fa-toggle-off", name: "Toggle Off" },
  { id: "toggle-large-on", icon: "faToggleLargeOn", slug: "fa-toggle-large-on", name: "Toggle Large On" },
  { id: "toggle-large-off", icon: "faToggleLargeOff", slug: "fa-toggle-large-off", name: "Toggle Large Off" },
  { id: "tire-rugged", icon: "faTireRugged", slug: "fa-tire-rugged", name: "Tire Rugged" },
  { id: "tire-pressure-warning", icon: "faTirePressureWarning", slug: "fa-tire-pressure-warning", name: "Tire Pressure Warning" },
  { id: "tire-flat", icon: "faTireFlat", slug: "fa-tire-flat", name: "Tire Flat" },
  { id: "tire", icon: "faTire", slug: "fa-tire", name: "Tire" },
  { id: "timer", icon: "faTimer", slug: "fa-timer", name: "Timer" },
  { id: "timeline-arrow", icon: "faTimelineArrow", slug: "fa-timeline-arrow", name: "Timeline Arrow" },
  { id: "timeline", icon: "faTimeline", slug: "fa-timeline", name: "Timeline" },
  { id: "tilde", icon: "faTilde", slug: "fa-tilde", name: "Tilde" },
  { id: "tickets-simple", icon: "faTicketsSimple", slug: "fa-tickets-simple", name: "Tickets Simple" },
  { id: "tickets-perforated", icon: "faTicketsPerforated", slug: "fa-tickets-perforated", name: "Tickets Perforated" },
  { id: "tickets-airline", icon: "faTicketsAirline", slug: "fa-tickets-airline", name: "Tickets Airline" },
  { id: "tickets", icon: "faTickets", slug: "fa-tickets", name: "Tickets" },
  { id: "ticket-simple", icon: "faTicketSimple", slug: "fa-ticket-simple", name: "Ticket Simple" },
  { id: "ticket-perforated", icon: "faTicketPerforated", slug: "fa-ticket-perforated", name: "Ticket Perforated" },
  { id: "ticket-airline", icon: "faTicketAirline", slug: "fa-ticket-airline", name: "Ticket Airline" },
  { id: "tick", icon: "faTick", slug: "fa-tick", name: "Tick" },
  { id: "theta", icon: "faTheta", slug: "fa-theta", name: "Theta" },
  { id: "thermometer", icon: "faThermometer", slug: "fa-thermometer", name: "Thermometer" },
  { id: "text-width", icon: "faTextWidth", slug: "fa-text-width", name: "Text Width" },
  { id: "text-slash", icon: "faTextSlash", slug: "fa-text-slash", name: "Text Slash" },
  { id: "text-height", icon: "faTextHeight", slug: "fa-text-height", name: "Text Height" },
  { id: "terminal", icon: "faTerminal", slug: "fa-terminal", name: "Terminal" },
  { id: "tents", icon: "faTents", slug: "fa-tents", name: "Tents" },
  { id: "tent-double-peak", icon: "faTentDoublePeak", slug: "fa-tent-double-peak", name: "Tent Double Peak" },
  { id: "tent-arrows-down", icon: "faTentArrowsDown", slug: "fa-tent-arrows-down", name: "Tent Arrows Down" },
  { id: "tent-arrow-turn-left", icon: "faTentArrowTurnLeft", slug: "fa-tent-arrow-turn-left", name: "Tent Arrow Turn Left" },
  { id: "tent-arrow-left-right", icon: "faTentArrowLeftRight", slug: "fa-tent-arrow-left-right", name: "Tent Arrow Left Right" },
  { id: "tent-arrow-down-to-line", icon: "faTentArrowDownToLine", slug: "fa-tent-arrow-down-to-line", name: "Tent Arrow Down To Line" },
  { id: "tent", icon: "faTent", slug: "fa-tent", name: "Tent" },
  { id: "tennis-ball", icon: "faTennisBall", slug: "fa-tennis-ball", name: "Tennis Ball" },
  { id: "tenge-sign", icon: "faTengeSign", slug: "fa-tenge-sign", name: "Tenge Sign" },
  { id: "temperature-three-quarters", icon: "faTemperatureThreeQuarters", slug: "fa-temperature-three-quarters", name: "Temperature Three Quarters" },
  { id: "temperature-sun", icon: "faTemperatureSun", slug: "fa-temperature-sun", name: "Temperature Sun" },
  { id: "temperature-snow", icon: "faTemperatureSnow", slug: "fa-temperature-snow", name: "Temperature Snow" },
  { id: "temperature-quarter", icon: "faTemperatureQuarter", slug: "fa-temperature-quarter", name: "Temperature Quarter" },
  { id: "temperature-low", icon: "faTemperatureLow", slug: "fa-temperature-low", name: "Temperature Low" },
  { id: "temperature-list", icon: "faTemperatureList", slug: "fa-temperature-list", name: "Temperature List" },
  { id: "temperature-high", icon: "faTemperatureHigh", slug: "fa-temperature-high", name: "Temperature High" },
  { id: "temperature-half", icon: "faTemperatureHalf", slug: "fa-temperature-half", name: "Temperature Half" },
  { id: "temperature-full", icon: "faTemperatureFull", slug: "fa-temperature-full", name: "Temperature Full" },
  { id: "temperature-empty", icon: "faTemperatureEmpty", slug: "fa-temperature-empty", name: "Temperature Empty" },
  { id: "temperature-arrow-up", icon: "faTemperatureArrowUp", slug: "fa-temperature-arrow-up", name: "Temperature Arrow Up" },
  { id: "temperature-arrow-down", icon: "faTemperatureArrowDown", slug: "fa-temperature-arrow-down", name: "Temperature Arrow Down" },
  { id: "telescope", icon: "faTelescope", slug: "fa-telescope", name: "Telescope" },
  { id: "teeth-open", icon: "faTeethOpen", slug: "fa-teeth-open", name: "Teeth Open" },
  { id: "teeth", icon: "faTeeth", slug: "fa-teeth", name: "Teeth" },
  { id: "teddy-bear", icon: "faTeddyBear", slug: "fa-teddy-bear", name: "Teddy Bear" },
  { id: "taxi-bus", icon: "faTaxiBus", slug: "fa-taxi-bus", name: "Taxi Bus" },
  { id: "taxi", icon: "faTaxi", slug: "fa-taxi", name: "Taxi" },
  { id: "tarp-droplet", icon: "faTarpDroplet", slug: "fa-tarp-droplet", name: "Tarp Droplet" },
  { id: "tarp", icon: "faTarp", slug: "fa-tarp", name: "Tarp" },
  { id: "tape", icon: "faTape", slug: "fa-tape", name: "Tape" },
  { id: "tank-water", icon: "faTankWater", slug: "fa-tank-water", name: "Tank Water" },
  { id: "tamale", icon: "faTamale", slug: "fa-tamale", name: "Tamale" },
  { id: "tally-4", icon: "faTally4", slug: "fa-tally-4", name: "Tally 4" },
  { id: "tally-3", icon: "faTally3", slug: "fa-tally-3", name: "Tally 3" },
  { id: "tally-2", icon: "faTally2", slug: "fa-tally-2", name: "Tally 2" },
  { id: "tally-1", icon: "faTally1", slug: "fa-tally-1", name: "Tally 1" },
  { id: "taco", icon: "faTaco", slug: "fa-taco", name: "Taco" },
  { id: "tachograph-digital", icon: "faTachographDigital", slug: "fa-tachograph-digital", name: "Tachograph Digital" },
  { id: "tablet-screen-button", icon: "faTabletScreenButton", slug: "fa-tablet-screen-button", name: "Tablet Screen Button" },
  { id: "tablet-screen", icon: "faTabletScreen", slug: "fa-tablet-screen", name: "Tablet Screen" },
  { id: "tablet-button", icon: "faTabletButton", slug: "fa-tablet-button", name: "Tablet Button" },
  { id: "table-tree", icon: "faTableTree", slug: "fa-table-tree", name: "Table Tree" },
  { id: "table-tennis-paddle-ball", icon: "faTableTennisPaddleBall", slug: "fa-table-tennis-paddle-ball", name: "Table Tennis Paddle Ball" },
  { id: "table-rows", icon: "faTableRows", slug: "fa-table-rows", name: "Table Rows" },
  { id: "table-pivot", icon: "faTablePivot", slug: "fa-table-pivot", name: "Table Pivot" },
  { id: "table-picnic", icon: "faTablePicnic", slug: "fa-table-picnic", name: "Table Picnic" },
  { id: "table-list", icon: "faTableList", slug: "fa-table-list", name: "Table List" },
  { id: "table-layout", icon: "faTableLayout", slug: "fa-table-layout", name: "Table Layout" },
  { id: "table-columns", icon: "faTableColumns", slug: "fa-table-columns", name: "Table Columns" },
  { id: "table-cells-row-lock", icon: "faTableCellsRowLock", slug: "fa-table-cells-row-lock", name: "Table Cells Row Lock" },
  { id: "table-cells-lock", icon: "faTableCellsLock", slug: "fa-table-cells-lock", name: "Table Cells Lock" },
  { id: "table-cells-large", icon: "faTableCellsLarge", slug: "fa-table-cells-large", name: "Table Cells Large" },
  { id: "table-cells-column-lock", icon: "faTableCellsColumnLock", slug: "fa-table-cells-column-lock", name: "Table Cells Column Lock" },
  { id: "table-cells", icon: "faTableCells", slug: "fa-table-cells", name: "Table Cells" },
  { id: "t-rex", icon: "faTRex", slug: "fa-t-rex", name: "T Rex" },
  { id: "t", icon: "faT", slug: "fa-t", name: "T" },
  { id: "syringe", icon: "faSyringe", slug: "fa-syringe", name: "Syringe" },
  { id: "synagogue", icon: "faSynagogue", slug: "fa-synagogue", name: "Synagogue" },
  { id: "symbols", icon: "faSymbols", slug: "fa-symbols", name: "Symbols" },
  { id: "swords-laser", icon: "faSwordsLaser", slug: "fa-swords-laser", name: "Swords Laser" },
  { id: "swords", icon: "faSwords", slug: "fa-swords", name: "Swords" },
  { id: "sword-laser-alt", icon: "faSwordLaserAlt", slug: "fa-sword-laser-alt", name: "Sword Laser Alt" },
  { id: "sword-laser", icon: "faSwordLaser", slug: "fa-sword-laser", name: "Sword Laser" },
  { id: "sword", icon: "faSword", slug: "fa-sword", name: "Sword" },
  { id: "swatchbook", icon: "faSwatchbook", slug: "fa-swatchbook", name: "Swatchbook" },
  { id: "swap-arrows", icon: "faSwapArrows", slug: "fa-swap-arrows", name: "Swap Arrows" },
  { id: "swap", icon: "faSwap", slug: "fa-swap", name: "Swap" },
  { id: "sushi-roll", icon: "faSushiRoll", slug: "fa-sushi-roll", name: "Sushi Roll" },
  { id: "sushi", icon: "faSushi", slug: "fa-sushi", name: "Sushi" },
  { id: "superscript", icon: "faSuperscript", slug: "fa-superscript", name: "Superscript" },
  { id: "sunset", icon: "faSunset", slug: "fa-sunset", name: "Sunset" },
  { id: "sunrise", icon: "faSunrise", slug: "fa-sunrise", name: "Sunrise" },
  { id: "sunglasses", icon: "faSunglasses", slug: "fa-sunglasses", name: "Sunglasses" },
  { id: "sun-plant-wilt", icon: "faSunPlantWilt", slug: "fa-sun-plant-wilt", name: "Sun Plant Wilt" },
  { id: "sun-haze", icon: "faSunHaze", slug: "fa-sun-haze", name: "Sun Haze" },
  { id: "sun-dust", icon: "faSunDust", slug: "fa-sun-dust", name: "Sun Dust" },
  { id: "sun-cloud", icon: "faSunCloud", slug: "fa-sun-cloud", name: "Sun Cloud" },
  { id: "sun-bright", icon: "faSunBright", slug: "fa-sun-bright", name: "Sun Bright" },
  { id: "suitcase-rolling", icon: "faSuitcaseRolling", slug: "fa-suitcase-rolling", name: "Suitcase Rolling" },
  { id: "suitcase-medical", icon: "faSuitcaseMedical", slug: "fa-suitcase-medical", name: "Suitcase Medical" },
  { id: "suitcase", icon: "faSuitcase", slug: "fa-suitcase", name: "Suitcase" },
  { id: "subtitles-slash", icon: "faSubtitlesSlash", slug: "fa-subtitles-slash", name: "Subtitles Slash" },
  { id: "subtitles", icon: "faSubtitles", slug: "fa-subtitles", name: "Subtitles" },
  { id: "stroopwafel", icon: "faStroopwafel", slug: "fa-stroopwafel", name: "Stroopwafel" },
  { id: "stretcher", icon: "faStretcher", slug: "fa-stretcher", name: "Stretcher" },
  { id: "store-slash", icon: "faStoreSlash", slug: "fa-store-slash", name: "Store Slash" },
  { id: "store-lock", icon: "faStoreLock", slug: "fa-store-lock", name: "Store Lock" },
  { id: "stopwatch-20", icon: "faStopwatch20", slug: "fa-stopwatch-20", name: "Stopwatch 20" },
  { id: "stopwatch", icon: "faStopwatch", slug: "fa-stopwatch", name: "Stopwatch" },
  { id: "stomach", icon: "faStomach", slug: "fa-stomach", name: "Stomach" },
  { id: "sterling-sign", icon: "faSterlingSign", slug: "fa-sterling-sign", name: "Sterling Sign" },
  { id: "steering-wheel", icon: "faSteeringWheel", slug: "fa-steering-wheel", name: "Steering Wheel" },
  { id: "steak", icon: "faSteak", slug: "fa-steak", name: "Steak" },
  { id: "starship", icon: "faStarship", slug: "fa-starship", name: "Starship" },
  { id: "starfighter-twin-ion-engine-advanced", icon: "faStarfighterTwinIonEngineAdvanced", slug: "fa-starfighter-twin-ion-engine-advanced", name: "Starfighter Twin Ion Engine Advanced" },
  { id: "starfighter-twin-ion-engine", icon: "faStarfighterTwinIonEngine", slug: "fa-starfighter-twin-ion-engine", name: "Starfighter Twin Ion Engine" },
  { id: "starfighter", icon: "faStarfighter", slug: "fa-starfighter", name: "Starfighter" },
  { id: "star-shooting", icon: "faStarShooting", slug: "fa-star-shooting", name: "Star Shooting" },
  { id: "star-sharp-half-stroke", icon: "faStarSharpHalfStroke", slug: "fa-star-sharp-half-stroke", name: "Star Sharp Half Stroke" },
  { id: "star-sharp-half", icon: "faStarSharpHalf", slug: "fa-star-sharp-half", name: "Star Sharp Half" },
  { id: "star-sharp", icon: "faStarSharp", slug: "fa-star-sharp", name: "Star Sharp" },
  { id: "star-of-david", icon: "faStarOfDavid", slug: "fa-star-of-david", name: "Star Of David" },
  { id: "star-half-stroke", icon: "faStarHalfStroke", slug: "fa-star-half-stroke", name: "Star Half Stroke" },
  { id: "star-half", icon: "faStarHalf", slug: "fa-star-half", name: "Star Half" },
  { id: "star-exclamation", icon: "faStarExclamation", slug: "fa-star-exclamation", name: "Star Exclamation" },
  { id: "star-christmas", icon: "faStarChristmas", slug: "fa-star-christmas", name: "Star Christmas" },
  { id: "star-and-crescent", icon: "faStarAndCrescent", slug: "fa-star-and-crescent", name: "Star And Crescent" },
  { id: "stapler", icon: "faStapler", slug: "fa-stapler", name: "Stapler" },
  { id: "standard-definition", icon: "faStandardDefinition", slug: "fa-standard-definition", name: "Standard Definition" },
  { id: "stairs", icon: "faStairs", slug: "fa-stairs", name: "Stairs" },
  { id: "staff-snake", icon: "faStaffSnake", slug: "fa-staff-snake", name: "Staff Snake" },
  { id: "squirrel", icon: "faSquirrel", slug: "fa-squirrel", name: "Squirrel" },
  { id: "squid", icon: "faSquid", slug: "fa-squid", name: "Squid" },
  { id: "square-z", icon: "faSquareZ", slug: "fa-square-z", name: "Square Z" },
  { id: "square-y", icon: "faSquareY", slug: "fa-square-y", name: "Square Y" },
  { id: "square-x", icon: "faSquareX", slug: "fa-square-x", name: "Square X" },
  { id: "square-w", icon: "faSquareW", slug: "fa-square-w", name: "Square W" },
  { id: "square-virus", icon: "faSquareVirus", slug: "fa-square-virus", name: "Square Virus" },
  { id: "square-v", icon: "faSquareV", slug: "fa-square-v", name: "Square V" },
  { id: "square-user", icon: "faSquareUser", slug: "fa-square-user", name: "Square User" },
  { id: "square-up-right", icon: "faSquareUpRight", slug: "fa-square-up-right", name: "Square Up Right" },
  { id: "square-up-left", icon: "faSquareUpLeft", slug: "fa-square-up-left", name: "Square Up Left" },
  { id: "square-up", icon: "faSquareUp", slug: "fa-square-up", name: "Square Up" },
  { id: "square-u", icon: "faSquareU", slug: "fa-square-u", name: "Square U" },
  { id: "square-this-way-up", icon: "faSquareThisWayUp", slug: "fa-square-this-way-up", name: "Square This Way Up" },
  { id: "square-terminal", icon: "faSquareTerminal", slug: "fa-square-terminal", name: "Square Terminal" },
  { id: "square-t", icon: "faSquareT", slug: "fa-square-t", name: "Square T" },
  { id: "square-star", icon: "faSquareStar", slug: "fa-square-star", name: "Square Star" },
  { id: "square-small", icon: "faSquareSmall", slug: "fa-square-small", name: "Square Small" },
  { id: "square-sliders-vertical", icon: "faSquareSlidersVertical", slug: "fa-square-sliders-vertical", name: "Square Sliders Vertical" },
  { id: "square-sliders", icon: "faSquareSliders", slug: "fa-square-sliders", name: "Square Sliders" },
  { id: "square-share-nodes", icon: "faSquareShareNodes", slug: "fa-square-share-nodes", name: "Square Share Nodes" },
  { id: "square-s", icon: "faSquareS", slug: "fa-square-s", name: "Square S" },
  { id: "square-rss", icon: "faSquareRss", slug: "fa-square-rss", name: "Square Rss" },
  { id: "square-root-variable", icon: "faSquareRootVariable", slug: "fa-square-root-variable", name: "Square Root Variable" },
  { id: "square-root", icon: "faSquareRoot", slug: "fa-square-root", name: "Square Root" },
  { id: "square-ring", icon: "faSquareRing", slug: "fa-square-ring", name: "Square Ring" },
  { id: "square-right", icon: "faSquareRight", slug: "fa-square-right", name: "Square Right" },
  { id: "square-r", icon: "faSquareR", slug: "fa-square-r", name: "Square R" },
  { id: "square-quote", icon: "faSquareQuote", slug: "fa-square-quote", name: "Square Quote" },
  { id: "square-quarters", icon: "faSquareQuarters", slug: "fa-square-quarters", name: "Square Quarters" },
  { id: "square-q", icon: "faSquareQ", slug: "fa-square-q", name: "Square Q" },
  { id: "square-poll-vertical", icon: "faSquarePollVertical", slug: "fa-square-poll-vertical", name: "Square Poll Vertical" },
  { id: "square-poll-horizontal", icon: "faSquarePollHorizontal", slug: "fa-square-poll-horizontal", name: "Square Poll Horizontal" },
  { id: "square-plus", icon: "faSquarePlus", slug: "fa-square-plus", name: "Square Plus" },
  { id: "square-phone-hangup", icon: "faSquarePhoneHangup", slug: "fa-square-phone-hangup", name: "Square Phone Hangup" },
  { id: "square-phone-flip", icon: "faSquarePhoneFlip", slug: "fa-square-phone-flip", name: "Square Phone Flip" },
  { id: "square-phone", icon: "faSquarePhone", slug: "fa-square-phone", name: "Square Phone" },
  { id: "square-person-confined", icon: "faSquarePersonConfined", slug: "fa-square-person-confined", name: "Square Person Confined" },
  { id: "square-pen", icon: "faSquarePen", slug: "fa-square-pen", name: "Square Pen" },
  { id: "square-parking-slash", icon: "faSquareParkingSlash", slug: "fa-square-parking-slash", name: "Square Parking Slash" },
  { id: "square-parking", icon: "faSquareParking", slug: "fa-square-parking", name: "Square Parking" },
  { id: "square-p", icon: "faSquareP", slug: "fa-square-p", name: "Square P" },
  { id: "square-o", icon: "faSquareO", slug: "fa-square-o", name: "Square O" },
  { id: "square-nfi", icon: "faSquareNfi", slug: "fa-square-nfi", name: "Square Nfi" },
  { id: "square-n", icon: "faSquareN", slug: "fa-square-n", name: "Square N" },
  { id: "square-m", icon: "faSquareM", slug: "fa-square-m", name: "Square M" },
  { id: "square-list", icon: "faSquareList", slug: "fa-square-list", name: "Square List" },
  { id: "square-left", icon: "faSquareLeft", slug: "fa-square-left", name: "Square Left" },
  { id: "square-l", icon: "faSquareL", slug: "fa-square-l", name: "Square L" },
  { id: "square-kanban", icon: "faSquareKanban", slug: "fa-square-kanban", name: "Square Kanban" },
  { id: "square-k", icon: "faSquareK", slug: "fa-square-k", name: "Square K" },
  { id: "square-j", icon: "faSquareJ", slug: "fa-square-j", name: "Square J" },
  { id: "square-i", icon: "faSquareI", slug: "fa-square-i", name: "Square I" },
  { id: "square-h", icon: "faSquareH", slug: "fa-square-h", name: "Square H" },
  { id: "square-g", icon: "faSquareG", slug: "fa-square-g", name: "Square G" },
  { id: "square-full", icon: "faSquareFull", slug: "fa-square-full", name: "Square Full" },
  { id: "square-fragile", icon: "faSquareFragile", slug: "fa-square-fragile", name: "Square Fragile" },
  { id: "square-f", icon: "faSquareF", slug: "fa-square-f", name: "Square F" },
  { id: "square-exclamation", icon: "faSquareExclamation", slug: "fa-square-exclamation", name: "Square Exclamation" },
  { id: "square-envelope", icon: "faSquareEnvelope", slug: "fa-square-envelope", name: "Square Envelope" },
  { id: "square-ellipsis-vertical", icon: "faSquareEllipsisVertical", slug: "fa-square-ellipsis-vertical", name: "Square Ellipsis Vertical" },
  { id: "square-ellipsis", icon: "faSquareEllipsis", slug: "fa-square-ellipsis", name: "Square Ellipsis" },
  { id: "square-e", icon: "faSquareE", slug: "fa-square-e", name: "Square E" },
  { id: "square-down-right", icon: "faSquareDownRight", slug: "fa-square-down-right", name: "Square Down Right" },
  { id: "square-down-left", icon: "faSquareDownLeft", slug: "fa-square-down-left", name: "Square Down Left" },
  { id: "square-down", icon: "faSquareDown", slug: "fa-square-down", name: "Square Down" },
  { id: "square-dollar", icon: "faSquareDollar", slug: "fa-square-dollar", name: "Square Dollar" },
  { id: "square-divide", icon: "faSquareDivide", slug: "fa-square-divide", name: "Square Divide" },
  { id: "square-dashed-circle-plus", icon: "faSquareDashedCirclePlus", slug: "fa-square-dashed-circle-plus", name: "Square Dashed Circle Plus" },
  { id: "square-dashed", icon: "faSquareDashed", slug: "fa-square-dashed", name: "Square Dashed" },
  { id: "square-d", icon: "faSquareD", slug: "fa-square-d", name: "Square D" },
  { id: "square-code", icon: "faSquareCode", slug: "fa-square-code", name: "Square Code" },
  { id: "square-chevron-up", icon: "faSquareChevronUp", slug: "fa-square-chevron-up", name: "Square Chevron Up" },
  { id: "square-chevron-right", icon: "faSquareChevronRight", slug: "fa-square-chevron-right", name: "Square Chevron Right" },
  { id: "square-chevron-left", icon: "faSquareChevronLeft", slug: "fa-square-chevron-left", name: "Square Chevron Left" },
  { id: "square-chevron-down", icon: "faSquareChevronDown", slug: "fa-square-chevron-down", name: "Square Chevron Down" },
  { id: "square-caret-up", icon: "faSquareCaretUp", slug: "fa-square-caret-up", name: "Square Caret Up" },
  { id: "square-caret-right", icon: "faSquareCaretRight", slug: "fa-square-caret-right", name: "Square Caret Right" },
  { id: "square-caret-left", icon: "faSquareCaretLeft", slug: "fa-square-caret-left", name: "Square Caret Left" },
  { id: "square-caret-down", icon: "faSquareCaretDown", slug: "fa-square-caret-down", name: "Square Caret Down" },
  { id: "square-c", icon: "faSquareC", slug: "fa-square-c", name: "Square C" },
  { id: "square-bolt", icon: "faSquareBolt", slug: "fa-square-bolt", name: "Square Bolt" },
  { id: "square-b", icon: "faSquareB", slug: "fa-square-b", name: "Square B" },
  { id: "square-arrow-up-right", icon: "faSquareArrowUpRight", slug: "fa-square-arrow-up-right", name: "Square Arrow Up Right" },
  { id: "square-arrow-up-left", icon: "faSquareArrowUpLeft", slug: "fa-square-arrow-up-left", name: "Square Arrow Up Left" },
  { id: "square-arrow-up", icon: "faSquareArrowUp", slug: "fa-square-arrow-up", name: "Square Arrow Up" },
  { id: "square-arrow-right", icon: "faSquareArrowRight", slug: "fa-square-arrow-right", name: "Square Arrow Right" },
  { id: "square-arrow-left", icon: "faSquareArrowLeft", slug: "fa-square-arrow-left", name: "Square Arrow Left" },
  { id: "square-arrow-down-right", icon: "faSquareArrowDownRight", slug: "fa-square-arrow-down-right", name: "Square Arrow Down Right" },
  { id: "square-arrow-down-left", icon: "faSquareArrowDownLeft", slug: "fa-square-arrow-down-left", name: "Square Arrow Down Left" },
  { id: "square-arrow-down", icon: "faSquareArrowDown", slug: "fa-square-arrow-down", name: "Square Arrow Down" },
  { id: "square-ampersand", icon: "faSquareAmpersand", slug: "fa-square-ampersand", name: "Square Ampersand" },
  { id: "square-a-lock", icon: "faSquareALock", slug: "fa-square-a-lock", name: "Square A Lock" },
  { id: "square-a", icon: "faSquareA", slug: "fa-square-a", name: "Square A" },
  { id: "square-9", icon: "faSquare9", slug: "fa-square-9", name: "Square 9" },
  { id: "square-8", icon: "faSquare8", slug: "fa-square-8", name: "Square 8" },
  { id: "square-7", icon: "faSquare7", slug: "fa-square-7", name: "Square 7" },
  { id: "square-6", icon: "faSquare6", slug: "fa-square-6", name: "Square 6" },
  { id: "square-5", icon: "faSquare5", slug: "fa-square-5", name: "Square 5" },
  { id: "square-4", icon: "faSquare4", slug: "fa-square-4", name: "Square 4" },
  { id: "square-3", icon: "faSquare3", slug: "fa-square-3", name: "Square 3" },
  { id: "square-2", icon: "faSquare2", slug: "fa-square-2", name: "Square 2" },
  { id: "square-1", icon: "faSquare1", slug: "fa-square-1", name: "Square 1" },
  { id: "square-0", icon: "faSquare0", slug: "fa-square-0", name: "Square 0" },
  { id: "sprinkler-ceiling", icon: "faSprinklerCeiling", slug: "fa-sprinkler-ceiling", name: "Sprinkler Ceiling" },
  { id: "sprinkler", icon: "faSprinkler", slug: "fa-sprinkler", name: "Sprinkler" },
  { id: "spray-can-sparkles", icon: "faSprayCanSparkles", slug: "fa-spray-can-sparkles", name: "Spray Can Sparkles" },
  { id: "spray-can", icon: "faSprayCan", slug: "fa-spray-can", name: "Spray Can" },
  { id: "sportsball", icon: "faSportsball", slug: "fa-sportsball", name: "Sportsball" },
  { id: "spoon", icon: "faSpoon", slug: "fa-spoon", name: "Spoon" },
  { id: "splotch", icon: "faSplotch", slug: "fa-splotch", name: "Splotch" },
  { id: "split", icon: "faSplit", slug: "fa-split", name: "Split" },
  { id: "spinner-scale", icon: "faSpinnerScale", slug: "fa-spinner-scale", name: "Spinner Scale" },
  { id: "spider-web", icon: "faSpiderWeb", slug: "fa-spider-web", name: "Spider Web" },
  { id: "spider-black-widow", icon: "faSpiderBlackWidow", slug: "fa-spider-black-widow", name: "Spider Black Widow" },
  { id: "spider", icon: "faSpider", slug: "fa-spider", name: "Spider" },
  { id: "spell-check", icon: "faSpellCheck", slug: "fa-spell-check", name: "Spell Check" },
  { id: "sparkle", icon: "faSparkle", slug: "fa-sparkle", name: "Sparkle" },
  { id: "spaghetti-monster-flying", icon: "faSpaghettiMonsterFlying", slug: "fa-spaghetti-monster-flying", name: "Spaghetti Monster Flying" },
  { id: "spade", icon: "faSpade", slug: "fa-spade", name: "Spade" },
  { id: "space-station-moon-construction", icon: "faSpaceStationMoonConstruction", slug: "fa-space-station-moon-construction", name: "Space Station Moon Construction" },
  { id: "space-station-moon", icon: "faSpaceStationMoon", slug: "fa-space-station-moon", name: "Space Station Moon" },
  { id: "spa", icon: "faSpa", slug: "fa-spa", name: "Spa" },
  { id: "sort-up", icon: "faSortUp", slug: "fa-sort-up", name: "Sort Up" },
  { id: "sort-down", icon: "faSortDown", slug: "fa-sort-down", name: "Sort Down" },
  { id: "soft-serve", icon: "faSoftServe", slug: "fa-soft-serve", name: "Soft Serve" },
  { id: "socks", icon: "faSocks", slug: "fa-socks", name: "Socks" },
  { id: "snowplow", icon: "faSnowplow", slug: "fa-snowplow", name: "Snowplow" },
  { id: "snowman-head", icon: "faSnowmanHead", slug: "fa-snowman-head", name: "Snowman Head" },
  { id: "snowman", icon: "faSnowman", slug: "fa-snowman", name: "Snowman" },
  { id: "snowflakes", icon: "faSnowflakes", slug: "fa-snowflakes", name: "Snowflakes" },
  { id: "snowflake-droplets", icon: "faSnowflakeDroplets", slug: "fa-snowflake-droplets", name: "Snowflake Droplets" },
  { id: "snow-blowing", icon: "faSnowBlowing", slug: "fa-snow-blowing", name: "Snow Blowing" },
  { id: "snooze", icon: "faSnooze", slug: "fa-snooze", name: "Snooze" },
  { id: "snake", icon: "faSnake", slug: "fa-snake", name: "Snake" },
  { id: "smoking", icon: "faSmoking", slug: "fa-smoking", name: "Smoking" },
  { id: "smoke", icon: "faSmoke", slug: "fa-smoke", name: "Smoke" },
  { id: "smog", icon: "faSmog", slug: "fa-smog", name: "Smog" },
  { id: "slot-machine", icon: "faSlotMachine", slug: "fa-slot-machine", name: "Slot Machine" },
  { id: "sliders-up", icon: "faSlidersUp", slug: "fa-sliders-up", name: "Sliders Up" },
  { id: "sliders-simple", icon: "faSlidersSimple", slug: "fa-sliders-simple", name: "Sliders Simple" },
  { id: "slider", icon: "faSlider", slug: "fa-slider", name: "Slider" },
  { id: "sleigh", icon: "faSleigh", slug: "fa-sleigh", name: "Sleigh" },
  { id: "slash-forward", icon: "faSlashForward", slug: "fa-slash-forward", name: "Slash Forward" },
  { id: "slash-back", icon: "faSlashBack", slug: "fa-slash-back", name: "Slash Back" },
  { id: "skull-crossbones", icon: "faSkullCrossbones", slug: "fa-skull-crossbones", name: "Skull Crossbones" },
  { id: "skull-cow", icon: "faSkullCow", slug: "fa-skull-cow", name: "Skull Cow" },
  { id: "skull", icon: "faSkull", slug: "fa-skull", name: "Skull" },
  { id: "ski-boot-ski", icon: "faSkiBootSki", slug: "fa-ski-boot-ski", name: "Ski Boot Ski" },
  { id: "ski-boot", icon: "faSkiBoot", slug: "fa-ski-boot", name: "Ski Boot" },
  { id: "skeleton-ribs", icon: "faSkeletonRibs", slug: "fa-skeleton-ribs", name: "Skeleton Ribs" },
  { id: "skeleton", icon: "faSkeleton", slug: "fa-skeleton", name: "Skeleton" },
  { id: "siren-on", icon: "faSirenOn", slug: "fa-siren-on", name: "Siren On" },
  { id: "siren", icon: "faSiren", slug: "fa-siren", name: "Siren" },
  { id: "sink", icon: "faSink", slug: "fa-sink", name: "Sink" },
  { id: "sim-cards", icon: "faSimCards", slug: "fa-sim-cards", name: "Sim Cards" },
  { id: "sim-card", icon: "faSimCard", slug: "fa-sim-card", name: "Sim Card" },
  { id: "signs-post", icon: "faSignsPost", slug: "fa-signs-post", name: "Signs Post" },
  { id: "signature-slash", icon: "faSignatureSlash", slug: "fa-signature-slash", name: "Signature Slash" },
  { id: "signature-lock", icon: "faSignatureLock", slug: "fa-signature-lock", name: "Signature Lock" },
  { id: "signal-weak", icon: "faSignalWeak", slug: "fa-signal-weak", name: "Signal Weak" },
  { id: "signal-strong", icon: "faSignalStrong", slug: "fa-signal-strong", name: "Signal Strong" },
  { id: "signal-stream-slash", icon: "faSignalStreamSlash", slug: "fa-signal-stream-slash", name: "Signal Stream Slash" },
  { id: "signal-stream", icon: "faSignalStream", slug: "fa-signal-stream", name: "Signal Stream" },
  { id: "signal-good", icon: "faSignalGood", slug: "fa-signal-good", name: "Signal Good" },
  { id: "signal-fair", icon: "faSignalFair", slug: "fa-signal-fair", name: "Signal Fair" },
  { id: "signal-bars-weak", icon: "faSignalBarsWeak", slug: "fa-signal-bars-weak", name: "Signal Bars Weak" },
  { id: "signal-bars-slash", icon: "faSignalBarsSlash", slug: "fa-signal-bars-slash", name: "Signal Bars Slash" },
  { id: "signal-bars-good", icon: "faSignalBarsGood", slug: "fa-signal-bars-good", name: "Signal Bars Good" },
  { id: "signal-bars-fair", icon: "faSignalBarsFair", slug: "fa-signal-bars-fair", name: "Signal Bars Fair" },
  { id: "sign-posts-wrench", icon: "faSignPostsWrench", slug: "fa-sign-posts-wrench", name: "Sign Posts Wrench" },
  { id: "sign-posts", icon: "faSignPosts", slug: "fa-sign-posts", name: "Sign Posts" },
  { id: "sign-post", icon: "faSignPost", slug: "fa-sign-post", name: "Sign Post" },
  { id: "sign-hanging", icon: "faSignHanging", slug: "fa-sign-hanging", name: "Sign Hanging" },
  { id: "sigma", icon: "faSigma", slug: "fa-sigma", name: "Sigma" },
  { id: "sidebar-flip", icon: "faSidebarFlip", slug: "fa-sidebar-flip", name: "Sidebar Flip" },
  { id: "sidebar", icon: "faSidebar", slug: "fa-sidebar", name: "Sidebar" },
  { id: "sickle", icon: "faSickle", slug: "fa-sickle", name: "Sickle" },
  { id: "shuttlecock", icon: "faShuttlecock", slug: "fa-shuttlecock", name: "Shuttlecock" },
  { id: "shuttle-space", icon: "faShuttleSpace", slug: "fa-shuttle-space", name: "Shuttle Space" },
  { id: "shutters", icon: "faShutters", slug: "fa-shutters", name: "Shutters" },
  { id: "shuffle", icon: "faShuffle", slug: "fa-shuffle", name: "Shuffle" },
  { id: "shrimp", icon: "faShrimp", slug: "fa-shrimp", name: "Shrimp" },
  { id: "shredder", icon: "faShredder", slug: "fa-shredder", name: "Shredder" },
  { id: "shower-down", icon: "faShowerDown", slug: "fa-shower-down", name: "Shower Down" },
  { id: "shovel-snow", icon: "faShovelSnow", slug: "fa-shovel-snow", name: "Shovel Snow" },
  { id: "shovel", icon: "faShovel", slug: "fa-shovel", name: "Shovel" },
  { id: "shop-slash", icon: "faShopSlash", slug: "fa-shop-slash", name: "Shop Slash" },
  { id: "shop-lock", icon: "faShopLock", slug: "fa-shop-lock", name: "Shop Lock" },
  { id: "shoe-prints", icon: "faShoePrints", slug: "fa-shoe-prints", name: "Shoe Prints" },
  { id: "shish-kebab", icon: "faShishKebab", slug: "fa-shish-kebab", name: "Shish Kebab" },
  { id: "shirt-tank-top", icon: "faShirtTankTop", slug: "fa-shirt-tank-top", name: "Shirt Tank Top" },
  { id: "shirt-running", icon: "faShirtRunning", slug: "fa-shirt-running", name: "Shirt Running" },
  { id: "shirt-long-sleeve", icon: "faShirtLongSleeve", slug: "fa-shirt-long-sleeve", name: "Shirt Long Sleeve" },
  { id: "shield-xmark", icon: "faShieldXmark", slug: "fa-shield-xmark", name: "Shield Xmark" },
  { id: "shield-virus", icon: "faShieldVirus", slug: "fa-shield-virus", name: "Shield Virus" },
  { id: "shield-slash", icon: "faShieldSlash", slug: "fa-shield-slash", name: "Shield Slash" },
  { id: "shield-quartered", icon: "faShieldQuartered", slug: "fa-shield-quartered", name: "Shield Quartered" },
  { id: "shield-plus", icon: "faShieldPlus", slug: "fa-shield-plus", name: "Shield Plus" },
  { id: "shield-minus", icon: "faShieldMinus", slug: "fa-shield-minus", name: "Shield Minus" },
  { id: "shield-keyhole", icon: "faShieldKeyhole", slug: "fa-shield-keyhole", name: "Shield Keyhole" },
  { id: "shield-heart", icon: "faShieldHeart", slug: "fa-shield-heart", name: "Shield Heart" },
  { id: "shield-exclamation", icon: "faShieldExclamation", slug: "fa-shield-exclamation", name: "Shield Exclamation" },
  { id: "shield-dog", icon: "faShieldDog", slug: "fa-shield-dog", name: "Shield Dog" },
  { id: "shield-cross", icon: "faShieldCross", slug: "fa-shield-cross", name: "Shield Cross" },
  { id: "shield-cat", icon: "faShieldCat", slug: "fa-shield-cat", name: "Shield Cat" },
  { id: "shelves-empty", icon: "faShelvesEmpty", slug: "fa-shelves-empty", name: "Shelves Empty" },
  { id: "shelves", icon: "faShelves", slug: "fa-shelves", name: "Shelves" },
  { id: "shekel-sign", icon: "faShekelSign", slug: "fa-shekel-sign", name: "Shekel Sign" },
  { id: "sheet-plastic", icon: "faSheetPlastic", slug: "fa-sheet-plastic", name: "Sheet Plastic" },
  { id: "sheep", icon: "faSheep", slug: "fa-sheep", name: "Sheep" },
  { id: "share-nodes", icon: "faShareNodes", slug: "fa-share-nodes", name: "Share Nodes" },
  { id: "share-all", icon: "faShareAll", slug: "fa-share-all", name: "Share All" },
  { id: "shapes", icon: "faShapes", slug: "fa-shapes", name: "Shapes" },
  { id: "sensor-triangle-exclamation", icon: "faSensorTriangleExclamation", slug: "fa-sensor-triangle-exclamation", name: "Sensor Triangle Exclamation" },
  { id: "sensor-on", icon: "faSensorOn", slug: "fa-sensor-on", name: "Sensor On" },
  { id: "sensor-fire", icon: "faSensorFire", slug: "fa-sensor-fire", name: "Sensor Fire" },
  { id: "sensor-cloud", icon: "faSensorCloud", slug: "fa-sensor-cloud", name: "Sensor Cloud" },
  { id: "sensor", icon: "faSensor", slug: "fa-sensor", name: "Sensor" },
  { id: "send-backward", icon: "faSendBackward", slug: "fa-send-backward", name: "Send Backward" },
  { id: "send-back", icon: "faSendBack", slug: "fa-send-back", name: "Send Back" },
  { id: "semicolon", icon: "faSemicolon", slug: "fa-semicolon", name: "Semicolon" },
  { id: "section", icon: "faSection", slug: "fa-section", name: "Section" },
  { id: "seat-airline", icon: "faSeatAirline", slug: "fa-seat-airline", name: "Seat Airline" },
  { id: "seal-question", icon: "faSealQuestion", slug: "fa-seal-question", name: "Seal Question" },
  { id: "seal-exclamation", icon: "faSealExclamation", slug: "fa-seal-exclamation", name: "Seal Exclamation" },
  { id: "seal", icon: "faSeal", slug: "fa-seal", name: "Seal" },
  { id: "sd-cards", icon: "faSdCards", slug: "fa-sd-cards", name: "Sd Cards" },
  { id: "sd-card", icon: "faSdCard", slug: "fa-sd-card", name: "Sd Card" },
  { id: "scythe", icon: "faScythe", slug: "fa-scythe", name: "Scythe" },
  { id: "scrubber", icon: "faScrubber", slug: "fa-scrubber", name: "Scrubber" },
  { id: "scroll-torah", icon: "faScrollTorah", slug: "fa-scroll-torah", name: "Scroll Torah" },
  { id: "scroll-old", icon: "faScrollOld", slug: "fa-scroll-old", name: "Scroll Old" },
  { id: "scribble", icon: "faScribble", slug: "fa-scribble", name: "Scribble" },
  { id: "screwdriver-wrench", icon: "faScrewdriverWrench", slug: "fa-screwdriver-wrench", name: "Screwdriver Wrench" },
  { id: "screwdriver", icon: "faScrewdriver", slug: "fa-screwdriver", name: "Screwdriver" },
  { id: "screencast", icon: "faScreencast", slug: "fa-screencast", name: "Screencast" },
  { id: "screen-users", icon: "faScreenUsers", slug: "fa-screen-users", name: "Screen Users" },
  { id: "scissors", icon: "faScissors", slug: "fa-scissors", name: "Scissors" },
  { id: "school-lock", icon: "faSchoolLock", slug: "fa-school-lock", name: "School Lock" },
  { id: "school-flag", icon: "faSchoolFlag", slug: "fa-school-flag", name: "School Flag" },
  { id: "school-circle-xmark", icon: "faSchoolCircleXmark", slug: "fa-school-circle-xmark", name: "School Circle Xmark" },
  { id: "school-circle-exclamation", icon: "faSchoolCircleExclamation", slug: "fa-school-circle-exclamation", name: "School Circle Exclamation" },
  { id: "school-circle-check", icon: "faSchoolCircleCheck", slug: "fa-school-circle-check", name: "School Circle Check" },
  { id: "scarf", icon: "faScarf", slug: "fa-scarf", name: "Scarf" },
  { id: "scarecrow", icon: "faScarecrow", slug: "fa-scarecrow", name: "Scarecrow" },
  { id: "scanner-keyboard", icon: "faScannerKeyboard", slug: "fa-scanner-keyboard", name: "Scanner Keyboard" },
  { id: "scanner-image", icon: "faScannerImage", slug: "fa-scanner-image", name: "Scanner Image" },
  { id: "scanner-gun", icon: "faScannerGun", slug: "fa-scanner-gun", name: "Scanner Gun" },
  { id: "scalpel-line-dashed", icon: "faScalpelLineDashed", slug: "fa-scalpel-line-dashed", name: "Scalpel Line Dashed" },
  { id: "scalpel", icon: "faScalpel", slug: "fa-scalpel", name: "Scalpel" },
  { id: "scale-unbalanced-flip", icon: "faScaleUnbalancedFlip", slug: "fa-scale-unbalanced-flip", name: "Scale Unbalanced Flip" },
  { id: "scale-unbalanced", icon: "faScaleUnbalanced", slug: "fa-scale-unbalanced", name: "Scale Unbalanced" },
  { id: "scale-balanced", icon: "faScaleBalanced", slug: "fa-scale-balanced", name: "Scale Balanced" },
  { id: "saxophone-fire", icon: "faSaxophoneFire", slug: "fa-saxophone-fire", name: "Saxophone Fire" },
  { id: "saxophone", icon: "faSaxophone", slug: "fa-saxophone", name: "Saxophone" },
  { id: "sausage", icon: "faSausage", slug: "fa-sausage", name: "Sausage" },
  { id: "satellite-dish", icon: "faSatelliteDish", slug: "fa-satellite-dish", name: "Satellite Dish" },
  { id: "satellite", icon: "faSatellite", slug: "fa-satellite", name: "Satellite" },
  { id: "sandwich", icon: "faSandwich", slug: "fa-sandwich", name: "Sandwich" },
  { id: "salt-shaker", icon: "faSaltShaker", slug: "fa-salt-shaker", name: "Salt Shaker" },
  { id: "salad", icon: "faSalad", slug: "fa-salad", name: "Salad" },
  { id: "sailboat", icon: "faSailboat", slug: "fa-sailboat", name: "Sailboat" },
  { id: "sack-xmark", icon: "faSackXmark", slug: "fa-sack-xmark", name: "Sack Xmark" },
  { id: "sack-dollar", icon: "faSackDollar", slug: "fa-sack-dollar", name: "Sack Dollar" },
  { id: "sack", icon: "faSack", slug: "fa-sack", name: "Sack" },
  { id: "s", icon: "faS", slug: "fa-s", name: "S" },
  { id: "rv", icon: "faRv", slug: "fa-rv", name: "Rv" },
  { id: "rupiah-sign", icon: "faRupiahSign", slug: "fa-rupiah-sign", name: "Rupiah Sign" },
  { id: "rupee-sign", icon: "faRupeeSign", slug: "fa-rupee-sign", name: "Rupee Sign" },
  { id: "ruler-triangle", icon: "faRulerTriangle", slug: "fa-ruler-triangle", name: "Ruler Triangle" },
  { id: "ruler-horizontal", icon: "faRulerHorizontal", slug: "fa-ruler-horizontal", name: "Ruler Horizontal" },
  { id: "ruler-combined", icon: "faRulerCombined", slug: "fa-ruler-combined", name: "Ruler Combined" },
  { id: "rugby-ball", icon: "faRugbyBall", slug: "fa-rugby-ball", name: "Rugby Ball" },
  { id: "rug", icon: "faRug", slug: "fa-rug", name: "Rug" },
  { id: "ruble-sign", icon: "faRubleSign", slug: "fa-ruble-sign", name: "Ruble Sign" },
  { id: "rss", icon: "faRss", slug: "fa-rss", name: "Rss" },
  { id: "router", icon: "faRouter", slug: "fa-router", name: "Router" },
  { id: "route-interstate", icon: "faRouteInterstate", slug: "fa-route-interstate", name: "Route Interstate" },
  { id: "route-highway", icon: "faRouteHighway", slug: "fa-route-highway", name: "Route Highway" },
  { id: "rotate-reverse", icon: "faRotateReverse", slug: "fa-rotate-reverse", name: "Rotate Reverse" },
  { id: "rotate-left", icon: "faRotateLeft", slug: "fa-rotate-left", name: "Rotate Left" },
  { id: "rotate-exclamation", icon: "faRotateExclamation", slug: "fa-rotate-exclamation", name: "Rotate Exclamation" },
  { id: "rotate", icon: "faRotate", slug: "fa-rotate", name: "Rotate" },
  { id: "roller-coaster", icon: "faRollerCoaster", slug: "fa-roller-coaster", name: "Roller Coaster" },
  { id: "road-spikes", icon: "faRoadSpikes", slug: "fa-road-spikes", name: "Road Spikes" },
  { id: "road-lock", icon: "faRoadLock", slug: "fa-road-lock", name: "Road Lock" },
  { id: "road-circle-xmark", icon: "faRoadCircleXmark", slug: "fa-road-circle-xmark", name: "Road Circle Xmark" },
  { id: "road-circle-exclamation", icon: "faRoadCircleExclamation", slug: "fa-road-circle-exclamation", name: "Road Circle Exclamation" },
  { id: "road-circle-check", icon: "faRoadCircleCheck", slug: "fa-road-circle-check", name: "Road Circle Check" },
  { id: "road-bridge", icon: "faRoadBridge", slug: "fa-road-bridge", name: "Road Bridge" },
  { id: "road-barrier", icon: "faRoadBarrier", slug: "fa-road-barrier", name: "Road Barrier" },
  { id: "rings-wedding", icon: "faRingsWedding", slug: "fa-rings-wedding", name: "Rings Wedding" },
  { id: "ring-diamond", icon: "faRingDiamond", slug: "fa-ring-diamond", name: "Ring Diamond" },
  { id: "right-to-line", icon: "faRightToLine", slug: "fa-right-to-line", name: "Right To Line" },
  { id: "right-long-to-line", icon: "faRightLongToLine", slug: "fa-right-long-to-line", name: "Right Long To Line" },
  { id: "right-long", icon: "faRightLong", slug: "fa-right-long", name: "Right Long" },
  { id: "right-left-large", icon: "faRightLeftLarge", slug: "fa-right-left-large", name: "Right Left Large" },
  { id: "right-left", icon: "faRightLeft", slug: "fa-right-left", name: "Right Left" },
  { id: "right-from-line", icon: "faRightFromLine", slug: "fa-right-from-line", name: "Right From Line" },
  { id: "right-from-bracket", icon: "faRightFromBracket", slug: "fa-right-from-bracket", name: "Right From Bracket" },
  { id: "right", icon: "faRight", slug: "fa-right", name: "Right" },
  { id: "ribbon", icon: "faRibbon", slug: "fa-ribbon", name: "Ribbon" },
  { id: "rhombus", icon: "faRhombus", slug: "fa-rhombus", name: "Rhombus" },
  { id: "retweet", icon: "faRetweet", slug: "fa-retweet", name: "Retweet" },
  { id: "restroom-simple", icon: "faRestroomSimple", slug: "fa-restroom-simple", name: "Restroom Simple" },
  { id: "republican", icon: "faRepublican", slug: "fa-republican", name: "Republican" },
  { id: "reply-clock", icon: "faReplyClock", slug: "fa-reply-clock", name: "Reply Clock" },
  { id: "reply-all", icon: "faReplyAll", slug: "fa-reply-all", name: "Reply All" },
  { id: "repeat-1", icon: "faRepeat1", slug: "fa-repeat-1", name: "Repeat 1" },
  { id: "refrigerator", icon: "faRefrigerator", slug: "fa-refrigerator", name: "Refrigerator" },
  { id: "reflect-vertical", icon: "faReflectVertical", slug: "fa-reflect-vertical", name: "Reflect Vertical" },
  { id: "reflect-horizontal", icon: "faReflectHorizontal", slug: "fa-reflect-horizontal", name: "Reflect Horizontal" },
  { id: "reflect-both", icon: "faReflectBoth", slug: "fa-reflect-both", name: "Reflect Both" },
  { id: "reel", icon: "faReel", slug: "fa-reel", name: "Reel" },
  { id: "rectangles-mixed", icon: "faRectanglesMixed", slug: "fa-rectangles-mixed", name: "Rectangles Mixed" },
  { id: "rectangle-xmark", icon: "faRectangleXmark", slug: "fa-rectangle-xmark", name: "Rectangle Xmark" },
  { id: "rectangle-vertical-history", icon: "faRectangleVerticalHistory", slug: "fa-rectangle-vertical-history", name: "Rectangle Vertical History" },
  { id: "rectangle-vertical", icon: "faRectangleVertical", slug: "fa-rectangle-vertical", name: "Rectangle Vertical" },
  { id: "rectangle-terminal", icon: "faRectangleTerminal", slug: "fa-rectangle-terminal", name: "Rectangle Terminal" },
  { id: "rectangle-pro", icon: "faRectanglePro", slug: "fa-rectangle-pro", name: "Rectangle Pro" },
  { id: "rectangle-list", icon: "faRectangleList", slug: "fa-rectangle-list", name: "Rectangle List" },
  { id: "rectangle-history-circle-user", icon: "faRectangleHistoryCircleUser", slug: "fa-rectangle-history-circle-user", name: "Rectangle History Circle User" },
  { id: "rectangle-history-circle-plus", icon: "faRectangleHistoryCirclePlus", slug: "fa-rectangle-history-circle-plus", name: "Rectangle History Circle Plus" },
  { id: "rectangle-history", icon: "faRectangleHistory", slug: "fa-rectangle-history", name: "Rectangle History" },
  { id: "rectangle-code", icon: "faRectangleCode", slug: "fa-rectangle-code", name: "Rectangle Code" },
  { id: "rectangle-barcode", icon: "faRectangleBarcode", slug: "fa-rectangle-barcode", name: "Rectangle Barcode" },
  { id: "rectangle-ad", icon: "faRectangleAd", slug: "fa-rectangle-ad", name: "Rectangle Ad" },
  { id: "rectangle", icon: "faRectangle", slug: "fa-rectangle", name: "Rectangle" },
  { id: "ranking-star", icon: "faRankingStar", slug: "fa-ranking-star", name: "Ranking Star" },
  { id: "ramp-loading", icon: "faRampLoading", slug: "fa-ramp-loading", name: "Ramp Loading" },
  { id: "ram", icon: "faRam", slug: "fa-ram", name: "Ram" },
  { id: "rainbow", icon: "faRainbow", slug: "fa-rainbow", name: "Rainbow" },
  { id: "radio-tuner", icon: "faRadioTuner", slug: "fa-radio-tuner", name: "Radio Tuner" },
  { id: "radiation", icon: "faRadiation", slug: "fa-radiation", name: "Radiation" },
  { id: "racquet", icon: "faRacquet", slug: "fa-racquet", name: "Racquet" },
  { id: "raccoon", icon: "faRaccoon", slug: "fa-raccoon", name: "Raccoon" },
  { id: "rabbit-running", icon: "faRabbitRunning", slug: "fa-rabbit-running", name: "Rabbit Running" },
  { id: "rabbit", icon: "faRabbit", slug: "fa-rabbit", name: "Rabbit" },
  { id: "r", icon: "faR", slug: "fa-r", name: "R" },
  { id: "quotes", icon: "faQuotes", slug: "fa-quotes", name: "Quotes" },
  { id: "qrcode", icon: "faQrcode", slug: "fa-qrcode", name: "Qrcode" },
  { id: "q", icon: "faQ", slug: "fa-q", name: "Q" },
  { id: "puzzle-piece-simple", icon: "faPuzzlePieceSimple", slug: "fa-puzzle-piece-simple", name: "Puzzle Piece Simple" },
  { id: "puzzle", icon: "faPuzzle", slug: "fa-puzzle", name: "Puzzle" },
  { id: "pumpkin", icon: "faPumpkin", slug: "fa-pumpkin", name: "Pumpkin" },
  { id: "pump-soap", icon: "faPumpSoap", slug: "fa-pump-soap", name: "Pump Soap" },
  { id: "pump-medical", icon: "faPumpMedical", slug: "fa-pump-medical", name: "Pump Medical" },
  { id: "pump", icon: "faPump", slug: "fa-pump", name: "Pump" },
  { id: "print-slash", icon: "faPrintSlash", slug: "fa-print-slash", name: "Print Slash" },
  { id: "print-magnifying-glass", icon: "faPrintMagnifyingGlass", slug: "fa-print-magnifying-glass", name: "Print Magnifying Glass" },
  { id: "pretzel", icon: "faPretzel", slug: "fa-pretzel", name: "Pretzel" },
  { id: "presentation-screen", icon: "faPresentationScreen", slug: "fa-presentation-screen", name: "Presentation Screen" },
  { id: "prescription-bottle-pill", icon: "faPrescriptionBottlePill", slug: "fa-prescription-bottle-pill", name: "Prescription Bottle Pill" },
  { id: "prescription-bottle-medical", icon: "faPrescriptionBottleMedical", slug: "fa-prescription-bottle-medical", name: "Prescription Bottle Medical" },
  { id: "prescription-bottle", icon: "faPrescriptionBottle", slug: "fa-prescription-bottle", name: "Prescription Bottle" },
  { id: "prescription", icon: "faPrescription", slug: "fa-prescription", name: "Prescription" },
  { id: "potato", icon: "faPotato", slug: "fa-potato", name: "Potato" },
  { id: "pot-food", icon: "faPotFood", slug: "fa-pot-food", name: "Pot Food" },
  { id: "popsicle", icon: "faPopsicle", slug: "fa-popsicle", name: "Popsicle" },
  { id: "popcorn", icon: "faPopcorn", slug: "fa-popcorn", name: "Popcorn" },
  { id: "poop", icon: "faPoop", slug: "fa-poop", name: "Poop" },
  { id: "pool-8-ball", icon: "faPool8Ball", slug: "fa-pool-8-ball", name: "Pool 8 Ball" },
  { id: "pompebled", icon: "faPompebled", slug: "fa-pompebled", name: "Pompebled" },
  { id: "poll-people", icon: "faPollPeople", slug: "fa-poll-people", name: "Poll People" },
  { id: "podium-star", icon: "faPodiumStar", slug: "fa-podium-star", name: "Podium Star" },
  { id: "podium", icon: "faPodium", slug: "fa-podium", name: "Podium" },
  { id: "podcast", icon: "faPodcast", slug: "fa-podcast", name: "Podcast" },
  { id: "plus-minus", icon: "faPlusMinus", slug: "fa-plus-minus", name: "Plus Minus" },
  { id: "plus-large", icon: "faPlusLarge", slug: "fa-plus-large", name: "Plus Large" },
  { id: "plug-circle-xmark", icon: "faPlugCircleXmark", slug: "fa-plug-circle-xmark", name: "Plug Circle Xmark" },
  { id: "plug-circle-plus", icon: "faPlugCirclePlus", slug: "fa-plug-circle-plus", name: "Plug Circle Plus" },
  { id: "plug-circle-minus", icon: "faPlugCircleMinus", slug: "fa-plug-circle-minus", name: "Plug Circle Minus" },
  { id: "plug-circle-exclamation", icon: "faPlugCircleExclamation", slug: "fa-plug-circle-exclamation", name: "Plug Circle Exclamation" },
  { id: "plug-circle-check", icon: "faPlugCircleCheck", slug: "fa-plug-circle-check", name: "Plug Circle Check" },
  { id: "plug-circle-bolt", icon: "faPlugCircleBolt", slug: "fa-plug-circle-bolt", name: "Plug Circle Bolt" },
  { id: "plate-wheat", icon: "faPlateWheat", slug: "fa-plate-wheat", name: "Plate Wheat" },
  { id: "plate-utensils", icon: "faPlateUtensils", slug: "fa-plate-utensils", name: "Plate Utensils" },
  { id: "plant-wilt", icon: "faPlantWilt", slug: "fa-plant-wilt", name: "Plant Wilt" },
  { id: "plane-up-slash", icon: "faPlaneUpSlash", slug: "fa-plane-up-slash", name: "Plane Up Slash" },
  { id: "plane-up", icon: "faPlaneUp", slug: "fa-plane-up", name: "Plane Up" },
  { id: "plane-tail", icon: "faPlaneTail", slug: "fa-plane-tail", name: "Plane Tail" },
  { id: "plane-slash", icon: "faPlaneSlash", slug: "fa-plane-slash", name: "Plane Slash" },
  { id: "plane-prop", icon: "faPlaneProp", slug: "fa-plane-prop", name: "Plane Prop" },
  { id: "plane-lock", icon: "faPlaneLock", slug: "fa-plane-lock", name: "Plane Lock" },
  { id: "plane-engines", icon: "faPlaneEngines", slug: "fa-plane-engines", name: "Plane Engines" },
  { id: "plane-circle-xmark", icon: "faPlaneCircleXmark", slug: "fa-plane-circle-xmark", name: "Plane Circle Xmark" },
  { id: "plane-circle-exclamation", icon: "faPlaneCircleExclamation", slug: "fa-plane-circle-exclamation", name: "Plane Circle Exclamation" },
  { id: "plane-circle-check", icon: "faPlaneCircleCheck", slug: "fa-plane-circle-check", name: "Plane Circle Check" },
  { id: "plane-arrival", icon: "faPlaneArrival", slug: "fa-plane-arrival", name: "Plane Arrival" },
  { id: "pizza-slice", icon: "faPizzaSlice", slug: "fa-pizza-slice", name: "Pizza Slice" },
  { id: "pizza", icon: "faPizza", slug: "fa-pizza", name: "Pizza" },
  { id: "pipe-valve", icon: "faPipeValve", slug: "fa-pipe-valve", name: "Pipe Valve" },
  { id: "pipe-smoking", icon: "faPipeSmoking", slug: "fa-pipe-smoking", name: "Pipe Smoking" },
  { id: "pipe-section", icon: "faPipeSection", slug: "fa-pipe-section", name: "Pipe Section" },
  { id: "pipe-collar", icon: "faPipeCollar", slug: "fa-pipe-collar", name: "Pipe Collar" },
  { id: "pipe-circle-check", icon: "faPipeCircleCheck", slug: "fa-pipe-circle-check", name: "Pipe Circle Check" },
  { id: "pipe", icon: "faPipe", slug: "fa-pipe", name: "Pipe" },
  { id: "pineapple", icon: "faPineapple", slug: "fa-pineapple", name: "Pineapple" },
  { id: "pinball", icon: "faPinball", slug: "fa-pinball", name: "Pinball" },
  { id: "pinata", icon: "faPinata", slug: "fa-pinata", name: "Pinata" },
  { id: "pills", icon: "faPills", slug: "fa-pills", name: "Pills" },
  { id: "piggy-bank", icon: "faPiggyBank", slug: "fa-piggy-bank", name: "Piggy Bank" },
  { id: "pig", icon: "faPig", slug: "fa-pig", name: "Pig" },
  { id: "pie", icon: "faPie", slug: "fa-pie", name: "Pie" },
  { id: "pickleball", icon: "faPickleball", slug: "fa-pickleball", name: "Pickleball" },
  { id: "pickaxe", icon: "faPickaxe", slug: "fa-pickaxe", name: "Pickaxe" },
  { id: "piano-keyboard", icon: "faPianoKeyboard", slug: "fa-piano-keyboard", name: "Piano Keyboard" },
  { id: "piano", icon: "faPiano", slug: "fa-piano", name: "Piano" },
  { id: "pi", icon: "faPi", slug: "fa-pi", name: "Pi" },
  { id: "photo-film-music", icon: "faPhotoFilmMusic", slug: "fa-photo-film-music", name: "Photo Film Music" },
  { id: "photo-film", icon: "faPhotoFilm", slug: "fa-photo-film", name: "Photo Film" },
  { id: "phone-xmark", icon: "faPhoneXmark", slug: "fa-phone-xmark", name: "Phone Xmark" },
  { id: "phone-slash", icon: "faPhoneSlash", slug: "fa-phone-slash", name: "Phone Slash" },
  { id: "phone-rotary", icon: "faPhoneRotary", slug: "fa-phone-rotary", name: "Phone Rotary" },
  { id: "phone-plus", icon: "faPhonePlus", slug: "fa-phone-plus", name: "Phone Plus" },
  { id: "phone-missed", icon: "faPhoneMissed", slug: "fa-phone-missed", name: "Phone Missed" },
  { id: "phone-intercom", icon: "faPhoneIntercom", slug: "fa-phone-intercom", name: "Phone Intercom" },
  { id: "phone-hangup", icon: "faPhoneHangup", slug: "fa-phone-hangup", name: "Phone Hangup" },
  { id: "phone-flip", icon: "faPhoneFlip", slug: "fa-phone-flip", name: "Phone Flip" },
  { id: "phone-arrow-up-right", icon: "faPhoneArrowUpRight", slug: "fa-phone-arrow-up-right", name: "Phone Arrow Up Right" },
  { id: "phone-arrow-right", icon: "faPhoneArrowRight", slug: "fa-phone-arrow-right", name: "Phone Arrow Right" },
  { id: "phone-arrow-down-left", icon: "faPhoneArrowDownLeft", slug: "fa-phone-arrow-down-left", name: "Phone Arrow Down Left" },
  { id: "peso-sign", icon: "faPesoSign", slug: "fa-peso-sign", name: "Peso Sign" },
  { id: "peseta-sign", icon: "faPesetaSign", slug: "fa-peseta-sign", name: "Peseta Sign" },
  { id: "person-walking-with-cane", icon: "faPersonWalkingWithCane", slug: "fa-person-walking-with-cane", name: "Person Walking With Cane" },
  { id: "person-walking-luggage", icon: "faPersonWalkingLuggage", slug: "fa-person-walking-luggage", name: "Person Walking Luggage" },
  { id: "person-walking-dashed-line-arrow-right", icon: "faPersonWalkingDashedLineArrowRight", slug: "fa-person-walking-dashed-line-arrow-right", name: "Person Walking Dashed Line Arrow Right" },
  { id: "person-walking-arrow-right", icon: "faPersonWalkingArrowRight", slug: "fa-person-walking-arrow-right", name: "Person Walking Arrow Right" },
  { id: "person-walking-arrow-loop-left", icon: "faPersonWalkingArrowLoopLeft", slug: "fa-person-walking-arrow-loop-left", name: "Person Walking Arrow Loop Left" },
  { id: "person-walking", icon: "faPersonWalking", slug: "fa-person-walking", name: "Person Walking" },
  { id: "person-to-portal", icon: "faPersonToPortal", slug: "fa-person-to-portal", name: "Person To Portal" },
  { id: "person-to-door", icon: "faPersonToDoor", slug: "fa-person-to-door", name: "Person To Door" },
  { id: "person-through-window", icon: "faPersonThroughWindow", slug: "fa-person-through-window", name: "Person Through Window" },
  { id: "person-swimming", icon: "faPersonSwimming", slug: "fa-person-swimming", name: "Person Swimming" },
  { id: "person-snowmobiling", icon: "faPersonSnowmobiling", slug: "fa-person-snowmobiling", name: "Person Snowmobiling" },
  { id: "person-snowboarding", icon: "faPersonSnowboarding", slug: "fa-person-snowboarding", name: "Person Snowboarding" },
  { id: "person-sledding", icon: "faPersonSledding", slug: "fa-person-sledding", name: "Person Sledding" },
  { id: "person-skiing-nordic", icon: "faPersonSkiingNordic", slug: "fa-person-skiing-nordic", name: "Person Skiing Nordic" },
  { id: "person-skiing", icon: "faPersonSkiing", slug: "fa-person-skiing", name: "Person Skiing" },
  { id: "person-ski-lift", icon: "faPersonSkiLift", slug: "fa-person-ski-lift", name: "Person Ski Lift" },
  { id: "person-ski-jumping", icon: "faPersonSkiJumping", slug: "fa-person-ski-jumping", name: "Person Ski Jumping" },
  { id: "person-skating", icon: "faPersonSkating", slug: "fa-person-skating", name: "Person Skating" },
  { id: "person-simple", icon: "faPersonSimple", slug: "fa-person-simple", name: "Person Simple" },
  { id: "person-shelter", icon: "faPersonShelter", slug: "fa-person-shelter", name: "Person Shelter" },
  { id: "person-seat-reclined", icon: "faPersonSeatReclined", slug: "fa-person-seat-reclined", name: "Person Seat Reclined" },
  { id: "person-seat", icon: "faPersonSeat", slug: "fa-person-seat", name: "Person Seat" },
  { id: "person-running-fast", icon: "faPersonRunningFast", slug: "fa-person-running-fast", name: "Person Running Fast" },
  { id: "person-running", icon: "faPersonRunning", slug: "fa-person-running", name: "Person Running" },
  { id: "person-rifle", icon: "faPersonRifle", slug: "fa-person-rifle", name: "Person Rifle" },
  { id: "person-rays", icon: "faPersonRays", slug: "fa-person-rays", name: "Person Rays" },
  { id: "person-pregnant", icon: "faPersonPregnant", slug: "fa-person-pregnant", name: "Person Pregnant" },
  { id: "person-praying", icon: "faPersonPraying", slug: "fa-person-praying", name: "Person Praying" },
  { id: "person-pinball", icon: "faPersonPinball", slug: "fa-person-pinball", name: "Person Pinball" },
  { id: "person-military-to-person", icon: "faPersonMilitaryToPerson", slug: "fa-person-military-to-person", name: "Person Military To Person" },
  { id: "person-military-rifle", icon: "faPersonMilitaryRifle", slug: "fa-person-military-rifle", name: "Person Military Rifle" },
  { id: "person-military-pointing", icon: "faPersonMilitaryPointing", slug: "fa-person-military-pointing", name: "Person Military Pointing" },
  { id: "person-hiking", icon: "faPersonHiking", slug: "fa-person-hiking", name: "Person Hiking" },
  { id: "person-harassing", icon: "faPersonHarassing", slug: "fa-person-harassing", name: "Person Harassing" },
  { id: "person-half-dress", icon: "faPersonHalfDress", slug: "fa-person-half-dress", name: "Person Half Dress" },
  { id: "person-from-portal", icon: "faPersonFromPortal", slug: "fa-person-from-portal", name: "Person From Portal" },
  { id: "person-falling-burst", icon: "faPersonFallingBurst", slug: "fa-person-falling-burst", name: "Person Falling Burst" },
  { id: "person-falling", icon: "faPersonFalling", slug: "fa-person-falling", name: "Person Falling" },
  { id: "person-fairy", icon: "faPersonFairy", slug: "fa-person-fairy", name: "Person Fairy" },
  { id: "person-drowning", icon: "faPersonDrowning", slug: "fa-person-drowning", name: "Person Drowning" },
  { id: "person-dress-simple", icon: "faPersonDressSimple", slug: "fa-person-dress-simple", name: "Person Dress Simple" },
  { id: "person-dress-fairy", icon: "faPersonDressFairy", slug: "fa-person-dress-fairy", name: "Person Dress Fairy" },
  { id: "person-dress-burst", icon: "faPersonDressBurst", slug: "fa-person-dress-burst", name: "Person Dress Burst" },
  { id: "person-dots-from-line", icon: "faPersonDotsFromLine", slug: "fa-person-dots-from-line", name: "Person Dots From Line" },
  { id: "person-dolly", icon: "faPersonDolly", slug: "fa-person-dolly", name: "Person Dolly" },
  { id: "person-digging", icon: "faPersonDigging", slug: "fa-person-digging", name: "Person Digging" },
  { id: "person-circle-xmark", icon: "faPersonCircleXmark", slug: "fa-person-circle-xmark", name: "Person Circle Xmark" },
  { id: "person-circle-question", icon: "faPersonCircleQuestion", slug: "fa-person-circle-question", name: "Person Circle Question" },
  { id: "person-circle-plus", icon: "faPersonCirclePlus", slug: "fa-person-circle-plus", name: "Person Circle Plus" },
  { id: "person-circle-minus", icon: "faPersonCircleMinus", slug: "fa-person-circle-minus", name: "Person Circle Minus" },
  { id: "person-circle-exclamation", icon: "faPersonCircleExclamation", slug: "fa-person-circle-exclamation", name: "Person Circle Exclamation" },
  { id: "person-circle-check", icon: "faPersonCircleCheck", slug: "fa-person-circle-check", name: "Person Circle Check" },
  { id: "person-chalkboard", icon: "faPersonChalkboard", slug: "fa-person-chalkboard", name: "Person Chalkboard" },
  { id: "person-carry-box", icon: "faPersonCarryBox", slug: "fa-person-carry-box", name: "Person Carry Box" },
  { id: "person-cane", icon: "faPersonCane", slug: "fa-person-cane", name: "Person Cane" },
  { id: "person-burst", icon: "faPersonBurst", slug: "fa-person-burst", name: "Person Burst" },
  { id: "person-breastfeeding", icon: "faPersonBreastfeeding", slug: "fa-person-breastfeeding", name: "Person Breastfeeding" },
  { id: "person-booth", icon: "faPersonBooth", slug: "fa-person-booth", name: "Person Booth" },
  { id: "person-biking-mountain", icon: "faPersonBikingMountain", slug: "fa-person-biking-mountain", name: "Person Biking Mountain" },
  { id: "person-biking", icon: "faPersonBiking", slug: "fa-person-biking", name: "Person Biking" },
  { id: "person-arrow-up-from-line", icon: "faPersonArrowUpFromLine", slug: "fa-person-arrow-up-from-line", name: "Person Arrow Up From Line" },
  { id: "person-arrow-down-to-line", icon: "faPersonArrowDownToLine", slug: "fa-person-arrow-down-to-line", name: "Person Arrow Down To Line" },
  { id: "period", icon: "faPeriod", slug: "fa-period", name: "Period" },
  { id: "pepper-hot", icon: "faPepperHot", slug: "fa-pepper-hot", name: "Pepper Hot" },
  { id: "pepper", icon: "faPepper", slug: "fa-pepper", name: "Pepper" },
  { id: "people-roof", icon: "faPeopleRoof", slug: "fa-people-roof", name: "People Roof" },
  { id: "people-robbery", icon: "faPeopleRobbery", slug: "fa-people-robbery", name: "People Robbery" },
  { id: "people-pulling", icon: "faPeoplePulling", slug: "fa-people-pulling", name: "People Pulling" },
  { id: "people-pants-simple", icon: "faPeoplePantsSimple", slug: "fa-people-pants-simple", name: "People Pants Simple" },
  { id: "people-pants", icon: "faPeoplePants", slug: "fa-people-pants", name: "People Pants" },
  { id: "people-line", icon: "faPeopleLine", slug: "fa-people-line", name: "People Line" },
  { id: "people-group", icon: "faPeopleGroup", slug: "fa-people-group", name: "People Group" },
  { id: "people-dress-simple", icon: "faPeopleDressSimple", slug: "fa-people-dress-simple", name: "People Dress Simple" },
  { id: "people-dress", icon: "faPeopleDress", slug: "fa-people-dress", name: "People Dress" },
  { id: "people-carry-box", icon: "faPeopleCarryBox", slug: "fa-people-carry-box", name: "People Carry Box" },
  { id: "people", icon: "faPeople", slug: "fa-people", name: "People" },
  { id: "pencil-slash", icon: "faPencilSlash", slug: "fa-pencil-slash", name: "Pencil Slash" },
  { id: "pencil-mechanical", icon: "faPencilMechanical", slug: "fa-pencil-mechanical", name: "Pencil Mechanical" },
  { id: "pen-swirl", icon: "faPenSwirl", slug: "fa-pen-swirl", name: "Pen Swirl" },
  { id: "pen-slash", icon: "faPenSlash", slug: "fa-pen-slash", name: "Pen Slash" },
  { id: "pen-ruler", icon: "faPenRuler", slug: "fa-pen-ruler", name: "Pen Ruler" },
  { id: "pen-paintbrush", icon: "faPenPaintbrush", slug: "fa-pen-paintbrush", name: "Pen Paintbrush" },
  { id: "pen-nib-slash", icon: "faPenNibSlash", slug: "fa-pen-nib-slash", name: "Pen Nib Slash" },
  { id: "pen-line", icon: "faPenLine", slug: "fa-pen-line", name: "Pen Line" },
  { id: "pen-field", icon: "faPenField", slug: "fa-pen-field", name: "Pen Field" },
  { id: "pen-fancy-slash", icon: "faPenFancySlash", slug: "fa-pen-fancy-slash", name: "Pen Fancy Slash" },
  { id: "pen-clip-slash", icon: "faPenClipSlash", slug: "fa-pen-clip-slash", name: "Pen Clip Slash" },
  { id: "pen-clip", icon: "faPenClip", slug: "fa-pen-clip", name: "Pen Clip" },
  { id: "pen-circle", icon: "faPenCircle", slug: "fa-pen-circle", name: "Pen Circle" },
  { id: "pegasus", icon: "faPegasus", slug: "fa-pegasus", name: "Pegasus" },
  { id: "pedestal", icon: "faPedestal", slug: "fa-pedestal", name: "Pedestal" },
  { id: "pear", icon: "faPear", slug: "fa-pear", name: "Pear" },
  { id: "peapod", icon: "faPeapod", slug: "fa-peapod", name: "Peapod" },
  { id: "peanuts", icon: "faPeanuts", slug: "fa-peanuts", name: "Peanuts" },
  { id: "peanut", icon: "faPeanut", slug: "fa-peanut", name: "Peanut" },
  { id: "peach", icon: "faPeach", slug: "fa-peach", name: "Peach" },
  { id: "peace", icon: "faPeace", slug: "fa-peace", name: "Peace" },
  { id: "paw-simple", icon: "faPawSimple", slug: "fa-paw-simple", name: "Paw Simple" },
  { id: "paw-claws", icon: "faPawClaws", slug: "fa-paw-claws", name: "Paw Claws" },
  { id: "paw", icon: "faPaw", slug: "fa-paw", name: "Paw" },
  { id: "paste", icon: "faPaste", slug: "fa-paste", name: "Paste" },
  { id: "party-horn", icon: "faPartyHorn", slug: "fa-party-horn", name: "Party Horn" },
  { id: "party-bell", icon: "faPartyBell", slug: "fa-party-bell", name: "Party Bell" },
  { id: "paragraph-left", icon: "faParagraphLeft", slug: "fa-paragraph-left", name: "Paragraph Left" },
  { id: "paragraph", icon: "faParagraph", slug: "fa-paragraph", name: "Paragraph" },
  { id: "parachute-box", icon: "faParachuteBox", slug: "fa-parachute-box", name: "Parachute Box" },
  { id: "paperclip-vertical", icon: "faPaperclipVertical", slug: "fa-paperclip-vertical", name: "Paperclip Vertical" },
  { id: "paper-plane-top", icon: "faPaperPlaneTop", slug: "fa-paper-plane-top", name: "Paper Plane Top" },
  { id: "panorama", icon: "faPanorama", slug: "fa-panorama", name: "Panorama" },
  { id: "panel-fire", icon: "faPanelFire", slug: "fa-panel-fire", name: "Panel Fire" },
  { id: "panel-ews", icon: "faPanelEws", slug: "fa-panel-ews", name: "Panel Ews" },
  { id: "pancakes", icon: "faPancakes", slug: "fa-pancakes", name: "Pancakes" },
  { id: "pan-frying", icon: "faPanFrying", slug: "fa-pan-frying", name: "Pan Frying" },
  { id: "pan-food", icon: "faPanFood", slug: "fa-pan-food", name: "Pan Food" },
  { id: "pallet-boxes", icon: "faPalletBoxes", slug: "fa-pallet-boxes", name: "Pallet Boxes" },
  { id: "pallet-box", icon: "faPalletBox", slug: "fa-pallet-box", name: "Pallet Box" },
  { id: "pallet", icon: "faPallet", slug: "fa-pallet", name: "Pallet" },
  { id: "paintbrush-pencil", icon: "faPaintbrushPencil", slug: "fa-paintbrush-pencil", name: "Paintbrush Pencil" },
  { id: "paintbrush-fine", icon: "faPaintbrushFine", slug: "fa-paintbrush-fine", name: "Paintbrush Fine" },
  { id: "paintbrush", icon: "faPaintbrush", slug: "fa-paintbrush", name: "Paintbrush" },
  { id: "pager", icon: "faPager", slug: "fa-pager", name: "Pager" },
  { id: "page-caret-up", icon: "faPageCaretUp", slug: "fa-page-caret-up", name: "Page Caret Up" },
  { id: "page-caret-down", icon: "faPageCaretDown", slug: "fa-page-caret-down", name: "Page Caret Down" },
  { id: "page", icon: "faPage", slug: "fa-page", name: "Page" },
  { id: "p", icon: "faP", slug: "fa-p", name: "P" },
  { id: "overline", icon: "faOverline", slug: "fa-overline", name: "Overline" },
  { id: "oven", icon: "faOven", slug: "fa-oven", name: "Oven" },
  { id: "outlet", icon: "faOutlet", slug: "fa-outlet", name: "Outlet" },
  { id: "outdent", icon: "faOutdent", slug: "fa-outdent", name: "Outdent" },
  { id: "ornament", icon: "faOrnament", slug: "fa-ornament", name: "Ornament" },
  { id: "option", icon: "faOption", slug: "fa-option", name: "Option" },
  { id: "onion", icon: "faOnion", slug: "fa-onion", name: "Onion" },
  { id: "omega", icon: "faOmega", slug: "fa-omega", name: "Omega" },
  { id: "om", icon: "faOm", slug: "fa-om", name: "Om" },
  { id: "olive-branch", icon: "faOliveBranch", slug: "fa-olive-branch", name: "Olive Branch" },
  { id: "olive", icon: "faOlive", slug: "fa-olive", name: "Olive" },
  { id: "oil-well", icon: "faOilWell", slug: "fa-oil-well", name: "Oil Well" },
  { id: "oil-temperature", icon: "faOilTemperature", slug: "fa-oil-temperature", name: "Oil Temperature" },
  { id: "oil-can-drip", icon: "faOilCanDrip", slug: "fa-oil-can-drip", name: "Oil Can Drip" },
  { id: "oil-can", icon: "faOilCan", slug: "fa-oil-can", name: "Oil Can" },
  { id: "octagon-xmark", icon: "faOctagonXmark", slug: "fa-octagon-xmark", name: "Octagon Xmark" },
  { id: "octagon-plus", icon: "faOctagonPlus", slug: "fa-octagon-plus", name: "Octagon Plus" },
  { id: "octagon-minus", icon: "faOctagonMinus", slug: "fa-octagon-minus", name: "Octagon Minus" },
  { id: "octagon-exclamation", icon: "faOctagonExclamation", slug: "fa-octagon-exclamation", name: "Octagon Exclamation" },
  { id: "octagon-divide", icon: "faOctagonDivide", slug: "fa-octagon-divide", name: "Octagon Divide" },
  { id: "octagon-check", icon: "faOctagonCheck", slug: "fa-octagon-check", name: "Octagon Check" },
  { id: "octagon", icon: "faOctagon", slug: "fa-octagon", name: "Octagon" },
  { id: "objects-column", icon: "faObjectsColumn", slug: "fa-objects-column", name: "Objects Column" },
  { id: "objects-align-top", icon: "faObjectsAlignTop", slug: "fa-objects-align-top", name: "Objects Align Top" },
  { id: "objects-align-right", icon: "faObjectsAlignRight", slug: "fa-objects-align-right", name: "Objects Align Right" },
  { id: "objects-align-left", icon: "faObjectsAlignLeft", slug: "fa-objects-align-left", name: "Objects Align Left" },
  { id: "objects-align-center-vertical", icon: "faObjectsAlignCenterVertical", slug: "fa-objects-align-center-vertical", name: "Objects Align Center Vertical" },
  { id: "objects-align-center-horizontal", icon: "faObjectsAlignCenterHorizontal", slug: "fa-objects-align-center-horizontal", name: "Objects Align Center Horizontal" },
  { id: "objects-align-bottom", icon: "faObjectsAlignBottom", slug: "fa-objects-align-bottom", name: "Objects Align Bottom" },
  { id: "object-union", icon: "faObjectUnion", slug: "fa-object-union", name: "Object Union" },
  { id: "object-ungroup", icon: "faObjectUngroup", slug: "fa-object-ungroup", name: "Object Ungroup" },
  { id: "object-subtract", icon: "faObjectSubtract", slug: "fa-object-subtract", name: "Object Subtract" },
  { id: "object-intersect", icon: "faObjectIntersect", slug: "fa-object-intersect", name: "Object Intersect" },
  { id: "object-group", icon: "faObjectGroup", slug: "fa-object-group", name: "Object Group" },
  { id: "object-exclude", icon: "faObjectExclude", slug: "fa-object-exclude", name: "Object Exclude" },
  { id: "o", icon: "faO", slug: "fa-o", name: "O" },
  { id: "notes", icon: "faNotes", slug: "fa-notes", name: "Notes" },
  { id: "notebook", icon: "faNotebook", slug: "fa-notebook", name: "Notebook" },
  { id: "note-sticky", icon: "faNoteSticky", slug: "fa-note-sticky", name: "Note Sticky" },
  { id: "note-medical", icon: "faNoteMedical", slug: "fa-note-medical", name: "Note Medical" },
  { id: "note", icon: "faNote", slug: "fa-note", name: "Note" },
  { id: "notdef", icon: "faNotdef", slug: "fa-notdef", name: "Notdef" },
  { id: "nose", icon: "faNose", slug: "fa-nose", name: "Nose" },
  { id: "nfc-trash", icon: "faNfcTrash", slug: "fa-nfc-trash", name: "Nfc Trash" },
  { id: "nfc-symbol", icon: "faNfcSymbol", slug: "fa-nfc-symbol", name: "Nfc Symbol" },
  { id: "nfc-slash", icon: "faNfcSlash", slug: "fa-nfc-slash", name: "Nfc Slash" },
  { id: "nfc-signal", icon: "faNfcSignal", slug: "fa-nfc-signal", name: "Nfc Signal" },
  { id: "nfc-pen", icon: "faNfcPen", slug: "fa-nfc-pen", name: "Nfc Pen" },
  { id: "nfc-magnifying-glass", icon: "faNfcMagnifyingGlass", slug: "fa-nfc-magnifying-glass", name: "Nfc Magnifying Glass" },
  { id: "nfc-lock", icon: "faNfcLock", slug: "fa-nfc-lock", name: "Nfc Lock" },
  { id: "nfc", icon: "faNfc", slug: "fa-nfc", name: "Nfc" },
  { id: "neuter", icon: "faNeuter", slug: "fa-neuter", name: "Neuter" },
  { id: "nesting-dolls", icon: "faNestingDolls", slug: "fa-nesting-dolls", name: "Nesting Dolls" },
  { id: "narwhal", icon: "faNarwhal", slug: "fa-narwhal", name: "Narwhal" },
  { id: "naira-sign", icon: "faNairaSign", slug: "fa-naira-sign", name: "Naira Sign" },
  { id: "n", icon: "faN", slug: "fa-n", name: "N" },
  { id: "mustache", icon: "faMustache", slug: "fa-mustache", name: "Mustache" },
  { id: "music-slash", icon: "faMusicSlash", slug: "fa-music-slash", name: "Music Slash" },
  { id: "music-note-slash", icon: "faMusicNoteSlash", slug: "fa-music-note-slash", name: "Music Note Slash" },
  { id: "music-note", icon: "faMusicNote", slug: "fa-music-note", name: "Music Note" },
  { id: "music-magnifying-glass", icon: "faMusicMagnifyingGlass", slug: "fa-music-magnifying-glass", name: "Music Magnifying Glass" },
  { id: "mushroom", icon: "faMushroom", slug: "fa-mushroom", name: "Mushroom" },
  { id: "mug-tea-saucer", icon: "faMugTeaSaucer", slug: "fa-mug-tea-saucer", name: "Mug Tea Saucer" },
  { id: "mug-marshmallows", icon: "faMugMarshmallows", slug: "fa-mug-marshmallows", name: "Mug Marshmallows" },
  { id: "mug", icon: "faMug", slug: "fa-mug", name: "Mug" },
  { id: "mp3-player", icon: "faMp3Player", slug: "fa-mp3-player", name: "Mp3 Player" },
  { id: "mouse-field", icon: "faMouseField", slug: "fa-mouse-field", name: "Mouse Field" },
  { id: "mountains", icon: "faMountains", slug: "fa-mountains", name: "Mountains" },
  { id: "mountain-city", icon: "faMountainCity", slug: "fa-mountain-city", name: "Mountain City" },
  { id: "mountain", icon: "faMountain", slug: "fa-mountain", name: "Mountain" },
  { id: "mound", icon: "faMound", slug: "fa-mound", name: "Mound" },
  { id: "mosquito-net", icon: "faMosquitoNet", slug: "fa-mosquito-net", name: "Mosquito Net" },
  { id: "mosquito", icon: "faMosquito", slug: "fa-mosquito", name: "Mosquito" },
  { id: "mosque", icon: "faMosque", slug: "fa-mosque", name: "Mosque" },
  { id: "mortar-pestle", icon: "faMortarPestle", slug: "fa-mortar-pestle", name: "Mortar Pestle" },
  { id: "moon-over-sun", icon: "faMoonOverSun", slug: "fa-moon-over-sun", name: "Moon Over Sun" },
  { id: "moon-cloud", icon: "faMoonCloud", slug: "fa-moon-cloud", name: "Moon Cloud" },
  { id: "monument", icon: "faMonument", slug: "fa-monument", name: "Monument" },
  { id: "monkey", icon: "faMonkey", slug: "fa-monkey", name: "Monkey" },
  { id: "monitor-waveform", icon: "faMonitorWaveform", slug: "fa-monitor-waveform", name: "Monitor Waveform" },
  { id: "money-simple-from-bracket", icon: "faMoneySimpleFromBracket", slug: "fa-money-simple-from-bracket", name: "Money Simple From Bracket" },
  { id: "money-from-bracket", icon: "faMoneyFromBracket", slug: "fa-money-from-bracket", name: "Money From Bracket" },
  { id: "money-check-pen", icon: "faMoneyCheckPen", slug: "fa-money-check-pen", name: "Money Check Pen" },
  { id: "money-check-dollar-pen", icon: "faMoneyCheckDollarPen", slug: "fa-money-check-dollar-pen", name: "Money Check Dollar Pen" },
  { id: "money-check-dollar", icon: "faMoneyCheckDollar", slug: "fa-money-check-dollar", name: "Money Check Dollar" },
  { id: "money-check", icon: "faMoneyCheck", slug: "fa-money-check", name: "Money Check" },
  { id: "money-bills-simple", icon: "faMoneyBillsSimple", slug: "fa-money-bills-simple", name: "Money Bills Simple" },
  { id: "money-bills", icon: "faMoneyBills", slug: "fa-money-bills", name: "Money Bills" },
  { id: "money-bill-wheat", icon: "faMoneyBillWheat", slug: "fa-money-bill-wheat", name: "Money Bill Wheat" },
  { id: "money-bill-wave", icon: "faMoneyBillWave", slug: "fa-money-bill-wave", name: "Money Bill Wave" },
  { id: "money-bill-trend-up", icon: "faMoneyBillTrendUp", slug: "fa-money-bill-trend-up", name: "Money Bill Trend Up" },
  { id: "money-bill-transfer", icon: "faMoneyBillTransfer", slug: "fa-money-bill-transfer", name: "Money Bill Transfer" },
  { id: "money-bill-simple-wave", icon: "faMoneyBillSimpleWave", slug: "fa-money-bill-simple-wave", name: "Money Bill Simple Wave" },
  { id: "money-bill-simple", icon: "faMoneyBillSimple", slug: "fa-money-bill-simple", name: "Money Bill Simple" },
  { id: "money-bill-1-wave", icon: "faMoneyBill1Wave", slug: "fa-money-bill-1-wave", name: "Money Bill 1 Wave" },
  { id: "money-bill-1", icon: "faMoneyBill1", slug: "fa-money-bill-1", name: "Money Bill 1" },
  { id: "mobile-signal-out", icon: "faMobileSignalOut", slug: "fa-mobile-signal-out", name: "Mobile Signal Out" },
  { id: "mobile-signal", icon: "faMobileSignal", slug: "fa-mobile-signal", name: "Mobile Signal" },
  { id: "mobile-screen-button", icon: "faMobileScreenButton", slug: "fa-mobile-screen-button", name: "Mobile Screen Button" },
  { id: "mobile-screen", icon: "faMobileScreen", slug: "fa-mobile-screen", name: "Mobile Screen" },
  { id: "mobile-retro", icon: "faMobileRetro", slug: "fa-mobile-retro", name: "Mobile Retro" },
  { id: "mobile-notch", icon: "faMobileNotch", slug: "fa-mobile-notch", name: "Mobile Notch" },
  { id: "mobile-button", icon: "faMobileButton", slug: "fa-mobile-button", name: "Mobile Button" },
  { id: "mitten", icon: "faMitten", slug: "fa-mitten", name: "Mitten" },
  { id: "mistletoe", icon: "faMistletoe", slug: "fa-mistletoe", name: "Mistletoe" },
  { id: "minimize", icon: "faMinimize", slug: "fa-minimize", name: "Minimize" },
  { id: "mill-sign", icon: "faMillSign", slug: "fa-mill-sign", name: "Mill Sign" },
  { id: "microscope", icon: "faMicroscope", slug: "fa-microscope", name: "Microscope" },
  { id: "microphone-stand", icon: "faMicrophoneStand", slug: "fa-microphone-stand", name: "Microphone Stand" },
  { id: "microphone-lines-slash", icon: "faMicrophoneLinesSlash", slug: "fa-microphone-lines-slash", name: "Microphone Lines Slash" },
  { id: "microphone-lines", icon: "faMicrophoneLines", slug: "fa-microphone-lines", name: "Microphone Lines" },
  { id: "microchip-ai", icon: "faMicrochipAi", slug: "fa-microchip-ai", name: "Microchip Ai" },
  { id: "meter-fire", icon: "faMeterFire", slug: "fa-meter-fire", name: "Meter Fire" },
  { id: "meter-droplet", icon: "faMeterDroplet", slug: "fa-meter-droplet", name: "Meter Droplet" },
  { id: "meter-bolt", icon: "faMeterBolt", slug: "fa-meter-bolt", name: "Meter Bolt" },
  { id: "meter", icon: "faMeter", slug: "fa-meter", name: "Meter" },
  { id: "meteor", icon: "faMeteor", slug: "fa-meteor", name: "Meteor" },
  { id: "messages-question", icon: "faMessagesQuestion", slug: "fa-messages-question", name: "Messages Question" },
  { id: "messages-dollar", icon: "faMessagesDollar", slug: "fa-messages-dollar", name: "Messages Dollar" },
  { id: "messages", icon: "faMessages", slug: "fa-messages", name: "Messages" },
  { id: "message-xmark", icon: "faMessageXmark", slug: "fa-message-xmark", name: "Message Xmark" },
  { id: "message-text", icon: "faMessageText", slug: "fa-message-text", name: "Message Text" },
  { id: "message-sms", icon: "faMessageSms", slug: "fa-message-sms", name: "Message Sms" },
  { id: "message-smile", icon: "faMessageSmile", slug: "fa-message-smile", name: "Message Smile" },
  { id: "message-slash", icon: "faMessageSlash", slug: "fa-message-slash", name: "Message Slash" },
  { id: "message-quote", icon: "faMessageQuote", slug: "fa-message-quote", name: "Message Quote" },
  { id: "message-question", icon: "faMessageQuestion", slug: "fa-message-question", name: "Message Question" },
  { id: "message-plus", icon: "faMessagePlus", slug: "fa-message-plus", name: "Message Plus" },
  { id: "message-pen", icon: "faMessagePen", slug: "fa-message-pen", name: "Message Pen" },
  { id: "message-music", icon: "faMessageMusic", slug: "fa-message-music", name: "Message Music" },
  { id: "message-minus", icon: "faMessageMinus", slug: "fa-message-minus", name: "Message Minus" },
  { id: "message-middle-top", icon: "faMessageMiddleTop", slug: "fa-message-middle-top", name: "Message Middle Top" },
  { id: "message-middle", icon: "faMessageMiddle", slug: "fa-message-middle", name: "Message Middle" },
  { id: "message-medical", icon: "faMessageMedical", slug: "fa-message-medical", name: "Message Medical" },
  { id: "message-lines", icon: "faMessageLines", slug: "fa-message-lines", name: "Message Lines" },
  { id: "message-image", icon: "faMessageImage", slug: "fa-message-image", name: "Message Image" },
  { id: "message-heart", icon: "faMessageHeart", slug: "fa-message-heart", name: "Message Heart" },
  { id: "message-exclamation", icon: "faMessageExclamation", slug: "fa-message-exclamation", name: "Message Exclamation" },
  { id: "message-dots", icon: "faMessageDots", slug: "fa-message-dots", name: "Message Dots" },
  { id: "message-dollar", icon: "faMessageDollar", slug: "fa-message-dollar", name: "Message Dollar" },
  { id: "message-code", icon: "faMessageCode", slug: "fa-message-code", name: "Message Code" },
  { id: "message-check", icon: "faMessageCheck", slug: "fa-message-check", name: "Message Check" },
  { id: "message-captions", icon: "faMessageCaptions", slug: "fa-message-captions", name: "Message Captions" },
  { id: "message-bot", icon: "faMessageBot", slug: "fa-message-bot", name: "Message Bot" },
  { id: "message-arrow-up-right", icon: "faMessageArrowUpRight", slug: "fa-message-arrow-up-right", name: "Message Arrow Up Right" },
  { id: "message-arrow-up", icon: "faMessageArrowUp", slug: "fa-message-arrow-up", name: "Message Arrow Up" },
  { id: "message-arrow-down", icon: "faMessageArrowDown", slug: "fa-message-arrow-down", name: "Message Arrow Down" },
  { id: "message", icon: "faMessage", slug: "fa-message", name: "Message" },
  { id: "merge", icon: "faMerge", slug: "fa-merge", name: "Merge" },
  { id: "mercury", icon: "faMercury", slug: "fa-mercury", name: "Mercury" },
  { id: "menorah", icon: "faMenorah", slug: "fa-menorah", name: "Menorah" },
  { id: "memory", icon: "faMemory", slug: "fa-memory", name: "Memory" },
  { id: "memo-pad", icon: "faMemoPad", slug: "fa-memo-pad", name: "Memo Pad" },
  { id: "memo-circle-info", icon: "faMemoCircleInfo", slug: "fa-memo-circle-info", name: "Memo Circle Info" },
  { id: "memo-circle-check", icon: "faMemoCircleCheck", slug: "fa-memo-circle-check", name: "Memo Circle Check" },
  { id: "memo", icon: "faMemo", slug: "fa-memo", name: "Memo" },
  { id: "melon-slice", icon: "faMelonSlice", slug: "fa-melon-slice", name: "Melon Slice" },
  { id: "melon", icon: "faMelon", slug: "fa-melon", name: "Melon" },
  { id: "maximize", icon: "faMaximize", slug: "fa-maximize", name: "Maximize" },
  { id: "mattress-pillow", icon: "faMattressPillow", slug: "fa-mattress-pillow", name: "Mattress Pillow" },
  { id: "masks-theater", icon: "faMasksTheater", slug: "fa-masks-theater", name: "Masks Theater" },
  { id: "mask-ventilator", icon: "faMaskVentilator", slug: "fa-mask-ventilator", name: "Mask Ventilator" },
  { id: "mask-snorkel", icon: "faMaskSnorkel", slug: "fa-mask-snorkel", name: "Mask Snorkel" },
  { id: "mask-face", icon: "faMaskFace", slug: "fa-mask-face", name: "Mask Face" },
  { id: "martini-glass-empty", icon: "faMartiniGlassEmpty", slug: "fa-martini-glass-empty", name: "Martini Glass Empty" },
  { id: "martini-glass-citrus", icon: "faMartiniGlassCitrus", slug: "fa-martini-glass-citrus", name: "Martini Glass Citrus" },
  { id: "martini-glass", icon: "faMartiniGlass", slug: "fa-martini-glass", name: "Martini Glass" },
  { id: "mars-stroke-up", icon: "faMarsStrokeUp", slug: "fa-mars-stroke-up", name: "Mars Stroke Up" },
  { id: "mars-stroke-right", icon: "faMarsStrokeRight", slug: "fa-mars-stroke-right", name: "Mars Stroke Right" },
  { id: "mars-stroke", icon: "faMarsStroke", slug: "fa-mars-stroke", name: "Mars Stroke" },
  { id: "mars-double", icon: "faMarsDouble", slug: "fa-mars-double", name: "Mars Double" },
  { id: "mars-and-venus-burst", icon: "faMarsAndVenusBurst", slug: "fa-mars-and-venus-burst", name: "Mars And Venus Burst" },
  { id: "mars-and-venus", icon: "faMarsAndVenus", slug: "fa-mars-and-venus", name: "Mars And Venus" },
  { id: "mars", icon: "faMars", slug: "fa-mars", name: "Mars" },
  { id: "map-pin", icon: "faMapPin", slug: "fa-map-pin", name: "Map Pin" },
  { id: "map-location-dot", icon: "faMapLocationDot", slug: "fa-map-location-dot", name: "Map Location Dot" },
  { id: "map-location", icon: "faMapLocation", slug: "fa-map-location", name: "Map Location" },
  { id: "map", icon: "faMap", slug: "fa-map", name: "Map" },
  { id: "manhole", icon: "faManhole", slug: "fa-manhole", name: "Manhole" },
  { id: "mango", icon: "faMango", slug: "fa-mango", name: "Mango" },
  { id: "mandolin", icon: "faMandolin", slug: "fa-mandolin", name: "Mandolin" },
  { id: "manat-sign", icon: "faManatSign", slug: "fa-manat-sign", name: "Manat Sign" },
  { id: "mailbox-flag-up", icon: "faMailboxFlagUp", slug: "fa-mailbox-flag-up", name: "Mailbox Flag Up" },
  { id: "magnifying-glass-waveform", icon: "faMagnifyingGlassWaveform", slug: "fa-magnifying-glass-waveform", name: "Magnifying Glass Waveform" },
  { id: "magnifying-glass-plus", icon: "faMagnifyingGlassPlus", slug: "fa-magnifying-glass-plus", name: "Magnifying Glass Plus" },
  { id: "magnifying-glass-play", icon: "faMagnifyingGlassPlay", slug: "fa-magnifying-glass-play", name: "Magnifying Glass Play" },
  { id: "magnifying-glass-music", icon: "faMagnifyingGlassMusic", slug: "fa-magnifying-glass-music", name: "Magnifying Glass Music" },
  { id: "magnifying-glass-minus", icon: "faMagnifyingGlassMinus", slug: "fa-magnifying-glass-minus", name: "Magnifying Glass Minus" },
  { id: "magnifying-glass-location", icon: "faMagnifyingGlassLocation", slug: "fa-magnifying-glass-location", name: "Magnifying Glass Location" },
  { id: "magnifying-glass-dollar", icon: "faMagnifyingGlassDollar", slug: "fa-magnifying-glass-dollar", name: "Magnifying Glass Dollar" },
  { id: "magnifying-glass-chart", icon: "faMagnifyingGlassChart", slug: "fa-magnifying-glass-chart", name: "Magnifying Glass Chart" },
  { id: "magnifying-glass-arrows-rotate", icon: "faMagnifyingGlassArrowsRotate", slug: "fa-magnifying-glass-arrows-rotate", name: "Magnifying Glass Arrows Rotate" },
  { id: "magnifying-glass-arrow-right", icon: "faMagnifyingGlassArrowRight", slug: "fa-magnifying-glass-arrow-right", name: "Magnifying Glass Arrow Right" },
  { id: "mace", icon: "faMace", slug: "fa-mace", name: "Mace" },
  { id: "m", icon: "faM", slug: "fa-m", name: "M" },
  { id: "lungs-virus", icon: "faLungsVirus", slug: "fa-lungs-virus", name: "Lungs Virus" },
  { id: "lungs", icon: "faLungs", slug: "fa-lungs", name: "Lungs" },
  { id: "luchador-mask", icon: "faLuchadorMask", slug: "fa-luchador-mask", name: "Luchador Mask" },
  { id: "lollipop", icon: "faLollipop", slug: "fa-lollipop", name: "Lollipop" },
  { id: "locust", icon: "faLocust", slug: "fa-locust", name: "Locust" },
  { id: "lock-open", icon: "faLockOpen", slug: "fa-lock-open", name: "Lock Open" },
  { id: "lock-keyhole-open", icon: "faLockKeyholeOpen", slug: "fa-lock-keyhole-open", name: "Lock Keyhole Open" },
  { id: "lock-keyhole", icon: "faLockKeyhole", slug: "fa-lock-keyhole", name: "Lock Keyhole" },
  { id: "lock-hashtag", icon: "faLockHashtag", slug: "fa-lock-hashtag", name: "Lock Hashtag" },
  { id: "lock-a", icon: "faLockA", slug: "fa-lock-a", name: "Lock A" },
  { id: "location-xmark", icon: "faLocationXmark", slug: "fa-location-xmark", name: "Location Xmark" },
  { id: "location-smile", icon: "faLocationSmile", slug: "fa-location-smile", name: "Location Smile" },
  { id: "location-question", icon: "faLocationQuestion", slug: "fa-location-question", name: "Location Question" },
  { id: "location-plus", icon: "faLocationPlus", slug: "fa-location-plus", name: "Location Plus" },
  { id: "location-pin-slash", icon: "faLocationPinSlash", slug: "fa-location-pin-slash", name: "Location Pin Slash" },
  { id: "location-pin-lock", icon: "faLocationPinLock", slug: "fa-location-pin-lock", name: "Location Pin Lock" },
  { id: "location-pen", icon: "faLocationPen", slug: "fa-location-pen", name: "Location Pen" },
  { id: "location-minus", icon: "faLocationMinus", slug: "fa-location-minus", name: "Location Minus" },
  { id: "location-exclamation", icon: "faLocationExclamation", slug: "fa-location-exclamation", name: "Location Exclamation" },
  { id: "location-dot-slash", icon: "faLocationDotSlash", slug: "fa-location-dot-slash", name: "Location Dot Slash" },
  { id: "location-crosshairs-slash", icon: "faLocationCrosshairsSlash", slug: "fa-location-crosshairs-slash", name: "Location Crosshairs Slash" },
  { id: "location-crosshairs", icon: "faLocationCrosshairs", slug: "fa-location-crosshairs", name: "Location Crosshairs" },
  { id: "location-check", icon: "faLocationCheck", slug: "fa-location-check", name: "Location Check" },
  { id: "location-arrow-up", icon: "faLocationArrowUp", slug: "fa-location-arrow-up", name: "Location Arrow Up" },
  { id: "location-arrow", icon: "faLocationArrow", slug: "fa-location-arrow", name: "Location Arrow" },
  { id: "lobster", icon: "faLobster", slug: "fa-lobster", name: "Lobster" },
  { id: "loader", icon: "faLoader", slug: "fa-loader", name: "Loader" },
  { id: "litecoin-sign", icon: "faLitecoinSign", slug: "fa-litecoin-sign", name: "Litecoin Sign" },
  { id: "list-ul", icon: "faListUl", slug: "fa-list-ul", name: "List Ul" },
  { id: "list-tree", icon: "faListTree", slug: "fa-list-tree", name: "List Tree" },
  { id: "list-timeline", icon: "faListTimeline", slug: "fa-list-timeline", name: "List Timeline" },
  { id: "list-radio", icon: "faListRadio", slug: "fa-list-radio", name: "List Radio" },
  { id: "list-ol", icon: "faListOl", slug: "fa-list-ol", name: "List Ol" },
  { id: "list-music", icon: "faListMusic", slug: "fa-list-music", name: "List Music" },
  { id: "list-dropdown", icon: "faListDropdown", slug: "fa-list-dropdown", name: "List Dropdown" },
  { id: "list-check", icon: "faListCheck", slug: "fa-list-check", name: "List Check" },
  { id: "lira-sign", icon: "faLiraSign", slug: "fa-lira-sign", name: "Lira Sign" },
  { id: "lips", icon: "faLips", slug: "fa-lips", name: "Lips" },
  { id: "link-slash", icon: "faLinkSlash", slug: "fa-link-slash", name: "Link Slash" },
  { id: "link-simple-slash", icon: "faLinkSimpleSlash", slug: "fa-link-simple-slash", name: "Link Simple Slash" },
  { id: "link-simple", icon: "faLinkSimple", slug: "fa-link-simple", name: "Link Simple" },
  { id: "link-horizontal-slash", icon: "faLinkHorizontalSlash", slug: "fa-link-horizontal-slash", name: "Link Horizontal Slash" },
  { id: "link-horizontal", icon: "faLinkHorizontal", slug: "fa-link-horizontal", name: "Link Horizontal" },
  { id: "lines-leaning", icon: "faLinesLeaning", slug: "fa-lines-leaning", name: "Lines Leaning" },
  { id: "line-columns", icon: "faLineColumns", slug: "fa-line-columns", name: "Line Columns" },
  { id: "lighthouse", icon: "faLighthouse", slug: "fa-lighthouse", name: "Lighthouse" },
  { id: "lightbulb-on", icon: "faLightbulbOn", slug: "fa-lightbulb-on", name: "Lightbulb On" },
  { id: "lightbulb-gear", icon: "faLightbulbGear", slug: "fa-lightbulb-gear", name: "Lightbulb Gear" },
  { id: "lightbulb-exclamation-on", icon: "faLightbulbExclamationOn", slug: "fa-lightbulb-exclamation-on", name: "Lightbulb Exclamation On" },
  { id: "lightbulb-exclamation", icon: "faLightbulbExclamation", slug: "fa-lightbulb-exclamation", name: "Lightbulb Exclamation" },
  { id: "lightbulb-dollar", icon: "faLightbulbDollar", slug: "fa-lightbulb-dollar", name: "Lightbulb Dollar" },
  { id: "lightbulb-cfl-on", icon: "faLightbulbCflOn", slug: "fa-lightbulb-cfl-on", name: "Lightbulb Cfl On" },
  { id: "lightbulb-cfl", icon: "faLightbulbCfl", slug: "fa-lightbulb-cfl", name: "Lightbulb Cfl" },
  { id: "light-switch-on", icon: "faLightSwitchOn", slug: "fa-light-switch-on", name: "Light Switch On" },
  { id: "light-switch-off", icon: "faLightSwitchOff", slug: "fa-light-switch-off", name: "Light Switch Off" },
  { id: "light-emergency-on", icon: "faLightEmergencyOn", slug: "fa-light-emergency-on", name: "Light Emergency On" },
  { id: "light-emergency", icon: "faLightEmergency", slug: "fa-light-emergency", name: "Light Emergency" },
  { id: "light-ceiling", icon: "faLightCeiling", slug: "fa-light-ceiling", name: "Light Ceiling" },
  { id: "life-ring", icon: "faLifeRing", slug: "fa-life-ring", name: "Life Ring" },
  { id: "less-than-equal", icon: "faLessThanEqual", slug: "fa-less-than-equal", name: "Less Than Equal" },
  { id: "less-than", icon: "faLessThan", slug: "fa-less-than", name: "Less Than" },
  { id: "left-to-line", icon: "faLeftToLine", slug: "fa-left-to-line", name: "Left To Line" },
  { id: "left-to-bracket", icon: "faLeftToBracket", slug: "fa-left-to-bracket", name: "Left To Bracket" },
  { id: "left-right", icon: "faLeftRight", slug: "fa-left-right", name: "Left Right" },
  { id: "left-long-to-line", icon: "faLeftLongToLine", slug: "fa-left-long-to-line", name: "Left Long To Line" },
  { id: "left-long", icon: "faLeftLong", slug: "fa-left-long", name: "Left Long" },
  { id: "left-from-line", icon: "faLeftFromLine", slug: "fa-left-from-line", name: "Left From Line" },
  { id: "left-from-bracket", icon: "faLeftFromBracket", slug: "fa-left-from-bracket", name: "Left From Bracket" },
  { id: "left", icon: "faLeft", slug: "fa-left", name: "Left" },
  { id: "leafy-green", icon: "faLeafyGreen", slug: "fa-leafy-green", name: "Leafy Green" },
  { id: "leaf-oak", icon: "faLeafOak", slug: "fa-leaf-oak", name: "Leaf Oak" },
  { id: "leaf-maple", icon: "faLeafMaple", slug: "fa-leaf-maple", name: "Leaf Maple" },
  { id: "leaf-heart", icon: "faLeafHeart", slug: "fa-leaf-heart", name: "Leaf Heart" },
  { id: "layer-plus", icon: "faLayerPlus", slug: "fa-layer-plus", name: "Layer Plus" },
  { id: "layer-minus", icon: "faLayerMinus", slug: "fa-layer-minus", name: "Layer Minus" },
  { id: "lasso-sparkles", icon: "faLassoSparkles", slug: "fa-lasso-sparkles", name: "Lasso Sparkles" },
  { id: "lasso", icon: "faLasso", slug: "fa-lasso", name: "Lasso" },
  { id: "lari-sign", icon: "faLariSign", slug: "fa-lari-sign", name: "Lari Sign" },
  { id: "laptop-slash", icon: "faLaptopSlash", slug: "fa-laptop-slash", name: "Laptop Slash" },
  { id: "laptop-mobile", icon: "faLaptopMobile", slug: "fa-laptop-mobile", name: "Laptop Mobile" },
  { id: "laptop-medical", icon: "faLaptopMedical", slug: "fa-laptop-medical", name: "Laptop Medical" },
  { id: "laptop-file", icon: "faLaptopFile", slug: "fa-laptop-file", name: "Laptop File" },
  { id: "laptop-code", icon: "faLaptopCode", slug: "fa-laptop-code", name: "Laptop Code" },
  { id: "laptop-binary", icon: "faLaptopBinary", slug: "fa-laptop-binary", name: "Laptop Binary" },
  { id: "laptop-arrow-down", icon: "faLaptopArrowDown", slug: "fa-laptop-arrow-down", name: "Laptop Arrow Down" },
  { id: "landmark-magnifying-glass", icon: "faLandmarkMagnifyingGlass", slug: "fa-landmark-magnifying-glass", name: "Landmark Magnifying Glass" },
  { id: "landmark-flag", icon: "faLandmarkFlag", slug: "fa-landmark-flag", name: "Landmark Flag" },
  { id: "landmark-dome", icon: "faLandmarkDome", slug: "fa-landmark-dome", name: "Landmark Dome" },
  { id: "land-mine-on", icon: "faLandMineOn", slug: "fa-land-mine-on", name: "Land Mine On" },
  { id: "lamp-street", icon: "faLampStreet", slug: "fa-lamp-street", name: "Lamp Street" },
  { id: "lamp-floor", icon: "faLampFloor", slug: "fa-lamp-floor", name: "Lamp Floor" },
  { id: "lamp-desk", icon: "faLampDesk", slug: "fa-lamp-desk", name: "Lamp Desk" },
  { id: "lambda", icon: "faLambda", slug: "fa-lambda", name: "Lambda" },
  { id: "lacrosse-stick-ball", icon: "faLacrosseStickBall", slug: "fa-lacrosse-stick-ball", name: "Lacrosse Stick Ball" },
  { id: "lacrosse-stick", icon: "faLacrosseStick", slug: "fa-lacrosse-stick", name: "Lacrosse Stick" },
  { id: "l", icon: "faL", slug: "fa-l", name: "L" },
  { id: "knife", icon: "faKnife", slug: "fa-knife", name: "Knife" },
  { id: "kiwi-fruit", icon: "faKiwiFruit", slug: "fa-kiwi-fruit", name: "Kiwi Fruit" },
  { id: "kite", icon: "faKite", slug: "fa-kite", name: "Kite" },
  { id: "kitchen-set", icon: "faKitchenSet", slug: "fa-kitchen-set", name: "Kitchen Set" },
  { id: "kit-medical", icon: "faKitMedical", slug: "fa-kit-medical", name: "Kit Medical" },
  { id: "kip-sign", icon: "faKipSign", slug: "fa-kip-sign", name: "Kip Sign" },
  { id: "khanda", icon: "faKhanda", slug: "fa-khanda", name: "Khanda" },
  { id: "keynote", icon: "faKeynote", slug: "fa-keynote", name: "Keynote" },
  { id: "keyboard-left", icon: "faKeyboardLeft", slug: "fa-keyboard-left", name: "Keyboard Left" },
  { id: "keyboard-down", icon: "faKeyboardDown", slug: "fa-keyboard-down", name: "Keyboard Down" },
  { id: "keyboard-brightness-low", icon: "faKeyboardBrightnessLow", slug: "fa-keyboard-brightness-low", name: "Keyboard Brightness Low" },
  { id: "keyboard-brightness", icon: "faKeyboardBrightness", slug: "fa-keyboard-brightness", name: "Keyboard Brightness" },
  { id: "key-skeleton-left-right", icon: "faKeySkeletonLeftRight", slug: "fa-key-skeleton-left-right", name: "Key Skeleton Left Right" },
  { id: "key-skeleton", icon: "faKeySkeleton", slug: "fa-key-skeleton", name: "Key Skeleton" },
  { id: "kerning", icon: "faKerning", slug: "fa-kerning", name: "Kerning" },
  { id: "kazoo", icon: "faKazoo", slug: "fa-kazoo", name: "Kazoo" },
  { id: "kaaba", icon: "faKaaba", slug: "fa-kaaba", name: "Kaaba" },
  { id: "k", icon: "faK", slug: "fa-k", name: "K" },
  { id: "jug-detergent", icon: "faJugDetergent", slug: "fa-jug-detergent", name: "Jug Detergent" },
  { id: "jug-bottle", icon: "faJugBottle", slug: "fa-jug-bottle", name: "Jug Bottle" },
  { id: "jug", icon: "faJug", slug: "fa-jug", name: "Jug" },
  { id: "jet-fighter-up", icon: "faJetFighterUp", slug: "fa-jet-fighter-up", name: "Jet Fighter Up" },
  { id: "jet-fighter", icon: "faJetFighter", slug: "fa-jet-fighter", name: "Jet Fighter" },
  { id: "jedi", icon: "faJedi", slug: "fa-jedi", name: "Jedi" },
  { id: "jar-wheat", icon: "faJarWheat", slug: "fa-jar-wheat", name: "Jar Wheat" },
  { id: "jar", icon: "faJar", slug: "fa-jar", name: "Jar" },
  { id: "jack-o-lantern", icon: "faJackOLantern", slug: "fa-jack-o-lantern", name: "Jack O Lantern" },
  { id: "j", icon: "faJ", slug: "fa-j", name: "J" },
  { id: "italic", icon: "faItalic", slug: "fa-italic", name: "Italic" },
  { id: "island-tropical", icon: "faIslandTropical", slug: "fa-island-tropical", name: "Island Tropical" },
  { id: "intersection", icon: "faIntersection", slug: "fa-intersection", name: "Intersection" },
  { id: "interrobang", icon: "faInterrobang", slug: "fa-interrobang", name: "Interrobang" },
  { id: "integral", icon: "faIntegral", slug: "fa-integral", name: "Integral" },
  { id: "input-text", icon: "faInputText", slug: "fa-input-text", name: "Input Text" },
  { id: "input-pipe", icon: "faInputPipe", slug: "fa-input-pipe", name: "Input Pipe" },
  { id: "input-numeric", icon: "faInputNumeric", slug: "fa-input-numeric", name: "Input Numeric" },
  { id: "inhaler", icon: "faInhaler", slug: "fa-inhaler", name: "Inhaler" },
  { id: "infinity", icon: "faInfinity", slug: "fa-infinity", name: "Infinity" },
  { id: "industry-windows", icon: "faIndustryWindows", slug: "fa-industry-windows", name: "Industry Windows" },
  { id: "indian-rupee-sign", icon: "faIndianRupeeSign", slug: "fa-indian-rupee-sign", name: "Indian Rupee Sign" },
  { id: "indent", icon: "faIndent", slug: "fa-indent", name: "Indent" },
  { id: "inboxes", icon: "faInboxes", slug: "fa-inboxes", name: "Inboxes" },
  { id: "inbox-out", icon: "faInboxOut", slug: "fa-inbox-out", name: "Inbox Out" },
  { id: "inbox-in", icon: "faInboxIn", slug: "fa-inbox-in", name: "Inbox In" },
  { id: "inbox-full", icon: "faInboxFull", slug: "fa-inbox-full", name: "Inbox Full" },
  { id: "images-user", icon: "faImagesUser", slug: "fa-images-user", name: "Images User" },
  { id: "image-user", icon: "faImageUser", slug: "fa-image-user", name: "Image User" },
  { id: "image-slash", icon: "faImageSlash", slug: "fa-image-slash", name: "Image Slash" },
  { id: "image-portrait", icon: "faImagePortrait", slug: "fa-image-portrait", name: "Image Portrait" },
  { id: "image-polaroid-user", icon: "faImagePolaroidUser", slug: "fa-image-polaroid-user", name: "Image Polaroid User" },
  { id: "image-polaroid", icon: "faImagePolaroid", slug: "fa-image-polaroid", name: "Image Polaroid" },
  { id: "image-landscape", icon: "faImageLandscape", slug: "fa-image-landscape", name: "Image Landscape" },
  { id: "igloo", icon: "faIgloo", slug: "fa-igloo", name: "Igloo" },
  { id: "id-card-clip", icon: "faIdCardClip", slug: "fa-id-card-clip", name: "Id Card Clip" },
  { id: "id-card", icon: "faIdCard", slug: "fa-id-card", name: "Id Card" },
  { id: "id-badge", icon: "faIdBadge", slug: "fa-id-badge", name: "Id Badge" },
  { id: "icicles", icon: "faIcicles", slug: "fa-icicles", name: "Icicles" },
  { id: "ice-skate", icon: "faIceSkate", slug: "fa-ice-skate", name: "Ice Skate" },
  { id: "ice-cream", icon: "faIceCream", slug: "fa-ice-cream", name: "Ice Cream" },
  { id: "i", icon: "faI", slug: "fa-i", name: "I" },
  { id: "hyphen", icon: "faHyphen", slug: "fa-hyphen", name: "Hyphen" },
  { id: "hurricane", icon: "faHurricane", slug: "fa-hurricane", name: "Hurricane" },
  { id: "hundred-points", icon: "faHundredPoints", slug: "fa-hundred-points", name: "Hundred Points" },
  { id: "hryvnia-sign", icon: "faHryvniaSign", slug: "fa-hryvnia-sign", name: "Hryvnia Sign" },
  { id: "house-window", icon: "faHouseWindow", slug: "fa-house-window", name: "House Window" },
  { id: "house-water", icon: "faHouseWater", slug: "fa-house-water", name: "House Water" },
  { id: "house-user", icon: "faHouseUser", slug: "fa-house-user", name: "House User" },
  { id: "house-turret", icon: "faHouseTurret", slug: "fa-house-turret", name: "House Turret" },
  { id: "house-tsunami", icon: "faHouseTsunami", slug: "fa-house-tsunami", name: "House Tsunami" },
  { id: "house-tree", icon: "faHouseTree", slug: "fa-house-tree", name: "House Tree" },
  { id: "house-signal", icon: "faHouseSignal", slug: "fa-house-signal", name: "House Signal" },
  { id: "house-person-return", icon: "faHousePersonReturn", slug: "fa-house-person-return", name: "House Person Return" },
  { id: "house-person-leave", icon: "faHousePersonLeave", slug: "fa-house-person-leave", name: "House Person Leave" },
  { id: "house-medical-flag", icon: "faHouseMedicalFlag", slug: "fa-house-medical-flag", name: "House Medical Flag" },
  { id: "house-medical-circle-xmark", icon: "faHouseMedicalCircleXmark", slug: "fa-house-medical-circle-xmark", name: "House Medical Circle Xmark" },
  { id: "house-medical-circle-exclamation", icon: "faHouseMedicalCircleExclamation", slug: "fa-house-medical-circle-exclamation", name: "House Medical Circle Exclamation" },
  { id: "house-medical-circle-check", icon: "faHouseMedicalCircleCheck", slug: "fa-house-medical-circle-check", name: "House Medical Circle Check" },
  { id: "house-medical", icon: "faHouseMedical", slug: "fa-house-medical", name: "House Medical" },
  { id: "house-lock", icon: "faHouseLock", slug: "fa-house-lock", name: "House Lock" },
  { id: "house-laptop", icon: "faHouseLaptop", slug: "fa-house-laptop", name: "House Laptop" },
  { id: "house-heart", icon: "faHouseHeart", slug: "fa-house-heart", name: "House Heart" },
  { id: "house-flood-water-circle-arrow-right", icon: "faHouseFloodWaterCircleArrowRight", slug: "fa-house-flood-water-circle-arrow-right", name: "House Flood Water Circle Arrow Right" },
  { id: "house-flood-water", icon: "faHouseFloodWater", slug: "fa-house-flood-water", name: "House Flood Water" },
  { id: "house-flag", icon: "faHouseFlag", slug: "fa-house-flag", name: "House Flag" },
  { id: "house-fire", icon: "faHouseFire", slug: "fa-house-fire", name: "House Fire" },
  { id: "house-day", icon: "faHouseDay", slug: "fa-house-day", name: "House Day" },
  { id: "house-crack", icon: "faHouseCrack", slug: "fa-house-crack", name: "House Crack" },
  { id: "house-circle-xmark", icon: "faHouseCircleXmark", slug: "fa-house-circle-xmark", name: "House Circle Xmark" },
  { id: "house-circle-exclamation", icon: "faHouseCircleExclamation", slug: "fa-house-circle-exclamation", name: "House Circle Exclamation" },
  { id: "house-circle-check", icon: "faHouseCircleCheck", slug: "fa-house-circle-check", name: "House Circle Check" },
  { id: "house-chimney-window", icon: "faHouseChimneyWindow", slug: "fa-house-chimney-window", name: "House Chimney Window" },
  { id: "house-chimney-user", icon: "faHouseChimneyUser", slug: "fa-house-chimney-user", name: "House Chimney User" },
  { id: "house-chimney-medical", icon: "faHouseChimneyMedical", slug: "fa-house-chimney-medical", name: "House Chimney Medical" },
  { id: "house-chimney-heart", icon: "faHouseChimneyHeart", slug: "fa-house-chimney-heart", name: "House Chimney Heart" },
  { id: "house-chimney-crack", icon: "faHouseChimneyCrack", slug: "fa-house-chimney-crack", name: "House Chimney Crack" },
  { id: "house-chimney-blank", icon: "faHouseChimneyBlank", slug: "fa-house-chimney-blank", name: "House Chimney Blank" },
  { id: "house-chimney", icon: "faHouseChimney", slug: "fa-house-chimney", name: "House Chimney" },
  { id: "house-building", icon: "faHouseBuilding", slug: "fa-house-building", name: "House Building" },
  { id: "house-blank", icon: "faHouseBlank", slug: "fa-house-blank", name: "House Blank" },
  { id: "hourglass-half", icon: "faHourglassHalf", slug: "fa-hourglass-half", name: "Hourglass Half" },
  { id: "hourglass-end", icon: "faHourglassEnd", slug: "fa-hourglass-end", name: "Hourglass End" },
  { id: "hourglass-clock", icon: "faHourglassClock", slug: "fa-hourglass-clock", name: "Hourglass Clock" },
  { id: "hourglass", icon: "faHourglass", slug: "fa-hourglass", name: "Hourglass" },
  { id: "hotdog", icon: "faHotdog", slug: "fa-hotdog", name: "Hotdog" },
  { id: "hot-tub-person", icon: "faHotTubPerson", slug: "fa-hot-tub-person", name: "Hot Tub Person" },
  { id: "hospitals", icon: "faHospitals", slug: "fa-hospitals", name: "Hospitals" },
  { id: "hospital-user", icon: "faHospitalUser", slug: "fa-hospital-user", name: "Hospital User" },
  { id: "hose-reel", icon: "faHoseReel", slug: "fa-hose-reel", name: "Hose Reel" },
  { id: "hose", icon: "faHose", slug: "fa-hose", name: "Hose" },
  { id: "horse-saddle", icon: "faHorseSaddle", slug: "fa-horse-saddle", name: "Horse Saddle" },
  { id: "horse-head", icon: "faHorseHead", slug: "fa-horse-head", name: "Horse Head" },
  { id: "horse", icon: "faHorse", slug: "fa-horse", name: "Horse" },
  { id: "horizontal-rule", icon: "faHorizontalRule", slug: "fa-horizontal-rule", name: "Horizontal Rule" },
  { id: "hood-cloak", icon: "faHoodCloak", slug: "fa-hood-cloak", name: "Hood Cloak" },
  { id: "honey-pot", icon: "faHoneyPot", slug: "fa-honey-pot", name: "Honey Pot" },
  { id: "holly-berry", icon: "faHollyBerry", slug: "fa-holly-berry", name: "Holly Berry" },
  { id: "hockey-sticks", icon: "faHockeySticks", slug: "fa-hockey-sticks", name: "Hockey Sticks" },
  { id: "hockey-stick-puck", icon: "faHockeyStickPuck", slug: "fa-hockey-stick-puck", name: "Hockey Stick Puck" },
  { id: "hockey-puck", icon: "faHockeyPuck", slug: "fa-hockey-puck", name: "Hockey Puck" },
  { id: "hockey-mask", icon: "faHockeyMask", slug: "fa-hockey-mask", name: "Hockey Mask" },
  { id: "hill-rockslide", icon: "faHillRockslide", slug: "fa-hill-rockslide", name: "Hill Rockslide" },
  { id: "hill-avalanche", icon: "faHillAvalanche", slug: "fa-hill-avalanche", name: "Hill Avalanche" },
  { id: "highlighter-line", icon: "faHighlighterLine", slug: "fa-highlighter-line", name: "Highlighter Line" },
  { id: "highlighter", icon: "faHighlighter", slug: "fa-highlighter", name: "Highlighter" },
  { id: "high-definition", icon: "faHighDefinition", slug: "fa-high-definition", name: "High Definition" },
  { id: "hexagon-xmark", icon: "faHexagonXmark", slug: "fa-hexagon-xmark", name: "Hexagon Xmark" },
  { id: "hexagon-vertical-nft-slanted", icon: "faHexagonVerticalNftSlanted", slug: "fa-hexagon-vertical-nft-slanted", name: "Hexagon Vertical Nft Slanted" },
  { id: "hexagon-vertical-nft", icon: "faHexagonVerticalNft", slug: "fa-hexagon-vertical-nft", name: "Hexagon Vertical Nft" },
  { id: "hexagon-plus", icon: "faHexagonPlus", slug: "fa-hexagon-plus", name: "Hexagon Plus" },
  { id: "hexagon-minus", icon: "faHexagonMinus", slug: "fa-hexagon-minus", name: "Hexagon Minus" },
  { id: "hexagon-image", icon: "faHexagonImage", slug: "fa-hexagon-image", name: "Hexagon Image" },
  { id: "hexagon-exclamation", icon: "faHexagonExclamation", slug: "fa-hexagon-exclamation", name: "Hexagon Exclamation" },
  { id: "hexagon-divide", icon: "faHexagonDivide", slug: "fa-hexagon-divide", name: "Hexagon Divide" },
  { id: "hexagon-check", icon: "faHexagonCheck", slug: "fa-hexagon-check", name: "Hexagon Check" },
  { id: "hexagon", icon: "faHexagon", slug: "fa-hexagon", name: "Hexagon" },
  { id: "helmet-un", icon: "faHelmetUn", slug: "fa-helmet-un", name: "Helmet Un" },
  { id: "helmet-safety", icon: "faHelmetSafety", slug: "fa-helmet-safety", name: "Helmet Safety" },
  { id: "helmet-battle", icon: "faHelmetBattle", slug: "fa-helmet-battle", name: "Helmet Battle" },
  { id: "helicopter-symbol", icon: "faHelicopterSymbol", slug: "fa-helicopter-symbol", name: "Helicopter Symbol" },
  { id: "helicopter", icon: "faHelicopter", slug: "fa-helicopter", name: "Helicopter" },
  { id: "heat", icon: "faHeat", slug: "fa-heat", name: "Heat" },
  { id: "heart-pulse", icon: "faHeartPulse", slug: "fa-heart-pulse", name: "Heart Pulse" },
  { id: "heart-half-stroke", icon: "faHeartHalfStroke", slug: "fa-heart-half-stroke", name: "Heart Half Stroke" },
  { id: "heart-half", icon: "faHeartHalf", slug: "fa-heart-half", name: "Heart Half" },
  { id: "heart-crack", icon: "faHeartCrack", slug: "fa-heart-crack", name: "Heart Crack" },
  { id: "heart-circle-xmark", icon: "faHeartCircleXmark", slug: "fa-heart-circle-xmark", name: "Heart Circle Xmark" },
  { id: "heart-circle-plus", icon: "faHeartCirclePlus", slug: "fa-heart-circle-plus", name: "Heart Circle Plus" },
  { id: "heart-circle-minus", icon: "faHeartCircleMinus", slug: "fa-heart-circle-minus", name: "Heart Circle Minus" },
  { id: "heart-circle-exclamation", icon: "faHeartCircleExclamation", slug: "fa-heart-circle-exclamation", name: "Heart Circle Exclamation" },
  { id: "heart-circle-check", icon: "faHeartCircleCheck", slug: "fa-heart-circle-check", name: "Heart Circle Check" },
  { id: "heart-circle-bolt", icon: "faHeartCircleBolt", slug: "fa-heart-circle-bolt", name: "Heart Circle Bolt" },
  { id: "headphones-simple", icon: "faHeadphonesSimple", slug: "fa-headphones-simple", name: "Headphones Simple" },
  { id: "head-side-virus", icon: "faHeadSideVirus", slug: "fa-head-side-virus", name: "Head Side Virus" },
  { id: "head-side-medical", icon: "faHeadSideMedical", slug: "fa-head-side-medical", name: "Head Side Medical" },
  { id: "head-side-mask", icon: "faHeadSideMask", slug: "fa-head-side-mask", name: "Head Side Mask" },
  { id: "head-side-heart", icon: "faHeadSideHeart", slug: "fa-head-side-heart", name: "Head Side Heart" },
  { id: "head-side-headphones", icon: "faHeadSideHeadphones", slug: "fa-head-side-headphones", name: "Head Side Headphones" },
  { id: "head-side-goggles", icon: "faHeadSideGoggles", slug: "fa-head-side-goggles", name: "Head Side Goggles" },
  { id: "head-side-gear", icon: "faHeadSideGear", slug: "fa-head-side-gear", name: "Head Side Gear" },
  { id: "head-side-cough-slash", icon: "faHeadSideCoughSlash", slug: "fa-head-side-cough-slash", name: "Head Side Cough Slash" },
  { id: "head-side-cough", icon: "faHeadSideCough", slug: "fa-head-side-cough", name: "Head Side Cough" },
  { id: "head-side-brain", icon: "faHeadSideBrain", slug: "fa-head-side-brain", name: "Head Side Brain" },
  { id: "hat-wizard", icon: "faHatWizard", slug: "fa-hat-wizard", name: "Hat Wizard" },
  { id: "hat-witch", icon: "faHatWitch", slug: "fa-hat-witch", name: "Hat Witch" },
  { id: "hat-winter", icon: "faHatWinter", slug: "fa-hat-winter", name: "Hat Winter" },
  { id: "hat-santa", icon: "faHatSanta", slug: "fa-hat-santa", name: "Hat Santa" },
  { id: "hat-cowboy-side", icon: "faHatCowboySide", slug: "fa-hat-cowboy-side", name: "Hat Cowboy Side" },
  { id: "hat-cowboy", icon: "faHatCowboy", slug: "fa-hat-cowboy", name: "Hat Cowboy" },
  { id: "hat-chef", icon: "faHatChef", slug: "fa-hat-chef", name: "Hat Chef" },
  { id: "hat-beach", icon: "faHatBeach", slug: "fa-hat-beach", name: "Hat Beach" },
  { id: "hashtag-lock", icon: "faHashtagLock", slug: "fa-hashtag-lock", name: "Hashtag Lock" },
  { id: "hard-drive", icon: "faHardDrive", slug: "fa-hard-drive", name: "Hard Drive" },
  { id: "hanukiah", icon: "faHanukiah", slug: "fa-hanukiah", name: "Hanukiah" },
  { id: "handshake-simple-slash", icon: "faHandshakeSimpleSlash", slug: "fa-handshake-simple-slash", name: "Handshake Simple Slash" },
  { id: "handshake-simple", icon: "faHandshakeSimple", slug: "fa-handshake-simple", name: "Handshake Simple" },
  { id: "handshake-angle", icon: "faHandshakeAngle", slug: "fa-handshake-angle", name: "Handshake Angle" },
  { id: "hands-praying", icon: "faHandsPraying", slug: "fa-hands-praying", name: "Hands Praying" },
  { id: "hands-holding-heart", icon: "faHandsHoldingHeart", slug: "fa-hands-holding-heart", name: "Hands Holding Heart" },
  { id: "hands-holding-dollar", icon: "faHandsHoldingDollar", slug: "fa-hands-holding-dollar", name: "Hands Holding Dollar" },
  { id: "hands-holding-diamond", icon: "faHandsHoldingDiamond", slug: "fa-hands-holding-diamond", name: "Hands Holding Diamond" },
  { id: "hands-holding-circle", icon: "faHandsHoldingCircle", slug: "fa-hands-holding-circle", name: "Hands Holding Circle" },
  { id: "hands-holding-child", icon: "faHandsHoldingChild", slug: "fa-hands-holding-child", name: "Hands Holding Child" },
  { id: "hands-holding", icon: "faHandsHolding", slug: "fa-hands-holding", name: "Hands Holding" },
  { id: "hands-clapping", icon: "faHandsClapping", slug: "fa-hands-clapping", name: "Hands Clapping" },
  { id: "hands-bubbles", icon: "faHandsBubbles", slug: "fa-hands-bubbles", name: "Hands Bubbles" },
  { id: "hands-bound", icon: "faHandsBound", slug: "fa-hands-bound", name: "Hands Bound" },
  { id: "hands-asl-interpreting", icon: "faHandsAslInterpreting", slug: "fa-hands-asl-interpreting", name: "Hands Asl Interpreting" },
  { id: "handcuffs", icon: "faHandcuffs", slug: "fa-handcuffs", name: "Handcuffs" },
  { id: "hand-wave", icon: "faHandWave", slug: "fa-hand-wave", name: "Hand Wave" },
  { id: "hand-spock", icon: "faHandSpock", slug: "fa-hand-spock", name: "Hand Spock" },
  { id: "hand-sparkles", icon: "faHandSparkles", slug: "fa-hand-sparkles", name: "Hand Sparkles" },
  { id: "hand-scissors", icon: "faHandScissors", slug: "fa-hand-scissors", name: "Hand Scissors" },
  { id: "hand-pointer", icon: "faHandPointer", slug: "fa-hand-pointer", name: "Hand Pointer" },
  { id: "hand-point-right", icon: "faHandPointRight", slug: "fa-hand-point-right", name: "Hand Point Right" },
  { id: "hand-point-ribbon", icon: "faHandPointRibbon", slug: "fa-hand-point-ribbon", name: "Hand Point Ribbon" },
  { id: "hand-point-left", icon: "faHandPointLeft", slug: "fa-hand-point-left", name: "Hand Point Left" },
  { id: "hand-point-down", icon: "faHandPointDown", slug: "fa-hand-point-down", name: "Hand Point Down" },
  { id: "hand-peace", icon: "faHandPeace", slug: "fa-hand-peace", name: "Hand Peace" },
  { id: "hand-middle-finger", icon: "faHandMiddleFinger", slug: "fa-hand-middle-finger", name: "Hand Middle Finger" },
  { id: "hand-love", icon: "faHandLove", slug: "fa-hand-love", name: "Hand Love" },
  { id: "hand-lizard", icon: "faHandLizard", slug: "fa-hand-lizard", name: "Hand Lizard" },
  { id: "hand-horns", icon: "faHandHorns", slug: "fa-hand-horns", name: "Hand Horns" },
  { id: "hand-holding-skull", icon: "faHandHoldingSkull", slug: "fa-hand-holding-skull", name: "Hand Holding Skull" },
  { id: "hand-holding-seedling", icon: "faHandHoldingSeedling", slug: "fa-hand-holding-seedling", name: "Hand Holding Seedling" },
  { id: "hand-holding-medical", icon: "faHandHoldingMedical", slug: "fa-hand-holding-medical", name: "Hand Holding Medical" },
  { id: "hand-holding-magic", icon: "faHandHoldingMagic", slug: "fa-hand-holding-magic", name: "Hand Holding Magic" },
  { id: "hand-holding-hand", icon: "faHandHoldingHand", slug: "fa-hand-holding-hand", name: "Hand Holding Hand" },
  { id: "hand-holding-droplet", icon: "faHandHoldingDroplet", slug: "fa-hand-holding-droplet", name: "Hand Holding Droplet" },
  { id: "hand-holding-dollar", icon: "faHandHoldingDollar", slug: "fa-hand-holding-dollar", name: "Hand Holding Dollar" },
  { id: "hand-holding-circle-dollar", icon: "faHandHoldingCircleDollar", slug: "fa-hand-holding-circle-dollar", name: "Hand Holding Circle Dollar" },
  { id: "hand-holding-box", icon: "faHandHoldingBox", slug: "fa-hand-holding-box", name: "Hand Holding Box" },
  { id: "hand-holding", icon: "faHandHolding", slug: "fa-hand-holding", name: "Hand Holding" },
  { id: "hand-heart", icon: "faHandHeart", slug: "fa-hand-heart", name: "Hand Heart" },
  { id: "hand-fist", icon: "faHandFist", slug: "fa-hand-fist", name: "Hand Fist" },
  { id: "hand-fingers-crossed", icon: "faHandFingersCrossed", slug: "fa-hand-fingers-crossed", name: "Hand Fingers Crossed" },
  { id: "hand-dots", icon: "faHandDots", slug: "fa-hand-dots", name: "Hand Dots" },
  { id: "hand-back-point-up", icon: "faHandBackPointUp", slug: "fa-hand-back-point-up", name: "Hand Back Point Up" },
  { id: "hand-back-point-right", icon: "faHandBackPointRight", slug: "fa-hand-back-point-right", name: "Hand Back Point Right" },
  { id: "hand-back-point-ribbon", icon: "faHandBackPointRibbon", slug: "fa-hand-back-point-ribbon", name: "Hand Back Point Ribbon" },
  { id: "hand-back-point-left", icon: "faHandBackPointLeft", slug: "fa-hand-back-point-left", name: "Hand Back Point Left" },
  { id: "hand-back-point-down", icon: "faHandBackPointDown", slug: "fa-hand-back-point-down", name: "Hand Back Point Down" },
  { id: "hand-back-fist", icon: "faHandBackFist", slug: "fa-hand-back-fist", name: "Hand Back Fist" },
  { id: "hamsa", icon: "faHamsa", slug: "fa-hamsa", name: "Hamsa" },
  { id: "hammer-war", icon: "faHammerWar", slug: "fa-hammer-war", name: "Hammer War" },
  { id: "hammer-crash", icon: "faHammerCrash", slug: "fa-hammer-crash", name: "Hammer Crash" },
  { id: "hammer-brush", icon: "faHammerBrush", slug: "fa-hammer-brush", name: "Hammer Brush" },
  { id: "h6", icon: "faH6", slug: "fa-h6", name: "H6" },
  { id: "h5", icon: "faH5", slug: "fa-h5", name: "H5" },
  { id: "h4", icon: "faH4", slug: "fa-h4", name: "H4" },
  { id: "h3", icon: "faH3", slug: "fa-h3", name: "H3" },
  { id: "h2", icon: "faH2", slug: "fa-h2", name: "H2" },
  { id: "h1", icon: "faH1", slug: "fa-h1", name: "H1" },
  { id: "h", icon: "faH", slug: "fa-h", name: "H" },
  { id: "gun-squirt", icon: "faGunSquirt", slug: "fa-gun-squirt", name: "Gun Squirt" },
  { id: "gun-slash", icon: "faGunSlash", slug: "fa-gun-slash", name: "Gun Slash" },
  { id: "gun", icon: "faGun", slug: "fa-gun", name: "Gun" },
  { id: "guitars", icon: "faGuitars", slug: "fa-guitars", name: "Guitars" },
  { id: "guitar-electric", icon: "faGuitarElectric", slug: "fa-guitar-electric", name: "Guitar Electric" },
  { id: "guitar", icon: "faGuitar", slug: "fa-guitar", name: "Guitar" },
  { id: "guarani-sign", icon: "faGuaraniSign", slug: "fa-guarani-sign", name: "Guarani Sign" },
  { id: "group-arrows-rotate", icon: "faGroupArrowsRotate", slug: "fa-group-arrows-rotate", name: "Group Arrows Rotate" },
  { id: "grip-vertical", icon: "faGripVertical", slug: "fa-grip-vertical", name: "Grip Vertical" },
  { id: "grip-lines-vertical", icon: "faGripLinesVertical", slug: "fa-grip-lines-vertical", name: "Grip Lines Vertical" },
  { id: "grip-lines", icon: "faGripLines", slug: "fa-grip-lines", name: "Grip Lines" },
  { id: "grip-dots-vertical", icon: "faGripDotsVertical", slug: "fa-grip-dots-vertical", name: "Grip Dots Vertical" },
  { id: "grip-dots", icon: "faGripDots", slug: "fa-grip-dots", name: "Grip Dots" },
  { id: "grip", icon: "faGrip", slug: "fa-grip", name: "Grip" },
  { id: "grill-fire", icon: "faGrillFire", slug: "fa-grill-fire", name: "Grill Fire" },
  { id: "grill", icon: "faGrill", slug: "fa-grill", name: "Grill" },
  { id: "grid-round-5", icon: "faGridRound5", slug: "fa-grid-round-5", name: "Grid Round 5" },
  { id: "grid-round-4", icon: "faGridRound4", slug: "fa-grid-round-4", name: "Grid Round 4" },
  { id: "grid-round-2-plus", icon: "faGridRound2Plus", slug: "fa-grid-round-2-plus", name: "Grid Round 2 Plus" },
  { id: "grid-round-2", icon: "faGridRound2", slug: "fa-grid-round-2", name: "Grid Round 2" },
  { id: "grid-round", icon: "faGridRound", slug: "fa-grid-round", name: "Grid Round" },
  { id: "grid-horizontal", icon: "faGridHorizontal", slug: "fa-grid-horizontal", name: "Grid Horizontal" },
  { id: "grid-dividers", icon: "faGridDividers", slug: "fa-grid-dividers", name: "Grid Dividers" },
  { id: "grid-5", icon: "faGrid5", slug: "fa-grid-5", name: "Grid 5" },
  { id: "grid-4", icon: "faGrid4", slug: "fa-grid-4", name: "Grid 4" },
  { id: "grid-2-plus", icon: "faGrid2Plus", slug: "fa-grid-2-plus", name: "Grid 2 Plus" },
  { id: "grid", icon: "faGrid", slug: "fa-grid", name: "Grid" },
  { id: "greater-than-equal", icon: "faGreaterThanEqual", slug: "fa-greater-than-equal", name: "Greater Than Equal" },
  { id: "greater-than", icon: "faGreaterThan", slug: "fa-greater-than", name: "Greater Than" },
  { id: "grate-droplet", icon: "faGrateDroplet", slug: "fa-grate-droplet", name: "Grate Droplet" },
  { id: "grate", icon: "faGrate", slug: "fa-grate", name: "Grate" },
  { id: "grapes", icon: "faGrapes", slug: "fa-grapes", name: "Grapes" },
  { id: "gramophone", icon: "faGramophone", slug: "fa-gramophone", name: "Gramophone" },
  { id: "graduation-cap", icon: "faGraduationCap", slug: "fa-graduation-cap", name: "Graduation Cap" },
  { id: "golf-flag-hole", icon: "faGolfFlagHole", slug: "fa-golf-flag-hole", name: "Golf Flag Hole" },
  { id: "golf-club", icon: "faGolfClub", slug: "fa-golf-club", name: "Golf Club" },
  { id: "golf-ball-tee", icon: "faGolfBallTee", slug: "fa-golf-ball-tee", name: "Golf Ball Tee" },
  { id: "goal-net", icon: "faGoalNet", slug: "fa-goal-net", name: "Goal Net" },
  { id: "globe-snow", icon: "faGlobeSnow", slug: "fa-globe-snow", name: "Globe Snow" },
  { id: "globe-pointer", icon: "faGlobePointer", slug: "fa-globe-pointer", name: "Globe Pointer" },
  { id: "glasses-round", icon: "faGlassesRound", slug: "fa-glasses-round", name: "Glasses Round" },
  { id: "glass-water-droplet", icon: "faGlassWaterDroplet", slug: "fa-glass-water-droplet", name: "Glass Water Droplet" },
  { id: "glass-water", icon: "faGlassWater", slug: "fa-glass-water", name: "Glass Water" },
  { id: "glass-half", icon: "faGlassHalf", slug: "fa-glass-half", name: "Glass Half" },
  { id: "glass-empty", icon: "faGlassEmpty", slug: "fa-glass-empty", name: "Glass Empty" },
  { id: "glass-citrus", icon: "faGlassCitrus", slug: "fa-glass-citrus", name: "Glass Citrus" },
  { id: "gingerbread-man", icon: "faGingerbreadMan", slug: "fa-gingerbread-man", name: "Gingerbread Man" },
  { id: "gifts", icon: "faGifts", slug: "fa-gifts", name: "Gifts" },
  { id: "gift-card", icon: "faGiftCard", slug: "fa-gift-card", name: "Gift Card" },
  { id: "gif", icon: "faGif", slug: "fa-gif", name: "Gif" },
  { id: "genderless", icon: "faGenderless", slug: "fa-genderless", name: "Genderless" },
  { id: "gem", icon: "faGem", slug: "fa-gem", name: "Gem" },
  { id: "gear-complex-code", icon: "faGearComplexCode", slug: "fa-gear-complex-code", name: "Gear Complex Code" },
  { id: "gear-complex", icon: "faGearComplex", slug: "fa-gear-complex", name: "Gear Complex" },
  { id: "gear-code", icon: "faGearCode", slug: "fa-gear-code", name: "Gear Code" },
  { id: "gavel", icon: "faGavel", slug: "fa-gavel", name: "Gavel" },
  { id: "gauge-simple-min", icon: "faGaugeSimpleMin", slug: "fa-gauge-simple-min", name: "Gauge Simple Min" },
  { id: "gauge-simple-max", icon: "faGaugeSimpleMax", slug: "fa-gauge-simple-max", name: "Gauge Simple Max" },
  { id: "gauge-simple-low", icon: "faGaugeSimpleLow", slug: "fa-gauge-simple-low", name: "Gauge Simple Low" },
  { id: "gauge-simple-high", icon: "faGaugeSimpleHigh", slug: "fa-gauge-simple-high", name: "Gauge Simple High" },
  { id: "gauge-simple", icon: "faGaugeSimple", slug: "fa-gauge-simple", name: "Gauge Simple" },
  { id: "gauge-min", icon: "faGaugeMin", slug: "fa-gauge-min", name: "Gauge Min" },
  { id: "gauge-max", icon: "faGaugeMax", slug: "fa-gauge-max", name: "Gauge Max" },
  { id: "gauge-low", icon: "faGaugeLow", slug: "fa-gauge-low", name: "Gauge Low" },
  { id: "gauge-high", icon: "faGaugeHigh", slug: "fa-gauge-high", name: "Gauge High" },
  { id: "gauge-circle-plus", icon: "faGaugeCirclePlus", slug: "fa-gauge-circle-plus", name: "Gauge Circle Plus" },
  { id: "gauge-circle-minus", icon: "faGaugeCircleMinus", slug: "fa-gauge-circle-minus", name: "Gauge Circle Minus" },
  { id: "gauge-circle-bolt", icon: "faGaugeCircleBolt", slug: "fa-gauge-circle-bolt", name: "Gauge Circle Bolt" },
  { id: "gas-pump-slash", icon: "faGasPumpSlash", slug: "fa-gas-pump-slash", name: "Gas Pump Slash" },
  { id: "gas-pump", icon: "faGasPump", slug: "fa-gas-pump", name: "Gas Pump" },
  { id: "garlic", icon: "faGarlic", slug: "fa-garlic", name: "Garlic" },
  { id: "garage-open", icon: "faGarageOpen", slug: "fa-garage-open", name: "Garage Open" },
  { id: "garage-car", icon: "faGarageCar", slug: "fa-garage-car", name: "Garage Car" },
  { id: "garage", icon: "faGarage", slug: "fa-garage", name: "Garage" },
  { id: "gamepad-modern", icon: "faGamepadModern", slug: "fa-gamepad-modern", name: "Gamepad Modern" },
  { id: "game-console-handheld-crank", icon: "faGameConsoleHandheldCrank", slug: "fa-game-console-handheld-crank", name: "Game Console Handheld Crank" },
  { id: "game-board-simple", icon: "faGameBoardSimple", slug: "fa-game-board-simple", name: "Game Board Simple" },
  { id: "game-board", icon: "faGameBoard", slug: "fa-game-board", name: "Game Board" },
  { id: "gallery-thumbnails", icon: "faGalleryThumbnails", slug: "fa-gallery-thumbnails", name: "Gallery Thumbnails" },
  { id: "galaxy", icon: "faGalaxy", slug: "fa-galaxy", name: "Galaxy" },
  { id: "g", icon: "faG", slug: "fa-g", name: "G" },
  { id: "futbol", icon: "faFutbol", slug: "fa-futbol", name: "Futbol" },
  { id: "function", icon: "faFunction", slug: "fa-function", name: "Function" },
  { id: "frog", icon: "faFrog", slug: "fa-frog", name: "Frog" },
  { id: "french-fries", icon: "faFrenchFries", slug: "fa-french-fries", name: "French Fries" },
  { id: "franc-sign", icon: "faFrancSign", slug: "fa-franc-sign", name: "Franc Sign" },
  { id: "frame", icon: "faFrame", slug: "fa-frame", name: "Frame" },
  { id: "forward-step", icon: "faForwardStep", slug: "fa-forward-step", name: "Forward Step" },
  { id: "forward-fast", icon: "faForwardFast", slug: "fa-forward-fast", name: "Forward Fast" },
  { id: "forklift", icon: "faForklift", slug: "fa-forklift", name: "Forklift" },
  { id: "fork-knife", icon: "faForkKnife", slug: "fa-fork-knife", name: "Fork Knife" },
  { id: "fork", icon: "faFork", slug: "fa-fork", name: "Fork" },
  { id: "football-helmet", icon: "faFootballHelmet", slug: "fa-football-helmet", name: "Football Helmet" },
  { id: "football", icon: "faFootball", slug: "fa-football", name: "Football" },
  { id: "font-awesome", icon: "faFontAwesome", slug: "fa-font-awesome", name: "Font Awesome" },
  { id: "fondue-pot", icon: "faFonduePot", slug: "fa-fondue-pot", name: "Fondue Pot" },
  { id: "folders", icon: "faFolders", slug: "fa-folders", name: "Folders" },
  { id: "folder-xmark", icon: "faFolderXmark", slug: "fa-folder-xmark", name: "Folder Xmark" },
  { id: "folder-user", icon: "faFolderUser", slug: "fa-folder-user", name: "Folder User" },
  { id: "folder-tree", icon: "faFolderTree", slug: "fa-folder-tree", name: "Folder Tree" },
  { id: "folder-plus", icon: "faFolderPlus", slug: "fa-folder-plus", name: "Folder Plus" },
  { id: "folder-music", icon: "faFolderMusic", slug: "fa-folder-music", name: "Folder Music" },
  { id: "folder-minus", icon: "faFolderMinus", slug: "fa-folder-minus", name: "Folder Minus" },
  { id: "folder-medical", icon: "faFolderMedical", slug: "fa-folder-medical", name: "Folder Medical" },
  { id: "folder-magnifying-glass", icon: "faFolderMagnifyingGlass", slug: "fa-folder-magnifying-glass", name: "Folder Magnifying Glass" },
  { id: "folder-image", icon: "faFolderImage", slug: "fa-folder-image", name: "Folder Image" },
  { id: "folder-heart", icon: "faFolderHeart", slug: "fa-folder-heart", name: "Folder Heart" },
  { id: "folder-grid", icon: "faFolderGrid", slug: "fa-folder-grid", name: "Folder Grid" },
  { id: "folder-gear", icon: "faFolderGear", slug: "fa-folder-gear", name: "Folder Gear" },
  { id: "folder-closed", icon: "faFolderClosed", slug: "fa-folder-closed", name: "Folder Closed" },
  { id: "folder-check", icon: "faFolderCheck", slug: "fa-folder-check", name: "Folder Check" },
  { id: "folder-bookmark", icon: "faFolderBookmark", slug: "fa-folder-bookmark", name: "Folder Bookmark" },
  { id: "folder-arrow-up", icon: "faFolderArrowUp", slug: "fa-folder-arrow-up", name: "Folder Arrow Up" },
  { id: "folder-arrow-down", icon: "faFolderArrowDown", slug: "fa-folder-arrow-down", name: "Folder Arrow Down" },
  { id: "flying-disc", icon: "faFlyingDisc", slug: "fa-flying-disc", name: "Flying Disc" },
  { id: "flux-capacitor", icon: "faFluxCapacitor", slug: "fa-flux-capacitor", name: "Flux Capacitor" },
  { id: "flute", icon: "faFlute", slug: "fa-flute", name: "Flute" },
  { id: "flower-daffodil", icon: "faFlowerDaffodil", slug: "fa-flower-daffodil", name: "Flower Daffodil" },
  { id: "florin-sign", icon: "faFlorinSign", slug: "fa-florin-sign", name: "Florin Sign" },
  { id: "floppy-disks", icon: "faFloppyDisks", slug: "fa-floppy-disks", name: "Floppy Disks" },
  { id: "floppy-disk-pen", icon: "faFloppyDiskPen", slug: "fa-floppy-disk-pen", name: "Floppy Disk Pen" },
  { id: "floppy-disk-circle-xmark", icon: "faFloppyDiskCircleXmark", slug: "fa-floppy-disk-circle-xmark", name: "Floppy Disk Circle Xmark" },
  { id: "floppy-disk-circle-arrow-right", icon: "faFloppyDiskCircleArrowRight", slug: "fa-floppy-disk-circle-arrow-right", name: "Floppy Disk Circle Arrow Right" },
  { id: "floppy-disk", icon: "faFloppyDisk", slug: "fa-floppy-disk", name: "Floppy Disk" },
  { id: "flatbread-stuffed", icon: "faFlatbreadStuffed", slug: "fa-flatbread-stuffed", name: "Flatbread Stuffed" },
  { id: "flatbread", icon: "faFlatbread", slug: "fa-flatbread", name: "Flatbread" },
  { id: "flask-vial", icon: "faFlaskVial", slug: "fa-flask-vial", name: "Flask Vial" },
  { id: "flask-round-potion", icon: "faFlaskRoundPotion", slug: "fa-flask-round-potion", name: "Flask Round Potion" },
  { id: "flask-round-poison", icon: "faFlaskRoundPoison", slug: "fa-flask-round-poison", name: "Flask Round Poison" },
  { id: "flask-gear", icon: "faFlaskGear", slug: "fa-flask-gear", name: "Flask Gear" },
  { id: "flashlight", icon: "faFlashlight", slug: "fa-flashlight", name: "Flashlight" },
  { id: "flag-usa", icon: "faFlagUsa", slug: "fa-flag-usa", name: "Flag Usa" },
  { id: "flag-swallowtail", icon: "faFlagSwallowtail", slug: "fa-flag-swallowtail", name: "Flag Swallowtail" },
  { id: "flag-pennant", icon: "faFlagPennant", slug: "fa-flag-pennant", name: "Flag Pennant" },
  { id: "fishing-rod", icon: "faFishingRod", slug: "fa-fishing-rod", name: "Fishing Rod" },
  { id: "fish-fins", icon: "faFishFins", slug: "fa-fish-fins", name: "Fish Fins" },
  { id: "fish-cooked", icon: "faFishCooked", slug: "fa-fish-cooked", name: "Fish Cooked" },
  { id: "fish-bones", icon: "faFishBones", slug: "fa-fish-bones", name: "Fish Bones" },
  { id: "fireplace", icon: "faFireplace", slug: "fa-fireplace", name: "Fireplace" },
  { id: "fire-smoke", icon: "faFireSmoke", slug: "fa-fire-smoke", name: "Fire Smoke" },
  { id: "fire-hydrant", icon: "faFireHydrant", slug: "fa-fire-hydrant", name: "Fire Hydrant" },
  { id: "fire-flame-simple", icon: "faFireFlameSimple", slug: "fa-fire-flame-simple", name: "Fire Flame Simple" },
  { id: "fire-flame-curved", icon: "faFireFlameCurved", slug: "fa-fire-flame-curved", name: "Fire Flame Curved" },
  { id: "fire-flame", icon: "faFireFlame", slug: "fa-fire-flame", name: "Fire Flame" },
  { id: "fire-extinguisher", icon: "faFireExtinguisher", slug: "fa-fire-extinguisher", name: "Fire Extinguisher" },
  { id: "fire-burner", icon: "faFireBurner", slug: "fa-fire-burner", name: "Fire Burner" },
  { id: "filters", icon: "faFilters", slug: "fa-filters", name: "Filters" },
  { id: "filter-slash", icon: "faFilterSlash", slug: "fa-filter-slash", name: "Filter Slash" },
  { id: "filter-list", icon: "faFilterList", slug: "fa-filter-list", name: "Filter List" },
  { id: "filter-circle-xmark", icon: "faFilterCircleXmark", slug: "fa-filter-circle-xmark", name: "Filter Circle Xmark" },
  { id: "filter-circle-dollar", icon: "faFilterCircleDollar", slug: "fa-filter-circle-dollar", name: "Filter Circle Dollar" },
  { id: "films", icon: "faFilms", slug: "fa-films", name: "Films" },
  { id: "film-slash", icon: "faFilmSlash", slug: "fa-film-slash", name: "Film Slash" },
  { id: "film-simple", icon: "faFilmSimple", slug: "fa-film-simple", name: "Film Simple" },
  { id: "film-canister", icon: "faFilmCanister", slug: "fa-film-canister", name: "Film Canister" },
  { id: "fill-drip", icon: "faFillDrip", slug: "fa-fill-drip", name: "Fill Drip" },
  { id: "fill", icon: "faFill", slug: "fa-fill", name: "Fill" },
  { id: "files-medical", icon: "faFilesMedical", slug: "fa-files-medical", name: "Files Medical" },
  { id: "file-zipper", icon: "faFileZipper", slug: "fa-file-zipper", name: "File Zipper" },
  { id: "file-zip", icon: "faFileZip", slug: "fa-file-zip", name: "File Zip" },
  { id: "file-xml", icon: "faFileXml", slug: "fa-file-xml", name: "File Xml" },
  { id: "file-xmark", icon: "faFileXmark", slug: "fa-file-xmark", name: "File Xmark" },
  { id: "file-xls", icon: "faFileXls", slug: "fa-file-xls", name: "File Xls" },
  { id: "file-waveform", icon: "faFileWaveform", slug: "fa-file-waveform", name: "File Waveform" },
  { id: "file-video", icon: "faFileVideo", slug: "fa-file-video", name: "File Video" },
  { id: "file-vector", icon: "faFileVector", slug: "fa-file-vector", name: "File Vector" },
  { id: "file-user", icon: "faFileUser", slug: "fa-file-user", name: "File User" },
  { id: "file-svg", icon: "faFileSvg", slug: "fa-file-svg", name: "File Svg" },
  { id: "file-slash", icon: "faFileSlash", slug: "fa-file-slash", name: "File Slash" },
  { id: "file-signature", icon: "faFileSignature", slug: "fa-file-signature", name: "File Signature" },
  { id: "file-shield", icon: "faFileShield", slug: "fa-file-shield", name: "File Shield" },
  { id: "file-prescription", icon: "faFilePrescription", slug: "fa-file-prescription", name: "File Prescription" },
  { id: "file-ppt", icon: "faFilePpt", slug: "fa-file-ppt", name: "File Ppt" },
  { id: "file-powerpoint", icon: "faFilePowerpoint", slug: "fa-file-powerpoint", name: "File Powerpoint" },
  { id: "file-png", icon: "faFilePng", slug: "fa-file-png", name: "File Png" },
  { id: "file-plus-minus", icon: "faFilePlusMinus", slug: "fa-file-plus-minus", name: "File Plus Minus" },
  { id: "file-plus", icon: "faFilePlus", slug: "fa-file-plus", name: "File Plus" },
  { id: "file-pen", icon: "faFilePen", slug: "fa-file-pen", name: "File Pen" },
  { id: "file-music", icon: "faFileMusic", slug: "fa-file-music", name: "File Music" },
  { id: "file-mp4", icon: "faFileMp4", slug: "fa-file-mp4", name: "File Mp4" },
  { id: "file-mp3", icon: "faFileMp3", slug: "fa-file-mp3", name: "File Mp3" },
  { id: "file-mov", icon: "faFileMov", slug: "fa-file-mov", name: "File Mov" },
  { id: "file-minus", icon: "faFileMinus", slug: "fa-file-minus", name: "File Minus" },
  { id: "file-medical", icon: "faFileMedical", slug: "fa-file-medical", name: "File Medical" },
  { id: "file-magnifying-glass", icon: "faFileMagnifyingGlass", slug: "fa-file-magnifying-glass", name: "File Magnifying Glass" },
  { id: "file-lock", icon: "faFileLock", slug: "fa-file-lock", name: "File Lock" },
  { id: "file-lines", icon: "faFileLines", slug: "fa-file-lines", name: "File Lines" },
  { id: "file-jpg", icon: "faFileJpg", slug: "fa-file-jpg", name: "File Jpg" },
  { id: "file-invoice-dollar", icon: "faFileInvoiceDollar", slug: "fa-file-invoice-dollar", name: "File Invoice Dollar" },
  { id: "file-image", icon: "faFileImage", slug: "fa-file-image", name: "File Image" },
  { id: "file-heart", icon: "faFileHeart", slug: "fa-file-heart", name: "File Heart" },
  { id: "file-gif", icon: "faFileGif", slug: "fa-file-gif", name: "File Gif" },
  { id: "file-eps", icon: "faFileEps", slug: "fa-file-eps", name: "File Eps" },
  { id: "file-doc", icon: "faFileDoc", slug: "fa-file-doc", name: "File Doc" },
  { id: "file-dashed-line", icon: "faFileDashedLine", slug: "fa-file-dashed-line", name: "File Dashed Line" },
  { id: "file-csv", icon: "faFileCsv", slug: "fa-file-csv", name: "File Csv" },
  { id: "file-code", icon: "faFileCode", slug: "fa-file-code", name: "File Code" },
  { id: "file-circle-xmark", icon: "faFileCircleXmark", slug: "fa-file-circle-xmark", name: "File Circle Xmark" },
  { id: "file-circle-question", icon: "faFileCircleQuestion", slug: "fa-file-circle-question", name: "File Circle Question" },
  { id: "file-circle-plus", icon: "faFileCirclePlus", slug: "fa-file-circle-plus", name: "File Circle Plus" },
  { id: "file-circle-minus", icon: "faFileCircleMinus", slug: "fa-file-circle-minus", name: "File Circle Minus" },
  { id: "file-circle-info", icon: "faFileCircleInfo", slug: "fa-file-circle-info", name: "File Circle Info" },
  { id: "file-circle-exclamation", icon: "faFileCircleExclamation", slug: "fa-file-circle-exclamation", name: "File Circle Exclamation" },
  { id: "file-circle-check", icon: "faFileCircleCheck", slug: "fa-file-circle-check", name: "File Circle Check" },
  { id: "file-chart-pie", icon: "faFileChartPie", slug: "fa-file-chart-pie", name: "File Chart Pie" },
  { id: "file-chart-column", icon: "faFileChartColumn", slug: "fa-file-chart-column", name: "File Chart Column" },
  { id: "file-certificate", icon: "faFileCertificate", slug: "fa-file-certificate", name: "File Certificate" },
  { id: "file-cad", icon: "faFileCad", slug: "fa-file-cad", name: "File Cad" },
  { id: "file-binary", icon: "faFileBinary", slug: "fa-file-binary", name: "File Binary" },
  { id: "file-audio", icon: "faFileAudio", slug: "fa-file-audio", name: "File Audio" },
  { id: "file-arrow-up", icon: "faFileArrowUp", slug: "fa-file-arrow-up", name: "File Arrow Up" },
  { id: "file-arrow-down", icon: "faFileArrowDown", slug: "fa-file-arrow-down", name: "File Arrow Down" },
  { id: "field-hockey-stick-ball", icon: "faFieldHockeyStickBall", slug: "fa-field-hockey-stick-ball", name: "Field Hockey Stick Ball" },
  { id: "ferry", icon: "faFerry", slug: "fa-ferry", name: "Ferry" },
  { id: "ferris-wheel", icon: "faFerrisWheel", slug: "fa-ferris-wheel", name: "Ferris Wheel" },
  { id: "fence", icon: "faFence", slug: "fa-fence", name: "Fence" },
  { id: "feather-pointed", icon: "faFeatherPointed", slug: "fa-feather-pointed", name: "Feather Pointed" },
  { id: "fax", icon: "faFax", slug: "fa-fax", name: "Fax" },
  { id: "faucet-drip", icon: "faFaucetDrip", slug: "fa-faucet-drip", name: "Faucet Drip" },
  { id: "faucet", icon: "faFaucet", slug: "fa-faucet", name: "Faucet" },
  { id: "farm", icon: "faFarm", slug: "fa-farm", name: "Farm" },
  { id: "fan-table", icon: "faFanTable", slug: "fa-fan-table", name: "Fan Table" },
  { id: "fan", icon: "faFan", slug: "fa-fan", name: "Fan" },
  { id: "family-pants", icon: "faFamilyPants", slug: "fa-family-pants", name: "Family Pants" },
  { id: "family-dress", icon: "faFamilyDress", slug: "fa-family-dress", name: "Family Dress" },
  { id: "family", icon: "faFamily", slug: "fa-family", name: "Family" },
  { id: "falafel", icon: "faFalafel", slug: "fa-falafel", name: "Falafel" },
  { id: "face-zipper", icon: "faFaceZipper", slug: "fa-face-zipper", name: "Face Zipper" },
  { id: "face-zany", icon: "faFaceZany", slug: "fa-face-zany", name: "Face Zany" },
  { id: "face-worried", icon: "faFaceWorried", slug: "fa-face-worried", name: "Face Worried" },
  { id: "face-woozy", icon: "faFaceWoozy", slug: "fa-face-woozy", name: "Face Woozy" },
  { id: "face-weary", icon: "faFaceWeary", slug: "fa-face-weary", name: "Face Weary" },
  { id: "face-viewfinder", icon: "faFaceViewfinder", slug: "fa-face-viewfinder", name: "Face Viewfinder" },
  { id: "face-unamused", icon: "faFaceUnamused", slug: "fa-face-unamused", name: "Face Unamused" },
  { id: "face-tongue-sweat", icon: "faFaceTongueSweat", slug: "fa-face-tongue-sweat", name: "Face Tongue Sweat" },
  { id: "face-tongue-money", icon: "faFaceTongueMoney", slug: "fa-face-tongue-money", name: "Face Tongue Money" },
  { id: "face-tissue", icon: "faFaceTissue", slug: "fa-face-tissue", name: "Face Tissue" },
  { id: "face-tired", icon: "faFaceTired", slug: "fa-face-tired", name: "Face Tired" },
  { id: "face-thinking", icon: "faFaceThinking", slug: "fa-face-thinking", name: "Face Thinking" },
  { id: "face-thermometer", icon: "faFaceThermometer", slug: "fa-face-thermometer", name: "Face Thermometer" },
  { id: "face-swear", icon: "faFaceSwear", slug: "fa-face-swear", name: "Face Swear" },
  { id: "face-surprise", icon: "faFaceSurprise", slug: "fa-face-surprise", name: "Face Surprise" },
  { id: "face-sunglasses", icon: "faFaceSunglasses", slug: "fa-face-sunglasses", name: "Face Sunglasses" },
  { id: "face-spiral-eyes", icon: "faFaceSpiralEyes", slug: "fa-face-spiral-eyes", name: "Face Spiral Eyes" },
  { id: "face-smirking", icon: "faFaceSmirking", slug: "fa-face-smirking", name: "Face Smirking" },
  { id: "face-smiling-hands", icon: "faFaceSmilingHands", slug: "fa-face-smiling-hands", name: "Face Smiling Hands" },
  { id: "face-smile-wink", icon: "faFaceSmileWink", slug: "fa-face-smile-wink", name: "Face Smile Wink" },
  { id: "face-smile-upside-down", icon: "faFaceSmileUpsideDown", slug: "fa-face-smile-upside-down", name: "Face Smile Upside Down" },
  { id: "face-smile-tongue", icon: "faFaceSmileTongue", slug: "fa-face-smile-tongue", name: "Face Smile Tongue" },
  { id: "face-smile-tear", icon: "faFaceSmileTear", slug: "fa-face-smile-tear", name: "Face Smile Tear" },
  { id: "face-smile-relaxed", icon: "faFaceSmileRelaxed", slug: "fa-face-smile-relaxed", name: "Face Smile Relaxed" },
  { id: "face-smile-plus", icon: "faFaceSmilePlus", slug: "fa-face-smile-plus", name: "Face Smile Plus" },
  { id: "face-smile-horns", icon: "faFaceSmileHorns", slug: "fa-face-smile-horns", name: "Face Smile Horns" },
  { id: "face-smile-hearts", icon: "faFaceSmileHearts", slug: "fa-face-smile-hearts", name: "Face Smile Hearts" },
  { id: "face-smile-halo", icon: "faFaceSmileHalo", slug: "fa-face-smile-halo", name: "Face Smile Halo" },
  { id: "face-smile-beam", icon: "faFaceSmileBeam", slug: "fa-face-smile-beam", name: "Face Smile Beam" },
  { id: "face-sleepy", icon: "faFaceSleepy", slug: "fa-face-sleepy", name: "Face Sleepy" },
  { id: "face-sleeping", icon: "faFaceSleeping", slug: "fa-face-sleeping", name: "Face Sleeping" },
  { id: "face-shush", icon: "faFaceShush", slug: "fa-face-shush", name: "Face Shush" },
  { id: "face-scream", icon: "faFaceScream", slug: "fa-face-scream", name: "Face Scream" },
  { id: "face-saluting", icon: "faFaceSaluting", slug: "fa-face-saluting", name: "Face Saluting" },
  { id: "face-sad-tear", icon: "faFaceSadTear", slug: "fa-face-sad-tear", name: "Face Sad Tear" },
  { id: "face-sad-sweat", icon: "faFaceSadSweat", slug: "fa-face-sad-sweat", name: "Face Sad Sweat" },
  { id: "face-sad-cry", icon: "faFaceSadCry", slug: "fa-face-sad-cry", name: "Face Sad Cry" },
  { id: "face-rolling-eyes", icon: "faFaceRollingEyes", slug: "fa-face-rolling-eyes", name: "Face Rolling Eyes" },
  { id: "face-relieved", icon: "faFaceRelieved", slug: "fa-face-relieved", name: "Face Relieved" },
  { id: "face-raised-eyebrow", icon: "faFaceRaisedEyebrow", slug: "fa-face-raised-eyebrow", name: "Face Raised Eyebrow" },
  { id: "face-pouting", icon: "faFacePouting", slug: "fa-face-pouting", name: "Face Pouting" },
  { id: "face-pleading", icon: "faFacePleading", slug: "fa-face-pleading", name: "Face Pleading" },
  { id: "face-persevering", icon: "faFacePersevering", slug: "fa-face-persevering", name: "Face Persevering" },
  { id: "face-pensive", icon: "faFacePensive", slug: "fa-face-pensive", name: "Face Pensive" },
  { id: "face-party", icon: "faFaceParty", slug: "fa-face-party", name: "Face Party" },
  { id: "face-nose-steam", icon: "faFaceNoseSteam", slug: "fa-face-nose-steam", name: "Face Nose Steam" },
  { id: "face-nauseated", icon: "faFaceNauseated", slug: "fa-face-nauseated", name: "Face Nauseated" },
  { id: "face-monocle", icon: "faFaceMonocle", slug: "fa-face-monocle", name: "Face Monocle" },
  { id: "face-melting", icon: "faFaceMelting", slug: "fa-face-melting", name: "Face Melting" },
  { id: "face-meh-blank", icon: "faFaceMehBlank", slug: "fa-face-meh-blank", name: "Face Meh Blank" },
  { id: "face-meh", icon: "faFaceMeh", slug: "fa-face-meh", name: "Face Meh" },
  { id: "face-mask", icon: "faFaceMask", slug: "fa-face-mask", name: "Face Mask" },
  { id: "face-lying", icon: "faFaceLying", slug: "fa-face-lying", name: "Face Lying" },
  { id: "face-laugh-wink", icon: "faFaceLaughWink", slug: "fa-face-laugh-wink", name: "Face Laugh Wink" },
  { id: "face-laugh-squint", icon: "faFaceLaughSquint", slug: "fa-face-laugh-squint", name: "Face Laugh Squint" },
  { id: "face-laugh-beam", icon: "faFaceLaughBeam", slug: "fa-face-laugh-beam", name: "Face Laugh Beam" },
  { id: "face-laugh", icon: "faFaceLaugh", slug: "fa-face-laugh", name: "Face Laugh" },
  { id: "face-kiss-wink-heart", icon: "faFaceKissWinkHeart", slug: "fa-face-kiss-wink-heart", name: "Face Kiss Wink Heart" },
  { id: "face-kiss-closed-eyes", icon: "faFaceKissClosedEyes", slug: "fa-face-kiss-closed-eyes", name: "Face Kiss Closed Eyes" },
  { id: "face-kiss-beam", icon: "faFaceKissBeam", slug: "fa-face-kiss-beam", name: "Face Kiss Beam" },
  { id: "face-kiss", icon: "faFaceKiss", slug: "fa-face-kiss", name: "Face Kiss" },
  { id: "face-icicles", icon: "faFaceIcicles", slug: "fa-face-icicles", name: "Face Icicles" },
  { id: "face-hushed", icon: "faFaceHushed", slug: "fa-face-hushed", name: "Face Hushed" },
  { id: "face-holding-back-tears", icon: "faFaceHoldingBackTears", slug: "fa-face-holding-back-tears", name: "Face Holding Back Tears" },
  { id: "face-head-bandage", icon: "faFaceHeadBandage", slug: "fa-face-head-bandage", name: "Face Head Bandage" },
  { id: "face-hand-yawn", icon: "faFaceHandYawn", slug: "fa-face-hand-yawn", name: "Face Hand Yawn" },
  { id: "face-hand-peeking", icon: "faFaceHandPeeking", slug: "fa-face-hand-peeking", name: "Face Hand Peeking" },
  { id: "face-hand-over-mouth", icon: "faFaceHandOverMouth", slug: "fa-face-hand-over-mouth", name: "Face Hand Over Mouth" },
  { id: "face-grin-wink", icon: "faFaceGrinWink", slug: "fa-face-grin-wink", name: "Face Grin Wink" },
  { id: "face-grin-wide", icon: "faFaceGrinWide", slug: "fa-face-grin-wide", name: "Face Grin Wide" },
  { id: "face-grin-tongue-wink", icon: "faFaceGrinTongueWink", slug: "fa-face-grin-tongue-wink", name: "Face Grin Tongue Wink" },
  { id: "face-grin-tongue-squint", icon: "faFaceGrinTongueSquint", slug: "fa-face-grin-tongue-squint", name: "Face Grin Tongue Squint" },
  { id: "face-grin-tongue", icon: "faFaceGrinTongue", slug: "fa-face-grin-tongue", name: "Face Grin Tongue" },
  { id: "face-grin-tears", icon: "faFaceGrinTears", slug: "fa-face-grin-tears", name: "Face Grin Tears" },
  { id: "face-grin-stars", icon: "faFaceGrinStars", slug: "fa-face-grin-stars", name: "Face Grin Stars" },
  { id: "face-grin-squint-tears", icon: "faFaceGrinSquintTears", slug: "fa-face-grin-squint-tears", name: "Face Grin Squint Tears" },
  { id: "face-grin-squint", icon: "faFaceGrinSquint", slug: "fa-face-grin-squint", name: "Face Grin Squint" },
  { id: "face-grin-hearts", icon: "faFaceGrinHearts", slug: "fa-face-grin-hearts", name: "Face Grin Hearts" },
  { id: "face-grin-beam-sweat", icon: "faFaceGrinBeamSweat", slug: "fa-face-grin-beam-sweat", name: "Face Grin Beam Sweat" },
  { id: "face-grin-beam", icon: "faFaceGrinBeam", slug: "fa-face-grin-beam", name: "Face Grin Beam" },
  { id: "face-grin", icon: "faFaceGrin", slug: "fa-face-grin", name: "Face Grin" },
  { id: "face-grimace", icon: "faFaceGrimace", slug: "fa-face-grimace", name: "Face Grimace" },
  { id: "face-glasses", icon: "faFaceGlasses", slug: "fa-face-glasses", name: "Face Glasses" },
  { id: "face-frown-slight", icon: "faFaceFrownSlight", slug: "fa-face-frown-slight", name: "Face Frown Slight" },
  { id: "face-frown-open", icon: "faFaceFrownOpen", slug: "fa-face-frown-open", name: "Face Frown Open" },
  { id: "face-frown", icon: "faFaceFrown", slug: "fa-face-frown", name: "Face Frown" },
  { id: "face-flushed", icon: "faFaceFlushed", slug: "fa-face-flushed", name: "Face Flushed" },
  { id: "face-fearful", icon: "faFaceFearful", slug: "fa-face-fearful", name: "Face Fearful" },
  { id: "face-eyes-xmarks", icon: "faFaceEyesXmarks", slug: "fa-face-eyes-xmarks", name: "Face Eyes Xmarks" },
  { id: "face-expressionless", icon: "faFaceExpressionless", slug: "fa-face-expressionless", name: "Face Expressionless" },
  { id: "face-explode", icon: "faFaceExplode", slug: "fa-face-explode", name: "Face Explode" },
  { id: "face-exhaling", icon: "faFaceExhaling", slug: "fa-face-exhaling", name: "Face Exhaling" },
  { id: "face-drooling", icon: "faFaceDrooling", slug: "fa-face-drooling", name: "Face Drooling" },
  { id: "face-downcast-sweat", icon: "faFaceDowncastSweat", slug: "fa-face-downcast-sweat", name: "Face Downcast Sweat" },
  { id: "face-dotted", icon: "faFaceDotted", slug: "fa-face-dotted", name: "Face Dotted" },
  { id: "face-dizzy", icon: "faFaceDizzy", slug: "fa-face-dizzy", name: "Face Dizzy" },
  { id: "face-disguise", icon: "faFaceDisguise", slug: "fa-face-disguise", name: "Face Disguise" },
  { id: "face-disappointed", icon: "faFaceDisappointed", slug: "fa-face-disappointed", name: "Face Disappointed" },
  { id: "face-diagonal-mouth", icon: "faFaceDiagonalMouth", slug: "fa-face-diagonal-mouth", name: "Face Diagonal Mouth" },
  { id: "face-cowboy-hat", icon: "faFaceCowboyHat", slug: "fa-face-cowboy-hat", name: "Face Cowboy Hat" },
  { id: "face-confused", icon: "faFaceConfused", slug: "fa-face-confused", name: "Face Confused" },
  { id: "face-confounded", icon: "faFaceConfounded", slug: "fa-face-confounded", name: "Face Confounded" },
  { id: "face-clouds", icon: "faFaceClouds", slug: "fa-face-clouds", name: "Face Clouds" },
  { id: "face-beam-hand-over-mouth", icon: "faFaceBeamHandOverMouth", slug: "fa-face-beam-hand-over-mouth", name: "Face Beam Hand Over Mouth" },
  { id: "face-astonished", icon: "faFaceAstonished", slug: "fa-face-astonished", name: "Face Astonished" },
  { id: "face-anxious-sweat", icon: "faFaceAnxiousSweat", slug: "fa-face-anxious-sweat", name: "Face Anxious Sweat" },
  { id: "face-anguished", icon: "faFaceAnguished", slug: "fa-face-anguished", name: "Face Anguished" },
  { id: "face-angry-horns", icon: "faFaceAngryHorns", slug: "fa-face-angry-horns", name: "Face Angry Horns" },
  { id: "face-angry", icon: "faFaceAngry", slug: "fa-face-angry", name: "Face Angry" },
  { id: "f", icon: "faF", slug: "fa-f", name: "F" },
  { id: "eyes", icon: "faEyes", slug: "fa-eyes", name: "Eyes" },
  { id: "eye-low-vision", icon: "faEyeLowVision", slug: "fa-eye-low-vision", name: "Eye Low Vision" },
  { id: "eye-evil", icon: "faEyeEvil", slug: "fa-eye-evil", name: "Eye Evil" },
  { id: "eye-dropper-half", icon: "faEyeDropperHalf", slug: "fa-eye-dropper-half", name: "Eye Dropper Half" },
  { id: "eye-dropper-full", icon: "faEyeDropperFull", slug: "fa-eye-dropper-full", name: "Eye Dropper Full" },
  { id: "eye-dropper", icon: "faEyeDropper", slug: "fa-eye-dropper", name: "Eye Dropper" },
  { id: "explosion", icon: "faExplosion", slug: "fa-explosion", name: "Explosion" },
  { id: "expand-wide", icon: "faExpandWide", slug: "fa-expand-wide", name: "Expand Wide" },
  { id: "excavator", icon: "faExcavator", slug: "fa-excavator", name: "Excavator" },
  { id: "euro-sign", icon: "faEuroSign", slug: "fa-euro-sign", name: "Euro Sign" },
  { id: "ethernet", icon: "faEthernet", slug: "fa-ethernet", name: "Ethernet" },
  { id: "escalator", icon: "faEscalator", slug: "fa-escalator", name: "Escalator" },
  { id: "equals", icon: "faEquals", slug: "fa-equals", name: "Equals" },
  { id: "envelopes-bulk", icon: "faEnvelopesBulk", slug: "fa-envelopes-bulk", name: "Envelopes Bulk" },
  { id: "envelope-open-text", icon: "faEnvelopeOpenText", slug: "fa-envelope-open-text", name: "Envelope Open Text" },
  { id: "envelope-open-dollar", icon: "faEnvelopeOpenDollar", slug: "fa-envelope-open-dollar", name: "Envelope Open Dollar" },
  { id: "envelope-open", icon: "faEnvelopeOpen", slug: "fa-envelope-open", name: "Envelope Open" },
  { id: "envelope-dot", icon: "faEnvelopeDot", slug: "fa-envelope-dot", name: "Envelope Dot" },
  { id: "envelope-circle-check", icon: "faEnvelopeCircleCheck", slug: "fa-envelope-circle-check", name: "Envelope Circle Check" },
  { id: "engine", icon: "faEngine", slug: "fa-engine", name: "Engine" },
  { id: "ellipsis-vertical", icon: "faEllipsisVertical", slug: "fa-ellipsis-vertical", name: "Ellipsis Vertical" },
  { id: "ellipsis-stroke-vertical", icon: "faEllipsisStrokeVertical", slug: "fa-ellipsis-stroke-vertical", name: "Ellipsis Stroke Vertical" },
  { id: "ellipsis-stroke", icon: "faEllipsisStroke", slug: "fa-ellipsis-stroke", name: "Ellipsis Stroke" },
  { id: "ellipsis", icon: "faEllipsis", slug: "fa-ellipsis", name: "Ellipsis" },
  { id: "elevator", icon: "faElevator", slug: "fa-elevator", name: "Elevator" },
  { id: "elephant", icon: "faElephant", slug: "fa-elephant", name: "Elephant" },
  { id: "eject", icon: "faEject", slug: "fa-eject", name: "Eject" },
  { id: "eggplant", icon: "faEggplant", slug: "fa-eggplant", name: "Eggplant" },
  { id: "egg-fried", icon: "faEggFried", slug: "fa-egg-fried", name: "Egg Fried" },
  { id: "egg", icon: "faEgg", slug: "fa-egg", name: "Egg" },
  { id: "eclipse", icon: "faEclipse", slug: "fa-eclipse", name: "Eclipse" },
  { id: "earth-oceania", icon: "faEarthOceania", slug: "fa-earth-oceania", name: "Earth Oceania" },
  { id: "earth-europe", icon: "faEarthEurope", slug: "fa-earth-europe", name: "Earth Europe" },
  { id: "earth-asia", icon: "faEarthAsia", slug: "fa-earth-asia", name: "Earth Asia" },
  { id: "earth-africa", icon: "faEarthAfrica", slug: "fa-earth-africa", name: "Earth Africa" },
  { id: "ear-muffs", icon: "faEarMuffs", slug: "fa-ear-muffs", name: "Ear Muffs" },
  { id: "ear-listen", icon: "faEarListen", slug: "fa-ear-listen", name: "Ear Listen" },
  { id: "ear-deaf", icon: "faEarDeaf", slug: "fa-ear-deaf", name: "Ear Deaf" },
  { id: "ear", icon: "faEar", slug: "fa-ear", name: "Ear" },
  { id: "e", icon: "faE", slug: "fa-e", name: "E" },
  { id: "dungeon", icon: "faDungeon", slug: "fa-dungeon", name: "Dungeon" },
  { id: "dumpster", icon: "faDumpster", slug: "fa-dumpster", name: "Dumpster" },
  { id: "duck", icon: "faDuck", slug: "fa-duck", name: "Duck" },
  { id: "dryer-heat", icon: "faDryerHeat", slug: "fa-dryer-heat", name: "Dryer Heat" },
  { id: "dryer", icon: "faDryer", slug: "fa-dryer", name: "Dryer" },
  { id: "drumstick-bite", icon: "faDrumstickBite", slug: "fa-drumstick-bite", name: "Drumstick Bite" },
  { id: "drumstick", icon: "faDrumstick", slug: "fa-drumstick", name: "Drumstick" },
  { id: "drum-steelpan", icon: "faDrumSteelpan", slug: "fa-drum-steelpan", name: "Drum Steelpan" },
  { id: "drum", icon: "faDrum", slug: "fa-drum", name: "Drum" },
  { id: "droplet-slash", icon: "faDropletSlash", slug: "fa-droplet-slash", name: "Droplet Slash" },
  { id: "droplet-percent", icon: "faDropletPercent", slug: "fa-droplet-percent", name: "Droplet Percent" },
  { id: "droplet-degree", icon: "faDropletDegree", slug: "fa-droplet-degree", name: "Droplet Degree" },
  { id: "drone-front", icon: "faDroneFront", slug: "fa-drone-front", name: "Drone Front" },
  { id: "drone", icon: "faDrone", slug: "fa-drone", name: "Drone" },
  { id: "dreidel", icon: "faDreidel", slug: "fa-dreidel", name: "Dreidel" },
  { id: "draw-polygon", icon: "faDrawPolygon", slug: "fa-draw-polygon", name: "Draw Polygon" },
  { id: "draw-circle", icon: "faDrawCircle", slug: "fa-draw-circle", name: "Draw Circle" },
  { id: "down-to-line", icon: "faDownToLine", slug: "fa-down-to-line", name: "Down To Line" },
  { id: "down-to-dotted-line", icon: "faDownToDottedLine", slug: "fa-down-to-dotted-line", name: "Down To Dotted Line" },
  { id: "down-to-bracket", icon: "faDownToBracket", slug: "fa-down-to-bracket", name: "Down To Bracket" },
  { id: "down-right", icon: "faDownRight", slug: "fa-down-right", name: "Down Right" },
  { id: "down-long", icon: "faDownLong", slug: "fa-down-long", name: "Down Long" },
  { id: "down-left-and-up-right-to-center", icon: "faDownLeftAndUpRightToCenter", slug: "fa-down-left-and-up-right-to-center", name: "Down Left And Up Right To Center" },
  { id: "down-left", icon: "faDownLeft", slug: "fa-down-left", name: "Down Left" },
  { id: "down-from-line", icon: "faDownFromLine", slug: "fa-down-from-line", name: "Down From Line" },
  { id: "down-from-dotted-line", icon: "faDownFromDottedLine", slug: "fa-down-from-dotted-line", name: "Down From Dotted Line" },
  { id: "down-from-bracket", icon: "faDownFromBracket", slug: "fa-down-from-bracket", name: "Down From Bracket" },
  { id: "down", icon: "faDown", slug: "fa-down", name: "Down" },
  { id: "dove", icon: "faDove", slug: "fa-dove", name: "Dove" },
  { id: "door-closed", icon: "faDoorClosed", slug: "fa-door-closed", name: "Door Closed" },
  { id: "donut", icon: "faDonut", slug: "fa-donut", name: "Donut" },
  { id: "dong-sign", icon: "faDongSign", slug: "fa-dong-sign", name: "Dong Sign" },
  { id: "dolly-empty", icon: "faDollyEmpty", slug: "fa-dolly-empty", name: "Dolly Empty" },
  { id: "dolly", icon: "faDolly", slug: "fa-dolly", name: "Dolly" },
  { id: "dog-leashed", icon: "faDogLeashed", slug: "fa-dog-leashed", name: "Dog Leashed" },
  { id: "dog", icon: "faDog", slug: "fa-dog", name: "Dog" },
  { id: "do-not-enter", icon: "faDoNotEnter", slug: "fa-do-not-enter", name: "Do Not Enter" },
  { id: "dna", icon: "faDna", slug: "fa-dna", name: "Dna" },
  { id: "divide", icon: "faDivide", slug: "fa-divide", name: "Divide" },
  { id: "ditto", icon: "faDitto", slug: "fa-ditto", name: "Ditto" },
  { id: "distribute-spacing-vertical", icon: "faDistributeSpacingVertical", slug: "fa-distribute-spacing-vertical", name: "Distribute Spacing Vertical" },
  { id: "distribute-spacing-horizontal", icon: "faDistributeSpacingHorizontal", slug: "fa-distribute-spacing-horizontal", name: "Distribute Spacing Horizontal" },
  { id: "display-slash", icon: "faDisplaySlash", slug: "fa-display-slash", name: "Display Slash" },
  { id: "display-medical", icon: "faDisplayMedical", slug: "fa-display-medical", name: "Display Medical" },
  { id: "display-code", icon: "faDisplayCode", slug: "fa-display-code", name: "Display Code" },
  { id: "display-chart-up-circle-dollar", icon: "faDisplayChartUpCircleDollar", slug: "fa-display-chart-up-circle-dollar", name: "Display Chart Up Circle Dollar" },
  { id: "display-chart-up-circle-currency", icon: "faDisplayChartUpCircleCurrency", slug: "fa-display-chart-up-circle-currency", name: "Display Chart Up Circle Currency" },
  { id: "display-chart-up", icon: "faDisplayChartUp", slug: "fa-display-chart-up", name: "Display Chart Up" },
  { id: "display-arrow-down", icon: "faDisplayArrowDown", slug: "fa-display-arrow-down", name: "Display Arrow Down" },
  { id: "display", icon: "faDisplay", slug: "fa-display", name: "Display" },
  { id: "disease", icon: "faDisease", slug: "fa-disease", name: "Disease" },
  { id: "disc-drive", icon: "faDiscDrive", slug: "fa-disc-drive", name: "Disc Drive" },
  { id: "diploma", icon: "faDiploma", slug: "fa-diploma", name: "Diploma" },
  { id: "dinosaur", icon: "faDinosaur", slug: "fa-dinosaur", name: "Dinosaur" },
  { id: "dice-two", icon: "faDiceTwo", slug: "fa-dice-two", name: "Dice Two" },
  { id: "dice-three", icon: "faDiceThree", slug: "fa-dice-three", name: "Dice Three" },
  { id: "dice-six", icon: "faDiceSix", slug: "fa-dice-six", name: "Dice Six" },
  { id: "dice-one", icon: "faDiceOne", slug: "fa-dice-one", name: "Dice One" },
  { id: "dice-four", icon: "faDiceFour", slug: "fa-dice-four", name: "Dice Four" },
  { id: "dice-five", icon: "faDiceFive", slug: "fa-dice-five", name: "Dice Five" },
  { id: "dice-d8", icon: "faDiceD8", slug: "fa-dice-d8", name: "Dice D8" },
  { id: "dice-d6", icon: "faDiceD6", slug: "fa-dice-d6", name: "Dice D6" },
  { id: "dice-d4", icon: "faDiceD4", slug: "fa-dice-d4", name: "Dice D4" },
  { id: "dice-d20", icon: "faDiceD20", slug: "fa-dice-d20", name: "Dice D20" },
  { id: "dice-d12", icon: "faDiceD12", slug: "fa-dice-d12", name: "Dice D12" },
  { id: "dice-d10", icon: "faDiceD10", slug: "fa-dice-d10", name: "Dice D10" },
  { id: "diamond-turn-right", icon: "faDiamondTurnRight", slug: "fa-diamond-turn-right", name: "Diamond Turn Right" },
  { id: "diamond-half-stroke", icon: "faDiamondHalfStroke", slug: "fa-diamond-half-stroke", name: "Diamond Half Stroke" },
  { id: "diamond-half", icon: "faDiamondHalf", slug: "fa-diamond-half", name: "Diamond Half" },
  { id: "diamond-exclamation", icon: "faDiamondExclamation", slug: "fa-diamond-exclamation", name: "Diamond Exclamation" },
  { id: "dial-off", icon: "faDialOff", slug: "fa-dial-off", name: "Dial Off" },
  { id: "dial-min", icon: "faDialMin", slug: "fa-dial-min", name: "Dial Min" },
  { id: "dial-med-low", icon: "faDialMedLow", slug: "fa-dial-med-low", name: "Dial Med Low" },
  { id: "dial-med", icon: "faDialMed", slug: "fa-dial-med", name: "Dial Med" },
  { id: "dial-max", icon: "faDialMax", slug: "fa-dial-max", name: "Dial Max" },
  { id: "dial-low", icon: "faDialLow", slug: "fa-dial-low", name: "Dial Low" },
  { id: "dial-high", icon: "faDialHigh", slug: "fa-dial-high", name: "Dial High" },
  { id: "dial", icon: "faDial", slug: "fa-dial", name: "Dial" },
  { id: "diagram-successor", icon: "faDiagramSuccessor", slug: "fa-diagram-successor", name: "Diagram Successor" },
  { id: "diagram-subtask", icon: "faDiagramSubtask", slug: "fa-diagram-subtask", name: "Diagram Subtask" },
  { id: "diagram-sankey", icon: "faDiagramSankey", slug: "fa-diagram-sankey", name: "Diagram Sankey" },
  { id: "diagram-project", icon: "faDiagramProject", slug: "fa-diagram-project", name: "Diagram Project" },
  { id: "diagram-previous", icon: "faDiagramPrevious", slug: "fa-diagram-previous", name: "Diagram Previous" },
  { id: "diagram-predecessor", icon: "faDiagramPredecessor", slug: "fa-diagram-predecessor", name: "Diagram Predecessor" },
  { id: "diagram-next", icon: "faDiagramNext", slug: "fa-diagram-next", name: "Diagram Next" },
  { id: "diagram-nested", icon: "faDiagramNested", slug: "fa-diagram-nested", name: "Diagram Nested" },
  { id: "diagram-lean-canvas", icon: "faDiagramLeanCanvas", slug: "fa-diagram-lean-canvas", name: "Diagram Lean Canvas" },
  { id: "diagram-cells", icon: "faDiagramCells", slug: "fa-diagram-cells", name: "Diagram Cells" },
  { id: "dharmachakra", icon: "faDharmachakra", slug: "fa-dharmachakra", name: "Dharmachakra" },
  { id: "desktop-arrow-down", icon: "faDesktopArrowDown", slug: "fa-desktop-arrow-down", name: "Desktop Arrow Down" },
  { id: "democrat", icon: "faDemocrat", slug: "fa-democrat", name: "Democrat" },
  { id: "delete-right", icon: "faDeleteRight", slug: "fa-delete-right", name: "Delete Right" },
  { id: "delete-left", icon: "faDeleteLeft", slug: "fa-delete-left", name: "Delete Left" },
  { id: "deer-rudolph", icon: "faDeerRudolph", slug: "fa-deer-rudolph", name: "Deer Rudolph" },
  { id: "deer", icon: "faDeer", slug: "fa-deer", name: "Deer" },
  { id: "dash", icon: "faDash", slug: "fa-dash", name: "Dash" },
  { id: "dagger", icon: "faDagger", slug: "fa-dagger", name: "Dagger" },
  { id: "d", icon: "faD", slug: "fa-d", name: "D" },
  { id: "custard", icon: "faCustard", slug: "fa-custard", name: "Custard" },
  { id: "curling-stone", icon: "faCurlingStone", slug: "fa-curling-stone", name: "Curling Stone" },
  { id: "cupcake", icon: "faCupcake", slug: "fa-cupcake", name: "Cupcake" },
  { id: "cup-togo", icon: "faCupTogo", slug: "fa-cup-togo", name: "Cup Togo" },
  { id: "cup-straw-swoosh", icon: "faCupStrawSwoosh", slug: "fa-cup-straw-swoosh", name: "Cup Straw Swoosh" },
  { id: "cup-straw", icon: "faCupStraw", slug: "fa-cup-straw", name: "Cup Straw" },
  { id: "cucumber", icon: "faCucumber", slug: "fa-cucumber", name: "Cucumber" },
  { id: "cubes-stacked", icon: "faCubesStacked", slug: "fa-cubes-stacked", name: "Cubes Stacked" },
  { id: "cubes", icon: "faCubes", slug: "fa-cubes", name: "Cubes" },
  { id: "crystal-ball", icon: "faCrystalBall", slug: "fa-crystal-ball", name: "Crystal Ball" },
  { id: "cruzeiro-sign", icon: "faCruzeiroSign", slug: "fa-cruzeiro-sign", name: "Cruzeiro Sign" },
  { id: "crutches", icon: "faCrutches", slug: "fa-crutches", name: "Crutches" },
  { id: "crutch", icon: "faCrutch", slug: "fa-crutch", name: "Crutch" },
  { id: "crow", icon: "faCrow", slug: "fa-crow", name: "Crow" },
  { id: "crosshairs-simple", icon: "faCrosshairsSimple", slug: "fa-crosshairs-simple", name: "Crosshairs Simple" },
  { id: "crop-simple", icon: "faCropSimple", slug: "fa-crop-simple", name: "Crop Simple" },
  { id: "crop", icon: "faCrop", slug: "fa-crop", name: "Crop" },
  { id: "croissant", icon: "faCroissant", slug: "fa-croissant", name: "Croissant" },
  { id: "cricket-bat-ball", icon: "faCricketBatBall", slug: "fa-cricket-bat-ball", name: "Cricket Bat Ball" },
  { id: "credit-card-front", icon: "faCreditCardFront", slug: "fa-credit-card-front", name: "Credit Card Front" },
  { id: "credit-card-blank", icon: "faCreditCardBlank", slug: "fa-credit-card-blank", name: "Credit Card Blank" },
  { id: "crate-empty", icon: "faCrateEmpty", slug: "fa-crate-empty", name: "Crate Empty" },
  { id: "crate-apple", icon: "faCrateApple", slug: "fa-crate-apple", name: "Crate Apple" },
  { id: "cowbell-circle-plus", icon: "faCowbellCirclePlus", slug: "fa-cowbell-circle-plus", name: "Cowbell Circle Plus" },
  { id: "cowbell", icon: "faCowbell", slug: "fa-cowbell", name: "Cowbell" },
  { id: "cow", icon: "faCow", slug: "fa-cow", name: "Cow" },
  { id: "court-sport", icon: "faCourtSport", slug: "fa-court-sport", name: "Court Sport" },
  { id: "couch", icon: "faCouch", slug: "fa-couch", name: "Couch" },
  { id: "corner", icon: "faCorner", slug: "fa-corner", name: "Corner" },
  { id: "corn", icon: "faCorn", slug: "fa-corn", name: "Corn" },
  { id: "cookie", icon: "faCookie", slug: "fa-cookie", name: "Cookie" },
  { id: "conveyor-belt-empty", icon: "faConveyorBeltEmpty", slug: "fa-conveyor-belt-empty", name: "Conveyor Belt Empty" },
  { id: "conveyor-belt-boxes", icon: "faConveyorBeltBoxes", slug: "fa-conveyor-belt-boxes", name: "Conveyor Belt Boxes" },
  { id: "conveyor-belt-arm", icon: "faConveyorBeltArm", slug: "fa-conveyor-belt-arm", name: "Conveyor Belt Arm" },
  { id: "conveyor-belt", icon: "faConveyorBelt", slug: "fa-conveyor-belt", name: "Conveyor Belt" },
  { id: "computer-mouse", icon: "faComputerMouse", slug: "fa-computer-mouse", name: "Computer Mouse" },
  { id: "computer", icon: "faComputer", slug: "fa-computer", name: "Computer" },
  { id: "compress-wide", icon: "faCompressWide", slug: "fa-compress-wide", name: "Compress Wide" },
  { id: "compress", icon: "faCompress", slug: "fa-compress", name: "Compress" },
  { id: "compass-slash", icon: "faCompassSlash", slug: "fa-compass-slash", name: "Compass Slash" },
  { id: "compass-drafting", icon: "faCompassDrafting", slug: "fa-compass-drafting", name: "Compass Drafting" },
  { id: "compact-disc", icon: "faCompactDisc", slug: "fa-compact-disc", name: "Compact Disc" },
  { id: "comments-question-check", icon: "faCommentsQuestionCheck", slug: "fa-comments-question-check", name: "Comments Question Check" },
  { id: "comments-question", icon: "faCommentsQuestion", slug: "fa-comments-question", name: "Comments Question" },
  { id: "comment-xmark", icon: "faCommentXmark", slug: "fa-comment-xmark", name: "Comment Xmark" },
  { id: "comment-text", icon: "faCommentText", slug: "fa-comment-text", name: "Comment Text" },
  { id: "comment-sms", icon: "faCommentSms", slug: "fa-comment-sms", name: "Comment Sms" },
  { id: "comment-smile", icon: "faCommentSmile", slug: "fa-comment-smile", name: "Comment Smile" },
  { id: "comment-slash", icon: "faCommentSlash", slug: "fa-comment-slash", name: "Comment Slash" },
  { id: "comment-quote", icon: "faCommentQuote", slug: "fa-comment-quote", name: "Comment Quote" },
  { id: "comment-question", icon: "faCommentQuestion", slug: "fa-comment-question", name: "Comment Question" },
  { id: "comment-plus", icon: "faCommentPlus", slug: "fa-comment-plus", name: "Comment Plus" },
  { id: "comment-pen", icon: "faCommentPen", slug: "fa-comment-pen", name: "Comment Pen" },
  { id: "comment-music", icon: "faCommentMusic", slug: "fa-comment-music", name: "Comment Music" },
  { id: "comment-minus", icon: "faCommentMinus", slug: "fa-comment-minus", name: "Comment Minus" },
  { id: "comment-middle-top", icon: "faCommentMiddleTop", slug: "fa-comment-middle-top", name: "Comment Middle Top" },
  { id: "comment-middle", icon: "faCommentMiddle", slug: "fa-comment-middle", name: "Comment Middle" },
  { id: "comment-medical", icon: "faCommentMedical", slug: "fa-comment-medical", name: "Comment Medical" },
  { id: "comment-lines", icon: "faCommentLines", slug: "fa-comment-lines", name: "Comment Lines" },
  { id: "comment-image", icon: "faCommentImage", slug: "fa-comment-image", name: "Comment Image" },
  { id: "comment-heart", icon: "faCommentHeart", slug: "fa-comment-heart", name: "Comment Heart" },
  { id: "comment-exclamation", icon: "faCommentExclamation", slug: "fa-comment-exclamation", name: "Comment Exclamation" },
  { id: "comment-dots", icon: "faCommentDots", slug: "fa-comment-dots", name: "Comment Dots" },
  { id: "comment-dollar", icon: "faCommentDollar", slug: "fa-comment-dollar", name: "Comment Dollar" },
  { id: "comment-code", icon: "faCommentCode", slug: "fa-comment-code", name: "Comment Code" },
  { id: "comment-check", icon: "faCommentCheck", slug: "fa-comment-check", name: "Comment Check" },
  { id: "comment-captions", icon: "faCommentCaptions", slug: "fa-comment-captions", name: "Comment Captions" },
  { id: "comment-arrow-up-right", icon: "faCommentArrowUpRight", slug: "fa-comment-arrow-up-right", name: "Comment Arrow Up Right" },
  { id: "comment-arrow-up", icon: "faCommentArrowUp", slug: "fa-comment-arrow-up", name: "Comment Arrow Up" },
  { id: "comment-arrow-down", icon: "faCommentArrowDown", slug: "fa-comment-arrow-down", name: "Comment Arrow Down" },
  { id: "command", icon: "faCommand", slug: "fa-command", name: "Command" },
  { id: "comma", icon: "faComma", slug: "fa-comma", name: "Comma" },
  { id: "comet", icon: "faComet", slug: "fa-comet", name: "Comet" },
  { id: "columns-3", icon: "faColumns3", slug: "fa-columns-3", name: "Columns 3" },
  { id: "colon-sign", icon: "faColonSign", slug: "fa-colon-sign", name: "Colon Sign" },
  { id: "colon", icon: "faColon", slug: "fa-colon", name: "Colon" },
  { id: "coin-vertical", icon: "faCoinVertical", slug: "fa-coin-vertical", name: "Coin Vertical" },
  { id: "coin-front", icon: "faCoinFront", slug: "fa-coin-front", name: "Coin Front" },
  { id: "coin-blank", icon: "faCoinBlank", slug: "fa-coin-blank", name: "Coin Blank" },
  { id: "coffin-cross", icon: "faCoffinCross", slug: "fa-coffin-cross", name: "Coffin Cross" },
  { id: "coffin", icon: "faCoffin", slug: "fa-coffin", name: "Coffin" },
  { id: "coffee-pot", icon: "faCoffeePot", slug: "fa-coffee-pot", name: "Coffee Pot" },
  { id: "coffee-beans", icon: "faCoffeeBeans", slug: "fa-coffee-beans", name: "Coffee Beans" },
  { id: "coffee-bean", icon: "faCoffeeBean", slug: "fa-coffee-bean", name: "Coffee Bean" },
  { id: "code-simple", icon: "faCodeSimple", slug: "fa-code-simple", name: "Code Simple" },
  { id: "code-pull-request-draft", icon: "faCodePullRequestDraft", slug: "fa-code-pull-request-draft", name: "Code Pull Request Draft" },
  { id: "code-pull-request-closed", icon: "faCodePullRequestClosed", slug: "fa-code-pull-request-closed", name: "Code Pull Request Closed" },
  { id: "code-pull-request", icon: "faCodePullRequest", slug: "fa-code-pull-request", name: "Code Pull Request" },
  { id: "code-merge", icon: "faCodeMerge", slug: "fa-code-merge", name: "Code Merge" },
  { id: "code-fork", icon: "faCodeFork", slug: "fa-code-fork", name: "Code Fork" },
  { id: "code-commit", icon: "faCodeCommit", slug: "fa-code-commit", name: "Code Commit" },
  { id: "coconut", icon: "faCoconut", slug: "fa-coconut", name: "Coconut" },
  { id: "club", icon: "faClub", slug: "fa-club", name: "Club" },
  { id: "clover", icon: "faClover", slug: "fa-clover", name: "Clover" },
  { id: "clouds-sun", icon: "faCloudsSun", slug: "fa-clouds-sun", name: "Clouds Sun" },
  { id: "clouds-moon", icon: "faCloudsMoon", slug: "fa-clouds-moon", name: "Clouds Moon" },
  { id: "clouds", icon: "faClouds", slug: "fa-clouds", name: "Clouds" },
  { id: "cloud-xmark", icon: "faCloudXmark", slug: "fa-cloud-xmark", name: "Cloud Xmark" },
  { id: "cloud-word", icon: "faCloudWord", slug: "fa-cloud-word", name: "Cloud Word" },
  { id: "cloud-sun-rain", icon: "faCloudSunRain", slug: "fa-cloud-sun-rain", name: "Cloud Sun Rain" },
  { id: "cloud-sun", icon: "faCloudSun", slug: "fa-cloud-sun", name: "Cloud Sun" },
  { id: "cloud-snow", icon: "faCloudSnow", slug: "fa-cloud-snow", name: "Cloud Snow" },
  { id: "cloud-sleet", icon: "faCloudSleet", slug: "fa-cloud-sleet", name: "Cloud Sleet" },
  { id: "cloud-slash", icon: "faCloudSlash", slug: "fa-cloud-slash", name: "Cloud Slash" },
  { id: "cloud-showers-water", icon: "faCloudShowersWater", slug: "fa-cloud-showers-water", name: "Cloud Showers Water" },
  { id: "cloud-showers-heavy", icon: "faCloudShowersHeavy", slug: "fa-cloud-showers-heavy", name: "Cloud Showers Heavy" },
  { id: "cloud-showers", icon: "faCloudShowers", slug: "fa-cloud-showers", name: "Cloud Showers" },
  { id: "cloud-rainbow", icon: "faCloudRainbow", slug: "fa-cloud-rainbow", name: "Cloud Rainbow" },
  { id: "cloud-rain", icon: "faCloudRain", slug: "fa-cloud-rain", name: "Cloud Rain" },
  { id: "cloud-question", icon: "faCloudQuestion", slug: "fa-cloud-question", name: "Cloud Question" },
  { id: "cloud-plus", icon: "faCloudPlus", slug: "fa-cloud-plus", name: "Cloud Plus" },
  { id: "cloud-music", icon: "faCloudMusic", slug: "fa-cloud-music", name: "Cloud Music" },
  { id: "cloud-moon-rain", icon: "faCloudMoonRain", slug: "fa-cloud-moon-rain", name: "Cloud Moon Rain" },
  { id: "cloud-moon", icon: "faCloudMoon", slug: "fa-cloud-moon", name: "Cloud Moon" },
  { id: "cloud-minus", icon: "faCloudMinus", slug: "fa-cloud-minus", name: "Cloud Minus" },
  { id: "cloud-meatball", icon: "faCloudMeatball", slug: "fa-cloud-meatball", name: "Cloud Meatball" },
  { id: "cloud-hail-mixed", icon: "faCloudHailMixed", slug: "fa-cloud-hail-mixed", name: "Cloud Hail Mixed" },
  { id: "cloud-hail", icon: "faCloudHail", slug: "fa-cloud-hail", name: "Cloud Hail" },
  { id: "cloud-fog", icon: "faCloudFog", slug: "fa-cloud-fog", name: "Cloud Fog" },
  { id: "cloud-exclamation", icon: "faCloudExclamation", slug: "fa-cloud-exclamation", name: "Cloud Exclamation" },
  { id: "cloud-drizzle", icon: "faCloudDrizzle", slug: "fa-cloud-drizzle", name: "Cloud Drizzle" },
  { id: "cloud-check", icon: "faCloudCheck", slug: "fa-cloud-check", name: "Cloud Check" },
  { id: "cloud-bolt-sun", icon: "faCloudBoltSun", slug: "fa-cloud-bolt-sun", name: "Cloud Bolt Sun" },
  { id: "cloud-bolt-moon", icon: "faCloudBoltMoon", slug: "fa-cloud-bolt-moon", name: "Cloud Bolt Moon" },
  { id: "cloud-bolt", icon: "faCloudBolt", slug: "fa-cloud-bolt", name: "Cloud Bolt" },
  { id: "cloud-binary", icon: "faCloudBinary", slug: "fa-cloud-binary", name: "Cloud Binary" },
  { id: "cloud-arrow-down", icon: "faCloudArrowDown", slug: "fa-cloud-arrow-down", name: "Cloud Arrow Down" },
  { id: "clothes-hanger", icon: "faClothesHanger", slug: "fa-clothes-hanger", name: "Clothes Hanger" },
  { id: "closed-captioning-slash", icon: "faClosedCaptioningSlash", slug: "fa-closed-captioning-slash", name: "Closed Captioning Slash" },
  { id: "clock-two-thirty", icon: "faClockTwoThirty", slug: "fa-clock-two-thirty", name: "Clock Two Thirty" },
  { id: "clock-two", icon: "faClockTwo", slug: "fa-clock-two", name: "Clock Two" },
  { id: "clock-twelve-thirty", icon: "faClockTwelveThirty", slug: "fa-clock-twelve-thirty", name: "Clock Twelve Thirty" },
  { id: "clock-twelve", icon: "faClockTwelve", slug: "fa-clock-twelve", name: "Clock Twelve" },
  { id: "clock-three-thirty", icon: "faClockThreeThirty", slug: "fa-clock-three-thirty", name: "Clock Three Thirty" },
  { id: "clock-three", icon: "faClockThree", slug: "fa-clock-three", name: "Clock Three" },
  { id: "clock-ten-thirty", icon: "faClockTenThirty", slug: "fa-clock-ten-thirty", name: "Clock Ten Thirty" },
  { id: "clock-ten", icon: "faClockTen", slug: "fa-clock-ten", name: "Clock Ten" },
  { id: "clock-six-thirty", icon: "faClockSixThirty", slug: "fa-clock-six-thirty", name: "Clock Six Thirty" },
  { id: "clock-six", icon: "faClockSix", slug: "fa-clock-six", name: "Clock Six" },
  { id: "clock-seven-thirty", icon: "faClockSevenThirty", slug: "fa-clock-seven-thirty", name: "Clock Seven Thirty" },
  { id: "clock-seven", icon: "faClockSeven", slug: "fa-clock-seven", name: "Clock Seven" },
  { id: "clock-rotate-left", icon: "faClockRotateLeft", slug: "fa-clock-rotate-left", name: "Clock Rotate Left" },
  { id: "clock-one-thirty", icon: "faClockOneThirty", slug: "fa-clock-one-thirty", name: "Clock One Thirty" },
  { id: "clock-one", icon: "faClockOne", slug: "fa-clock-one", name: "Clock One" },
  { id: "clock-nine-thirty", icon: "faClockNineThirty", slug: "fa-clock-nine-thirty", name: "Clock Nine Thirty" },
  { id: "clock-nine", icon: "faClockNine", slug: "fa-clock-nine", name: "Clock Nine" },
  { id: "clock-four-thirty", icon: "faClockFourThirty", slug: "fa-clock-four-thirty", name: "Clock Four Thirty" },
  { id: "clock-five-thirty", icon: "faClockFiveThirty", slug: "fa-clock-five-thirty", name: "Clock Five Thirty" },
  { id: "clock-five", icon: "faClockFive", slug: "fa-clock-five", name: "Clock Five" },
  { id: "clock-eleven-thirty", icon: "faClockElevenThirty", slug: "fa-clock-eleven-thirty", name: "Clock Eleven Thirty" },
  { id: "clock-eleven", icon: "faClockEleven", slug: "fa-clock-eleven", name: "Clock Eleven" },
  { id: "clock-eight-thirty", icon: "faClockEightThirty", slug: "fa-clock-eight-thirty", name: "Clock Eight Thirty" },
  { id: "clock-eight", icon: "faClockEight", slug: "fa-clock-eight", name: "Clock Eight" },
  { id: "clock-desk", icon: "faClockDesk", slug: "fa-clock-desk", name: "Clock Desk" },
  { id: "clipboard-user", icon: "faClipboardUser", slug: "fa-clipboard-user", name: "Clipboard User" },
  { id: "clipboard-question", icon: "faClipboardQuestion", slug: "fa-clipboard-question", name: "Clipboard Question" },
  { id: "clipboard-prescription", icon: "faClipboardPrescription", slug: "fa-clipboard-prescription", name: "Clipboard Prescription" },
  { id: "clipboard-medical", icon: "faClipboardMedical", slug: "fa-clipboard-medical", name: "Clipboard Medical" },
  { id: "clipboard-list-check", icon: "faClipboardListCheck", slug: "fa-clipboard-list-check", name: "Clipboard List Check" },
  { id: "clipboard-list", icon: "faClipboardList", slug: "fa-clipboard-list", name: "Clipboard List" },
  { id: "clipboard-check", icon: "faClipboardCheck", slug: "fa-clipboard-check", name: "Clipboard Check" },
  { id: "claw-marks", icon: "faClawMarks", slug: "fa-claw-marks", name: "Claw Marks" },
  { id: "clarinet", icon: "faClarinet", slug: "fa-clarinet", name: "Clarinet" },
  { id: "clapperboard-play", icon: "faClapperboardPlay", slug: "fa-clapperboard-play", name: "Clapperboard Play" },
  { id: "clapperboard", icon: "faClapperboard", slug: "fa-clapperboard", name: "Clapperboard" },
  { id: "citrus-slice", icon: "faCitrusSlice", slug: "fa-citrus-slice", name: "Citrus Slice" },
  { id: "citrus", icon: "faCitrus", slug: "fa-citrus", name: "Citrus" },
  { id: "circles-overlap", icon: "faCirclesOverlap", slug: "fa-circles-overlap", name: "Circles Overlap" },
  { id: "circle-z", icon: "faCircleZ", slug: "fa-circle-z", name: "Circle Z" },
  { id: "circle-yen", icon: "faCircleYen", slug: "fa-circle-yen", name: "Circle Yen" },
  { id: "circle-y", icon: "faCircleY", slug: "fa-circle-y", name: "Circle Y" },
  { id: "circle-x", icon: "faCircleX", slug: "fa-circle-x", name: "Circle X" },
  { id: "circle-wifi-circle-wifi", icon: "faCircleWifiCircleWifi", slug: "fa-circle-wifi-circle-wifi", name: "Circle Wifi Circle Wifi" },
  { id: "circle-wifi", icon: "faCircleWifi", slug: "fa-circle-wifi", name: "Circle Wifi" },
  { id: "circle-waveform-lines", icon: "faCircleWaveformLines", slug: "fa-circle-waveform-lines", name: "Circle Waveform Lines" },
  { id: "circle-w", icon: "faCircleW", slug: "fa-circle-w", name: "Circle W" },
  { id: "circle-video", icon: "faCircleVideo", slug: "fa-circle-video", name: "Circle Video" },
  { id: "circle-v", icon: "faCircleV", slug: "fa-circle-v", name: "Circle V" },
  { id: "circle-up-right", icon: "faCircleUpRight", slug: "fa-circle-up-right", name: "Circle Up Right" },
  { id: "circle-up-left", icon: "faCircleUpLeft", slug: "fa-circle-up-left", name: "Circle Up Left" },
  { id: "circle-u", icon: "faCircleU", slug: "fa-circle-u", name: "Circle U" },
  { id: "circle-trash", icon: "faCircleTrash", slug: "fa-circle-trash", name: "Circle Trash" },
  { id: "circle-three-quarters-stroke", icon: "faCircleThreeQuartersStroke", slug: "fa-circle-three-quarters-stroke", name: "Circle Three Quarters Stroke" },
  { id: "circle-three-quarters", icon: "faCircleThreeQuarters", slug: "fa-circle-three-quarters", name: "Circle Three Quarters" },
  { id: "circle-t", icon: "faCircleT", slug: "fa-circle-t", name: "Circle T" },
  { id: "circle-stop", icon: "faCircleStop", slug: "fa-circle-stop", name: "Circle Stop" },
  { id: "circle-sterling", icon: "faCircleSterling", slug: "fa-circle-sterling", name: "Circle Sterling" },
  { id: "circle-star", icon: "faCircleStar", slug: "fa-circle-star", name: "Circle Star" },
  { id: "circle-sort-up", icon: "faCircleSortUp", slug: "fa-circle-sort-up", name: "Circle Sort Up" },
  { id: "circle-sort-down", icon: "faCircleSortDown", slug: "fa-circle-sort-down", name: "Circle Sort Down" },
  { id: "circle-sort", icon: "faCircleSort", slug: "fa-circle-sort", name: "Circle Sort" },
  { id: "circle-small", icon: "faCircleSmall", slug: "fa-circle-small", name: "Circle Small" },
  { id: "circle-s", icon: "faCircleS", slug: "fa-circle-s", name: "Circle S" },
  { id: "circle-right", icon: "faCircleRight", slug: "fa-circle-right", name: "Circle Right" },
  { id: "circle-radiation", icon: "faCircleRadiation", slug: "fa-circle-radiation", name: "Circle Radiation" },
  { id: "circle-r", icon: "faCircleR", slug: "fa-circle-r", name: "Circle R" },
  { id: "circle-question", icon: "faCircleQuestion", slug: "fa-circle-question", name: "Circle Question" },
  { id: "circle-quarters", icon: "faCircleQuarters", slug: "fa-circle-quarters", name: "Circle Quarters" },
  { id: "circle-quarter-stroke", icon: "faCircleQuarterStroke", slug: "fa-circle-quarter-stroke", name: "Circle Quarter Stroke" },
  { id: "circle-quarter", icon: "faCircleQuarter", slug: "fa-circle-quarter", name: "Circle Quarter" },
  { id: "circle-q", icon: "faCircleQ", slug: "fa-circle-q", name: "Circle Q" },
  { id: "circle-plus", icon: "faCirclePlus", slug: "fa-circle-plus", name: "Circle Plus" },
  { id: "circle-play", icon: "faCirclePlay", slug: "fa-circle-play", name: "Circle Play" },
  { id: "circle-phone-hangup", icon: "faCirclePhoneHangup", slug: "fa-circle-phone-hangup", name: "Circle Phone Hangup" },
  { id: "circle-phone-flip", icon: "faCirclePhoneFlip", slug: "fa-circle-phone-flip", name: "Circle Phone Flip" },
  { id: "circle-phone", icon: "faCirclePhone", slug: "fa-circle-phone", name: "Circle Phone" },
  { id: "circle-pause", icon: "faCirclePause", slug: "fa-circle-pause", name: "Circle Pause" },
  { id: "circle-parking", icon: "faCircleParking", slug: "fa-circle-parking", name: "Circle Parking" },
  { id: "circle-p", icon: "faCircleP", slug: "fa-circle-p", name: "Circle P" },
  { id: "circle-o", icon: "faCircleO", slug: "fa-circle-o", name: "Circle O" },
  { id: "circle-nodes", icon: "faCircleNodes", slug: "fa-circle-nodes", name: "Circle Nodes" },
  { id: "circle-n", icon: "faCircleN", slug: "fa-circle-n", name: "Circle N" },
  { id: "circle-minus", icon: "faCircleMinus", slug: "fa-circle-minus", name: "Circle Minus" },
  { id: "circle-microphone-lines", icon: "faCircleMicrophoneLines", slug: "fa-circle-microphone-lines", name: "Circle Microphone Lines" },
  { id: "circle-microphone", icon: "faCircleMicrophone", slug: "fa-circle-microphone", name: "Circle Microphone" },
  { id: "circle-m", icon: "faCircleM", slug: "fa-circle-m", name: "Circle M" },
  { id: "circle-location-arrow", icon: "faCircleLocationArrow", slug: "fa-circle-location-arrow", name: "Circle Location Arrow" },
  { id: "circle-left", icon: "faCircleLeft", slug: "fa-circle-left", name: "Circle Left" },
  { id: "circle-l", icon: "faCircleL", slug: "fa-circle-l", name: "Circle L" },
  { id: "circle-k", icon: "faCircleK", slug: "fa-circle-k", name: "Circle K" },
  { id: "circle-j", icon: "faCircleJ", slug: "fa-circle-j", name: "Circle J" },
  { id: "circle-i", icon: "faCircleI", slug: "fa-circle-i", name: "Circle I" },
  { id: "circle-heart", icon: "faCircleHeart", slug: "fa-circle-heart", name: "Circle Heart" },
  { id: "circle-half", icon: "faCircleHalf", slug: "fa-circle-half", name: "Circle Half" },
  { id: "circle-h", icon: "faCircleH", slug: "fa-circle-h", name: "Circle H" },
  { id: "circle-gf", icon: "faCircleGf", slug: "fa-circle-gf", name: "Circle Gf" },
  { id: "circle-g", icon: "faCircleG", slug: "fa-circle-g", name: "Circle G" },
  { id: "circle-f", icon: "faCircleF", slug: "fa-circle-f", name: "Circle F" },
  { id: "circle-exclamation-check", icon: "faCircleExclamationCheck", slug: "fa-circle-exclamation-check", name: "Circle Exclamation Check" },
  { id: "circle-euro", icon: "faCircleEuro", slug: "fa-circle-euro", name: "Circle Euro" },
  { id: "circle-envelope", icon: "faCircleEnvelope", slug: "fa-circle-envelope", name: "Circle Envelope" },
  { id: "circle-ellipsis-vertical", icon: "faCircleEllipsisVertical", slug: "fa-circle-ellipsis-vertical", name: "Circle Ellipsis Vertical" },
  { id: "circle-ellipsis", icon: "faCircleEllipsis", slug: "fa-circle-ellipsis", name: "Circle Ellipsis" },
  { id: "circle-e", icon: "faCircleE", slug: "fa-circle-e", name: "Circle E" },
  { id: "circle-down-right", icon: "faCircleDownRight", slug: "fa-circle-down-right", name: "Circle Down Right" },
  { id: "circle-down-left", icon: "faCircleDownLeft", slug: "fa-circle-down-left", name: "Circle Down Left" },
  { id: "circle-dot", icon: "faCircleDot", slug: "fa-circle-dot", name: "Circle Dot" },
  { id: "circle-dollar-to-slot", icon: "faCircleDollarToSlot", slug: "fa-circle-dollar-to-slot", name: "Circle Dollar To Slot" },
  { id: "circle-dollar", icon: "faCircleDollar", slug: "fa-circle-dollar", name: "Circle Dollar" },
  { id: "circle-divide", icon: "faCircleDivide", slug: "fa-circle-divide", name: "Circle Divide" },
  { id: "circle-dashed", icon: "faCircleDashed", slug: "fa-circle-dashed", name: "Circle Dashed" },
  { id: "circle-d", icon: "faCircleD", slug: "fa-circle-d", name: "Circle D" },
  { id: "circle-chevron-up", icon: "faCircleChevronUp", slug: "fa-circle-chevron-up", name: "Circle Chevron Up" },
  { id: "circle-chevron-right", icon: "faCircleChevronRight", slug: "fa-circle-chevron-right", name: "Circle Chevron Right" },
  { id: "circle-chevron-left", icon: "faCircleChevronLeft", slug: "fa-circle-chevron-left", name: "Circle Chevron Left" },
  { id: "circle-chevron-down", icon: "faCircleChevronDown", slug: "fa-circle-chevron-down", name: "Circle Chevron Down" },
  { id: "circle-caret-up", icon: "faCircleCaretUp", slug: "fa-circle-caret-up", name: "Circle Caret Up" },
  { id: "circle-caret-right", icon: "faCircleCaretRight", slug: "fa-circle-caret-right", name: "Circle Caret Right" },
  { id: "circle-caret-left", icon: "faCircleCaretLeft", slug: "fa-circle-caret-left", name: "Circle Caret Left" },
  { id: "circle-caret-down", icon: "faCircleCaretDown", slug: "fa-circle-caret-down", name: "Circle Caret Down" },
  { id: "circle-camera", icon: "faCircleCamera", slug: "fa-circle-camera", name: "Circle Camera" },
  { id: "circle-calendar", icon: "faCircleCalendar", slug: "fa-circle-calendar", name: "Circle Calendar" },
  { id: "circle-c", icon: "faCircleC", slug: "fa-circle-c", name: "Circle C" },
  { id: "circle-bookmark", icon: "faCircleBookmark", slug: "fa-circle-bookmark", name: "Circle Bookmark" },
  { id: "circle-book-open", icon: "faCircleBookOpen", slug: "fa-circle-book-open", name: "Circle Book Open" },
  { id: "circle-bolt", icon: "faCircleBolt", slug: "fa-circle-bolt", name: "Circle Bolt" },
  { id: "circle-b", icon: "faCircleB", slug: "fa-circle-b", name: "Circle B" },
  { id: "circle-arrow-up-right", icon: "faCircleArrowUpRight", slug: "fa-circle-arrow-up-right", name: "Circle Arrow Up Right" },
  { id: "circle-arrow-up-left", icon: "faCircleArrowUpLeft", slug: "fa-circle-arrow-up-left", name: "Circle Arrow Up Left" },
  { id: "circle-arrow-up", icon: "faCircleArrowUp", slug: "fa-circle-arrow-up", name: "Circle Arrow Up" },
  { id: "circle-arrow-right", icon: "faCircleArrowRight", slug: "fa-circle-arrow-right", name: "Circle Arrow Right" },
  { id: "circle-arrow-left", icon: "faCircleArrowLeft", slug: "fa-circle-arrow-left", name: "Circle Arrow Left" },
  { id: "circle-arrow-down-right", icon: "faCircleArrowDownRight", slug: "fa-circle-arrow-down-right", name: "Circle Arrow Down Right" },
  { id: "circle-arrow-down-left", icon: "faCircleArrowDownLeft", slug: "fa-circle-arrow-down-left", name: "Circle Arrow Down Left" },
  { id: "circle-arrow-down", icon: "faCircleArrowDown", slug: "fa-circle-arrow-down", name: "Circle Arrow Down" },
  { id: "circle-ampersand", icon: "faCircleAmpersand", slug: "fa-circle-ampersand", name: "Circle Ampersand" },
  { id: "circle-a", icon: "faCircleA", slug: "fa-circle-a", name: "Circle A" },
  { id: "circle-9", icon: "faCircle9", slug: "fa-circle-9", name: "Circle 9" },
  { id: "circle-8", icon: "faCircle8", slug: "fa-circle-8", name: "Circle 8" },
  { id: "circle-7", icon: "faCircle7", slug: "fa-circle-7", name: "Circle 7" },
  { id: "circle-6", icon: "faCircle6", slug: "fa-circle-6", name: "Circle 6" },
  { id: "circle-5", icon: "faCircle5", slug: "fa-circle-5", name: "Circle 5" },
  { id: "circle-4", icon: "faCircle4", slug: "fa-circle-4", name: "Circle 4" },
  { id: "circle-3", icon: "faCircle3", slug: "fa-circle-3", name: "Circle 3" },
  { id: "circle-2", icon: "faCircle2", slug: "fa-circle-2", name: "Circle 2" },
  { id: "circle-1", icon: "faCircle1", slug: "fa-circle-1", name: "Circle 1" },
  { id: "circle-0", icon: "faCircle0", slug: "fa-circle-0", name: "Circle 0" },
  { id: "chopsticks", icon: "faChopsticks", slug: "fa-chopsticks", name: "Chopsticks" },
  { id: "chimney", icon: "faChimney", slug: "fa-chimney", name: "Chimney" },
  { id: "children", icon: "faChildren", slug: "fa-children", name: "Children" },
  { id: "child-reaching", icon: "faChildReaching", slug: "fa-child-reaching", name: "Child Reaching" },
  { id: "child-dress", icon: "faChildDress", slug: "fa-child-dress", name: "Child Dress" },
  { id: "child-combatant", icon: "faChildCombatant", slug: "fa-child-combatant", name: "Child Combatant" },
  { id: "chf-sign", icon: "faChfSign", slug: "fa-chf-sign", name: "Chf Sign" },
  { id: "chevrons-up", icon: "faChevronsUp", slug: "fa-chevrons-up", name: "Chevrons Up" },
  { id: "chevrons-right", icon: "faChevronsRight", slug: "fa-chevrons-right", name: "Chevrons Right" },
  { id: "chevrons-left", icon: "faChevronsLeft", slug: "fa-chevrons-left", name: "Chevrons Left" },
  { id: "chevrons-down", icon: "faChevronsDown", slug: "fa-chevrons-down", name: "Chevrons Down" },
  { id: "chevron-right", icon: "faChevronRight", slug: "fa-chevron-right", name: "Chevron Right" },
  { id: "chevron-left", icon: "faChevronLeft", slug: "fa-chevron-left", name: "Chevron Left" },
  { id: "chestnut", icon: "faChestnut", slug: "fa-chestnut", name: "Chestnut" },
  { id: "chess-rook-piece", icon: "faChessRookPiece", slug: "fa-chess-rook-piece", name: "Chess Rook Piece" },
  { id: "chess-rook", icon: "faChessRook", slug: "fa-chess-rook", name: "Chess Rook" },
  { id: "chess-queen-piece", icon: "faChessQueenPiece", slug: "fa-chess-queen-piece", name: "Chess Queen Piece" },
  { id: "chess-queen", icon: "faChessQueen", slug: "fa-chess-queen", name: "Chess Queen" },
  { id: "chess-pawn-piece", icon: "faChessPawnPiece", slug: "fa-chess-pawn-piece", name: "Chess Pawn Piece" },
  { id: "chess-pawn", icon: "faChessPawn", slug: "fa-chess-pawn", name: "Chess Pawn" },
  { id: "chess-knight-piece", icon: "faChessKnightPiece", slug: "fa-chess-knight-piece", name: "Chess Knight Piece" },
  { id: "chess-knight", icon: "faChessKnight", slug: "fa-chess-knight", name: "Chess Knight" },
  { id: "chess-king-piece", icon: "faChessKingPiece", slug: "fa-chess-king-piece", name: "Chess King Piece" },
  { id: "chess-king", icon: "faChessKing", slug: "fa-chess-king", name: "Chess King" },
  { id: "chess-clock-flip", icon: "faChessClockFlip", slug: "fa-chess-clock-flip", name: "Chess Clock Flip" },
  { id: "chess-clock", icon: "faChessClock", slug: "fa-chess-clock", name: "Chess Clock" },
  { id: "chess-board", icon: "faChessBoard", slug: "fa-chess-board", name: "Chess Board" },
  { id: "chess-bishop-piece", icon: "faChessBishopPiece", slug: "fa-chess-bishop-piece", name: "Chess Bishop Piece" },
  { id: "chess-bishop", icon: "faChessBishop", slug: "fa-chess-bishop", name: "Chess Bishop" },
  { id: "chess", icon: "faChess", slug: "fa-chess", name: "Chess" },
  { id: "cherries", icon: "faCherries", slug: "fa-cherries", name: "Cherries" },
  { id: "cheese-swiss", icon: "faCheeseSwiss", slug: "fa-cheese-swiss", name: "Cheese Swiss" },
  { id: "cheese", icon: "faCheese", slug: "fa-cheese", name: "Cheese" },
  { id: "check-to-slot", icon: "faCheckToSlot", slug: "fa-check-to-slot", name: "Check To Slot" },
  { id: "check-double", icon: "faCheckDouble", slug: "fa-check-double", name: "Check Double" },
  { id: "chart-waterfall", icon: "faChartWaterfall", slug: "fa-chart-waterfall", name: "Chart Waterfall" },
  { id: "chart-user", icon: "faChartUser", slug: "fa-chart-user", name: "Chart User" },
  { id: "chart-tree-map", icon: "faChartTreeMap", slug: "fa-chart-tree-map", name: "Chart Tree Map" },
  { id: "chart-simple-horizontal", icon: "faChartSimpleHorizontal", slug: "fa-chart-simple-horizontal", name: "Chart Simple Horizontal" },
  { id: "chart-scatter-bubble", icon: "faChartScatterBubble", slug: "fa-chart-scatter-bubble", name: "Chart Scatter Bubble" },
  { id: "chart-scatter-3d", icon: "faChartScatter3d", slug: "fa-chart-scatter-3d", name: "Chart Scatter 3d" },
  { id: "chart-radar", icon: "faChartRadar", slug: "fa-chart-radar", name: "Chart Radar" },
  { id: "chart-pyramid", icon: "faChartPyramid", slug: "fa-chart-pyramid", name: "Chart Pyramid" },
  { id: "chart-pie-simple-circle-dollar", icon: "faChartPieSimpleCircleDollar", slug: "fa-chart-pie-simple-circle-dollar", name: "Chart Pie Simple Circle Dollar" },
  { id: "chart-pie-simple-circle-currency", icon: "faChartPieSimpleCircleCurrency", slug: "fa-chart-pie-simple-circle-currency", name: "Chart Pie Simple Circle Currency" },
  { id: "chart-pie-simple", icon: "faChartPieSimple", slug: "fa-chart-pie-simple", name: "Chart Pie Simple" },
  { id: "chart-pie", icon: "faChartPie", slug: "fa-chart-pie", name: "Chart Pie" },
  { id: "chart-network", icon: "faChartNetwork", slug: "fa-chart-network", name: "Chart Network" },
  { id: "chart-mixed-up-circle-dollar", icon: "faChartMixedUpCircleDollar", slug: "fa-chart-mixed-up-circle-dollar", name: "Chart Mixed Up Circle Dollar" },
  { id: "chart-mixed-up-circle-currency", icon: "faChartMixedUpCircleCurrency", slug: "fa-chart-mixed-up-circle-currency", name: "Chart Mixed Up Circle Currency" },
  { id: "chart-mixed", icon: "faChartMixed", slug: "fa-chart-mixed", name: "Chart Mixed" },
  { id: "chart-line-up-down", icon: "faChartLineUpDown", slug: "fa-chart-line-up-down", name: "Chart Line Up Down" },
  { id: "chart-line-up", icon: "faChartLineUp", slug: "fa-chart-line-up", name: "Chart Line Up" },
  { id: "chart-line-down", icon: "faChartLineDown", slug: "fa-chart-line-down", name: "Chart Line Down" },
  { id: "chart-line", icon: "faChartLine", slug: "fa-chart-line", name: "Chart Line" },
  { id: "chart-kanban", icon: "faChartKanban", slug: "fa-chart-kanban", name: "Chart Kanban" },
  { id: "chart-gantt", icon: "faChartGantt", slug: "fa-chart-gantt", name: "Chart Gantt" },
  { id: "chart-column", icon: "faChartColumn", slug: "fa-chart-column", name: "Chart Column" },
  { id: "chart-candlestick", icon: "faChartCandlestick", slug: "fa-chart-candlestick", name: "Chart Candlestick" },
  { id: "chart-bullet", icon: "faChartBullet", slug: "fa-chart-bullet", name: "Chart Bullet" },
  { id: "chart-bar", icon: "faChartBar", slug: "fa-chart-bar", name: "Chart Bar" },
  { id: "chart-area", icon: "faChartArea", slug: "fa-chart-area", name: "Chart Area" },
  { id: "charging-station", icon: "faChargingStation", slug: "fa-charging-station", name: "Charging Station" },
  { id: "champagne-glasses", icon: "faChampagneGlasses", slug: "fa-champagne-glasses", name: "Champagne Glasses" },
  { id: "champagne-glass", icon: "faChampagneGlass", slug: "fa-champagne-glass", name: "Champagne Glass" },
  { id: "chalkboard-user", icon: "faChalkboardUser", slug: "fa-chalkboard-user", name: "Chalkboard User" },
  { id: "chair-office", icon: "faChairOffice", slug: "fa-chair-office", name: "Chair Office" },
  { id: "cent-sign", icon: "faCentSign", slug: "fa-cent-sign", name: "Cent Sign" },
  { id: "cedi-sign", icon: "faCediSign", slug: "fa-cedi-sign", name: "Cedi Sign" },
  { id: "cat-space", icon: "faCatSpace", slug: "fa-cat-space", name: "Cat Space" },
  { id: "cat", icon: "faCat", slug: "fa-cat", name: "Cat" },
  { id: "castle", icon: "faCastle", slug: "fa-castle", name: "Castle" },
  { id: "cassette-vhs", icon: "faCassetteVhs", slug: "fa-cassette-vhs", name: "Cassette Vhs" },
  { id: "cassette-tape", icon: "faCassetteTape", slug: "fa-cassette-tape", name: "Cassette Tape" },
  { id: "cassette-betamax", icon: "faCassetteBetamax", slug: "fa-cassette-betamax", name: "Cassette Betamax" },
  { id: "cart-xmark", icon: "faCartXmark", slug: "fa-cart-xmark", name: "Cart Xmark" },
  { id: "cart-shopping-fast", icon: "faCartShoppingFast", slug: "fa-cart-shopping-fast", name: "Cart Shopping Fast" },
  { id: "cart-minus", icon: "faCartMinus", slug: "fa-cart-minus", name: "Cart Minus" },
  { id: "cart-flatbed-suitcase", icon: "faCartFlatbedSuitcase", slug: "fa-cart-flatbed-suitcase", name: "Cart Flatbed Suitcase" },
  { id: "cart-flatbed-empty", icon: "faCartFlatbedEmpty", slug: "fa-cart-flatbed-empty", name: "Cart Flatbed Empty" },
  { id: "cart-flatbed-boxes", icon: "faCartFlatbedBoxes", slug: "fa-cart-flatbed-boxes", name: "Cart Flatbed Boxes" },
  { id: "cart-flatbed", icon: "faCartFlatbed", slug: "fa-cart-flatbed", name: "Cart Flatbed" },
  { id: "cart-circle-xmark", icon: "faCartCircleXmark", slug: "fa-cart-circle-xmark", name: "Cart Circle Xmark" },
  { id: "cart-circle-plus", icon: "faCartCirclePlus", slug: "fa-cart-circle-plus", name: "Cart Circle Plus" },
  { id: "cart-circle-exclamation", icon: "faCartCircleExclamation", slug: "fa-cart-circle-exclamation", name: "Cart Circle Exclamation" },
  { id: "cart-circle-check", icon: "faCartCircleCheck", slug: "fa-cart-circle-check", name: "Cart Circle Check" },
  { id: "cart-circle-arrow-up", icon: "faCartCircleArrowUp", slug: "fa-cart-circle-arrow-up", name: "Cart Circle Arrow Up" },
  { id: "cart-circle-arrow-down", icon: "faCartCircleArrowDown", slug: "fa-cart-circle-arrow-down", name: "Cart Circle Arrow Down" },
  { id: "cart-arrow-up", icon: "faCartArrowUp", slug: "fa-cart-arrow-up", name: "Cart Arrow Up" },
  { id: "cart-arrow-down", icon: "faCartArrowDown", slug: "fa-cart-arrow-down", name: "Cart Arrow Down" },
  { id: "cars", icon: "faCars", slug: "fa-cars", name: "Cars" },
  { id: "carrot", icon: "faCarrot", slug: "fa-carrot", name: "Carrot" },
  { id: "caret-right", icon: "faCaretRight", slug: "fa-caret-right", name: "Caret Right" },
  { id: "caret-left", icon: "faCaretLeft", slug: "fa-caret-left", name: "Caret Left" },
  { id: "cards-blank", icon: "faCardsBlank", slug: "fa-cards-blank", name: "Cards Blank" },
  { id: "cards", icon: "faCards", slug: "fa-cards", name: "Cards" },
  { id: "card-spade", icon: "faCardSpade", slug: "fa-card-spade", name: "Card Spade" },
  { id: "card-heart", icon: "faCardHeart", slug: "fa-card-heart", name: "Card Heart" },
  { id: "card-diamond", icon: "faCardDiamond", slug: "fa-card-diamond", name: "Card Diamond" },
  { id: "card-club", icon: "faCardClub", slug: "fa-card-club", name: "Card Club" },
  { id: "caravan-simple", icon: "faCaravanSimple", slug: "fa-caravan-simple", name: "Caravan Simple" },
  { id: "caravan", icon: "faCaravan", slug: "fa-caravan", name: "Caravan" },
  { id: "car-wrench", icon: "faCarWrench", slug: "fa-car-wrench", name: "Car Wrench" },
  { id: "car-wash", icon: "faCarWash", slug: "fa-car-wash", name: "Car Wash" },
  { id: "car-tunnel", icon: "faCarTunnel", slug: "fa-car-tunnel", name: "Car Tunnel" },
  { id: "car-tilt", icon: "faCarTilt", slug: "fa-car-tilt", name: "Car Tilt" },
  { id: "car-side-bolt", icon: "faCarSideBolt", slug: "fa-car-side-bolt", name: "Car Side Bolt" },
  { id: "car-rear", icon: "faCarRear", slug: "fa-car-rear", name: "Car Rear" },
  { id: "car-on", icon: "faCarOn", slug: "fa-car-on", name: "Car On" },
  { id: "car-mirrors", icon: "faCarMirrors", slug: "fa-car-mirrors", name: "Car Mirrors" },
  { id: "car-garage", icon: "faCarGarage", slug: "fa-car-garage", name: "Car Garage" },
  { id: "car-circle-bolt", icon: "faCarCircleBolt", slug: "fa-car-circle-bolt", name: "Car Circle Bolt" },
  { id: "car-bus", icon: "faCarBus", slug: "fa-car-bus", name: "Car Bus" },
  { id: "car-burst", icon: "faCarBurst", slug: "fa-car-burst", name: "Car Burst" },
  { id: "car-bump", icon: "faCarBump", slug: "fa-car-bump", name: "Car Bump" },
  { id: "car-bolt", icon: "faCarBolt", slug: "fa-car-bolt", name: "Car Bolt" },
  { id: "car-battery", icon: "faCarBattery", slug: "fa-car-battery", name: "Car Battery" },
  { id: "capsules", icon: "faCapsules", slug: "fa-capsules", name: "Capsules" },
  { id: "cannon", icon: "faCannon", slug: "fa-cannon", name: "Cannon" },
  { id: "cannabis", icon: "faCannabis", slug: "fa-cannabis", name: "Cannabis" },
  { id: "candy-corn", icon: "faCandyCorn", slug: "fa-candy-corn", name: "Candy Corn" },
  { id: "candy-cane", icon: "faCandyCane", slug: "fa-candy-cane", name: "Candy Cane" },
  { id: "candy-bar", icon: "faCandyBar", slug: "fa-candy-bar", name: "Candy Bar" },
  { id: "candy", icon: "faCandy", slug: "fa-candy", name: "Candy" },
  { id: "candle-holder", icon: "faCandleHolder", slug: "fa-candle-holder", name: "Candle Holder" },
  { id: "can-food", icon: "faCanFood", slug: "fa-can-food", name: "Can Food" },
  { id: "campground", icon: "faCampground", slug: "fa-campground", name: "Campground" },
  { id: "camera-web-slash", icon: "faCameraWebSlash", slug: "fa-camera-web-slash", name: "Camera Web Slash" },
  { id: "camera-web", icon: "faCameraWeb", slug: "fa-camera-web", name: "Camera Web" },
  { id: "camera-viewfinder", icon: "faCameraViewfinder", slug: "fa-camera-viewfinder", name: "Camera Viewfinder" },
  { id: "camera-slash", icon: "faCameraSlash", slug: "fa-camera-slash", name: "Camera Slash" },
  { id: "camera-security", icon: "faCameraSecurity", slug: "fa-camera-security", name: "Camera Security" },
  { id: "camera-rotate", icon: "faCameraRotate", slug: "fa-camera-rotate", name: "Camera Rotate" },
  { id: "camera-polaroid", icon: "faCameraPolaroid", slug: "fa-camera-polaroid", name: "Camera Polaroid" },
  { id: "camera-cctv", icon: "faCameraCctv", slug: "fa-camera-cctv", name: "Camera Cctv" },
  { id: "camcorder", icon: "faCamcorder", slug: "fa-camcorder", name: "Camcorder" },
  { id: "calendars", icon: "faCalendars", slug: "fa-calendars", name: "Calendars" },
  { id: "calendar-xmark", icon: "faCalendarXmark", slug: "fa-calendar-xmark", name: "Calendar Xmark" },
  { id: "calendar-users", icon: "faCalendarUsers", slug: "fa-calendar-users", name: "Calendar Users" },
  { id: "calendar-star", icon: "faCalendarStar", slug: "fa-calendar-star", name: "Calendar Star" },
  { id: "calendar-range", icon: "faCalendarRange", slug: "fa-calendar-range", name: "Calendar Range" },
  { id: "calendar-plus", icon: "faCalendarPlus", slug: "fa-calendar-plus", name: "Calendar Plus" },
  { id: "calendar-pen", icon: "faCalendarPen", slug: "fa-calendar-pen", name: "Calendar Pen" },
  { id: "calendar-minus", icon: "faCalendarMinus", slug: "fa-calendar-minus", name: "Calendar Minus" },
  { id: "calendar-lines-pen", icon: "faCalendarLinesPen", slug: "fa-calendar-lines-pen", name: "Calendar Lines Pen" },
  { id: "calendar-lines", icon: "faCalendarLines", slug: "fa-calendar-lines", name: "Calendar Lines" },
  { id: "calendar-image", icon: "faCalendarImage", slug: "fa-calendar-image", name: "Calendar Image" },
  { id: "calendar-heart", icon: "faCalendarHeart", slug: "fa-calendar-heart", name: "Calendar Heart" },
  { id: "calendar-exclamation", icon: "faCalendarExclamation", slug: "fa-calendar-exclamation", name: "Calendar Exclamation" },
  { id: "calendar-day", icon: "faCalendarDay", slug: "fa-calendar-day", name: "Calendar Day" },
  { id: "calendar-clock", icon: "faCalendarClock", slug: "fa-calendar-clock", name: "Calendar Clock" },
  { id: "calendar-circle-user", icon: "faCalendarCircleUser", slug: "fa-calendar-circle-user", name: "Calendar Circle User" },
  { id: "calendar-circle-plus", icon: "faCalendarCirclePlus", slug: "fa-calendar-circle-plus", name: "Calendar Circle Plus" },
  { id: "calendar-circle-minus", icon: "faCalendarCircleMinus", slug: "fa-calendar-circle-minus", name: "Calendar Circle Minus" },
  { id: "calendar-circle-exclamation", icon: "faCalendarCircleExclamation", slug: "fa-calendar-circle-exclamation", name: "Calendar Circle Exclamation" },
  { id: "calendar-check", icon: "faCalendarCheck", slug: "fa-calendar-check", name: "Calendar Check" },
  { id: "calendar-arrow-up", icon: "faCalendarArrowUp", slug: "fa-calendar-arrow-up", name: "Calendar Arrow Up" },
  { id: "calendar-arrow-down", icon: "faCalendarArrowDown", slug: "fa-calendar-arrow-down", name: "Calendar Arrow Down" },
  { id: "calculator-simple", icon: "faCalculatorSimple", slug: "fa-calculator-simple", name: "Calculator Simple" },
  { id: "cake-slice", icon: "faCakeSlice", slug: "fa-cake-slice", name: "Cake Slice" },
  { id: "cake-candles", icon: "faCakeCandles", slug: "fa-cake-candles", name: "Cake Candles" },
  { id: "caduceus", icon: "faCaduceus", slug: "fa-caduceus", name: "Caduceus" },
  { id: "cactus", icon: "faCactus", slug: "fa-cactus", name: "Cactus" },
  { id: "cabinet-filing", icon: "faCabinetFiling", slug: "fa-cabinet-filing", name: "Cabinet Filing" },
  { id: "cabin", icon: "faCabin", slug: "fa-cabin", name: "Cabin" },
  { id: "c", icon: "faC", slug: "fa-c", name: "C" },
  { id: "butter", icon: "faButter", slug: "fa-butter", name: "Butter" },
  { id: "bus-simple", icon: "faBusSimple", slug: "fa-bus-simple", name: "Bus Simple" },
  { id: "bus-school", icon: "faBusSchool", slug: "fa-bus-school", name: "Bus School" },
  { id: "bus", icon: "faBus", slug: "fa-bus", name: "Bus" },
  { id: "burst", icon: "faBurst", slug: "fa-burst", name: "Burst" },
  { id: "burger-lettuce", icon: "faBurgerLettuce", slug: "fa-burger-lettuce", name: "Burger Lettuce" },
  { id: "burger-glass", icon: "faBurgerGlass", slug: "fa-burger-glass", name: "Burger Glass" },
  { id: "burger-fries", icon: "faBurgerFries", slug: "fa-burger-fries", name: "Burger Fries" },
  { id: "burger-cheese", icon: "faBurgerCheese", slug: "fa-burger-cheese", name: "Burger Cheese" },
  { id: "burger", icon: "faBurger", slug: "fa-burger", name: "Burger" },
  { id: "buoy-mooring", icon: "faBuoyMooring", slug: "fa-buoy-mooring", name: "Buoy Mooring" },
  { id: "buoy", icon: "faBuoy", slug: "fa-buoy", name: "Buoy" },
  { id: "bullseye-arrow", icon: "faBullseyeArrow", slug: "fa-bullseye-arrow", name: "Bullseye Arrow" },
  { id: "bullhorn", icon: "faBullhorn", slug: "fa-bullhorn", name: "Bullhorn" },
  { id: "bulldozer", icon: "faBulldozer", slug: "fa-bulldozer", name: "Bulldozer" },
  { id: "buildings", icon: "faBuildings", slug: "fa-buildings", name: "Buildings" },
  { id: "building-wheat", icon: "faBuildingWheat", slug: "fa-building-wheat", name: "Building Wheat" },
  { id: "building-user", icon: "faBuildingUser", slug: "fa-building-user", name: "Building User" },
  { id: "building-un", icon: "faBuildingUn", slug: "fa-building-un", name: "Building Un" },
  { id: "building-shield", icon: "faBuildingShield", slug: "fa-building-shield", name: "Building Shield" },
  { id: "building-ngo", icon: "faBuildingNgo", slug: "fa-building-ngo", name: "Building Ngo" },
  { id: "building-memo", icon: "faBuildingMemo", slug: "fa-building-memo", name: "Building Memo" },
  { id: "building-magnifying-glass", icon: "faBuildingMagnifyingGlass", slug: "fa-building-magnifying-glass", name: "Building Magnifying Glass" },
  { id: "building-lock", icon: "faBuildingLock", slug: "fa-building-lock", name: "Building Lock" },
  { id: "building-flag", icon: "faBuildingFlag", slug: "fa-building-flag", name: "Building Flag" },
  { id: "building-columns", icon: "faBuildingColumns", slug: "fa-building-columns", name: "Building Columns" },
  { id: "building-circle-xmark", icon: "faBuildingCircleXmark", slug: "fa-building-circle-xmark", name: "Building Circle Xmark" },
  { id: "building-circle-exclamation", icon: "faBuildingCircleExclamation", slug: "fa-building-circle-exclamation", name: "Building Circle Exclamation" },
  { id: "building-circle-check", icon: "faBuildingCircleCheck", slug: "fa-building-circle-check", name: "Building Circle Check" },
  { id: "building-circle-arrow-right", icon: "faBuildingCircleArrowRight", slug: "fa-building-circle-arrow-right", name: "Building Circle Arrow Right" },
  { id: "bugs", icon: "faBugs", slug: "fa-bugs", name: "Bugs" },
  { id: "bug-slash", icon: "faBugSlash", slug: "fa-bug-slash", name: "Bug Slash" },
  { id: "bucket", icon: "faBucket", slug: "fa-bucket", name: "Bucket" },
  { id: "browsers", icon: "faBrowsers", slug: "fa-browsers", name: "Browsers" },
  { id: "broom-wide", icon: "faBroomWide", slug: "fa-broom-wide", name: "Broom Wide" },
  { id: "broom-ball", icon: "faBroomBall", slug: "fa-broom-ball", name: "Broom Ball" },
  { id: "broom", icon: "faBroom", slug: "fa-broom", name: "Broom" },
  { id: "broccoli", icon: "faBroccoli", slug: "fa-broccoli", name: "Broccoli" },
  { id: "bring-front", icon: "faBringFront", slug: "fa-bring-front", name: "Bring Front" },
  { id: "bring-forward", icon: "faBringForward", slug: "fa-bring-forward", name: "Bring Forward" },
  { id: "brightness-low", icon: "faBrightnessLow", slug: "fa-brightness-low", name: "Brightness Low" },
  { id: "brightness", icon: "faBrightness", slug: "fa-brightness", name: "Brightness" },
  { id: "briefcase-medical", icon: "faBriefcaseMedical", slug: "fa-briefcase-medical", name: "Briefcase Medical" },
  { id: "briefcase-blank", icon: "faBriefcaseBlank", slug: "fa-briefcase-blank", name: "Briefcase Blank" },
  { id: "briefcase-arrow-right", icon: "faBriefcaseArrowRight", slug: "fa-briefcase-arrow-right", name: "Briefcase Arrow Right" },
  { id: "bridge-water", icon: "faBridgeWater", slug: "fa-bridge-water", name: "Bridge Water" },
  { id: "bridge-suspension", icon: "faBridgeSuspension", slug: "fa-bridge-suspension", name: "Bridge Suspension" },
  { id: "bridge-lock", icon: "faBridgeLock", slug: "fa-bridge-lock", name: "Bridge Lock" },
  { id: "bridge-circle-xmark", icon: "faBridgeCircleXmark", slug: "fa-bridge-circle-xmark", name: "Bridge Circle Xmark" },
  { id: "bridge-circle-exclamation", icon: "faBridgeCircleExclamation", slug: "fa-bridge-circle-exclamation", name: "Bridge Circle Exclamation" },
  { id: "bridge-circle-check", icon: "faBridgeCircleCheck", slug: "fa-bridge-circle-check", name: "Bridge Circle Check" },
  { id: "bridge", icon: "faBridge", slug: "fa-bridge", name: "Bridge" },
  { id: "bread-slice-butter", icon: "faBreadSliceButter", slug: "fa-bread-slice-butter", name: "Bread Slice Butter" },
  { id: "bread-slice", icon: "faBreadSlice", slug: "fa-bread-slice", name: "Bread Slice" },
  { id: "bread-loaf", icon: "faBreadLoaf", slug: "fa-bread-loaf", name: "Bread Loaf" },
  { id: "brazilian-real-sign", icon: "faBrazilianRealSign", slug: "fa-brazilian-real-sign", name: "Brazilian Real Sign" },
  { id: "brake-warning", icon: "faBrakeWarning", slug: "fa-brake-warning", name: "Brake Warning" },
  { id: "brain-circuit", icon: "faBrainCircuit", slug: "fa-brain-circuit", name: "Brain Circuit" },
  { id: "brain-arrow-curved-right", icon: "faBrainArrowCurvedRight", slug: "fa-brain-arrow-curved-right", name: "Brain Arrow Curved Right" },
  { id: "braille", icon: "faBraille", slug: "fa-braille", name: "Braille" },
  { id: "brackets-square", icon: "faBracketsSquare", slug: "fa-brackets-square", name: "Brackets Square" },
  { id: "brackets-round", icon: "faBracketsRound", slug: "fa-brackets-round", name: "Brackets Round" },
  { id: "bracket-square-right", icon: "faBracketSquareRight", slug: "fa-bracket-square-right", name: "Bracket Square Right" },
  { id: "bracket-square", icon: "faBracketSquare", slug: "fa-bracket-square", name: "Bracket Square" },
  { id: "bracket-round-right", icon: "faBracketRoundRight", slug: "fa-bracket-round-right", name: "Bracket Round Right" },
  { id: "bracket-round", icon: "faBracketRound", slug: "fa-bracket-round", name: "Bracket Round" },
  { id: "bracket-curly-right", icon: "faBracketCurlyRight", slug: "fa-bracket-curly-right", name: "Bracket Curly Right" },
  { id: "bracket-curly", icon: "faBracketCurly", slug: "fa-bracket-curly", name: "Bracket Curly" },
  { id: "boxing-glove", icon: "faBoxingGlove", slug: "fa-boxing-glove", name: "Boxing Glove" },
  { id: "boxes-stacked", icon: "faBoxesStacked", slug: "fa-boxes-stacked", name: "Boxes Stacked" },
  { id: "boxes-packing", icon: "faBoxesPacking", slug: "fa-boxes-packing", name: "Boxes Packing" },
  { id: "box-tissue", icon: "faBoxTissue", slug: "fa-box-tissue", name: "Box Tissue" },
  { id: "box-taped", icon: "faBoxTaped", slug: "fa-box-taped", name: "Box Taped" },
  { id: "box-open-full", icon: "faBoxOpenFull", slug: "fa-box-open-full", name: "Box Open Full" },
  { id: "box-open", icon: "faBoxOpen", slug: "fa-box-open", name: "Box Open" },
  { id: "box-heart", icon: "faBoxHeart", slug: "fa-box-heart", name: "Box Heart" },
  { id: "box-dollar", icon: "faBoxDollar", slug: "fa-box-dollar", name: "Box Dollar" },
  { id: "box-circle-check", icon: "faBoxCircleCheck", slug: "fa-box-circle-check", name: "Box Circle Check" },
  { id: "box-check", icon: "faBoxCheck", slug: "fa-box-check", name: "Box Check" },
  { id: "box-ballot", icon: "faBoxBallot", slug: "fa-box-ballot", name: "Box Ballot" },
  { id: "box-archive", icon: "faBoxArchive", slug: "fa-box-archive", name: "Box Archive" },
  { id: "box", icon: "faBox", slug: "fa-box", name: "Box" },
  { id: "bowling-pins", icon: "faBowlingPins", slug: "fa-bowling-pins", name: "Bowling Pins" },
  { id: "bowling-ball-pin", icon: "faBowlingBallPin", slug: "fa-bowling-ball-pin", name: "Bowling Ball Pin" },
  { id: "bowling-ball", icon: "faBowlingBall", slug: "fa-bowling-ball", name: "Bowling Ball" },
  { id: "bowl-spoon", icon: "faBowlSpoon", slug: "fa-bowl-spoon", name: "Bowl Spoon" },
  { id: "bowl-soft-serve", icon: "faBowlSoftServe", slug: "fa-bowl-soft-serve", name: "Bowl Soft Serve" },
  { id: "bowl-scoops", icon: "faBowlScoops", slug: "fa-bowl-scoops", name: "Bowl Scoops" },
  { id: "bowl-scoop", icon: "faBowlScoop", slug: "fa-bowl-scoop", name: "Bowl Scoop" },
  { id: "bowl-rice", icon: "faBowlRice", slug: "fa-bowl-rice", name: "Bowl Rice" },
  { id: "bowl-hot", icon: "faBowlHot", slug: "fa-bowl-hot", name: "Bowl Hot" },
  { id: "bowl-food", icon: "faBowlFood", slug: "fa-bowl-food", name: "Bowl Food" },
  { id: "bowl-chopsticks", icon: "faBowlChopsticks", slug: "fa-bowl-chopsticks", name: "Bowl Chopsticks" },
  { id: "bow-arrow", icon: "faBowArrow", slug: "fa-bow-arrow", name: "Bow Arrow" },
  { id: "bottle-water", icon: "faBottleWater", slug: "fa-bottle-water", name: "Bottle Water" },
  { id: "bottle-droplet", icon: "faBottleDroplet", slug: "fa-bottle-droplet", name: "Bottle Droplet" },
  { id: "bottle-baby", icon: "faBottleBaby", slug: "fa-bottle-baby", name: "Bottle Baby" },
  { id: "bore-hole", icon: "faBoreHole", slug: "fa-bore-hole", name: "Bore Hole" },
  { id: "border-top-left", icon: "faBorderTopLeft", slug: "fa-border-top-left", name: "Border Top Left" },
  { id: "border-right", icon: "faBorderRight", slug: "fa-border-right", name: "Border Right" },
  { id: "border-outer", icon: "faBorderOuter", slug: "fa-border-outer", name: "Border Outer" },
  { id: "border-left", icon: "faBorderLeft", slug: "fa-border-left", name: "Border Left" },
  { id: "border-inner", icon: "faBorderInner", slug: "fa-border-inner", name: "Border Inner" },
  { id: "border-center-v", icon: "faBorderCenterV", slug: "fa-border-center-v", name: "Border Center V" },
  { id: "border-center-h", icon: "faBorderCenterH", slug: "fa-border-center-h", name: "Border Center H" },
  { id: "border-bottom-right", icon: "faBorderBottomRight", slug: "fa-border-bottom-right", name: "Border Bottom Right" },
  { id: "border-bottom", icon: "faBorderBottom", slug: "fa-border-bottom", name: "Border Bottom" },
  { id: "border-all", icon: "faBorderAll", slug: "fa-border-all", name: "Border All" },
  { id: "boot-heeled", icon: "faBootHeeled", slug: "fa-boot-heeled", name: "Boot Heeled" },
  { id: "boot", icon: "faBoot", slug: "fa-boot", name: "Boot" },
  { id: "boombox", icon: "faBoombox", slug: "fa-boombox", name: "Boombox" },
  { id: "books-medical", icon: "faBooksMedical", slug: "fa-books-medical", name: "Books Medical" },
  { id: "bookmark-slash", icon: "faBookmarkSlash", slug: "fa-bookmark-slash", name: "Bookmark Slash" },
  { id: "book-user", icon: "faBookUser", slug: "fa-book-user", name: "Book User" },
  { id: "book-tanakh", icon: "faBookTanakh", slug: "fa-book-tanakh", name: "Book Tanakh" },
  { id: "book-sparkles", icon: "faBookSparkles", slug: "fa-book-sparkles", name: "Book Sparkles" },
  { id: "book-skull", icon: "faBookSkull", slug: "fa-book-skull", name: "Book Skull" },
  { id: "book-section", icon: "faBookSection", slug: "fa-book-section", name: "Book Section" },
  { id: "book-quran", icon: "faBookQuran", slug: "fa-book-quran", name: "Book Quran" },
  { id: "book-open-reader", icon: "faBookOpenReader", slug: "fa-book-open-reader", name: "Book Open Reader" },
  { id: "book-open-cover", icon: "faBookOpenCover", slug: "fa-book-open-cover", name: "Book Open Cover" },
  { id: "book-open", icon: "faBookOpen", slug: "fa-book-open", name: "Book Open" },
  { id: "book-medical", icon: "faBookMedical", slug: "fa-book-medical", name: "Book Medical" },
  { id: "book-journal-whills", icon: "faBookJournalWhills", slug: "fa-book-journal-whills", name: "Book Journal Whills" },
  { id: "book-heart", icon: "faBookHeart", slug: "fa-book-heart", name: "Book Heart" },
  { id: "book-font", icon: "faBookFont", slug: "fa-book-font", name: "Book Font" },
  { id: "book-copy", icon: "faBookCopy", slug: "fa-book-copy", name: "Book Copy" },
  { id: "book-circle-arrow-up", icon: "faBookCircleArrowUp", slug: "fa-book-circle-arrow-up", name: "Book Circle Arrow Up" },
  { id: "book-circle-arrow-right", icon: "faBookCircleArrowRight", slug: "fa-book-circle-arrow-right", name: "Book Circle Arrow Right" },
  { id: "book-bookmark", icon: "faBookBookmark", slug: "fa-book-bookmark", name: "Book Bookmark" },
  { id: "book-blank", icon: "faBookBlank", slug: "fa-book-blank", name: "Book Blank" },
  { id: "book-bible", icon: "faBookBible", slug: "fa-book-bible", name: "Book Bible" },
  { id: "book-atlas", icon: "faBookAtlas", slug: "fa-book-atlas", name: "Book Atlas" },
  { id: "book-arrow-up", icon: "faBookArrowUp", slug: "fa-book-arrow-up", name: "Book Arrow Up" },
  { id: "book-arrow-right", icon: "faBookArrowRight", slug: "fa-book-arrow-right", name: "Book Arrow Right" },
  { id: "bong", icon: "faBong", slug: "fa-bong", name: "Bong" },
  { id: "bone-break", icon: "faBoneBreak", slug: "fa-bone-break", name: "Bone Break" },
  { id: "bone", icon: "faBone", slug: "fa-bone", name: "Bone" },
  { id: "bolt-slash", icon: "faBoltSlash", slug: "fa-bolt-slash", name: "Bolt Slash" },
  { id: "bolt-lightning", icon: "faBoltLightning", slug: "fa-bolt-lightning", name: "Bolt Lightning" },
  { id: "bolt-auto", icon: "faBoltAuto", slug: "fa-bolt-auto", name: "Bolt Auto" },
  { id: "bold", icon: "faBold", slug: "fa-bold", name: "Bold" },
  { id: "bluetooth", icon: "faBluetooth", slug: "fa-bluetooth", name: "Bluetooth" },
  { id: "blueberries", icon: "faBlueberries", slug: "fa-blueberries", name: "Blueberries" },
  { id: "block-quote", icon: "faBlockQuote", slug: "fa-block-quote", name: "Block Quote" },
  { id: "block-brick-fire", icon: "faBlockBrickFire", slug: "fa-block-brick-fire", name: "Block Brick Fire" },
  { id: "block-brick", icon: "faBlockBrick", slug: "fa-block-brick", name: "Block Brick" },
  { id: "block", icon: "faBlock", slug: "fa-block", name: "Block" },
  { id: "blinds-open", icon: "faBlindsOpen", slug: "fa-blinds-open", name: "Blinds Open" },
  { id: "blinds", icon: "faBlinds", slug: "fa-blinds", name: "Blinds" },
  { id: "blender-phone", icon: "faBlenderPhone", slug: "fa-blender-phone", name: "Blender Phone" },
  { id: "blender", icon: "faBlender", slug: "fa-blender", name: "Blender" },
  { id: "blanket-fire", icon: "faBlanketFire", slug: "fa-blanket-fire", name: "Blanket Fire" },
  { id: "bitcoin-sign", icon: "faBitcoinSign", slug: "fa-bitcoin-sign", name: "Bitcoin Sign" },
  { id: "biohazard", icon: "faBiohazard", slug: "fa-biohazard", name: "Biohazard" },
  { id: "binary-slash", icon: "faBinarySlash", slug: "fa-binary-slash", name: "Binary Slash" },
  { id: "binary-lock", icon: "faBinaryLock", slug: "fa-binary-lock", name: "Binary Lock" },
  { id: "binary-circle-check", icon: "faBinaryCircleCheck", slug: "fa-binary-circle-check", name: "Binary Circle Check" },
  { id: "binary", icon: "faBinary", slug: "fa-binary", name: "Binary" },
  { id: "bin-recycle", icon: "faBinRecycle", slug: "fa-bin-recycle", name: "Bin Recycle" },
  { id: "bin-bottles-recycle", icon: "faBinBottlesRecycle", slug: "fa-bin-bottles-recycle", name: "Bin Bottles Recycle" },
  { id: "bin-bottles", icon: "faBinBottles", slug: "fa-bin-bottles", name: "Bin Bottles" },
  { id: "billboard", icon: "faBillboard", slug: "fa-billboard", name: "Billboard" },
  { id: "bezier-curve", icon: "faBezierCurve", slug: "fa-bezier-curve", name: "Bezier Curve" },
  { id: "bench-tree", icon: "faBenchTree", slug: "fa-bench-tree", name: "Bench Tree" },
  { id: "bells", icon: "faBells", slug: "fa-bells", name: "Bells" },
  { id: "bell-slash", icon: "faBellSlash", slug: "fa-bell-slash", name: "Bell Slash" },
  { id: "bell-school-slash", icon: "faBellSchoolSlash", slug: "fa-bell-school-slash", name: "Bell School Slash" },
  { id: "bell-school", icon: "faBellSchool", slug: "fa-bell-school", name: "Bell School" },
  { id: "bell-ring", icon: "faBellRing", slug: "fa-bell-ring", name: "Bell Ring" },
  { id: "bell-plus", icon: "faBellPlus", slug: "fa-bell-plus", name: "Bell Plus" },
  { id: "bell-on", icon: "faBellOn", slug: "fa-bell-on", name: "Bell On" },
  { id: "bell-exclamation", icon: "faBellExclamation", slug: "fa-bell-exclamation", name: "Bell Exclamation" },
  { id: "bell-concierge", icon: "faBellConcierge", slug: "fa-bell-concierge", name: "Bell Concierge" },
  { id: "beer-mug-empty", icon: "faBeerMugEmpty", slug: "fa-beer-mug-empty", name: "Beer Mug Empty" },
  { id: "beer-mug", icon: "faBeerMug", slug: "fa-beer-mug", name: "Beer Mug" },
  { id: "bee", icon: "faBee", slug: "fa-bee", name: "Bee" },
  { id: "bed-pulse", icon: "faBedPulse", slug: "fa-bed-pulse", name: "Bed Pulse" },
  { id: "bed-empty", icon: "faBedEmpty", slug: "fa-bed-empty", name: "Bed Empty" },
  { id: "bed-bunk", icon: "faBedBunk", slug: "fa-bed-bunk", name: "Bed Bunk" },
  { id: "bed", icon: "faBed", slug: "fa-bed", name: "Bed" },
  { id: "battery-three-quarters", icon: "faBatteryThreeQuarters", slug: "fa-battery-three-quarters", name: "Battery Three Quarters" },
  { id: "battery-slash", icon: "faBatterySlash", slug: "fa-battery-slash", name: "Battery Slash" },
  { id: "battery-quarter", icon: "faBatteryQuarter", slug: "fa-battery-quarter", name: "Battery Quarter" },
  { id: "battery-low", icon: "faBatteryLow", slug: "fa-battery-low", name: "Battery Low" },
  { id: "battery-half", icon: "faBatteryHalf", slug: "fa-battery-half", name: "Battery Half" },
  { id: "battery-full", icon: "faBatteryFull", slug: "fa-battery-full", name: "Battery Full" },
  { id: "battery-exclamation", icon: "faBatteryExclamation", slug: "fa-battery-exclamation", name: "Battery Exclamation" },
  { id: "battery-empty", icon: "faBatteryEmpty", slug: "fa-battery-empty", name: "Battery Empty" },
  { id: "battery-bolt", icon: "faBatteryBolt", slug: "fa-battery-bolt", name: "Battery Bolt" },
  { id: "basketball", icon: "faBasketball", slug: "fa-basketball", name: "Basketball" },
  { id: "basket-shopping-simple", icon: "faBasketShoppingSimple", slug: "fa-basket-shopping-simple", name: "Basket Shopping Simple" },
  { id: "basket-shopping-plus", icon: "faBasketShoppingPlus", slug: "fa-basket-shopping-plus", name: "Basket Shopping Plus" },
  { id: "basket-shopping-minus", icon: "faBasketShoppingMinus", slug: "fa-basket-shopping-minus", name: "Basket Shopping Minus" },
  { id: "basket-shopping", icon: "faBasketShopping", slug: "fa-basket-shopping", name: "Basket Shopping" },
  { id: "baseball-bat-ball", icon: "faBaseballBatBall", slug: "fa-baseball-bat-ball", name: "Baseball Bat Ball" },
  { id: "baseball", icon: "faBaseball", slug: "fa-baseball", name: "Baseball" },
  { id: "bars-staggered", icon: "faBarsStaggered", slug: "fa-bars-staggered", name: "Bars Staggered" },
  { id: "bars-sort", icon: "faBarsSort", slug: "fa-bars-sort", name: "Bars Sort" },
  { id: "bars-progress", icon: "faBarsProgress", slug: "fa-bars-progress", name: "Bars Progress" },
  { id: "bars-filter", icon: "faBarsFilter", slug: "fa-bars-filter", name: "Bars Filter" },
  { id: "barcode-scan", icon: "faBarcodeScan", slug: "fa-barcode-scan", name: "Barcode Scan" },
  { id: "barcode-read", icon: "faBarcodeRead", slug: "fa-barcode-read", name: "Barcode Read" },
  { id: "banjo", icon: "faBanjo", slug: "fa-banjo", name: "Banjo" },
  { id: "bangladeshi-taka-sign", icon: "faBangladeshiTakaSign", slug: "fa-bangladeshi-taka-sign", name: "Bangladeshi Taka Sign" },
  { id: "bandage", icon: "faBandage", slug: "fa-bandage", name: "Bandage" },
  { id: "banana", icon: "faBanana", slug: "fa-banana", name: "Banana" },
  { id: "ban-smoking", icon: "faBanSmoking", slug: "fa-ban-smoking", name: "Ban Smoking" },
  { id: "ban-parking", icon: "faBanParking", slug: "fa-ban-parking", name: "Ban Parking" },
  { id: "ban-bug", icon: "faBanBug", slug: "fa-ban-bug", name: "Ban Bug" },
  { id: "ban", icon: "faBan", slug: "fa-ban", name: "Ban" },
  { id: "ballot", icon: "faBallot", slug: "fa-ballot", name: "Ballot" },
  { id: "balloon", icon: "faBalloon", slug: "fa-balloon", name: "Balloon" },
  { id: "ball-pile", icon: "faBallPile", slug: "fa-ball-pile", name: "Ball Pile" },
  { id: "baht-sign", icon: "faBahtSign", slug: "fa-baht-sign", name: "Baht Sign" },
  { id: "bahai", icon: "faBahai", slug: "fa-bahai", name: "Bahai" },
  { id: "baguette", icon: "faBaguette", slug: "fa-baguette", name: "Baguette" },
  { id: "bags-shopping", icon: "faBagsShopping", slug: "fa-bags-shopping", name: "Bags Shopping" },
  { id: "bagel", icon: "faBagel", slug: "fa-bagel", name: "Bagel" },
  { id: "bag-shopping-plus", icon: "faBagShoppingPlus", slug: "fa-bag-shopping-plus", name: "Bag Shopping Plus" },
  { id: "bag-shopping-minus", icon: "faBagShoppingMinus", slug: "fa-bag-shopping-minus", name: "Bag Shopping Minus" },
  { id: "bag-seedling", icon: "faBagSeedling", slug: "fa-bag-seedling", name: "Bag Seedling" },
  { id: "badminton", icon: "faBadminton", slug: "fa-badminton", name: "Badminton" },
  { id: "badger-honey", icon: "faBadgerHoney", slug: "fa-badger-honey", name: "Badger Honey" },
  { id: "badge-sheriff", icon: "faBadgeSheriff", slug: "fa-badge-sheriff", name: "Badge Sheriff" },
  { id: "badge-percent", icon: "faBadgePercent", slug: "fa-badge-percent", name: "Badge Percent" },
  { id: "badge-dollar", icon: "faBadgeDollar", slug: "fa-badge-dollar", name: "Badge Dollar" },
  { id: "bacterium", icon: "faBacterium", slug: "fa-bacterium", name: "Bacterium" },
  { id: "bacteria", icon: "faBacteria", slug: "fa-bacteria", name: "Bacteria" },
  { id: "bacon", icon: "faBacon", slug: "fa-bacon", name: "Bacon" },
  { id: "backward-step", icon: "faBackwardStep", slug: "fa-backward-step", name: "Backward Step" },
  { id: "backward-fast", icon: "faBackwardFast", slug: "fa-backward-fast", name: "Backward Fast" },
  { id: "backpack", icon: "faBackpack", slug: "fa-backpack", name: "Backpack" },
  { id: "baby-carriage", icon: "faBabyCarriage", slug: "fa-baby-carriage", name: "Baby Carriage" },
  { id: "b", icon: "faB", slug: "fa-b", name: "B" },
  { id: "axe-battle", icon: "faAxeBattle", slug: "fa-axe-battle", name: "Axe Battle" },
  { id: "axe", icon: "faAxe", slug: "fa-axe", name: "Axe" },
  { id: "award-simple", icon: "faAwardSimple", slug: "fa-award-simple", name: "Award Simple" },
  { id: "avocado", icon: "faAvocado", slug: "fa-avocado", name: "Avocado" },
  { id: "austral-sign", icon: "faAustralSign", slug: "fa-austral-sign", name: "Austral Sign" },
  { id: "audio-description-slash", icon: "faAudioDescriptionSlash", slug: "fa-audio-description-slash", name: "Audio Description Slash" },
  { id: "atom", icon: "faAtom", slug: "fa-atom", name: "Atom" },
  { id: "at", icon: "faAt", slug: "fa-at", name: "At" },
  { id: "asterisk", icon: "faAsterisk", slug: "fa-asterisk", name: "Asterisk" },
  { id: "arrows-up-to-line", icon: "faArrowsUpToLine", slug: "fa-arrows-up-to-line", name: "Arrows Up To Line" },
  { id: "arrows-up-down-left-right", icon: "faArrowsUpDownLeftRight", slug: "fa-arrows-up-down-left-right", name: "Arrows Up Down Left Right" },
  { id: "arrows-up-down", icon: "faArrowsUpDown", slug: "fa-arrows-up-down", name: "Arrows Up Down" },
  { id: "arrows-turn-to-dots", icon: "faArrowsTurnToDots", slug: "fa-arrows-turn-to-dots", name: "Arrows Turn To Dots" },
  { id: "arrows-turn-right", icon: "faArrowsTurnRight", slug: "fa-arrows-turn-right", name: "Arrows Turn Right" },
  { id: "arrows-to-line", icon: "faArrowsToLine", slug: "fa-arrows-to-line", name: "Arrows To Line" },
  { id: "arrows-to-eye", icon: "faArrowsToEye", slug: "fa-arrows-to-eye", name: "Arrows To Eye" },
  { id: "arrows-to-dotted-line", icon: "faArrowsToDottedLine", slug: "fa-arrows-to-dotted-line", name: "Arrows To Dotted Line" },
  { id: "arrows-to-dot", icon: "faArrowsToDot", slug: "fa-arrows-to-dot", name: "Arrows To Dot" },
  { id: "arrows-to-circle", icon: "faArrowsToCircle", slug: "fa-arrows-to-circle", name: "Arrows To Circle" },
  { id: "arrows-split-up-and-left", icon: "faArrowsSplitUpAndLeft", slug: "fa-arrows-split-up-and-left", name: "Arrows Split Up And Left" },
  { id: "arrows-spin", icon: "faArrowsSpin", slug: "fa-arrows-spin", name: "Arrows Spin" },
  { id: "arrows-rotate-reverse", icon: "faArrowsRotateReverse", slug: "fa-arrows-rotate-reverse", name: "Arrows Rotate Reverse" },
  { id: "arrows-rotate", icon: "faArrowsRotate", slug: "fa-arrows-rotate", name: "Arrows Rotate" },
  { id: "arrows-retweet", icon: "faArrowsRetweet", slug: "fa-arrows-retweet", name: "Arrows Retweet" },
  { id: "arrows-repeat-1", icon: "faArrowsRepeat1", slug: "fa-arrows-repeat-1", name: "Arrows Repeat 1" },
  { id: "arrows-repeat", icon: "faArrowsRepeat", slug: "fa-arrows-repeat", name: "Arrows Repeat" },
  { id: "arrows-minimize", icon: "faArrowsMinimize", slug: "fa-arrows-minimize", name: "Arrows Minimize" },
  { id: "arrows-maximize", icon: "faArrowsMaximize", slug: "fa-arrows-maximize", name: "Arrows Maximize" },
  { id: "arrows-left-right-to-line", icon: "faArrowsLeftRightToLine", slug: "fa-arrows-left-right-to-line", name: "Arrows Left Right To Line" },
  { id: "arrows-left-right", icon: "faArrowsLeftRight", slug: "fa-arrows-left-right", name: "Arrows Left Right" },
  { id: "arrows-from-line", icon: "faArrowsFromLine", slug: "fa-arrows-from-line", name: "Arrows From Line" },
  { id: "arrows-from-dotted-line", icon: "faArrowsFromDottedLine", slug: "fa-arrows-from-dotted-line", name: "Arrows From Dotted Line" },
  { id: "arrows-down-to-people", icon: "faArrowsDownToPeople", slug: "fa-arrows-down-to-people", name: "Arrows Down To People" },
  { id: "arrows-down-to-line", icon: "faArrowsDownToLine", slug: "fa-arrows-down-to-line", name: "Arrows Down To Line" },
  { id: "arrows-cross", icon: "faArrowsCross", slug: "fa-arrows-cross", name: "Arrows Cross" },
  { id: "arrow-up-z-a", icon: "faArrowUpZA", slug: "fa-arrow-up-z-a", name: "Arrow Up Z A" },
  { id: "arrow-up-wide-short", icon: "faArrowUpWideShort", slug: "fa-arrow-up-wide-short", name: "Arrow Up Wide Short" },
  { id: "arrow-up-triangle-square", icon: "faArrowUpTriangleSquare", slug: "fa-arrow-up-triangle-square", name: "Arrow Up Triangle Square" },
  { id: "arrow-up-to-line", icon: "faArrowUpToLine", slug: "fa-arrow-up-to-line", name: "Arrow Up To Line" },
  { id: "arrow-up-to-dotted-line", icon: "faArrowUpToDottedLine", slug: "fa-arrow-up-to-dotted-line", name: "Arrow Up To Dotted Line" },
  { id: "arrow-up-to-bracket", icon: "faArrowUpToBracket", slug: "fa-arrow-up-to-bracket", name: "Arrow Up To Bracket" },
  { id: "arrow-up-to-arc", icon: "faArrowUpToArc", slug: "fa-arrow-up-to-arc", name: "Arrow Up To Arc" },
  { id: "arrow-up-square-triangle", icon: "faArrowUpSquareTriangle", slug: "fa-arrow-up-square-triangle", name: "Arrow Up Square Triangle" },
  { id: "arrow-up-small-big", icon: "faArrowUpSmallBig", slug: "fa-arrow-up-small-big", name: "Arrow Up Small Big" },
  { id: "arrow-up-short-wide", icon: "faArrowUpShortWide", slug: "fa-arrow-up-short-wide", name: "Arrow Up Short Wide" },
  { id: "arrow-up-right-dots", icon: "faArrowUpRightDots", slug: "fa-arrow-up-right-dots", name: "Arrow Up Right Dots" },
  {
    id: "arrow-up-right-and-arrow-down-left-from-center",
    icon: "faArrowUpRightAndArrowDownLeftFromCenter",
    slug: "fa-arrow-up-right-and-arrow-down-left-from-center",
    name: "Arrow Up Right And Arrow Down Left From Center",
  },
  { id: "arrow-up-right", icon: "faArrowUpRight", slug: "fa-arrow-up-right", name: "Arrow Up Right" },
  { id: "arrow-up-long", icon: "faArrowUpLong", slug: "fa-arrow-up-long", name: "Arrow Up Long" },
  { id: "arrow-up-left-from-circle", icon: "faArrowUpLeftFromCircle", slug: "fa-arrow-up-left-from-circle", name: "Arrow Up Left From Circle" },
  { id: "arrow-up-left", icon: "faArrowUpLeft", slug: "fa-arrow-up-left", name: "Arrow Up Left" },
  { id: "arrow-up-from-water-pump", icon: "faArrowUpFromWaterPump", slug: "fa-arrow-up-from-water-pump", name: "Arrow Up From Water Pump" },
  { id: "arrow-up-from-square", icon: "faArrowUpFromSquare", slug: "fa-arrow-up-from-square", name: "Arrow Up From Square" },
  { id: "arrow-up-from-line", icon: "faArrowUpFromLine", slug: "fa-arrow-up-from-line", name: "Arrow Up From Line" },
  { id: "arrow-up-from-ground-water", icon: "faArrowUpFromGroundWater", slug: "fa-arrow-up-from-ground-water", name: "Arrow Up From Ground Water" },
  { id: "arrow-up-from-dotted-line", icon: "faArrowUpFromDottedLine", slug: "fa-arrow-up-from-dotted-line", name: "Arrow Up From Dotted Line" },
  { id: "arrow-up-from-arc", icon: "faArrowUpFromArc", slug: "fa-arrow-up-from-arc", name: "Arrow Up From Arc" },
  { id: "arrow-up-big-small", icon: "faArrowUpBigSmall", slug: "fa-arrow-up-big-small", name: "Arrow Up Big Small" },
  { id: "arrow-up-arrow-down", icon: "faArrowUpArrowDown", slug: "fa-arrow-up-arrow-down", name: "Arrow Up Arrow Down" },
  { id: "arrow-up-a-z", icon: "faArrowUpAZ", slug: "fa-arrow-up-a-z", name: "Arrow Up A Z" },
  { id: "arrow-up-9-1", icon: "faArrowUp91", slug: "fa-arrow-up-9-1", name: "Arrow Up 9 1" },
  { id: "arrow-up-1-9", icon: "faArrowUp19", slug: "fa-arrow-up-1-9", name: "Arrow Up 1 9" },
  { id: "arrow-turn-up", icon: "faArrowTurnUp", slug: "fa-arrow-turn-up", name: "Arrow Turn Up" },
  { id: "arrow-turn-right", icon: "faArrowTurnRight", slug: "fa-arrow-turn-right", name: "Arrow Turn Right" },
  { id: "arrow-turn-left-up", icon: "faArrowTurnLeftUp", slug: "fa-arrow-turn-left-up", name: "Arrow Turn Left Up" },
  { id: "arrow-turn-left-down", icon: "faArrowTurnLeftDown", slug: "fa-arrow-turn-left-down", name: "Arrow Turn Left Down" },
  { id: "arrow-turn-left", icon: "faArrowTurnLeft", slug: "fa-arrow-turn-left", name: "Arrow Turn Left" },
  { id: "arrow-turn-down-right", icon: "faArrowTurnDownRight", slug: "fa-arrow-turn-down-right", name: "Arrow Turn Down Right" },
  { id: "arrow-turn-down-left", icon: "faArrowTurnDownLeft", slug: "fa-arrow-turn-down-left", name: "Arrow Turn Down Left" },
  { id: "arrow-turn-down", icon: "faArrowTurnDown", slug: "fa-arrow-turn-down", name: "Arrow Turn Down" },
  { id: "arrow-trend-up", icon: "faArrowTrendUp", slug: "fa-arrow-trend-up", name: "Arrow Trend Up" },
  { id: "arrow-trend-down", icon: "faArrowTrendDown", slug: "fa-arrow-trend-down", name: "Arrow Trend Down" },
  { id: "arrow-rotate-right", icon: "faArrowRotateRight", slug: "fa-arrow-rotate-right", name: "Arrow Rotate Right" },
  { id: "arrow-rotate-left", icon: "faArrowRotateLeft", slug: "fa-arrow-rotate-left", name: "Arrow Rotate Left" },
  { id: "arrow-right-to-line", icon: "faArrowRightToLine", slug: "fa-arrow-right-to-line", name: "Arrow Right To Line" },
  { id: "arrow-right-to-city", icon: "faArrowRightToCity", slug: "fa-arrow-right-to-city", name: "Arrow Right To City" },
  { id: "arrow-right-to-bracket", icon: "faArrowRightToBracket", slug: "fa-arrow-right-to-bracket", name: "Arrow Right To Bracket" },
  { id: "arrow-right-to-arc", icon: "faArrowRightToArc", slug: "fa-arrow-right-to-arc", name: "Arrow Right To Arc" },
  { id: "arrow-right-long-to-line", icon: "faArrowRightLongToLine", slug: "fa-arrow-right-long-to-line", name: "Arrow Right Long To Line" },
  { id: "arrow-right-long", icon: "faArrowRightLong", slug: "fa-arrow-right-long", name: "Arrow Right Long" },
  { id: "arrow-right-from-line", icon: "faArrowRightFromLine", slug: "fa-arrow-right-from-line", name: "Arrow Right From Line" },
  { id: "arrow-right-from-bracket", icon: "faArrowRightFromBracket", slug: "fa-arrow-right-from-bracket", name: "Arrow Right From Bracket" },
  { id: "arrow-right-from-arc", icon: "faArrowRightFromArc", slug: "fa-arrow-right-from-arc", name: "Arrow Right From Arc" },
  { id: "arrow-right-arrow-left", icon: "faArrowRightArrowLeft", slug: "fa-arrow-right-arrow-left", name: "Arrow Right Arrow Left" },
  { id: "arrow-progress", icon: "faArrowProgress", slug: "fa-arrow-progress", name: "Arrow Progress" },
  { id: "arrow-pointer", icon: "faArrowPointer", slug: "fa-arrow-pointer", name: "Arrow Pointer" },
  { id: "arrow-left-to-line", icon: "faArrowLeftToLine", slug: "fa-arrow-left-to-line", name: "Arrow Left To Line" },
  { id: "arrow-left-to-bracket", icon: "faArrowLeftToBracket", slug: "fa-arrow-left-to-bracket", name: "Arrow Left To Bracket" },
  { id: "arrow-left-to-arc", icon: "faArrowLeftToArc", slug: "fa-arrow-left-to-arc", name: "Arrow Left To Arc" },
  { id: "arrow-left-long-to-line", icon: "faArrowLeftLongToLine", slug: "fa-arrow-left-long-to-line", name: "Arrow Left Long To Line" },
  { id: "arrow-left-long", icon: "faArrowLeftLong", slug: "fa-arrow-left-long", name: "Arrow Left Long" },
  { id: "arrow-left-from-line", icon: "faArrowLeftFromLine", slug: "fa-arrow-left-from-line", name: "Arrow Left From Line" },
  { id: "arrow-left-from-bracket", icon: "faArrowLeftFromBracket", slug: "fa-arrow-left-from-bracket", name: "Arrow Left From Bracket" },
  { id: "arrow-left-from-arc", icon: "faArrowLeftFromArc", slug: "fa-arrow-left-from-arc", name: "Arrow Left From Arc" },
  { id: "arrow-down-z-a", icon: "faArrowDownZA", slug: "fa-arrow-down-z-a", name: "Arrow Down Z A" },
  { id: "arrow-down-wide-short", icon: "faArrowDownWideShort", slug: "fa-arrow-down-wide-short", name: "Arrow Down Wide Short" },
  { id: "arrow-down-up-lock", icon: "faArrowDownUpLock", slug: "fa-arrow-down-up-lock", name: "Arrow Down Up Lock" },
  { id: "arrow-down-up-across-line", icon: "faArrowDownUpAcrossLine", slug: "fa-arrow-down-up-across-line", name: "Arrow Down Up Across Line" },
  { id: "arrow-down-triangle-square", icon: "faArrowDownTriangleSquare", slug: "fa-arrow-down-triangle-square", name: "Arrow Down Triangle Square" },
  { id: "arrow-down-to-square", icon: "faArrowDownToSquare", slug: "fa-arrow-down-to-square", name: "Arrow Down To Square" },
  { id: "arrow-down-to-dotted-line", icon: "faArrowDownToDottedLine", slug: "fa-arrow-down-to-dotted-line", name: "Arrow Down To Dotted Line" },
  { id: "arrow-down-to-bracket", icon: "faArrowDownToBracket", slug: "fa-arrow-down-to-bracket", name: "Arrow Down To Bracket" },
  { id: "arrow-down-to-arc", icon: "faArrowDownToArc", slug: "fa-arrow-down-to-arc", name: "Arrow Down To Arc" },
  { id: "arrow-down-square-triangle", icon: "faArrowDownSquareTriangle", slug: "fa-arrow-down-square-triangle", name: "Arrow Down Square Triangle" },
  { id: "arrow-down-small-big", icon: "faArrowDownSmallBig", slug: "fa-arrow-down-small-big", name: "Arrow Down Small Big" },
  { id: "arrow-down-short-wide", icon: "faArrowDownShortWide", slug: "fa-arrow-down-short-wide", name: "Arrow Down Short Wide" },
  { id: "arrow-down-right", icon: "faArrowDownRight", slug: "fa-arrow-down-right", name: "Arrow Down Right" },
  { id: "arrow-down-long", icon: "faArrowDownLong", slug: "fa-arrow-down-long", name: "Arrow Down Long" },
  {
    id: "arrow-down-left-and-arrow-up-right-to-center",
    icon: "faArrowDownLeftAndArrowUpRightToCenter",
    slug: "fa-arrow-down-left-and-arrow-up-right-to-center",
    name: "Arrow Down Left And Arrow Up Right To Center",
  },
  { id: "arrow-down-left", icon: "faArrowDownLeft", slug: "fa-arrow-down-left", name: "Arrow Down Left" },
  { id: "arrow-down-from-line", icon: "faArrowDownFromLine", slug: "fa-arrow-down-from-line", name: "Arrow Down From Line" },
  { id: "arrow-down-from-dotted-line", icon: "faArrowDownFromDottedLine", slug: "fa-arrow-down-from-dotted-line", name: "Arrow Down From Dotted Line" },
  { id: "arrow-down-from-bracket", icon: "faArrowDownFromBracket", slug: "fa-arrow-down-from-bracket", name: "Arrow Down From Bracket" },
  { id: "arrow-down-from-arc", icon: "faArrowDownFromArc", slug: "fa-arrow-down-from-arc", name: "Arrow Down From Arc" },
  { id: "arrow-down-big-small", icon: "faArrowDownBigSmall", slug: "fa-arrow-down-big-small", name: "Arrow Down Big Small" },
  { id: "arrow-down-arrow-up", icon: "faArrowDownArrowUp", slug: "fa-arrow-down-arrow-up", name: "Arrow Down Arrow Up" },
  { id: "arrow-down-a-z", icon: "faArrowDownAZ", slug: "fa-arrow-down-a-z", name: "Arrow Down A Z" },
  { id: "arrow-down-9-1", icon: "faArrowDown91", slug: "fa-arrow-down-9-1", name: "Arrow Down 9 1" },
  { id: "arrow-down-1-9", icon: "faArrowDown19", slug: "fa-arrow-down-1-9", name: "Arrow Down 1 9" },
  { id: "archway", icon: "faArchway", slug: "fa-archway", name: "Archway" },
  { id: "apple-whole", icon: "faAppleWhole", slug: "fa-apple-whole", name: "Apple Whole" },
  { id: "apple-core", icon: "faAppleCore", slug: "fa-apple-core", name: "Apple Core" },
  { id: "apostrophe", icon: "faApostrophe", slug: "fa-apostrophe", name: "Apostrophe" },
  { id: "apartment", icon: "faApartment", slug: "fa-apartment", name: "Apartment" },
  { id: "ant", icon: "faAnt", slug: "fa-ant", name: "Ant" },
  { id: "ankh", icon: "faAnkh", slug: "fa-ankh", name: "Ankh" },
  { id: "angles-up-down", icon: "faAnglesUpDown", slug: "fa-angles-up-down", name: "Angles Up Down" },
  { id: "angles-up", icon: "faAnglesUp", slug: "fa-angles-up", name: "Angles Up" },
  { id: "angles-right", icon: "faAnglesRight", slug: "fa-angles-right", name: "Angles Right" },
  { id: "angles-left", icon: "faAnglesLeft", slug: "fa-angles-left", name: "Angles Left" },
  { id: "angles-down", icon: "faAnglesDown", slug: "fa-angles-down", name: "Angles Down" },
  { id: "angle-right", icon: "faAngleRight", slug: "fa-angle-right", name: "Angle Right" },
  { id: "angle-left", icon: "faAngleLeft", slug: "fa-angle-left", name: "Angle Left" },
  { id: "angle-down", icon: "faAngleDown", slug: "fa-angle-down", name: "Angle Down" },
  { id: "angle-90", icon: "faAngle90", slug: "fa-angle-90", name: "Angle 90" },
  { id: "angle", icon: "faAngle", slug: "fa-angle", name: "Angle" },
  { id: "angel", icon: "faAngel", slug: "fa-angel", name: "Angel" },
  { id: "anchor-lock", icon: "faAnchorLock", slug: "fa-anchor-lock", name: "Anchor Lock" },
  { id: "anchor-circle-xmark", icon: "faAnchorCircleXmark", slug: "fa-anchor-circle-xmark", name: "Anchor Circle Xmark" },
  { id: "anchor-circle-exclamation", icon: "faAnchorCircleExclamation", slug: "fa-anchor-circle-exclamation", name: "Anchor Circle Exclamation" },
  { id: "anchor-circle-check", icon: "faAnchorCircleCheck", slug: "fa-anchor-circle-check", name: "Anchor Circle Check" },
  { id: "ampersand", icon: "faAmpersand", slug: "fa-ampersand", name: "Ampersand" },
  { id: "amp-guitar", icon: "faAmpGuitar", slug: "fa-amp-guitar", name: "Amp Guitar" },
  { id: "alt", icon: "faAlt", slug: "fa-alt", name: "Alt" },
  { id: "align-right", icon: "faAlignRight", slug: "fa-align-right", name: "Align Right" },
  { id: "align-left", icon: "faAlignLeft", slug: "fa-align-left", name: "Align Left" },
  { id: "align-justify", icon: "faAlignJustify", slug: "fa-align-justify", name: "Align Justify" },
  { id: "align-center", icon: "faAlignCenter", slug: "fa-align-center", name: "Align Center" },
  { id: "alien", icon: "faAlien", slug: "fa-alien", name: "Alien" },
  { id: "alicorn", icon: "faAlicorn", slug: "fa-alicorn", name: "Alicorn" },
  { id: "album-collection-circle-user", icon: "faAlbumCollectionCircleUser", slug: "fa-album-collection-circle-user", name: "Album Collection Circle User" },
  { id: "album-collection-circle-plus", icon: "faAlbumCollectionCirclePlus", slug: "fa-album-collection-circle-plus", name: "Album Collection Circle Plus" },
  { id: "album-collection", icon: "faAlbumCollection", slug: "fa-album-collection", name: "Album Collection" },
  { id: "album-circle-user", icon: "faAlbumCircleUser", slug: "fa-album-circle-user", name: "Album Circle User" },
  { id: "album-circle-plus", icon: "faAlbumCirclePlus", slug: "fa-album-circle-plus", name: "Album Circle Plus" },
  { id: "alarm-plus", icon: "faAlarmPlus", slug: "fa-alarm-plus", name: "Alarm Plus" },
  { id: "alarm-exclamation", icon: "faAlarmExclamation", slug: "fa-alarm-exclamation", name: "Alarm Exclamation" },
  { id: "airplay", icon: "faAirplay", slug: "fa-airplay", name: "Airplay" },
  { id: "air-conditioner", icon: "faAirConditioner", slug: "fa-air-conditioner", name: "Air Conditioner" },
  { id: "acorn", icon: "faAcorn", slug: "fa-acorn", name: "Acorn" },
  { id: "accent-grave", icon: "faAccentGrave", slug: "fa-accent-grave", name: "Accent Grave" },
  { id: "abacus", icon: "faAbacus", slug: "fa-abacus", name: "Abacus" },
  { id: "a", icon: "faA", slug: "fa-a", name: "A" },
  { id: "9", icon: "fa9", slug: "fa-9", name: "9" },
  { id: "8", icon: "fa8", slug: "fa-8", name: "8" },
  { id: "7", icon: "fa7", slug: "fa-7", name: "7" },
  { id: "6", icon: "fa6", slug: "fa-6", name: "6" },
  { id: "5", icon: "fa5", slug: "fa-5", name: "5" },
  { id: "4", icon: "fa4", slug: "fa-4", name: "4" },
  { id: "360-degrees", icon: "fa360Degrees", slug: "fa-360-degrees", name: "360 Degrees" },
  { id: "3", icon: "fa3", slug: "fa-3", name: "3" },
  { id: "2", icon: "fa2", slug: "fa-2", name: "2" },
  { id: "1", icon: "fa1", slug: "fa-1", name: "1" },
  { id: "00", icon: "fa00", slug: "fa-00", name: "00" },
  { id: "0", icon: "fa0", slug: "fa-0", name: "0" },
];

export { icons };
