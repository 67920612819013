import Icon from "app/components/atoms/icons/icon";
import OnOff from "app/components/atoms/onOff";
import Dropdown from "app/components/molecules/dropdown";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalElement from "app/components/molecules/modals/modalElement";
import Pagination from "app/components/molecules/pagination";
import { i18n } from "app/i18n";
import { useCreatePartnerMutation, useDeletePartnerMutation, useGetPartnersCountQuery, useGetPartnersQuery } from "app/stores/private";
import { setPage } from "app/stores/users";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function Partner() {
  // DISPATCH
  const dispatch = useDispatch();

  // PARAMS
  const { project } = useParams();
  const { language } = useSelector((store) => store.project);
  const { take, skip, where, orderBy, page } = useSelector((store) => store.private);

  // STATES
  const [modal, setModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // QUERY
  let { data: total, isLoading: isGettingTotal } = useGetPartnersCountQuery({ where });
  const { data, refetch, isLoading: isGetting } = useGetPartnersQuery({ take, skip, where, orderBy });

  // MUTATION
  const [createPartner, { isLoading: isCreating }] = useCreatePartnerMutation();
  const [deletePartner, { isLoading: isDeleting }] = useDeletePartnerMutation();

  // VARS
  const type = "PARTNER";
  const isLoading = isGetting || isGettingTotal || isCreating || isDeleting;

  useEffect(() => {
    return () => dispatch(setPage(0));
  }, []);

  // FUNCTIONS
  const onCreate = () => {
    setModal({
      type,
      element: null,
      isOpen: true,
      title: "Create Partner",
      project: project,
      language: language.slug,
      onClose: () => setModal(null),
      onSubmit: (data) => {
        createPartner({ ...data, language: language.slug, type }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_create"));
          else showServerError(res);
          refetch();
          total++;
        });
        setModal(null);
      },
    });
  };
  const onDeleteElement = (item) => {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: "Delete Story",
      text: "Do you really want to delete this story?",
      buttonText: "Delete",
      onClose: () => setModalConfirm(null),
      onConfirm: () => {
        deletePartner({ id: item.id }).then((res) => {
          if (res?.data) showServerSuccess(i18n("toast.success_create"));
          else showServerError(res);
          refetch();
          total--;
        });
        setModalConfirm(null);
      },
    });
  };

  return (
    <div className="max-w-7xl mx-auto pt-20">
      {/* BREADCRUMBS */}
      <div className="mb-3">
        <Link to={`/project/${project}/dashboard`} className="inline-flex items-center gap-2 text-sm text-gray-400 hover:text-primary-500 transition-all duration-200 ease-linear">
          <Icon name="chevronLeft" height="h-4" width="w-4" />
          <span>Back</span>
        </Link>
      </div>

      {/* HEADER */}
      <div className="flex items-center justify-between  mb-10">
        <h1 className="text-2xl font-bold text-slate-900">Parceiros</h1>
        {isLoading && "loading..."}
        <button type="button" onClick={onCreate} className="btn-primary-outline">
          <Icon name="plus" height="h-4" width="w-4" />
          <span>Add New</span>
        </button>
      </div>

      <div className="overflow-x-auto overflow-y-visible relative h-full pb-16">
        <table className="listTable w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
            <tr className="relative">
              <th scope="col" className="p-6">
                <span>Title</span>
              </th>
              <th scope="col" className="p-6">
                <span>Slug</span>
              </th>
              <th scope="col" className="p-6">
                <span>Score</span>
              </th>
              <th></th>
            </tr>
          </thead>
          {!!data?.length ? (
            <tbody className="relative">
              {data.map((item, key) => {
                const translation = language ? item.translations.find((e) => e.language.slug === language.slug) : null;
                return (
                  <tr key={key}>
                    <td>
                      <Link to={`${item.id}/${language?.slug}`}>
                        <OnOff active={translation?.published} />
                        <span>{translation?.title || "-"}</span>
                      </Link>
                    </td>
                    <td>
                      <span>{translation?.slug || "-"}</span>
                    </td>
                    <td>
                      <span>{translation?.score || "-"}</span>
                    </td>
                    <td>
                      <Dropdown icon="ellipsis" options={[{ title: i18n("button.delete"), onClick: () => onDeleteElement(item), icon: "delete", delete: true }]} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody className="relative">
              <tr>
                <td colSpan={2}>nothing found</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      <div className="flex justify-center mb-20">
        <Pagination total={total} perPage={take} current={page} onChange={(p) => dispatch(setPage(p))} />
      </div>

      <ModalElement {...modal} isLoading={isLoading} />
      <ModalConfirm {...modalConfirm} isLoading={isLoading} />
    </div>
  );
}
