import Button from "./button";
import Checkbox from "./checkbox";
import Checklist from "./checklist";
import Color from "./color";
import Date from "./date";
import Dropzone from "./dropzone";
import Editor from "./editor";
import Gallery from "./gallery";
import Group from "./group";
import List from "./list";
import Multimedia from "./multimedia";
import Password from "./password";
import Radio from "./radio";
import Select from "./select";
import Slug from "./slug";
import Text from "./text";
import Textarea from "./textarea";
import Toggle from "./toggle";
import Numeric from "./numeric";
import Range from "./range";
import Clock from "./clock";

// ADVANCED
import SelectPost from "./advanced/selectPost";
import SelectForm from "./advanced/selectForm";
import SelectCategory from "./advanced/selectCategory";
import DynamicContent from "./advanced/dynamicContent";
import DynamicCategory from "./advanced/dynamicCategory";

// SUPER
import Map from "./map";
import SelectIcon from "./advanced/selectIcon";
import SuperDynamic from "./advanced/superDynamic";
import SelectElement from "./advanced/selectElement";

export default {
  Text,
  Password,
  Toggle,
  List,
  Select,
  Checkbox,
  Multimedia,
  Dropzone,
  Group,
  Checklist,
  Editor,
  Date,
  Color,
  Textarea,
  Slug,
  Radio,
  Button,
  Gallery,
  SelectPost,
  SelectForm,
  DynamicContent,
  Numeric,
  Range,
  DynamicCategory,
  SelectIcon,
  Clock,
  SuperDynamic,
  SelectElement,
  Map,
  SelectCategory,
};
