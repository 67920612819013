import React from "react";

export default function Quote(props) {
  const { text, author, options } = props;

  const styles = {
    box: "flex justify-end w-full pr-[10%]",
  };

  if (options?.centered) styles.box += " justify-center pl-[10%]";

  return (
    <div className={styles.box}>
      <div className="w-full max-w-[40%] my-3">
        {text ? (
          <h1 className="text-2xl font-normal" dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          <>
            <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
            <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
            <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
            <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
            <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
          </>
        )}
        {author ? <p className="text-xs" dangerouslySetInnerHTML={{ __html: author }} /> : <div className="h-1.5 bg-gray-200 rounded-full max-w-[140px] mb-1" />}
      </div>
    </div>
  );
}
