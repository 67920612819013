import Icon from "app/components/atoms/icons/icon";
import Dropdown from "app/components/molecules/dropdown";
import { useGetAccountsQuery } from "app/stores/private";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function Account() {
  const { project } = useParams();

  const { take, skip, where, orderBy } = useSelector((store) => store.private);

  const { data } = useGetAccountsQuery({ take, skip, where, orderBy });

  return (
    <div className="max-w-7xl mx-auto pt-20">
      {/* BREADCRUMBS */}
      <div className="mb-3">
        <Link to={`/project/${project}/dashboard`} className="inline-flex items-center gap-2 text-sm text-gray-400 hover:text-primary-500 transition-all duration-200 ease-linear">
          <Icon name="chevronLeft" height="h-4" width="w-4" />
          <span>Back</span>
        </Link>
      </div>

      {/* HEADER */}
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold text-slate-900 mb-10">Contas</h1>
      </div>

      <div>
        <div className="overflow-x-auto overflow-y-visible relative h-full pb-16">
          <table className="listTable w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="relative text-xs text-gray-700 before:bg-gray-200 before:absolute before:inset-0 before:bottom-[10px] before:rounded-lg after:leading-[10px] after:-indent-96 after:block after:content-['']">
              <tr className="relative">
                <th scope="col" className="p-6">
                  <span>Name</span>
                </th>
                <th scope="col" className="p-6">
                  <span>Email</span>
                </th>
                <th></th>
              </tr>
            </thead>
            {!!data?.length ? (
              <tbody className="relative">
                {data.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <Link to={item.id}>
                        <span>{item.name}</span>
                      </Link>
                    </td>
                    <td>
                      <span>{item.email}</span>
                    </td>
                    <td>
                      <Dropdown icon="ellipsis" options={[]} />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody className="relative">
                <tr>
                  <td colSpan={2}>nothing found</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
