import Form from "app/components/organisms/form";
import { useGetElementsQuery } from "app/stores/inputs";
import React from "react";
import { useSelector } from "react-redux";

const defaultTypes = [
  { label: "Offer", value: "OFFER" },
  { label: "Experience", value: "EXPERIENCE" },
  { label: "Story", value: "STORY" },
  { label: "Partner", value: "PARTNER" },
];

export default function SelectElement(props) {
  const { value, types = defaultTypes, onChange } = props;

  // PARAMS
  const { language } = useSelector((store) => store.project);

  const dataType = value?.type?.value || undefined;

  const take = 12;
  const skip = 0;
  const where = { type: dataType };
  const orderBy = { id: "asc" };

  const { data } = useGetElementsQuery({ take, skip, where, orderBy }, { skip: !dataType });

  const options = data?.length ? data.map((e) => ({ value: e.id, label: e.translations.find((e) => e.language.slug === language.slug).title })) : null;

  const structure = [
    { type: "Select", key: "type", title: "Type", options: types },
    { type: "Select", key: "element", title: "Element", mode: "search", disabled: !options?.length, options: options || [] },
  ];

  return (
    <div className="input-select-flex">
      <Form structure={structure} value={value} onChange={onChange} />
    </div>
  );
}
