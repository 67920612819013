import React from "react";
import Image from "./image";
import LongTextImage from "./longtextimage";
import Quote from "./quote";
import TextElement from "./textelement";
import LongText from "./longtext";
import Gallery from "./gallery";
import Video from "./video";

export default function Elements(props) {
  if (props.type === "Image") return <Image title={props?.data?.title} caption={props?.data?.caption} image={props?.data?.image} options={props?.data?.options} />;
  if (props.type === "TextElement") return <TextElement text={props?.data?.text} element={props?.data?.element} options={props?.data?.options} />;
  if (props.type === "LongTextImage")
    return (
      <LongTextImage
        title={props?.data?.title}
        text={props?.data?.text}
        image={props?.data?.image}
        caption={props?.data?.caption}
        imagetitle={props?.data?.imagetitle}
        options={props?.data?.options}
      />
    );
  if (props.type === "Quote") return <Quote text={props?.data?.text} author={props?.data?.author} options={props?.data?.options} />;
  if (props.type === "LongText") return <LongText text={props?.data?.text} options={props?.data?.options} />;
  if (props.type === "Gallery") return <Gallery images={props?.data?.images} caption={props?.data?.caption} />;
  if (props.type === "Video") return <Video title={props?.data?.title} caption={props?.data?.caption} video={props?.data?.video} options={props?.data?.options} />;

  return <div />;
}
