import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import Elements from "./sections";
import Icon from "app/components/atoms/icons/iconFA";

export const Item = memo(function Item(props) {
  const { id, item, accept, moveCard, findCard, onEdit, onDelete } = props;

  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: accept,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );
  const [, drop] = useDrop(
    () => ({
      accept: accept,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const opacity = isDragging ? 0 : 1;

  return (
    <div style={{ opacity }} ref={(node) => drag(drop(node))}>
      <div className="py-3 px-6 border border-dashed rounded-md group relative bg-gray-50">
        <Elements type={item.key} data={item.data} />

        <button type="button" onClick={onEdit} className="absolute top-2 right-2 p-2 bg-gray-600 hover:bg-black text-white rounded-full opacity-0 group-hover:opacity-100">
          <Icon name="faPencil" height={4} />
        </button>

        <button type="button" onClick={onDelete} className="absolute top-14 right-2 p-2 bg-gray-400 hover:bg-red-600 text-white rounded-full opacity-0 group-hover:opacity-100">
          <Icon name="faTrash" height={4} />
        </button>
      </div>
    </div>
  );
});
