import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import { i18n } from "app/i18n";
import React, { useEffect, useRef, useState } from "react";
import { icons } from "./constants";
import Text from "app/components/atoms/inputs/text";
import Icon from "app/components/atoms/icons/iconFA";

export default function ModalIcon(props) {
  const { selected, isOpen, isLoading, buttonText = i18n("button.select"), buttonCleanText = i18n("button.clear"), onSubmit, onClose } = props;

  const containerRef = useRef(null);

  const [active, setActive] = useState(null);
  const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    if (isOpen && selected) setActive(selected);
    else if (active) setActive(null);
  }, [isOpen, selected]);

  const onFilter = (ev) => {
    setFilterText(ev);
  };

  const onClickSubmit = () => {
    onSubmit(active);
    onClose();
  };

  const onClickDelete = () => {
    onSubmit(null);
    onClose();
  };

  const smoothScroll = (element, start, end, duration) => {
    let startTime = null;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOutCubic = (t) => --t * t * t + 1; // Easing function
      element.scrollTop = start + (end - start) * easeInOutCubic(progress);
      if (elapsed < duration) {
        // Continue the animation
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  };

  const scrollToActive = () => {
    const scrollContainer = containerRef.current;

    const activeElement = scrollContainer.querySelector("#active");
    if (activeElement) {
      const start = scrollContainer.scrollTop;
      const end = activeElement.offsetTop - scrollContainer.offsetTop - 60;
      smoothScroll(scrollContainer, start, end, 500);
    }
  };

  const scrollToTop = () => {
    const scrollContainer = containerRef.current;
    const start = scrollContainer.scrollTop;
    const end = 0;
    smoothScroll(scrollContainer, start, end, 500);
  };

  return (
    <Struture isOpen={isOpen} onClose={onClose}>
      <Header title={i18n("label.select_icon")} onClose={onClose} />
      <Content noPad>
        <div className="h-[60vh] px-12 pb-10 overflow-auto relative" ref={containerRef}>
          <div className="sticky top-0 z-10 pt-4 pb-1 bg-white border-b">
            <Text title="Filter by:" value={filterText} onChange={onFilter} />
            <div className="flex justify-between text-gray-400">
              <div className="flex items-center text-xs gap-1">
                <span className="cursor-pointer ">{i18n("label.scroll_to")}</span>
                <u onClick={scrollToTop} className="text-gray-600 lowercase cursor-pointer">
                  {i18n("label.top")}
                </u>

                {active && (
                  <>
                    <span>{i18n("label.or")}</span>
                    <u onClick={scrollToActive} className="text-gray-600 lowercase cursor-pointer">
                      {i18n("label.active")}
                    </u>
                  </>
                )}
              </div>
              <div className="flex items-center text-xs gap-1">
                <span>
                  {
                    icons.filter((e) => {
                      if (!filterText) return true;
                      return e.name.toLowerCase().includes(filterText.toLowerCase());
                    }).length
                  }
                </span>
                <span>items</span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 mt-10">
            {icons
              .filter((e) => {
                if (!filterText) return true;

                return e.name.toLowerCase().includes(filterText.toLowerCase());
              })
              .map((item, index) => (
                <button
                  key={index}
                  type="button"
                  aria-checked={item.icon === active}
                  onClick={() => setActive(item.icon)}
                  id={item.icon === active ? "active" : ""}
                  className="flex border rounded-md col-span-1 p-2 items-center gap-4 aria-checked:shadow-lg aria-checked:bg-black aria-checked:text-white aria-checked:border-black"
                >
                  <Icon name={item.icon} width={5} height={5} />
                  <span className="text-xs text-left">{item.name}</span>
                </button>
              ))}
          </div>
        </div>
      </Content>
      <Footer
        loading={isLoading}
        cancel={{ text: i18n("button.close"), onClick: onClose }}
        clean={{ text: buttonCleanText, disabled: isLoading, onClick: onClickDelete }}
        submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }}
      />
    </Struture>
  );
}
