import { Content, Footer, Header, Struture } from "app/components/atoms/modal";
import { i18n } from "app/i18n";
import { layout, sections } from "./constants";
import { cleanStructure, getRandomString } from "app/utils/content";
import Form from "app/components/organisms/form";
import { useEffect, useState } from "react";

export default function ModalInputDynamic(props) {
  const { isOpen = false, item, isLoading, onSubmit, onClose = () => {} } = props;

  const [value, setValue] = useState(null);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    if (item) buildStructure();
    else setStructure(null);
  }, [item]);

  const buildStructure = () => {
    setValue({ ...item.data });

    const inputs = sections.find((e) => e.key === item.key).inputs;
    const options = sections.find((e) => e.key === item.key).options;

    let tempStructure = cleanStructure(inputs);
    if (options?.length) tempStructure.push({ type: "Group", title: i18n("label.options"), key: "options", children: options });

    setStructure([...tempStructure]);
  };
  const onSelect = (section) => {
    onSubmit({ key: section.key, id: getRandomString(10) });
  };

  const onClickSubmit = () => {
    onSubmit(value);
  };

  if (item) {
    return (
      <Struture isOpen={isOpen} onClose={onClose}>
        <Header title={i18n("label.edit_section")} onClose={onClose} />
        <Content>
          <div>
            <Form value={value} onChange={setValue} structure={structure} disabled={isLoading} />
          </div>
        </Content>
        <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: i18n("button.save"), disabled: isLoading, onClick: onClickSubmit }} />
      </Struture>
    );
  }

  return (
    <Struture isOpen={isOpen} onClose={onClose} rightSide={isOpen}>
      <Header title={i18n("label.add_section")} onClose={onClose} />
      <Content>
        <div>
          {sections.map((section, index) => (
            <button type="button" key={index} onClick={() => onSelect(section)} className="mb-4 w-full group">
              <h2 className="text-sm text-left mb-1">{section.title}</h2>
              <div className="relative p-2 border border-dashed rounded-md transition-all duration-500 ease-linear group-hover:bg-gray-400">
                {layout[section.key]}
                <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-xl opacity-0 transition-all duration-500 ease-linear group-hover:opacity-100">
                  {i18n("label.select")}
                </span>
              </div>
            </button>
          ))}
        </div>
      </Content>
      {/* <Footer loading={isLoading} cancel={{ text: i18n("button.close"), onClick: onClose }} submit={{ text: buttonText, disabled: isLoading, onClick: onClickSubmit }} /> */}
    </Struture>
  );
}
