import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/iconFA";
import ModalInputDynamic from "app/components/molecules/modals/modalInputDynamic";
import { i18n } from "app/i18n";
import { arraysAreFullEqual } from "app/utils/validators/dataValidator";
import update from "immutability-helper";
import { memo, useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { Item } from "./item";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";

const Input = memo(function SuperDynamic(props) {
  const { title, value, error, description, errorMessage, isRequired, disabled, onChange } = props;

  let styles = {
    div: "block relative mb-3",
    title: "block text-sm font-medium text-slate-700 ",
    description: "block text-xs font-light text-slate-400 -mt-0.5 mb-1",
  };

  if (error) styles.title += " text-red-600";

  const accept = "ITEM";

  const [modal, setModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  const onClickToAdd = () => {
    setModal({
      isOpen: true,
      onSubmit: (data) => {
        let temp = value ? [...value] : [];
        temp.push(data);
        onChange([...temp]);
        setModal(null);
      },
      onClose: () => setModal(null),
    });
  };

  const onEdit = (item) => {
    setModal({
      item,
      isOpen: true,
      onSubmit: (data) => {
        let temp = value ? [...value] : [];
        const index = temp.findIndex((e) => e.id === item.id);
        if (index !== -1) temp[index] = { ...temp[index], data: data };
        onChange([...temp]);
        setModal(null);
      },
      onClose: () => setModal(null),
    });
  };

  const onDelete = (item) => {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      title: "Eliminar secção",
      text: "Irá eliminar esta secção. Após a publicação será irreversível. Deseja continuar?",
      buttonText: "Eliminar",
      onConfirm: () => {
        let temp = value ? [...value] : [];
        const index = temp.findIndex((e) => e.id === item.id);
        if (index !== -1) temp.splice(index, 1);
        onChange([...temp]);
        setModalConfirm(null);
      },
      onClose: () => setModalConfirm(null),
    });
  };

  const [, drop] = useDrop(() => ({ accept }));

  const findCard = useCallback(
    (id) => {
      const item = value.filter((c) => `${c.id}` === id)[0];
      return { item, index: value.indexOf(item) };
    },
    [value]
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { item, index } = findCard(id);

      const cards = [...value];

      const newValue = update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, item],
        ],
      });

      if (!arraysAreFullEqual(value, newValue)) {
        setTimeout(() => {
          onChange(newValue);
        }, 600);
      }
    },
    [findCard, value]
  );

  return (
    <div className={styles.div}>
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className="">
        <div ref={drop} className="relative w-full">
          {!!value?.length && (
            <div className="space-y-2">
              {value.map((item) => (
                <Item key={item?.id} id={item?.id} item={item} accept={accept} moveCard={moveCard} findCard={findCard} onEdit={() => onEdit(item)} onDelete={() => onDelete(item)} />
              ))}
            </div>
          )}
        </div>
        <div className="relative w-full mt-2">
          <button
            type="button"
            onClick={onClickToAdd}
            className="border border-dashed border-gray-300 w-full flex justify-center items-center gap-3 rounded-lg p-4 transition-all duration-500 hover:border-gray-600"
          >
            <Icon name="faPlus" height={4} />
            <span>{i18n("button.add")}</span>
          </button>
        </div>
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}

      <ModalInputDynamic {...modal} />
      <ModalConfirm {...modalConfirm} />
    </div>
  );
});

export default Input;
