import { i18n } from "app/i18n";

const sections = [
  {
    title: "Long Text & Image",
    key: "LongTextImage",
    inputs: [
      {
        type: "Editor",
        key: "text",
        title: i18n("label.text"),
        modules: ["link", "bold", "underline", "italic", "blocks"],
        formats: ["=h1", "=h2", "=h3", "=p"],
      },
      {
        type: "Multimedia",
        key: "image",
        title: i18n("label.image"),
        formats: ["IMAGE"],
      },
      {
        type: "Text",
        key: "imagetitle",
        title: i18n("label.imagetitle"),
      },
      {
        type: "Text",
        key: "caption",
        title: i18n("label.caption"),
      },
    ],
    options: [
      {
        type: "Toggle",
        key: "inverted",
        title: i18n("label.inverted"),
      },
    ],
  },
  {
    title: "Quote",
    key: "Quote",
    inputs: [
      {
        type: "Editor",
        key: "text",
        title: i18n("label.text"),
        modules: ["link", "bold", "underline", "italic", "blocks"],
        formats: ["=h1", "=h2", "=h3", "=p"],
      },
      {
        type: "Text",
        key: "author",
        title: i18n("label.author"),
      },
    ],
    options: [
      {
        type: "Toggle",
        key: "centered",
        title: i18n("label.centered"),
      },
    ],
  },
  {
    title: "Image",
    key: "Image",
    inputs: [
      {
        type: "Multimedia",
        key: "image",
        title: i18n("label.image"),
        formats: ["IMAGE"],
      },
      {
        type: "Text",
        key: "title",
        title: i18n("label.title"),
      },
      {
        type: "Text",
        key: "caption",
        title: i18n("label.caption"),
      },
    ],
    options: [
      {
        type: "Toggle",
        key: "roundedCorners",
        title: i18n("label.rounded_corners"),
      },
      {
        type: "Select",
        key: "size",
        title: i18n("label.size"),
        options: [
          { value: "container", label: "Container" },
          { value: "shortcontainer", label: "Short Container" },
          { value: "fullwidth", label: "Full Width" },
        ],
      },
    ],
  },
  {
    title: "Gallery",
    key: "Gallery",
    inputs: [
      {
        type: "Gallery",
        key: "images",
        title: i18n("label.image"),
        formats: ["IMAGE"],
      },
      {
        type: "Text",
        key: "caption",
        title: i18n("label.caption"),
      },
    ],
  },
  {
    title: "Text & Element",
    key: "TextElement",
    inputs: [
      {
        type: "Editor",
        key: "text",
        title: i18n("label.text"),
        modules: ["link", "bold", "underline", "italic", "blocks"],
        formats: ["=h1", "=h2", "=h3", "=p"],
      },
      {
        type: "SelectElement",
        key: "element",
        title: i18n("label.element"),
        options: [],
      },
    ],
    options: [
      {
        type: "Toggle",
        key: "inverted",
        title: i18n("label.inverted"),
      },
    ],
  },
  {
    title: "LongText",
    key: "LongText",
    inputs: [
      {
        type: "Editor",
        key: "text",
        title: i18n("label.text"),
        modules: ["link", "bold", "underline", "italic", "blocks"],
        formats: ["=h1", "=h2", "=h3", "=p"],
      },
    ],
    options: [
      {
        type: "Select",
        key: "alignment",
        title: i18n("label.alignment"),
        options: [
          { value: "left", label: "Left" },
          { value: "center", label: "Center" },
          { value: "right", label: "Right" },
          { value: "full", label: "Full Width" },
        ],
      },
    ],
  },
  {
    title: "Video",
    key: "Video",
    inputs: [
      {
        type: "Multimedia",
        key: "video",
        title: i18n("label.video"),
        formats: ["VIDEO", "EXTERNAL_VIDEO"],
      },
      {
        type: "Text",
        key: "title",
        title: i18n("label.title"),
      },
      {
        type: "Text",
        key: "caption",
        title: i18n("label.caption"),
      },
    ],
    options: [
      {
        type: "Select",
        key: "size",
        title: i18n("label.size"),
        options: [
          { value: "container", label: "Container" },
          { value: "shortcontainer", label: "Short Container" },
          { value: "fullwidth", label: "Full Width" },
        ],
      },
    ],
  },
];

const layout = {
  LongTextImage: (
    <div className="flex w-full">
      <div className="w-2/3 my-3 pr-5">
        <div className="w-full h-full">
          <div className="h-2.5 bg-gray-200 rounded-full w-full mb-2" />
          <div className="h-2.5 bg-gray-200 rounded-full w-full mb-2" />
          <div className="h-2.5 bg-gray-200 rounded-full max-w-[130px] mb-4" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[180px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[170px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[180px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[170px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[140px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[170px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[140px] mb-1" />
        </div>
      </div>
      <div className="w-1/3 my-3">
        <div className="flex items-center justify-center w-full h-28 bg-gray-200 rounded mb-2">
          <svg className="w-8 h-8 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/3000/svg" fill="currentColor" viewBox="0 0 20 18">
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
        <div className="h-1 bg-gray-200 rounded-full max-w-[170px] mb-1" />
        <div className="h-1 bg-gray-200 rounded-full max-w-[170px] mb-1" />
      </div>
    </div>
  ),
  LongText: (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-[180px] my-3">
        <div className="h-1.5 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full w-full" />
      </div>
    </div>
  ),
  Quote: (
    <div className="flex justify-end w-full">
      <div className="w-full max-w-[180px] my-3 mr-6">
        <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-3 bg-gray-200 rounded-full w-full mb-2" />
        <div className="h-1.5 bg-gray-200 rounded-full max-w-[140px] mb-1" />
      </div>
    </div>
  ),
  Image: (
    <div className="flex justify-center w-full">
      <div className="flex items-center justify-center w-full h-28 bg-gray-200 rounded my-3">
        <svg className="w-8 h-8 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/3000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
    </div>
  ),
  TextElement: (
    <div className="flex w-full">
      <div className="w-2/3 my-3 pr-5">
        <div className="w-full h-full">
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[180px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[170px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[180px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[170px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[140px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[170px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[140px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[170px] mb-2" />
          <div className="h-1.5 bg-gray-200 rounded-full max-w-[140px]" />
        </div>
      </div>
      <div className="w-1/3 my-3">
        <div className="relative flex items-center justify-center w-full h-24 bg-gray-200 rounded mb-2">
          <div className="h-1 bg-gray-400 rounded-full w-6 absolute z-10 top-2 left-2" />
          <div className="h-2.5 bg-gray-400 rounded-full w-8 absolute z-10 bottom-1.5 left-2" />
          <div className="h-2.5 bg-gray-400 rounded-full w-3 absolute z-10 bottom-1.5 left-10 ml-1" />

          <svg className="w-8 h-8 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/3000/svg" fill="currentColor" viewBox="0 0 20 18">
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
        <div className="h-1 bg-gray-200 rounded-full max-w-[60px] mb-1" />
        <div className="h-1 bg-gray-200 rounded-full max-w-[40px]" />
      </div>
    </div>
  ),
  Gallery: (
    <div className="flex justify-center w-full">
      <div className="flex items-center justify-center w-full h-28 bg-gray-200 rounded my-3">
        <svg className="w-8 h-8 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/3000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
    </div>
  ),
  Video: (
    <div className="flex justify-center w-full">
      <div className="flex items-center justify-center w-full h-28 bg-gray-200 rounded my-3">
        <svg className="w-8 h-8 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/3000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
        </svg>
      </div>
    </div>
  ),
};

export { layout, sections };
