import React from "react";
import ReactPaginate from "react-paginate";

export default function Pagination(props) {
  const { total = 0, perPage, current, onChange } = props;

  const pages = Math.ceil(total / perPage) || 0;

  return (
    <ReactPaginate
      nextLabel=">"
      breakLabel="..."
      previousLabel="<"
      pageCount={pages}
      pageRangeDisplayed={3}
      className="posts-pagination"
      selectedPageRel={current + 1}
      onPageChange={(e) => {
        if (typeof onChange !== "undefined") onChange(e?.selected);
      }}
    />
  );
}
