import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import SelectBasic from "app/components/molecules/selects/basic";

export default function Clock(props) {
  const { title, value = 0, readOnly = false, disabled, error, errorMessage, description, isRequired, onChange } = props;

  let styles = {
    div: "block relative mb-3",
    input:
      "!focus-visible:border-none !outline-none !ring-0 disabled:text-gray-400 disabled:pointer-events-none bg-gray-50 border border-gray-300 focus:border-blue-700 text-gray-900 text-sm rounded-lg block w-full p-2.5 trasition-all duration-300 ease-in-out !ring-0 !outline-none hover:border-gray-400 read-only:text-gray-400 read-only:bg-gray-100 disabled:bg-gray-100",
    description: "block text-xs font-light text-slate-400 -mt-0.5 mb-1",
    title: "block text-sm font-medium text-slate-700 ",
  };

  if (error) {
    styles.input += " border-red-600 hover:border-red-600";
    styles.title += " text-red-600";
  }

  const handleChange = (event, type) => {
    const val = parseInt(event.value, 10);

    let hours = value ? Math.floor(value / 60) : 0;
    let minutes = value ? value % 60 : 0;

    if (type === "hours") hours = val;
    else minutes = val;

    onChange(hours * 60 + minutes);
  };

  const hours = value ? Math.floor(value / 60) : 0;
  const minutes = value ? value % 60 : 0;
  const formatNumber = (number, post) => `${number.toString().padStart(2, "0")}${post}`;

  return (
    <div className={styles.div}>
      <div className="flex items-end justify-between">
        <div>
          {title && <label className={styles.title}>{title}</label>}
          {description && <span className={styles.description}>{description}</span>}
        </div>
        {isRequired && <ExclamationTriangleIcon className={`h-4 w-4 mb-1 ${error ? "text-red-600" : "text-slate-700"}`} />}
      </div>
      <div className="flex items-center relative gap-4">
        <div className="flex-1">
          <SelectBasic
            value={hours}
            placement="top"
            disabled={readOnly || disabled}
            label={formatNumber(hours, "H")}
            onChange={(e) => handleChange(e, "hours")}
            options={Array.from({ length: 24 }).map((_, i) => ({ value: i, label: i }))}
          />
        </div>
        <div className="flex-1">
          <SelectBasic
            value={minutes}
            placement="top"
            disabled={readOnly || disabled}
            label={formatNumber(minutes, "M")}
            onChange={(e) => handleChange(e, "minutes")}
            options={Array.from({ length: 60 }).map((_, i) => ({ value: i, label: i }))}
          />
        </div>
      </div>
      {error && <p className="mt-1 text-xs text-red-600">{errorMessage}</p>}
    </div>
  );
}

// EDITOR
// --- https://tailwindcomponents.com/component/wysiwyg
// --- https://editor-tw.fouita.com/?ref=madewithsvelte.com
// https://www.npmjs.com/package/react-draft-wysiwyg
