import validator from "validator";
import { i18n } from "app/i18n";

export const components = [
  {
    type: "Text",
    key: "title",
    title: i18n("label.title"),
    rules: [
      {
        method: validator.isEmpty,
        validWhen: false,
        message: i18n("input.required_field"),
      },
    ],
  },
  {
    type: "Textarea",
    key: "description",
    title: i18n("label.description"),
  },
  {
    type: "Gallery",
    key: "gallery",
    title: i18n("label.gallery"),
  },
  {
    type: "Toggle",
    key: "status",
    title: i18n("label.public"),
  },
  {
    type: "List",
    key: "elements",
    title: i18n("label.elements"),
    components: [
      {
        type: "SelectElement",
        key: "element",
        types: [
          { label: "Experience", value: "EXPERIENCE" },
          { label: "Story", value: "STORY" },
        ],
        title: i18n("label.element"),
      },
    ],
  },
];
